import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetLeaguesApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    {
      message: string;
      id: Number;
    },
    Error,
    {
      league_id: number;
      game_id: string;
      player_id: number;
      opponent_id: number;
      result: string;
    }
  >(
    async payload => {
      const league_id = payload.league_id;
      delete payload?.league_id;
      const response = await ChessRestClient.post(
        `leagues/${league_id}/create_match`,
        payload,
      );
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        } else if (handleOnError) {
          handleOnError(data);
        }
      },
    },
  );

  return {
    ...mutation,
  };
}
