import CCColors from '@/Utils/CCColors';
import {isPhone} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {},
  dummySpacer: {
    width: 0,
    height: 32,
  },
  checkStyle: {
    width: 32,
    height: 32,
    backgroundColor: `${CCColors.Green}16`,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  crossStyle: {
    width: 32,
    height: 32,
    backgroundColor: `${CCColors.Red}16`,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellStyle: {
    width: '100%',
    height: '100%',
    borderWidth: 1,
    borderColor: '#26232220',
    margin: 0,
    padding: 10,
  },
  buttonCell: {
    width: '100%',
    borderWidth: 1,
    borderColor: '#26232220',
    margin: 0,
    padding: 10,
  },
  table: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 8,
    backgroundColor: CCColors.White,
    borderWidth: 0,
  },
  faq: {
    width: isPhone ? '95%' : '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bestSellerWrapper: {
    backgroundColor: CCColors.Orange,
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 4,
  },
  activeWrapper: {
    backgroundColor: CCColors.Primary.Light,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
  },
  bestSellerText: {
    color: CCColors.White,
    fontSize: 12,
    fontWeight: '500',
  },
  activeText: {
    color: CCColors.Primary.Dark,
    fontSize: 12,
    fontWeight: '600',
  },
  containerMobile: {
    width: '100%',
    margin: 0,
    paddingVertical: 20,
  },
  tooltip: {
    width: 32,
    height: 32,
    borderRadius: 25,
  },
  icon: {
    width: 22,
    height: 22,
    justifyContent: 'flex-end',
  },
  headerMobile: {
    flex: 1,
    borderWidth: 1,
    borderColor: `${CCColors.TextColor.Dark}20`,
    height: 100,
  },
  shadow: {
    width: '100%',
    borderWidth: 1,
    borderColor: `${CCColors.TextColor.Dark}20`,
    margin: 0,
    shadowColor: '#4D3F37',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 3,
  },
  Novice: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: `${CCColors.TextColor.Dark}20`,
    paddingVertical: 20,
    borderTopWidth: 1,
    borderTopColor: `${CCColors.TextColor.Dark}20`,
    justifyContent: 'center',
    alignItems: 'center',
  },
  aspLearner: {
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: `${CCColors.TextColor.Dark}20`,

    paddingVertical: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  aspChampion: {
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: `${CCColors.TextColor.Dark}20`,
    borderLeftWidth: 1,
    borderLeftColor: `${CCColors.TextColor.Dark}20`,
    paddingVertical: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
