import React, {useState, useEffect, useMemo} from 'react';
import {View, TouchableOpacity, Dimensions, Image} from 'react-native';
import Box from '@/Atoms/Box';
import {styles} from './styles';
import CCColors from '@/Utils/CCColors';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import {FontSizes, WINDOW_WIDTH} from '@/Utils/Dimensions';
import Modal from 'react-native-modal';
import Ionicons from 'react-native-vector-icons/Ionicons';
import streakDetailsBg from '@/Assets/Dashboard/StreakDropdownBg/streak_dropdown_bg.svg';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import {subtractDaysFromDate} from '@/Utils/CommonUtils';

const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const getWeekDays = (lastStreakDate, count) => {
  const today = new Date();
  // count = 0;
  // lastStreakDate = null;
  lastStreakDate = new Date(lastStreakDate);
  const lastStreakDateIndex = lastStreakDate.getDay();
  const todayIndex = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
  // If no startDayInWeek is provided, default to today's day
  if (lastStreakDate === null) {
    lastStreakDate = todayIndex;
  }
  // l
  let streakStartDate = subtractDaysFromDate(lastStreakDate, count);
  let startIndex = 0;
  if (lastStreakDateIndex === todayIndex) {
    streakStartDate = subtractDaysFromDate(lastStreakDate, count - 1);
    startIndex = streakStartDate.getDay();
  } else {
    startIndex = streakStartDate.getDay() + 1;
  }
  // Adjust the day to be Monday = 0, Sunday = 6
  // debugger;
  // startIndex = (startDayInWeek + 6) % 7;

  // Rotate the days of the week based on the start day
  const rotatedDays = [
    ...daysOfWeek.slice(startIndex),
    ...daysOfWeek.slice(0, startIndex),
  ];

  return rotatedDays.map((day, index) => {
    const actualDayIndex = (startIndex + index) % 7;
    // Highlight the first day in red (this is the day the streak started)
    if (index === 0 && count > 0) {
      return {title: day, color: CCColors?.Red};
    }

    // If today and the streak is still active, mark today as White
    else if (
      actualDayIndex === todayIndex &&
      lastStreakDateIndex !== todayIndex
    ) {
      return {title: day, color: CCColors?.White};
    }

    // For all streak days, mark them as Aqua
    else if (index < count) {
      return {title: day, color: CCColors?.Primary?.Aqua};
    }

    // All other days will be BrownShade2
    return {title: day, color: CCColors?.BrownShade2};
  });
};

function Streak() {
  const {streakConfig, streakDetailsSummary} = useLoggedInUserStore();
  const {isMobileOrTab} = useMediaQuery();
  const [streakDays, setStreakDays] = useState({count: 4, startDayInWeek: 7});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const navigation = useNavigation();
  const handleGo = (title: string) => {
    if (title?.toLowerCase()?.includes('positions')) {
      navigation.navigate(ScreenName?.Puzzles);
    } else if (title?.toLowerCase()?.includes('games')) {
      navigation.navigate(ScreenName?.Games);
    } else if (title?.toLowerCase()?.includes('calculation')) {
      navigation.navigate(ScreenName?.Visualisation, {selected: 'calculation'});
    }
  };
  useEffect(() => {
    setWeekDays(getWeekDays(streakDays.startDayInWeek, streakDays.count));
  }, [streakDays]);

  const streakDetails = useMemo(() => {
    return streakConfig?.map(streakItem => ({
      id: streakItem?.id,
      title: streakItem?.name?.includes('Review')
        ? streakItem?.name
        : `Solve ${streakItem?.name}`,
      description: streakItem?.description,
      subTitle: `${
        streakItem?.user_count >= streakItem?.target_count
          ? streakItem?.target_count
          : streakItem?.user_count
      }/${streakItem?.target_count} ${
        streakItem?.name?.includes('Review') ? 'review' : 'solved'
      }`,
      isCompleted: streakItem?.user_count >= streakItem?.target_count,
    }));
  }, [streakConfig]);

  useEffect(() => {
    if (streakDetailsSummary) {
      setStreakDays({
        count: streakDetailsSummary?.streak_count,
        startDayInWeek: streakDetailsSummary?.last_streak_date || 0,
      });
    }
  }, [streakDetailsSummary]);

  const toggleModal = e => {
    setIsModalVisible(!isModalVisible);
    e.preventDefault();
  };

  return (
    <Box
      style={[
        styles.streakContainer,
        isMobileOrTab && styles.streakContainerMobile,
      ]}>
      <TouchableOpacity onPress={toggleModal}>
        <Box
          style={[
            styles.streakWrapper,
            isMobileOrTab && styles.streakWrapperMobile,
          ]}>
          <MaterialIcon
            name={'lightning-bolt-circle'}
            size={21}
            color={CCColors?.Red}
          />
          {!isMobileOrTab ? (
            <>
              <Spacer spacing={5} horizontal />
              <CCText
                fontSize={FontSizes[12]}
                fontWeight="medium"
                style={styles.textWrapper}>
                {streakDays.count} days streak
              </CCText>
            </>
          ) : (
            <>
              <Spacer spacing={5} horizontal />
              <CCText fontWeight="medium" style={styles.textWrapper}>
                {streakDays.count}
              </CCText>
            </>
          )}
        </Box>
      </TouchableOpacity>
      <Modal
        isVisible={isModalVisible}
        animationIn={'zoomInDown'}
        animationOut={'zoomOutDown'}
        onBackdropPress={toggleModal}
        backdropOpacity={0}
        style={[
          styles.modal,
          {
            top: 73,
            right: WINDOW_WIDTH / 5,
          },
        ]}
        useNativeDriver>
        <Box style={styles.streakDetailsContainer}>
          <Box style={styles.streakDetailsTop}>
            <Image style={styles.bgImage} source={streakDetailsBg} />
            <View style={styles.streakDetailsContainerTitle}>
              <MaterialIcon
                name={'lightning-bolt'}
                size={30}
                color={CCColors?.Red}
              />
              <CCText
                color={CCColors?.White}
                fontWeight="medium"
                style={styles.textWrapper}>
                {streakDays.count}
              </CCText>
              <Spacer spacing={2} horizontal />
              <CCText color={CCColors?.White} style={styles.textWrapper}>
                days streak
              </CCText>
            </View>
            <View style={styles.weekDaysContainer}>
              {weekDays?.map((day, index) => (
                <View
                  key={index}
                  style={[styles.weekDay, {backgroundColor: day?.color}]}>
                  <CCText
                    color={
                      day.color?.includes('FFFFFF')
                        ? CCColors?.TextColor?.Primary
                        : CCColors?.White
                    }>
                    {day.title}
                  </CCText>
                </View>
              ))}
            </View>
            <CCText color={CCColors?.White} style={styles.textWrapper}>
              Complete the below activities to continue your streak{' '}
            </CCText>
          </Box>
          <Box style={styles.streakDetailsBottom}>
            {streakDetails?.map(eachStreak => (
              <React.Fragment key={eachStreak.id}>
                <View style={styles.eachStreakDetailsWrapper}>
                  <View>
                    <CCText
                      fontSize={FontSizes[14]}
                      {...(eachStreak.isCompleted && {
                        style: styles.strikethroughText,
                      })}>
                      {eachStreak.title}
                    </CCText>
                    <CCText
                      fontSize={FontSizes[12]}
                      color={CCColors?.TextColor?.Grey}>
                      {eachStreak.subTitle}
                    </CCText>
                  </View>
                  {eachStreak.isCompleted ? (
                    <Ionicons
                      name={'checkmark-circle'}
                      size={30}
                      color={CCColors?.Primary.Aqua}
                    />
                  ) : (
                    <Box
                      style={styles.goButton}
                      onPress={() => handleGo(eachStreak?.title)}>
                      <CCText fontWeight="semibold">GO</CCText>
                    </Box>
                  )}
                </View>
                <Spacer spacing={8} />
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Streak;
