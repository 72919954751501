/* eslint-disable react-native/no-inline-styles */
import React, {useState} from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';

import CCColors from '../../Utils/CCColors';
import {CCTextProps} from './types';
import {FontSizes} from '../../Utils/Dimensions';
import CCTextStyle from '@/Utils/TextStyles';
import fontWeightToFamily from '@/Assets/Fonts/fontWeightToFamily';
import {openInNewTab} from '@/Utils/CommonUtils';
import useThirdPartyReplace from '@/Hooks/useThirdPartyReplace/useThirdPartyReplace';

/**
 * CCText - A custom Text component for displaying textual content.
 *
 * @component
 *
 * @prop {ReactNode} children - The content to be displayed inside the text component.
 * @prop {string} color - The color of the text. Defaults to CCColors.TextColor.Primary.
 * @prop {number} fontSize - The font size of the text. Defaults to FontSizes[14].
 * @prop {string} fontWeight - The weight/thickness of the font characters.
 * @prop {Object} style - Additional styles to apply to the component.
 * @prop {number|string} width - Width of the text component.
 * @prop {Object} type - Object that defines the text type and section for applying custom styles.
 *                       For example, type: { textType: 'normal', section: 'primary' }
 *     @prop {string} type.textType - The style of the text. Can be 'normal' or 'header'.
 *     @prop {string} type.section - Represents the section of the app where the text is used.
 *                                   Can be 'primary', 'sidemodal', etc.
 * @prop {string} textAlign - Aligns the text within the component. Can be "left", "center", or "right".
 * @prop {boolean} showEllipses - If true, shows ellipses when the text overflows.
 * @prop {any} props - Any other additional props passed to the Text component.
 */
const CCText: React.FC<CCTextProps> = ({
  children,
  color = CCColors.TextColor.Primary,
  fontSize,
  fontWeight,
  isLink,
  style,
  width,
  type = {
    textType: 'normal',
    section: 'primary',
  },
  textAlign,
  showEllipses = false,
  ...props
}: CCTextProps) => {
  const [isTextExpanded, setTextExpanded] = useState(false);

  const toggleTextExpanded = () => {
    setTextExpanded(!isTextExpanded);
  };

  var weight = fontWeight || StyleSheet.flatten(style).fontWeight || 'regular';
  const numWeightToTextWeight = {
    '400': 'regular',
    '500': 'medium',
    '600': 'semibold',
    bold: 'semibold',
  };
  if (weight?.toString() in numWeightToTextWeight) {
    weight = numWeightToTextWeight[weight?.toString()];
  }
  const replaceText = useThirdPartyReplace();
  const updatedText = replaceText(children);
  return (
    // <TouchableOpacity
    //   // {...(showEllipses ? { onPress: toggleTextExpanded } : {})}
    //   activeOpacity={0.8}
    // >
    // <Pressable
    //   onPress={() => {
    //     if (isLink) {
    //       openInNewTab(children);
    //     }
    //   }}>
    <Text
      selectable={true}
      {...(showEllipses
        ? {
            // onPress: toggleTextExpanded,
            numberOfLines: isTextExpanded ? undefined : 1,
            ellipsizeMode: 'tail',
          }
        : {})}
      style={[
        {fontSize: FontSizes[12]}, // default
        {
          color,
          width,
          fontFamily: `${fontWeightToFamily(weight)?.trim()}`,
          includeFontPadding: false,
          textAlign,
        },
        style,
        // CCTextStyle?.[type?.section || 'primary']?.[type?.textType || 'header'],
        {
          fontSize,
        },
      ]}
      {...props}>
      {updatedText}
    </Text>
    // </Pressable>
  );
};

export default CCText;
