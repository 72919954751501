import React from 'react';
import Box from '@/Atoms/Box';
import {StyleSheet, Text, Image, ImageStyle, View} from 'react-native';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {FontSizes, isPhone, vs} from '@/Utils/Dimensions';
import fontWeightToFamily from '@/Assets/Fonts/fontWeightToFamily';
import Spacer from '@/Atoms/Spacer';
import CCButton from '@/Atoms/CCButton';
import ProgressBar from '@/Atoms/ProgressBar';
import {useNavigation} from '@react-navigation/native';
import Feather from '@expo/vector-icons/Feather';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CCText from '@/Atoms/CCText';
dayjs.extend(relativeTime);

function generateColor(index, total = 5) {
  // Calculate the hue based on the index and total number of items
  const hue = (360 / total) * index;
  // Convert HSL color to hexadecimal
  const hexColor = `hsl(${hue}, 70%, 50%)`;
  return hexColor;
}

const VideoCard: React.FC<{
  id: number;
  title: string;
  thumbnail: string;
  coach: string;
  difficulty: string;
  is_free: boolean;
  duration: number;
  subtitle?: string;
  price?: number;
  mrp?: number;
  is_bought?: boolean;
  time_spent?: number;
  expires_at?: string;
  userData?: Object;
  userId?: number;
  webinar_date?: string;
  orderBook?: ({}) => void;
}> = ({
  id,
  title,
  thumbnail,
  coach,
  difficulty,
  is_free,
  subtitle,
  price,
  mrp,
  is_bought,
  duration,
  time_spent,
  expires_at,
  userData,
  webinar_date,
  orderBook,
}) => {
  const navigation = useNavigation();

  let expiry;
  let isExpired;
  if (expires_at) {
    isExpired = dayjs(expires_at).diff(dayjs(), 's') < 0;
    expiry = dayjs().to(dayjs(expires_at));
  }

  return (
    <Box key={id} style={styles.container}>
      <View style={{position: 'relative'}}>
        <Image style={styles.imgStyle} source={thumbnail} alt="Video Image" />
      </View>
      <Box style={{padding: 15}}>
        <Text style={styles.title}>{title.replace(/_/g, ' ')}</Text>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
          {coach && (
            <Text style={styles.subtitle} numberOfLines={1}>
              By {coach.replace(/_/g, ' ')}
            </Text>
          )}
          {webinar_date && (
            <Text style={styles.webinarDate} numberOfLines={1}>
              Date: {webinar_date}
            </Text>
          )}
        </Box>
        <Spacer spacing={vs(18)} />
        {subtitle && (
          <>
            <Text
              style={{...styles.subtitle, color: CCColors.TextColor.Primary}}>
              {subtitle.replace(/_/g, ' ')}
            </Text>
            <Spacer spacing={vs(20)} />
          </>
        )}
        {!!time_spent && (
          <View>
            <Spacer spacing={vs(8)} />
            <ProgressBar
              style={{height: 6, backgroundColor: 'rgba(38, 35, 34, 0.1)'}}
              progress={time_spent / duration}
              fillStyle={{backgroundColor: '#3DAB9E'}}
              text={undefined}
              icon={undefined}
            />
            <Spacer spacing={vs(12)} />
            <Text style={styles.content}>
              {Number((time_spent / duration).toFixed(2)) * 100}% complete
            </Text>
            <Spacer spacing={vs(20)} />
          </View>
        )}
        {expires_at && (
          <Box type="row-flex-start" gap={10} style={{marginBottom: 10}}>
            <Feather name="clock" size={15} />
            <CCText color={isExpired ? CCColors.Red : CCColors.Green}>
              Expires {expiry}
            </CCText>
          </Box>
        )}
        {!is_free && !is_bought ? (
          <Box type="row-center-between">
            <View>
              <Box flexDirection="row" alignItems="center">
                <Text style={styles.price}>₹{Number(price)?.toFixed(0)}</Text>
                <CCText fontSize={FontSizes[8]} style={{marginLeft: 2}}>
                  (including taxes)
                </CCText>
              </Box>
              <Text style={styles.mrp}>₹{Number(mrp)?.toFixed(0)}</Text>
            </View>
            <CCButton
              contentContainerStyle={{paddingHorizontal: 20}}
              titleStyle={{color: CCColors.TextColor.Dark}}
              onPress={() =>
                orderBook({
                  customer_id: userData?.userId,
                  mobile_number: userData?.mobile_number,
                  email: userData?.email,
                  amount: price,
                  type: 4,
                  return_url: window?.location?.href || undefined,
                  course_id: id,
                })
              }>
              Buy Now
            </CCButton>
          </Box>
        ) : (
          <CCButton
            style={{
              backgroundColor: CCColors.Primary.Default,
            }}
            onPress={() => {
              navigation.setParams({course: id});
            }}
            titleStyle={{color: CCColors.TextColor.Dark}}>
            Watch Video
          </CCButton>
        )}
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    width: !isPhone ? 350 : 300,
    backgroundColor: CCColors.White,
    borderRadius: 8,
    border: `1px solid ${CCColors.Border}`,
  },
  bookTitle: {
    position: 'absolute',
    top: 20,
    height: 162,
    padding: 10,
    left: 68,
    width: 115,
  },
  imgStyle: {
    objectFit: 'contain',
    height: 220,
  },
  title: {
    fontSize: FontSizes[16],
    fontFamily: fontWeightToFamily('regular'),
    fontWeight: '500',
    color: CCColors.TextColor.Dark,
    textTransform: 'capitalize',
    lineHeight: 30,
    justifyContent: 'center', // Center the text vertically
  },
  subtitle: {
    fontSize: FontSizes[12],
    fontFamily: fontWeightToFamily('regular'),
    color: CCColors.TextColor.Grey,
    lineHeight: 30,
    justifyContent: 'center', // Center the text vertically
  },
  webinarDate: {
    fontSize: FontSizes[12],
    fontFamily: fontWeightToFamily('regular'),
    color: CCColors.TextColor.Primary,
    lineHeight: 30,
    justifyContent: 'center',
  },
  icon: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: CCColors.Primary.lightBrown,
  },
  iconImg: {
    width: 16,
    height: 16,
  },
  count: {
    fontSize: FontSizes[14],
    fontFamily: fontWeightToFamily('semibold'),
    color: CCColors.TextColor.Primary,
  },
  content: {
    fontSize: FontSizes[12],
    fontFamily: fontWeightToFamily('regular'),
    color: CCColors.TextColor.Primary,
  },
  details: {
    width: '50%',
  },
  price: {
    fontSize: FontSizes[14],
    fontFamily: fontWeightToFamily('semibold'),
    color: CCColors.TextColor.Primary,
  },
  mrp: {
    fontSize: FontSizes[12],
    fontFamily: fontWeightToFamily('regular'),
    color: CCColors.TextColor.Grey,
    textDecorationLine: 'line-through',
  },
});
export default VideoCard;
