import {useRoute} from '@react-navigation/native';
import AssessmentDetails from '@/Screens/Assessments/components/AssessmentDetails';

export default function AssessmentReport(dashboardData) {
  const route = useRoute();
  const assessmentId = route.params?.id;
  const openingGamePercentage = route.params?.openingGamePercentage;
  const middleGamePercentage = route.params?.middleGamePercentage;
  const endGamePercentage = route.params?.endGamePercentage;
  const calculationDepthPercentage = route.params?.calculationDepthPercentage;
  const cdcUsername = dashboardData.data.data.user.cdc_name;
  const licUsername = dashboardData.data.data.user.lic_name;
  const onboardingStatus =
    dashboardData.data.data.user.learning_onboarding_status;
  const firstAssessmentPending =
    dashboardData.data.data.user.first_assessment_pending;

  return (
    <AssessmentDetails
      handleBack={() => {}}
      assessmentId={assessmentId}
      openingGamePercentage={Number(openingGamePercentage).toFixed(2)}
      middleGamePercentage={Number(middleGamePercentage).toFixed(2)}
      endGamePercentage={Number(endGamePercentage).toFixed(2)}
      calculationDepthPercentage={Number(calculationDepthPercentage).toFixed(2)}
      onboardingStatus={onboardingStatus}
      firstAssessmentPending={firstAssessmentPending}
      cdcUsername={cdcUsername}
      licUsername={licUsername}
    />
  );
}
