import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {FontSizes, WINDOW_HEIGHT, vs} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const customStyles = StyleSheet.create({
  childSeq: {
    borderLeftWidth: 1,
    borderLeftColor: CCColors.Black,
    paddingTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  annotationIconsWrapper: {
    padding: 5,
    backgroundColor: CCColors?.Primary?.Action,
    borderRadius: 4,
    marginLeft: 5,
  },
  annotationIcons: {
    width: 14,
    height: 14,
    // opacity: 0.6,
    // backgroundColor: CCColors.Primary.Default,
    // borderRadius: 35,
  },
  eachMoveWrapper: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveWrapperSolve: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    paddingHorizontal: 5,
    paddingVertical: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveWrapper1: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveWrapper2: {
    borderColor: CCColors.Grey.Border,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 0,
    backgroundColor: CCColors.White,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  eachMoveInsideWrapper: {
    marginTop: 15,
    flexDirection: 'row',
  },
  AnalysisRightComponentWrapper: {
    flex: 1,
    height: '92vh',
    width: '100%',
    backgroundColor: CCColors.Dashboard.DashboardBackground,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  label: {
    fontSize: 18,
  },
  count: {
    fontSize: 18,
  },
  playerScore: {
    flex: 1,
    overflowY: 'auto',
  },
  reviewSummary: {
    marginTop: vs(18),
    marginHorizontal: vs(18),
    width: '90%',
    backgroundColor: 'white',
    padding: vs(8),
    borderRadius: vs(8),
    shadowRadius: vs(2),
    shadowOpacity: 0.5,
    shadowColor: '#4D3F37',
  },
  reviewSummaryHeader: {
    letterSpacing: 2,
  },
  reviewSummaryDescription: {
    marginTop: vs(8),
    letterSpacing: 1,
    lineHeight: vs(18),
  },
  playerScoreEach: {},
  rightReviewTopHeader: {
    backgroundColor: CCColors?.White,
    padding: 10,
    paddingTop: 30,
    paddingBottom: 15,
    fontSize: FontSizes[12],
    color: CCColors?.Tabs.InactiveTabFont,
    minWidth: 100,
    textAlign: 'center',
    width: '34%',
    borderBottomColor: CCColors?.Primary.Default,
    borderBottomWidth: 3,
  },
  rightReviewLeftHeader: {
    justifyContent: 'flex-start',
    padding: 10,
    width: '30%',
    color: CCColors?.White,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  selectedMove: {
    backgroundColor: CCColors.Primary.lightYellow,
    borderColor: CCColors.Primary.Default,
    borderWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: 15,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  rightReviewTopHeaderSub: {
    backgroundColor: CCColors?.White,
    padding: 10,
    paddingTop: 30,
    paddingBottom: 15,
    fontSize: FontSizes[12],
    color: CCColors?.Tabs.InactiveTabFont,
    minWidth: 100,
    textAlign: 'center',
    width: '33%',
  },
  rightReviewTopHeaderSubSub: {
    fontSize: FontSizes[3],
    height: 'fit-content',
  },
});
export default customStyles;
