import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import CCTextInput from '@/Atoms/CCTextInput';
import Spacer from '@/Atoms/Spacer';
import useUploadSupportApi from '@/Hooks/GetSupportModalApi/useUploadSupportApi';
import CCColors from '@/Utils/CCColors';
import {vs} from '@/Utils/Dimensions';
import {Ionicons} from '@expo/vector-icons';
import React, {useEffect, useMemo, useState} from 'react';
import {View} from 'react-native';
import styles from '../../Components/UserAssistance/component/SupportModal/styles';
import {ModalContent} from '@/Components/UserAssistance/component/SupportModal';

const ReportBug = () => {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {mutate: submitSupportTicket} = useUploadSupportApi(
    data => {
      setSuccess(true);
    },
    error => {
      setErrorMessage('Failed to submit support ticket');
    },
  );

  const isImage = useMemo(() => file && file.type.startsWith('image'), [file]);

  const handleDrop = (acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0];
    const fileSizeMB = acceptedFile.size / (1024 * 1024);

    const isValidFileType =
      acceptedFile.type.startsWith('image') ||
      acceptedFile.type.startsWith('video');
    const isValidSize = fileSizeMB <= 15;

    if (!isValidFileType || !isValidSize) {
      setFile(null);
      setErrorMessage(
        'File contains an unknown or unsupported file type, or exceeds the size limit of 15MB',
      );
    } else {
      setFile(acceptedFile);
      setErrorMessage(null);
    }
  };

  const handleSubmit = () => {
    if (!message || !file) {
      setErrorMessage('Both description and file are required');
      return;
    }

    setErrorMessage(null);
    submitSupportTicket({description: message, file});

    setMessage('');
    setFile(null);
  };

  const renderSuccess = () => (
    <View style={styles.successContainer}>
      <Ionicons
        name="checkmark-circle"
        size={100}
        color={CCColors?.Green}
        style={styles.successIcon}
      />
      <Spacer spacing={vs(10)} />
      <CCText style={styles.modalTitle}>We have received your feedback</CCText>
      <Spacer spacing={vs(10)} />
      <CCText style={styles.successText}>
        Thank you for bringing this to our attention. Our team will look into it
        promptly. Your feedback is invaluable in helping us elevate the chess
        experience.
      </CCText>
      <Spacer spacing={vs(20)} />
    </View>
  );

  return (
    <ModalContent
      success={success}
      renderSuccess={renderSuccess}
      message={message}
      setMessage={setMessage}
      file={file}
      handleDrop={handleDrop}
      errorMessage={errorMessage}
      setFile={setFile}
      isImage={isImage}
      handleSubmit={handleSubmit}
    />
  );
};

export default ReportBug;
