import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCFlatlist from '@/Atoms/CCFlatlist';
import CCText from '@/Atoms/CCText';
import ConditionalRender from '@/Atoms/ConditionalRender';
import Divider from '@/Atoms/Divider/Divider';
import CCColors from '@/Utils/CCColors';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Text, Animated, StyleSheet, Pressable, Linking} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import useGetPlanApi from '@/Hooks/SubscriptionApi/useGetPlan';
import useGeneratePaymentApi from '@/Hooks/SubscriptionApi/useGeneratePayment';
import useMakePaymentApi from '@/Hooks/SubscriptionApi/useMakePayment';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import CCLoader from '@/Atoms/CCLoader';
import {useUserPlanStore} from '@/Store/userPlanStore';
import useGetAllPlansApi from '@/Hooks/SubscriptionApi/useGetAllPlansApi';
import {formatCurrency} from '@/Utils/CommonUtils';
import {canSubscribe} from '../../helper';
import {Image} from 'react-native';
import {FontSizes} from '@/Utils/Dimensions';

interface SubscriptionComponentProps {
  selected?: boolean;
  planId?: number | string;
  subscribedPlanId?: number | string;
  getIcon?: () => React.ReactNode;
  listData?: string[];
  isBestSeller?: boolean;
  isActivePlan?: boolean;
  canBeActivePlan?: boolean;
  checkboxColor?: string;
  checkBoxTickColor?: string;
  title?: string;
  description?: string;
  actualPrice?: number;
  discountPrice?: number;
  tier?: string;
  onSelect?: () => void;
  onViewAllFeatures?: () => void;
  user: any;
  monthlyPrice?: string;
  monthlyMrp?: string;
  yearlyPrice?: string;
  userPlan?: string;
  yearlyMrp?: string;
  index?: number;
}

const SubscriptionComponent: React.FC<SubscriptionComponentProps> = ({
  user = {},
  selected,
  getIcon,
  planId: planIdProp,
  subscribedPlanId,
  listData,
  isBestSeller = false,
  isActivePlan = false,
  canBeActivePlan = false,
  checkboxColor,
  checkBoxTickColor,
  title,
  description,
  actualPrice,
  discountPrice,
  tier,
  onSelect,
  monthlyPrice,
  userPlan,
  monthlyMrp,
  yearlyPrice,
  yearlyMrp,
  onViewAllFeatures,
  index,
}) => {
  const [planId, setPlanId] = useState<any>();
  const [showFullFeatures, setShowFullFeatures] = useState<boolean>(false);
  const {isMobileOrTab} = useMediaQuery();
  const rotationAnim = useRef(new Animated.Value(0)).current;
  const planTaken = useMemo(
    () => userPlan?.planDetails?.plan_details?.fields?.name,
    [userPlan],
  );

  const price = useMemo(
    () =>
      tier?.toLowerCase()?.includes('month')
        ? monthlyPrice
        : tier?.toLowerCase()?.includes('year')
        ? yearlyPrice
        : 'Free',
    [tier],
  );
  const mrp = useMemo(
    () =>
      tier?.toLowerCase()?.includes('month')
        ? monthlyMrp
        : tier?.toLowerCase()?.includes('year')
        ? yearlyMrp
        : 'Free',
    [tier],
  );

  const store = useUserPlanStore();

  const toggleFeatures = () => {
    setShowFullFeatures(!showFullFeatures);
    Animated.timing(rotationAnim, {
      toValue: showFullFeatures ? 0 : 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };
  const userPlanDetails = useUserPlanStore();
  const [activePlan, setActivePlan] = useState(
    userPlanDetails.activePlan || 'novice',
  );
  const arrowRotation = rotationAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  let plan_type;
  let tenure;
  let type = title?.split(' ')[0];
  if (type?.toLowerCase() === 'free') {
    plan_type = 'basic';
  } else if (type === 'aspiringLearners') {
    plan_type = 'aspiringLearners';
  } else {
    plan_type = 'aspiringChampions';
  }

  tenure = tier?.toLowerCase();

  const handleOnSuccessPlan = data => {
    setPlanId(data?.plan_id);
    if (data?.data?.plan_id) {
      generatePayment({
        customer_id: user?.user_id,
        mobile_number: user?.mobile_number,
        email: user?.email,
        subscription_plan_id: data?.data?.plan_id,
      });
    }
  };

  const handleOnErrorPlan = error => {
    console.log('error while fetching plan', error);
  };

  const {
    mutate: getPlan,
    data,
    error,
    isLoading,
  } = useGetPlanApi(handleOnSuccessPlan, handleOnErrorPlan);

  const handleOnSuccessGeneratePayment = data => {
    console.log('successfully generated payment link', data);
  };

  const handleOnErrorGeneratePayment = error => {
    console.log('error while generating payment', error);
  };

  // const handleOnSuccessGetPlans = data => {
  //   setApiPlanData(data?.data);
  //   // setPlans(data?.data);
  // };

  // const handleOnErrorGetPlans = error => {
  //   console.log('Error while fetching plans', error);
  // };
  // const {mutate: getAllPlans, isLoading: plansLoading} = useGetAllPlansApi(
  //   handleOnSuccessGetPlans,
  //   handleOnErrorGetPlans,
  // );

  // useEffect(() => {
  //   getAllPlans();
  // }, []);

  const {
    mutate: generatePayment,
    data: paymentUrlData,
    error: paymentUrlDataError,
    isLoading: paymentLoading,
  } = useGeneratePaymentApi(
    handleOnSuccessGeneratePayment,
    handleOnErrorGeneratePayment,
  );

  useEffect(() => {
    if (isMobileOrTab) {
      if (paymentUrlData?.data?.content?.payment_links?.web) {
        Linking.openURL(paymentUrlData?.data?.content?.payment_links?.web);
      }
    } else {
      if (paymentUrlData?.data?.content?.payment_links?.web) {
        // @ts-ignore
        window.location.href =
          paymentUrlData?.data?.content?.payment_links?.web;
      }
    }
  }, [paymentUrlData]);

  if (isLoading || paymentLoading) {
    return <CCLoader loading={isLoading || paymentLoading} />;
  }

  return (
    <Box
      key={index}
      onPress={() => {
        onSelect;
      }}
      overflow="visible"
      flex={!isMobileOrTab ? 0.3 : undefined}
      style={{
        backgroundColor: isBestSeller ? CCColors.Primary.Brown : CCColors.White,
        borderRadius: 12,
        borderColor:
          isBestSeller && activePlan !== 'aspiringChampions'
            ? CCColors.Red
            : CCColors.Primary.Brown,
        borderWidth: isBestSeller && activePlan !== 'aspiringChampions' ? 4 : 0,
        width: isMobileOrTab ? '90%' : undefined,
        ...{
          height: '100%',
        },
      }}>
      <ConditionalRender
        condition={
          isBestSeller && !isActivePlan && activePlan !== 'aspiringChampions'
        }
        childrenA={
          <CCButton
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '80%',
              position: 'absolute',
              backgroundColor: isBestSeller ? CCColors.Red : CCColors.Green,
              top: -25,
              left: 20,
              right: 20,
            }}>
            <CCText
              fontSize={14}
              fontWeight="medium"
              style={{padding: 5}}
              color={CCColors.White}>
              Best Seller
            </CCText>
          </CCButton>
        }
      />
      {/* <ConditionalRender
        condition={isActivePlan}
        childrenA={
          <Box
            flex={0.4}
            flexDirection="row"
            justifyContent="flex-end"
            style={{
              backgroundColor: selected
                ? CCColors.TextColor.Dark
                : CCColors.White,
              borderRadius: 4,
              position: 'absolute',
              right: 5,
            }}>
            <CCText
              style={{
                paddingHorizontal: 12,
                paddingVertical: 6,
                marginTop: 5,
                marginRight: 5,
                backgroundColor: CCColors.Primary.lightBrown,
                borderRadius: 4,
              }}
              textAlign="right"
              fontWeight="semibold"
              fontSize={14}
              color={CCColors.TextColor.Dark}>
              Current Plan
            </CCText>
          </Box>
        }
      /> */}
      <ConditionalRender
        condition={isActivePlan}
        childrenA={
          <Box
            flex={0.4}
            flexDirection="row"
            justifyContent="flex-end"
            style={{
              backgroundColor: CCColors.Primary.lightBrown,
              borderRadius: 4,
              position: 'absolute',
              right: 5,
            }}>
            <CCText
              style={{
                paddingHorizontal: 12,
                paddingVertical: 6,
                backgroundColor: CCColors.Primary.lightBrown,
                borderRadius: 4,
              }}
              textAlign="right"
              fontWeight="semibold"
              fontSize={14}
              color={CCColors.TextColor.Dark}>
              Active Plan
            </CCText>
          </Box>
        }
      />
      <ConditionalRender
        condition={canBeActivePlan}
        childrenA={
          <Box
            flex={0.4}
            flexDirection="row"
            justifyContent="flex-end"
            style={{
              backgroundColor: CCColors.Primary.lightBrown,
              borderRadius: 4,
              position: 'absolute',
              right: 0,
              top: 5,
            }}>
            <CCText
              style={{
                paddingHorizontal: 8,
                paddingVertical: 4,
                backgroundColor: CCColors.Primary.lightBrown,
                borderRadius: 4,
              }}
              textAlign="right"
              fontWeight="semibold"
              fontSize={14}
              color={CCColors.TextColor.Dark}>
              {tier?.toLowerCase() === 'monthly'
                ? 'Annual plan subscribed'
                : 'Monthly plan subscribed'}
            </CCText>
          </Box>
        }
      />
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{paddingTop: 40, paddingBottom: 15}}>
        {getIcon?.()}
      </Box>
      <CCText
        fontSize={isMobileOrTab ? 14 : 18}
        fontWeight="semibold"
        textAlign="center"
        color={isBestSeller ? CCColors.White : CCColors.TextColor.Dark}>
        {title}
      </CCText>
      <Box flexDirection="row" justifyContent="center" alignItems="center">
        <CCText
          textAlign="center"
          color={isBestSeller && CCColors.White}
          fontSize={14}
          fontWeight="regular"
          style={{width: '70%', lineHeight: 20}}>
          {description}
        </CCText>
      </Box>
      <ConditionalRender
        condition={actualPrice && price !== 'Free'}
        childrenA={
          <CCText
            color={!selected ? CCColors.Grey.Shade200 : CCColors.Grey.BG100}
            fontSize={FontSizes[28]}
            fontWeight="semibold"
            textAlign="center"
            style={{
              paddingTop: 20,
              textDecorationLine: 'line-through',
              opacity: selected && 0.5,
            }}>
            {mrp}
          </CCText>
        }
        childrenB={
          <CCText
            color={'transparent'}
            fontSize={FontSizes[28]}
            fontWeight="semibold"
            textAlign="center"
            style={{
              paddingTop: 20,
              textDecorationLine: 'line-through',
              opacity: selected && 0.5,
            }}>
            {mrp}
          </CCText>
        }
      />
      <Box flexDirection="row" justifyContent="center" alignItems="center">
        <CCText
          color={isBestSeller ? CCColors.White : CCColors.TextColor.Dark}
          fontSize={FontSizes[28]}
          fontWeight="semibold"
          textAlign="center">
          {price}
        </CCText>
      </Box>
      {price !== 'Free' ? (
        <CCText
          fontWeight="regular"
          fontSize={14}
          textAlign="center"
          color={
            isBestSeller ? CCColors.White : `${CCColors.TextColor.Primary}80`
          }>
          {tier}
        </CCText>
      ) : (
        <></>
      )}
      <CCButton
        onPress={() => {
          // store.setChoosenPlan(title?.toLowerCase());
          // store.setTenure(tenure);
          getPlan({
            plan_type:
              title?.toLowerCase() === 'aspiring learners' ? 'casual' : 'PRO',
            tenure: tenure,
          });
        }}
        disabled={
          tier === 'Free' ||
          canSubscribe(planIdProp, subscribedPlanId) ||
          isActivePlan
        }
        style={{
          width: '90%',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: index === 1 ? 20 : 40,
          marginBottom: 40,
          backgroundColor:
            tier === 'Free' ||
            canSubscribe(planIdProp, subscribedPlanId) ||
            isActivePlan
              ? CCColors.LightYellow
              : undefined,
        }}>
        <CCText
          fontSize={FontSizes[18]}
          textAlign="center"
          fontWeight="medium"
          style={{padding: 10}}>
          {!isActivePlan && canSubscribe(planIdProp, subscribedPlanId)
            ? planTaken === 'Novice'
              ? 'Current Plan'
              : 'Select Another Plan'
            : !isActivePlan && 'Upgrade Plan'}

          {isActivePlan && 'Current Plan'}
        </CCText>
      </CCButton>

      <CCFlatlist
        data={
          !isMobileOrTab
            ? listData?.slice(0, 5)
            : showFullFeatures
            ? listData
            : listData?.slice(0, 5)
        }
        keyExtractor={(item, index) => index.toString()}
        renderItem={item => {
          return (
            <Box
              flexDirection="row"
              gap={12}
              style={{
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
              <Box
                style={{
                  borderRadius: '50%',
                  width: 20,
                  height: 20,
                  backgroundColor:
                    index === 0
                      ? CCColors.LightPink
                      : index === 1
                      ? CCColors.BlueAqua
                      : CCColors.LightBlue,
                }}>
                <MaterialIcons
                  name="check"
                  size={16}
                  color={
                    index === 0
                      ? CCColors.Pink
                      : index === 1
                      ? CCColors.White
                      : CCColors.Blue
                  }
                  style={styles.tick}
                />
              </Box>
              <CCText
                fontSize={14}
                fontWeight="medium"
                color={
                  isBestSeller ? CCColors.White : CCColors.TextColor.Primary
                }>
                {item.item}
              </CCText>
            </Box>
          );
        }}
      />
      <Divider style={styles.divider} />
      <Box flexDirection="row" justifyContent="center" alignItems="center">
        <Pressable
          onPress={() => {
            onViewAllFeatures();
          }}
          style={{
            flexDirection: isMobileOrTab ? 'row' : undefined,
            gap: isMobileOrTab ? 10 : undefined,
          }}>
          <CCText
            fontSize={14}
            color={isBestSeller ? CCColors.Primary.Default : CCColors.Green}
            fontWeight="medium"
            style={{marginBottom: 20}}>
            View all Features
          </CCText>
          <ConditionalRender
            condition={isMobileOrTab}
            childrenA={
              <Animated.View style={{transform: [{rotate: arrowRotation}]}}>
                <MaterialIcons
                  name={
                    showFullFeatures
                      ? 'keyboard-arrow-up'
                      : 'keyboard-arrow-down'
                  }
                  size={20}
                  color={selected ? CCColors.Primary.Default : CCColors.Green}
                />
              </Animated.View>
            }
          />
        </Pressable>
      </Box>
    </Box>
  );
};

export default SubscriptionComponent;

const styles = StyleSheet.create({
  divider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  tick: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: [{translateX: '50%'}, {translateY: '-50%'}],
    fontWeight: 600,
  },
});
