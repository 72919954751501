/* eslint-disable react-native/no-inline-styles */
import React, {useState, useEffect} from 'react';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import useWebinarApi from '@/Hooks/WebinarApi/useWebinarApi';
import Pagination from '../../Games/Pagination';
import CCLoader from '@/Atoms/CCLoader/CCLoader';
import CCPackageTable, {CustomCell} from '@/Atoms/CCPackageTable';
import CCText from '@/Atoms/CCText';
import Box from '@/Atoms/Box';
import Spacer from '@/Atoms/Spacer';
import {ScrollView, Pressable} from 'react-native';
import CCColors from '@/Utils/CCColors';
import {FontSizes, vs} from '@/Utils/Dimensions';

const tabHeaders = [
  'Webinar topic',
  'Coach',
  'Class Type',
  'Webinar date',
  'Webinar time',
  'Webinar URL',
];

const Meetings: React.FC<any> = ({userKey}) => {
  const [webinarLoading, setWebinarLoading] = useState(true);
  const [webinarData, setWebinarData] = useState([]);
  const handleOnSuccessWebinarDetails = data => {
    setWebinarData(data.data.webinar_list);
    setWebinarLoading(false);
  };
  const {mutate: getWebinarDetails} = useWebinarApi(
    handleOnSuccessWebinarDetails,
  );

  useEffect(() => {
    getWebinarDetails({user_key: userKey});
  }, []);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const paginate = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      getWebinarDetails({page: pageNumber, user_key: userKey});
    }
  };
  const {isMobileOrTab} = useMediaQuery();

  const [tableData, setTableData] = useState<
    Array<
      Array<{
        name: string;
        type: 'text' | 'link' | 'dropdown';
        url?: string;
        options?: string[];
      }>
    >
  >([]);
  useEffect(() => {
    if (webinarData && Array.isArray(webinarData)) {
      const itemsPerPage = 10;
      const totalItems = webinarData.length;

      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      const paginatedData = webinarData.slice(startIndex, endIndex);

      const formattedData = paginatedData.map(item => {
        console.log('game item is .... : ', item);
        const webinar_topic = item.topic;
        const webinarCoach = item.coach_name;
        const webinar_date = item.webinar_date;
        const webinar_time = item.webinar_time;
        const webinar_url = item.join_url;
        const webinar_classtype = item.class_type;

        let itemValues: any = [];
        itemValues.push({
          name: isMobileOrTab ? (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <CCText fontSize={FontSizes[15]}>{webinar_topic}</CCText>
            </Box>
          ) : (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <Box
                type="row-flex-start"
                style={{
                  margin: 'auto',
                  width: '100%',
                  flexWrap: 'wrap',
                  // justifyContent: 'row-center',
                }}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {webinar_topic}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <CCText fontSize={FontSizes[15]}>{webinarCoach}</CCText>
            </Box>
          ) : (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <Box
                type="row-flex-start"
                style={{
                  margin: 'auto',
                  width: '100%',
                  flexWrap: 'wrap',
                  // justifyContent: 'row-center',
                }}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {webinarCoach}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <CCText fontSize={FontSizes[15]}>{webinarCoach}</CCText>
            </Box>
          ) : (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <Box
                type="row-flex-start"
                style={{
                  margin: 'auto',
                  width: '100%',
                  flexWrap: 'wrap',
                  // justifyContent: 'row-center',
                }}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {webinar_classtype}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <CCText fontSize={FontSizes[15]}>{webinar_date}</CCText>
            </Box>
          ) : (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <Box
                type="row-flex-start"
                style={{
                  margin: 'auto',
                  width: '100%',
                  flexWrap: 'wrap',
                  // justifyContent: 'row-center',
                }}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {webinar_date}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <CCText fontSize={FontSizes[15]}>{webinar_time}</CCText>
            </Box>
          ) : (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <Box
                type="row-flex-start"
                style={{
                  margin: 'auto',
                  width: '100%',
                  flexWrap: 'wrap',
                  // justifyContent: 'row-center',
                }}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {webinar_time}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <Pressable
                onPress={() => {
                  window.open(webinar_url, '_blank');
                }}>
                <CCText color={CCColors.Blue} fontSize={FontSizes[15]}>
                  {webinar_url.substring(0, 28) + '...'}
                </CCText>
              </Pressable>
            </Box>
          ) : (
            <Box
              style={{
                padding: vs(12),
                width: '100%',
                height: '100%',
              }}>
              <Box
                type="row-flex-start"
                style={{
                  margin: 'auto',
                  width: '100%',
                  flexWrap: 'wrap',

                  // justifyContent: 'row-center',
                }}>
                <Pressable
                  onPress={() => {
                    window.open(webinar_url, '_blank');
                  }}>
                  <CCText color={CCColors.Blue} fontSize={FontSizes[13]}>
                    {webinar_url.substring(0, 28) + '...'}
                  </CCText>
                </Pressable>
              </Box>
            </Box>
          ),
          type: 'link',
        });
        return itemValues;
      });
      setTableData(formattedData || []);
    }
  }, [currentPage, isMobileOrTab, webinarData]);

  return (
    <ScrollView style={{padding: 20}}>
      <Spacer spacing={vs(16)} />
      <Box
        type={isMobileOrTab ? 'center' : 'row-center-between'}
        style={{
          marginLeft: 30,
        }}>
        <CCText fontSize={24} color={CCColors.Primary.Dark}>
          Meetings
        </CCText>
        {isMobileOrTab && <Spacer spacing={10} />}
      </Box>
      <Spacer spacing={vs(20)} />
      <Box
        style={{
          backgroundColor: isMobileOrTab ? CCColors.White : '',
          borderRadius: isMobileOrTab ? 8 : 0,
        }}>
        {webinarLoading ? (
          <CCLoader loading bgWhite isComponentLoader />
        ) : (
          <>
            <CCPackageTable
              flexArr={[2, 1.2, 1.2, 1.2, 1.4, 2]}
              headers={tabHeaders}
              tableData={tableData}
              isMargin={false}
            />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </>
        )}
      </Box>
    </ScrollView>
  );
};

export default Meetings;
