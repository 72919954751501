import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetLeaguesApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<Error>(
    async () => {
      const response = await ChessRestClient.get('leagues/');
      console.log('Retrieved profile = ', response.data);
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        } else if (handleOnError) {
          handleOnError(data);
        }
      },
    },
  );

  return {
    ...mutation,
  };
}
