import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  LayoutAnimation,
  Platform,
  UIManager,
  Animated,
  ScrollView,
  ViewStyle,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import CCText from '../CCText';
import {capitalizeFirstLetter} from '@/Utils/CommonUtils';
import {hs, vs} from '@/Utils/Dimensions';
import Box from '../Box';
import CCColors from '@/Utils/CCColors';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {StyleProp} from 'react-native';
// Enable LayoutAnimation on Android
if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

interface AccordionSectionProps {
  title: string;
  renderContent: () => React.ReactNode;
}

interface CCAccordionProps {
  sections: AccordionSectionProps[];
  fontWeight?: string;
  color?: string;
  fontSize?: number;
  customSections?: StyleProp<ViewStyle>;
  paddingVertical?: number;
}

interface AccordionSectionProps {
  title: string;
  renderContent: () => React.ReactNode;
  maxHeight?: number; // Maximum height for the accordion content
  selectedCount?: number;
  keepOpen?: boolean;
  showIcon?: boolean;
  header?: React.ReactNode;
  isScrollable?: boolean;
  fontWeight?: string;
  width?: string;
  margin?: string | number;
  changeAppearance?: boolean;
  customStyles?: StyleProp<ViewStyle>;
  customStylesContainer?: StyleProp<ViewStyle>;
  color?: string;
  fontSize?: number;
  customSections?: StyleProp<ViewStyle>;
}

export const AccordionSection: React.FC<AccordionSectionProps> = ({
  title,
  renderContent,
  maxHeight = hs(80),
  selectedCount = 0,
  keepOpen = false,
  showIcon = true,
  header,
  isScrollable = true,
  fontWeight = 'bold',
  width = '100%',
  margin,
  customStyles,
  customStylesContainer,
  changeAppearance = false,
  color,
  fontSize,
  customSections,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const heightAnim = useState(new Animated.Value(0))[0]; // Animated value for height
  const {isMobileOrTab} = useMediaQuery();

  const toggleAccordion = () => {
    Animated.timing(heightAnim, {
      toValue: isOpen ? 0 : maxHeight, // Animate to maxHeight or 0 based on isOpen
      duration: showIcon ? 300 : 0,
      useNativeDriver: false,
    }).start();

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (keepOpen) {
      toggleAccordion();
      setIsOpen(true);
    }
  }, [keepOpen]);

  return (
    <Box
      style={[
        customSections || styles.section,
        {
          width: width,
          margin: margin,
          backgroundColor: color || CCColors.White,
        },
      ]}>
      <TouchableOpacity
        onPress={toggleAccordion}
        style={[styles.titleContainer, customStyles]}>
        {header ? (
          header
        ) : (
          <CCText
            style={[
              styles.title,
              {fontWeight: fontWeight, fontSize: fontSize},
            ]}>
            {capitalizeFirstLetter(title)}
          </CCText>
        )}
        <Box flexDirection="row">
          {selectedCount > 0 && (
            <CCText color={CCColors.Primary.Aqua} style={{paddingRight: 10}}>
              (+ {selectedCount})
            </CCText>
          )}
          {showIcon && (
            <Box
              type="center"
              style={{
                backgroundColor: !changeAppearance
                  ? CCColors.Primary.Brown
                  : 'transparent',
                borderRadius: 50,
                width: !isMobileOrTab ? 25 : 20,
                height: !isMobileOrTab ? 25 : 20,
              }}>
              <Ionicons
                color={!changeAppearance ? CCColors.White : CCColors.Black}
                name={isOpen ? 'chevron-up' : 'chevron-down'}
                size={!isMobileOrTab ? 20 : 16}
              />
            </Box>
          )}
        </Box>
      </TouchableOpacity>
      {isScrollable ? (
        <Animated.View
          style={{
            ...styles.contentContainer,
            padding: !changeAppearance ? vs(10) : isOpen ? vs(10) : vs(0),
            backgroundColor: color,
          }}>
          <ScrollView>{isOpen && renderContent()}</ScrollView>
        </Animated.View>
      ) : (
        <View style={[styles.contentContainer, customStylesContainer]}>
          {isOpen && renderContent()}
        </View>
      )}
    </Box>
  );
};

const CCAccordion: React.FC<CCAccordionProps> = ({
  sections,
  fontWeight,
  changeAppearance = false,
  fontSize,
  color,
  customSections,
  paddingVertical,
}) => {
  return (
    <View style={[styles.container, {paddingVertical: paddingVertical}]}>
      {sections?.map((section, index) => (
        <AccordionSection
          changeAppearance={changeAppearance}
          fontWeight={fontWeight}
          key={index}
          title={section.title}
          renderContent={section.renderContent}
          color={color}
          fontSize={fontSize}
          customSections={customSections}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // Adding padding and ensuring the content is well-spaced
    paddingVertical: vs(40),
  },
  section: {
    width: '100%',

    marginVertical: vs(5),
    borderRadius: vs(10),
    shadowColor: '#4D3F37',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.05,
    shadowRadius: 3.84,
    elevation: 3,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: vs(15),
    paddingHorizontal: vs(20),
    alignItems: 'center',
    borderTopLeftRadius: vs(10),
    borderTopRightRadius: vs(10),
  },
  title: {
    color: CCColors.TextColor.Primary,
    fontSize: hs(12),
  },
  icon: {
    fontSize: hs(20),
    color: CCColors.Primary.Aqua,
  },
  contentContainer: {
    backgroundColor: CCColors.White,
    borderBottomLeftRadius: vs(10),
    borderBottomRightRadius: vs(10),
  },
});

export default CCAccordion;
