import {FontSizes, hs, isPhone, vs} from '../../Utils/Dimensions';

import CCColors from '../../Utils/CCColors';
import {Dimensions, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#F9F9F9',
    paddingHorizontal: vs(16),
    paddingTop: vs(20),
  },
  header: {
    fontSize: FontSizes[20],
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#4D3F37',
  },
  section: {
    marginVertical: vs(16),
    paddingHorizontal: vs(12),
  },
  sectionHeader: {
    fontSize: FontSizes[16],
    fontWeight: 'bold',
    color: '#4D3F37',
    marginBottom: vs(8),
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  input: {
    flex: 1,
    marginHorizontal: vs(8),
    marginVertical: vs(8),
    minWidth: vs(140),
  },
  avatarGrid: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: vs(12),
  },
  avatarPreview: {
    alignItems: 'center',
    marginTop: vs(12),
  },
  avatarName: {
    fontSize: FontSizes[14],
    fontWeight: 'bold',
    marginVertical: vs(4),
  },
  avatarTagline: {
    fontSize: FontSizes[12],
    color: '#6D6D6D',
    textAlign: 'center',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    marginVertical: vs(8),
  },
  successText: {
    color: 'green',
    textAlign: 'center',
    marginVertical: vs(8),
  },
  buttonContainer: {
    alignItems: 'center',
    marginVertical: vs(16),
  },
  saveButton: {
    backgroundColor: '#FACF47',
    borderRadius: vs(24),
    paddingVertical: vs(12),
    paddingHorizontal: vs(48),
  },
  item: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: vs(4),
    padding: vs(8),
    backgroundColor: '#FAF6EB',
    borderRadius: vs(8),
  },
  itemInvisible: {
    backgroundColor: 'transparent',
  },
  itemText: {
    fontSize: FontSizes[12],
    textAlign: 'center',
  },
});
