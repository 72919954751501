import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

type updateAnnotationType = {
  move_id: string | number;
  annotation: string;
  annotation_icon: string;
};
export default function useStudyUpdateAnnotation() {
  return useMutation(async (payload: updateAnnotationType) => {
    const response = await ChessRestClient.post(
      `learning/study_games_moves/update_annotation/`,
      payload,
    );
    return response.data;
  });
}

// {
//   name: newStudyDetails?.name,
//   chapter: {
//     name: newChapterDetails?.name,
//     ...startingPos,
//     orientation: orientation,
//   },
// }
