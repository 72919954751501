import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import SlideModal from '@/Atoms/SlideModal';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  FlatList,
  StyleSheet,
  Image,
  ImageSourcePropType,
  TouchableOpacity,
} from 'react-native';
import InterestingMove from '@/Assets/Annotation/interesting_move.svg';
import Feather from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import {FontSizes} from '@/Utils/Dimensions';
import Spacer from '@/Atoms/Spacer';
import CCTextInput from '@/Atoms/CCTextInput';
import {styles as studyStyles} from '../../styles';
import {moveAnnotations} from '@/Utils/CommonUtils';

type settingsProp = {
  commentToShow: string;
  setCommentToShow: Function;
  isSyncOn: boolean;
  handleSaveComment: Function;
  handleSaveAnnotationIcon: Function;
  annotation_icon: string;
};
function SettingsTab({
  commentToShow,
  setCommentToShow,
  isSyncOn,
  handleSaveComment,
  handleSaveAnnotationIcon,
  annotation_icon,
}: settingsProp) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [openAnnotationModal, setOpenAnnotationModal] = useState(false);
  const [searchAnnotation, setSearchAnnotation] = useState('');

  useEffect(() => {
    if (annotation_icon) {
      const tempAnnotationIcon = moveAnnotations?.filter(
        i => i?.name === annotation_icon,
      );
      setSelectedAnnotation(tempAnnotationIcon);
    } else {
      setSelectedAnnotation(null);
    }
  }, [annotation_icon]);

  const tabNames = useMemo(() => {
    // const tempIsSelected = selectedOption?.id === i?.id;
    return [
      {
        id: 1,
        name: '!?',
        headerName: 'Add annotation',
        icon: (i: {id: any; icon: ImageSourcePropType}) => (
          <Box
            onPress={() => {
              setSelectedOption(i);
              setOpenAnnotationModal(true);
            }}>
            <Image
              resizeMode="contain"
              style={[
                styles.annotationIcons2,
                // selectedOption?.id === i?.id && styles.selected,
                // selectedOption?.id === i?.id && {
                //   color: CCColors.White,
                //   opacity: 1,
                // },
              ]}
              source={InterestingMove}
            />
          </Box>
        ),
      },
      // {id: 2, name: 'chatbubble-outline'},
      {
        id: 2,
        name: 'D',
        headerName: 'Add comment',
        icon: (i: React.SetStateAction<null>) => (
          <MaterialCommunityIcons
            onPress={() => {
              setSelectedOption(i);
              setOpenAnnotationModal(true);
            }}
            name={'chat-outline'}
            size={24}
            color={CCColors.TextColor.Primary}
            style={[
              styles.icon,
              // selectedOption?.id === i?.id && styles.selected,
            ]}
          />
        ),
      },
    ];
  }, [selectedOption]);

  const closeModal = () => {
    setOpenAnnotationModal(false);
    setSelectedAnnotation(null);
  };
  const modalContent = useMemo(() => {
    switch (selectedOption?.id) {
      case 1:
        return (
          <>
            <CCTextInput
              value={searchAnnotation}
              onChangeText={text => setSearchAnnotation(text)}
              // label="Notes"
              placeholder="Search..."
              name="search"
              staticPrefix={
                <Feather
                  size={20}
                  name="search"
                  color={CCColors.Primary.Brown}
                />
              }
              // style={{height: '80%'}}
              // numberOfLines={10}
              // multiline={true}
            />
            <Spacer spacing={10} />
            <FlatList
              data={moveAnnotations?.filter(i =>
                i?.name?.includes(searchAnnotation),
              )}
              numColumns={2}
              contentContainerStyle={styles.contentContainerStyle}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({item}) => {
                const isSelected = selectedAnnotation === item?.name;
                return (
                  <Box
                    style={[
                      styles.itemContainer,
                      isSelected && styles.itemContainerSelected,
                    ]}
                    onPress={() => {
                      setSelectedAnnotation(item.name);
                      handleSaveAnnotationIcon(item.name);
                      closeModal();
                    }}>
                    {/* <item.icon
                  width={24}
                  height={24}
                  fill="black"
                  style={{
                    opacity: selectedAnnotation?.name === item?.name ? 1 : 0.6,
                  }}
                /> */}
                    <Image
                      resizeMode="contain"
                      style={[
                        styles.annotationIcons,
                        isSelected && {color: CCColors.White, opacity: 1},
                      ]}
                      source={isSelected ? item?.white_icon : item?.icon}
                    />
                    <Spacer spacing={6} horizontal />
                    <CCText
                      fontSize={FontSizes[12]}
                      color={isSelected && CCColors.White}>
                      {item.name}
                    </CCText>
                  </Box>
                );
              }}
            />
          </>
        );
      // commentToShow,
      // setCommentToShow,
      // isSyncOn,
      // handleSaveComment,
      case 2:
        return (
          <>
            <CCTextInput
              value={commentToShow}
              onChangeText={text => setCommentToShow(text)}
              // label="Notes"
              placeholder="Notes"
              name="Notes"
              style={{height: '80%'}}
              numberOfLines={10}
              multiline={true}
            />
            <Spacer spacing={10} />
            <TouchableOpacity
              style={[
                studyStyles.addNewBox,
                (commentToShow?.length < 1 || !isSyncOn) &&
                  studyStyles.disabled,
              ]}
              onPress={handleSaveComment}>
              <CCText textAlign="center" color={CCColors.White}>
                Save
              </CCText>
            </TouchableOpacity>
          </>
        );

      default:
        break;
    }
  }, [
    selectedOption?.id,
    searchAnnotation,
    commentToShow,
    isSyncOn,
    handleSaveComment,
    selectedAnnotation,
    setCommentToShow,
    handleSaveAnnotationIcon,
  ]);

  return (
    <>
      <Box type="row-flex-end" gap={5}>
        {tabNames?.map((i, index) => i?.icon(i))}
      </Box>
      <SlideModal
        visible={openAnnotationModal}
        onClose={closeModal}
        customStyle={styles.container}
        type="center"
        modalHeaderTitle={selectedOption?.headerName}>
        <Box style={styles.container}>
          {modalContent}
          {/* <Image
            source={require('@/Assets/Images/Caissa/Caissa_bot_girl.svg')}
            style={[styles.buttonGirlIcon]}
            resizeMode="contain"
          /> */}
        </Box>
      </SlideModal>
    </>
  );
}

export default SettingsTab;

const styles = StyleSheet.create({
  selected: {
    color: CCColors.TextColor.Primary,
    paddingBottom: 5,
    borderBottomWidth: 2,
    borderBottomColor: CCColors.Primary.Default,
  },
  contentContainerStyle: {
    // flex: 1,
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // flexWrap: 'wrap',
    gap: 10,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 5,
    maxHeight: '60vh',
    // maxWidth: '70vh',
  },
  itemContainerSelected: {
    backgroundColor: CCColors.Primary.Brown,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: CCColors.Primary.lightBrown,
    borderRadius: 8,
    marginHorizontal: 12,

    flexBasis: '45%',
    // width: '100%',
    // minWidth: 150,
  },
  icon: {
    // marginRight: 12,
  },
  text: {
    fontSize: 16,
    color: '#333',
  },
  annotationIcons: {
    width: 16,
    height: 16,
    opacity: 0.6,
    // backgroundColor: CCColors.Primary.Default,
    // borderRadius: 35,
  },
  annotationIcons2: {
    width: 20,
    height: 20,
    // opacity: 0.6,
    // backgroundColor: CCColors.Primary.Default,
    // borderRadius: 35,
  },
});

// export default MoveList;
