/* eslint-disable react-native/no-inline-styles */
import React, {useState} from 'react';
import {Platform} from 'react-native';
import {DatePicker as NativeDatePicker} from '@react-native-community/datetimepicker';
import Box from '../Box';
import CCColors from '@/Utils/CCColors';

const CCDatePicker = ({onChange, value}) => {
  // Get the user's local date and format it as 'YYYY-MM-DD' for the web picker
  // const today = new Date().toLocaleDateString('en-CA'); // 'YYYY-MM-DD' format for HTML input

  // Function to format the selected date as 'YYYY-MM-DD'
  const formatDateForInput = date => {
    // return date ? new Date(date).toLocaleDateString('en-CA') : '';

    if (!(date instanceof Date) || isNaN(date.getTime())) return '';
    const offsetDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    );
    return offsetDate.toISOString().split('T')[0];
  };

  const [rawValue, setRawValue] = useState(
    value ? formatDateForInput(value) : '',
  );

  // Parse a date string 'YYYY-MM-DD' into a Date object
  const parseDateFromInput = dateString => {
    if (!dateString || dateString.split('-').length < 3) return null; // Handle incomplete input
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Month is zero-indexed
  };

  // Today's date in 'YYYY-MM-DD' format
  const today = formatDateForInput(new Date());

  // if (Platform.OS === 'web') {
  return (
    <Box
      style={{
        borderRadius: 10,
        borderColor: CCColors.Grey.Border,
        borderWidth: 1,
      }}>
      <input
        type="date"
        value={rawValue} // Use raw string state
        onChange={e => {
          const inputValue = e.target.value;
          setRawValue(inputValue); // Update raw value

          // Parse only if input is complete and valid
          const selectedDate = parseDateFromInput(inputValue);
          if (selectedDate) {
            onChange(selectedDate); // Update parent state
          }
        }}
        style={{
          padding: 15,
          fontSize: 16,
          border: 'none',
          color: rawValue ? 'inherit' : '#A8A7A6',
        }}
        placeholder="mm/dd/yyyy"
        max={today} // Max date is today in user's timezone
      />
    </Box>
  );
  // } else {
  //   return (
  //     <NativeDatePicker
  //       value={value}
  //       mode="date"
  //       display="default"
  //       onChange={(event, selectedDate) => onChange(selectedDate || value)}
  //       maximumDate={new Date()} // Maximum date is today's date in user's timezone
  //     />
  //   );
  // }
};

export default CCDatePicker;
