import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetStandingsApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    any,
    any,
    {
      leagueId: number;
      start_date: string;
      end_date: string;
    },
    unknown
  >(
    async payload => {
      const response = await ChessRestClient.get(
        `leagues/${payload.leagueId}/get_league_standings?start_date=${payload.start_date}&end_date=${payload.end_date}`,
      );
      console.log('Retrieved profile = ', response.data);
      return response.data;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        } else if (handleOnError) {
          handleOnError(data);
        }
      },
    },
  );

  return {
    ...mutation,
  };
}
