/* eslint-disable react/no-unstable-nested-components */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Box from '@/Atoms/Box';
import {Chess} from 'chess.js';
import {useNavigation, useRoute, useLinkTo} from '@react-navigation/native';
import {Video, ResizeMode} from 'expo-av';
import {DrawerActions} from '@react-navigation/native';
import ChessboardComponent from 'chessboard-package';
import {styles, filterStyles, infoStyles} from '../Puzzles/styles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import useAnimation from '@/Hooks/useAnimation';
import Animated from 'react-native-reanimated';
import Ionicons from 'react-native-vector-icons/Ionicons';
import CCText from '@/Atoms/CCText';
import {Table, Row} from 'react-native-table-component';

import {
  useBooksApi,
  useBookOrderApi,
  useBookOrderStatusApi,
  useVideoCoursesApi,
} from '@/Hooks/BooksApi/useBooksApi';
import squares from '../../Assets/Images/Svgs/squares.svg';
import challengeIcon from '../../Assets/Images/Svgs/challengehover.svg';
import logoIcon from '../../Assets/Images/Svgs/logoicon.svg';
import caissaAvatar from '../../Assets/Images/Svgs/caissaAvatar.svg';
import chevronDoubleDown from '../../Assets/Images/Svgs/chevronDoubleDown.svg';
import chevronDoubleUp from '../../Assets/Images/Svgs/chevronDoubleUp.svg';
import useBookPuzzleSolveApi from '@/Hooks/BooksApi/useBookPuzzleSolveApi';
import CCSelect from '@/Atoms/CCSelect';
import {
  View,
  Image,
  Platform,
  ScrollView,
  Pressable,
  Dimensions,
} from 'react-native';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import wrongSound from '../../Assets/Sounds/wrong.mp3';
import bookIcon from '../../Assets/Images/png/book.png';
import chapterIcon from '../../Assets/Images/png/chapter.png';
import search from '../../Assets/Images/png/search.png';
import upload from '../../Assets/Images/png/upload.png';
import discover from '../../Assets/Images/png/book-closed.png';
import emptyBook from '../../Assets/Images/png/empty_book.png';
import emptyResult from '../../Assets/Images/png/empty_result.png';
import useSound from 'use-sound';
import {FontSizes, vs, WINDOW_WIDTH} from '@/Utils/Dimensions';
import CCButton from '@/Atoms/CCButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
// import DocumentPickerfrom 'react-native-document-picker';
import CCTabs from '@/Atoms/CCTabs';
import BookCard from './components/BookCard';
import VideoCard from './components/VideoCard';
import CCTextInput from '@/Atoms/CCTextInput';
import Spacer from '@/Atoms/Spacer';
import SlideModal from '@/Atoms/SlideModal';
import UploadBook from './components/UploadBook';
import ProgressBar from '@/Atoms/ProgressBar';
import Divider from '@/Atoms/Divider/Divider';
import Empty from '@/Atoms/Empty';
import ccleagueBanner from '../../Assets/Images/Svgs/ccleague.svg';
import {
  debounce,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  handleLogout,
} from '@/Utils/CommonUtils';
import {Linking} from 'react-native';
import {ScreenName} from '@/navigators/StackConstants';
import Loader from '@/Atoms/CCLoader/CCLoader';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Assignment from './components/Assignment';
import ChapterList from './components/ChaptersList';
import VideoPlayerWeb from '@/Atoms/VideoPlayerWeb';
import Leaderboard from './components/Leaderboard';
import UploadVideo from './components/UploadVideo';
import ChessboardWithArrow from '@/Components/ChessboardWithArrow';
import CCLeague from './components/CCLeague';
import Meetings from './components/Meetings';
import SubscriptionTab from './components/Subscription';
import {PopUp} from '../Visualisation/components/LevelModal';
import {useLoginState} from '@/Hooks/LoginApi/loginApi';

const ChessBoardLoader = ({children, isLoading = false}) => {
  const [opacityAnimStyle, startOpacityAnim] = useAnimation({
    prop: 'opacity',
    start: 0,
    end: 0.8,
    repeat: true,
  });

  const [isLoadingDelayed, setIsLoadingDelayed] = useState(isLoading);
  // const isLoadingDelayed = isLoading;
  // console.log('loader', isLoading, isLoadingDelayed);
  useEffect(() => {
    // reset isLoadingDelayed after reloading puzzle
    if (isLoading && !isLoadingDelayed) {
      setIsLoadingDelayed(true);
    }

    if (isLoading) {
      startOpacityAnim();
    } else {
      // chessboard has loaded, wait for pieces to finish flying around
      const id = setTimeout(() => {
        setIsLoadingDelayed(false);
        // setIsLoadingDelayed = false;
      }, 600);
      return () => {
        clearInterval(id);
      };
    }
  }, [isLoading]);

  return (
    <View>
      {isLoadingDelayed && (
        <>
          <Animated.View
            style={[
              {
                zIndex: 2,
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: CCColors.ChessBoard.Light,
              },
              opacityAnimStyle,
            ]}
          />
          <Image
            style={{
              zIndex: 1,
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
            source={require('@/Assets/Images/Svgs/chessboard.svg')}
          />
        </>
      )}
      {children}
    </View>
  );
};

const defaultFen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
let orderStatusTimer;

const orderStatusMapping = {
  pending: {
    title: 'Payment Processing',
    content:
      'Your payment is currently being processed. This may take a few moments.',
    subcontent:
      'Your payment is secure. We use industry-standard encryption to protect your information.',
    time: 'Estimated processing time: 2 minutes',
    feedback:
      "Thank you for choosing us! We're working hard to complete your payment smoothly.",
  },
  failure: {
    title: 'Payment Failed',
    content:
      "We're sorry, but we were unable to process your payment at this time.",
    subcontent:
      'Please review your payment details and try again. If you continue to experience issues, please contact your bank or try a different payment method.',
    feedback:
      'We apologize for any inconvenience caused. Thank you for your understanding.',
  },
  success: {
    title: 'Payment Successful',
    content: 'Congratulations! Your transaction has been completed.',
    subcontent:
      'Your payment is secure. We use industry-standard encryption to protect your information.',
    feedback:
      'Thank you for shopping with us! We look forward to serving you again soon.',
  },
};

const Books = ({dashboardData}) => {
  const {isMobileOrTab} = useMediaQuery();
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const userData = dashboardData.user;
  const route = useRoute();
  const navigator = useNavigation();
  const selectedBook = route.params?.bookKey;
  const bookName = route.params?.name;
  const courseId = route.params?.course;
  const {mutate: fetchBooks, data} = useBooksApi();
  const {mutate: verifyPuzzle, data: puzzleData} = useBookPuzzleSolveApi();
  const [orderId, setOrderId] = useState('');
  const [bookResponse, setBookResponse] = useState({});
  const [currentOrderStatus, setCurrentOrderStatus] =
    useState<string>('pending');
  const bookOrderSuccess = async (apiData: any) => {
    if (apiData?.content?.payment_links && orderBookId) {
      await setLocalStorage(
        'book_order',
        JSON.stringify({
          [bookType === 'video_courses' ? 'course_id' : 'book_id']: orderBookId,
          order_id: apiData?.content?.order_id,
        }),
      );
      if (apiData?.content?.payment_links?.web) {
        if (window) {
          window.location.href = apiData?.content?.payment_links?.web;
        } else {
          Linking.openURL(apiData?.content?.payment_links?.web);
        }
      }
    }
  };
  const bookOrderError = apiData => {};
  const {
    mutate: orderBook,
    data: orderData,
    isLoading,
  } = useBookOrderApi(bookOrderSuccess, bookOrderError);

  const bookOrderStatusSuccess = async (apiData: any) => {
    setCurrentOrderStatus(apiData.status);
    clearInterval(orderStatusTimer);
    if (apiData.status === 'success') {
      if (bookType === 'video_courses') {
        getVideoCourses({});
      }
      const bookOrder = await getLocalStorage('book_order');
      const data = JSON.parse(bookOrder);
      await removeLocalStorage('book_order');
      setTimeout(() => {
        setOrderId('');
      }, 2000);
      if (bookResponse['books'] && data.book_id) {
        const res = bookResponse;
        const index = res['books'].findIndex(x => x.id === data.book_id);
        res['books'][index]['paid'] = true;
        res['books'][index]['bought_by'].push(res.user_id);
        console.log(res, 'setBookResponse res');
        setBookResponse(res);
        setBookType('books');
      }
    }
  };

  const bookOrderStatusError = () => {
    clearInterval(orderStatusTimer);
  };

  const {mutate: orderBookStatus, data: orderStatusResponse} =
    useBookOrderStatusApi(bookOrderStatusSuccess, bookOrderStatusError);

  const getVideoCoursesSuccess = data => {
    const res = bookResponse;
    res['video_courses'] = data?.data;
    console.log(res, 'setBookResponse video_courses');
    setBookResponse(res);
    setVideoCourses(data?.data || []);
    setIsVideoCoursesLoaded(true);
  };

  const checkAndSetSelectedVideo = () => {
    if (bookResponse['video_courses'] && courseId) {
      const videoCourses = bookResponse['video_courses'].flatMap(
        v => v.courses,
      );
      const course = videoCourses.find(c => c.id === Number(courseId));
      setSelectedVideo(course);
    }
  };

  const getVideoCoursesError = error => {};

  const {mutate: getVideoCourses} = useVideoCoursesApi(
    getVideoCoursesSuccess,
    getVideoCoursesError,
  );
  const [webinarData, setWebinarData] = useState([]);

  const [userKey, setUserKey] = useState<string>(userData?.user_key);

  const [booksList, setBooksList] = useState([]);
  const [videoCourses, setVideoCourses] = useState([]);
  const [isVideoCoursesLoaded, setIsVideoCoursesLoaded] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [bookType, setBookType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [courseTerm, setCourseTerm] = useState('');

  const [selectedItem, setSelectedItem] = useState({});
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [result, setResult] = useState({text: '', isSuccess: false});
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [showChapters, setShowChapters] = useState<boolean>(false);
  const [videoLoading, setVideoLoading] = useState<boolean>(false);
  const [isChapterHover, setChapterHover] = useState<number>(0);
  const [answer, setAnswer] = useState('');
  const [progress, setProgress] = useState(0);
  const [orderBookId, setOrderBookId] = useState(null);
  const [moveCount, setMoveCount] = useState(0);
  const [opponentMoveFen, setOpponentMoveFen] = useState(null);
  const [isEvaluateRedirect, setIsEvaluateRedirect] = useState(false);
  const [isEvaluateLoader, setIsEvaluateLoader] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setDeleteShowPopup] = useState(false);
  const [reloadLoginState, setReloadLoginState] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // useEffect(() => {
  //   const unsubscribe = navigation.addListener('focus', () => {
  //     fetchBooks({product_type: productType, book_id: selectedBook});
  //     setOrderId(route.params?.order_id);
  //   });

  //   return unsubscribe;
  // }, [navigation]);

  useEffect(() => {
    if (
      (data?.data?.book?.completed || puzzleData?.data?.book_completed) === true
    ) {
      setIsModalVisible(true);
    }
  }, [data?.data?.book?.completed, puzzleData?.data?.book_completed]);

  const productType = useMemo(() => {
    setAnswer('');

    if (bookType === 'user_assignment') {
      return 1;
    }
    return 0;
  }, [bookType]);

  const handleRefetch = product_type => {
    fetchBooks(product_type);
  };

  const importSuccess = (product_type = 0) => {
    fetchBooks({product_type});
    setOpenDrawer(false);
  };

  // useEffect(() => {

  // }, []);

  // useEffect(() => {
  //   if (!selectedBook) {
  //     fetchBooks({product_type: productType, book_id: selectedBook});
  //     setOrderId(route.params?.order_id);
  //   }
  // }, [productType]);

  useEffect(() => {
    if (bookResponse['video_courses'] && courseId) {
      const videoCourses = bookResponse['video_courses'].flatMap(
        v => v.courses,
      );
      const course = videoCourses.find(c => c.id === Number(courseId));
      setSelectedVideo(course);
    }
  }, [bookResponse, courseId]);

  useEffect(() => {
    if (data?.data?.books) {
      setBooksList(data.data?.[bookType]);
      console.log(data?.data, 'setBookResponse data?.data');
      setBookResponse(data?.data);
    }
    if (data?.data?.chapters) {
      const formattedChapers = data.data.chapters?.map((i, index) =>
        i?.current_move
          ? {...i, orientation: 'white', chapter_index: index + 1}
          : {...i, orientation: 'black', chapter_index: index + 1},
      );

      setChapters(formattedChapers);
      let item = formattedChapers[0];
      // setSelectedChapter(item.id);
      let game;
      try {
        game = new Chess(item.fen);
      } catch (error) {
        game = new Chess(defaultFen);
      }

      item = {
        ...item,
        previousFen: game.fen(),
        currentPosition: game.fen(),
        originalPosition: game.fen(),
        // orientation: game.fen().indexOf(' b ') > 0 ? 'Black' : 'White',
        attempts: 0,
      };
      // setResult({text: `${game.fen()indexOf(' b ') > 0 ? 'Black' : 'White'} to play`, isSuccess: false});
      setResult({
        text: (game.turn() === 'b' ? 'Black' : 'White') + ' to play',
        isSuccess: false,
      });
      setSelectedItem(item);
    }
  }, [data]);

  useEffect(() => {
    if (isVideoCoursesLoaded && courseId) {
      checkAndSetSelectedVideo();
    }
  }, [isVideoCoursesLoaded, courseId]);

  useEffect(() => {
    // if (selectedBook) {
    if (isEvaluateRedirect) {
      fetchBooks({product_type: 2});
      setResult({text: '', isSuccess: false});
    } else {
      fetchBooks({book_id: selectedBook, product_type: productType});
      setResult({text: '', isSuccess: false});
    }
    // }
  }, [selectedBook, productType, isEvaluateRedirect]);

  const onSearchBook = debounce(text => {
    let books = bookResponse[bookType];
    if (text?.length) {
      books = books.filter(b =>
        b.book.toLowerCase().includes(text.toLowerCase()),
      );
    }
    setBooksList(books);
  }, 500);

  const onVideoSearch = debounce(text => {
    let videos = bookResponse[bookType];
    if (text.length) {
      videos = videos
        .map(ele => {
          const data = ele.courses.filter(
            v =>
              v.title.toLowerCase().includes(text.toLowerCase()) ||
              v.tags.includes(text.toLowerCase()),
          );
          return {...ele, courses: data};
        })
        .filter(e => e?.courses?.length);
    }
    setVideoCourses(videos);
  }, 500);

  const [resultOpacityAnimStyle, startResultOpacityAnim] = useAnimation({
    prop: 'opacity',
    start: 1,
    end: 1,
    repeat: true,
    numberOfReps: 2,
    inputRange: [0, 0.1],
    duration: 1000,
  });

  const [incorrectSound] = useSound(wrongSound);

  function showSolutionFeedback(isSuccess, stop = false) {
    if (isSuccess) {
      setResult({
        text: `Correct! ${
          stop ? 'Solved Puzzle successfully' : 'Please proceed with next move'
        }`,
        isSuccess: true,
      });
      setSelectedItem(prev => ({
        ...prev,
        solved: true,
      }));
    } else {
      incorrectSound();
      setResult({
        text: 'Your move is not the best. Try again!',
        isSuccess: false,
      });
    }
    startResultOpacityAnim();
  }

  const onChapterChange = value => {
    // setSelectedChapter(value);
    // setResult({text: '', isSuccess: false});
    setAnswer('');
    setMoveCount(0);

    let item = chapters.find(i => i.chapter_index === Number(value));
    let game;
    try {
      game = new Chess(item.fen);
    } catch (error) {
      game = new Chess(defaultFen);
    }
    item = {
      ...item,
      previousFen: game.fen(),
      currentPosition: game.fen(),
      originalPosition: game.fen(),
      // orientation: game.fen().indexOf(' b ') > 0 ? 'Black' : 'White',
      attempts: 0,
    };
    setResult({
      text: (game.turn() === 'b' ? 'Black' : 'White') + ' to play',
      isSuccess: false,
    });
    setSelectedItem(item);
  };

  useEffect(() => {
    if (['user_assignment', 'video_courses'].includes(bookType)) {
      if (bookType === 'video_courses') {
        if (!bookResponse[bookType]) {
          getVideoCourses({});
        } else {
          setVideoCourses(bookResponse[bookType]);
        }
      } else {
        setBooksList(
          bookResponse?.books?.[
            bookType === 'user_assignment' ? 'private' : bookType
          ] || [],
        );
      }
    }
  }, [bookResponse, bookType, getVideoCourses]);

  const handleOpponentMove = stop => {
    if (stop) {
      setResult({
        text: 'Solved Puzzle successfully',
        isSuccess: true,
      });
      return;
    }

    let game;
    try {
      game = new Chess(selectedItem.currentPosition);
    } catch (error) {
      game = new Chess(defaultFen);
    }

    const possibleMoves = game.moves();
    if (possibleMoves.length > 0) {
      const opponentMove = puzzleData?.data?.opponent_move;
      const moveResult = game.move(opponentMove);

      if (moveResult) {
        const newFen = game.fen();
        setOpponentMoveFen(newFen);
        setSelectedItem(prev => ({
          ...prev,
          previousFen: prev.currentPosition,
          currentPosition: newFen,
        }));

        setTimeout(() => {
          setResult({
            text: `Your turn to move!`,
            isSuccess: false,
          });
        }, 500); // Adjust the delay if needed
      } else {
        // If opponent's move is not valid, stop the puzzle
        setResult({
          text: 'No more moves possible. Puzzle complete!',
          isSuccess: true,
        });
      }
    } else {
      setResult({
        text: 'No more moves possible. Puzzle complete!',
        isSuccess: true,
      });
    }
  };

  const handlePieceDrop = tempPosition => {
    // debugger;
    const move = {
      // from: s,
      // to: d,
      // promotion: p,
    };
    // const game = new Chess(selectedItem.originalPosition);
    // const tempResult = game.move(move);
    verifyPuzzle({
      position_index: selectedItem.id,
      fen: selectedItem.currentPosition,
      move: tempPosition.san,
      move_count: moveCount,
    });
    setSelectedItem(prev => ({
      ...prev,
      currentPosition: tempPosition?.newFen,
      lastMove: tempPosition?.lastFen,
      attempts: prev.attempts + 1,
    }));

    // setTimeout(() => {
    //   handleOpponentMove(false);
    // }, 1000);
  };

  useEffect(() => {
    return () => {
      setSelectedItem({});
      setResult({text: '', isSuccess: false});
      setAnswer('');
      setMoveCount(0);
    };
  }, [selectedBook]);

  useEffect(() => {
    const data = puzzleData?.data;
    if (data) {
      showSolutionFeedback(!data?.incorrect, data?.stop);
      if (!data?.incorrect || data?.show_answer) {
        if (data?.answer_text) {
          setAnswer(`${data?.best_move}\n${data?.answer_text}`);
        } else {
          setAnswer(`${data?.best_move}`);
        }

        setMoveCount(data?.move_count);

        if (!data.incorrect && !data.stop) {
          setTimeout(() => {
            handleOpponentMove(puzzleData?.data?.stop);
          }, 1500);
        }
      } else {
        setAnswer('');
      }
      if (data.incorrect) {
        setSelectedItem(prev => {
          const revertedPosition =
            prev.lastMove || prev.previousFen || prev.currentPosition;
          return {
            ...prev,
            currentPosition: revertedPosition,
            originalPosition: revertedPosition,
          };
        });
      } else {
        setSelectedItem(prev => ({
          ...prev,
          originalPosition: prev.currentPosition,
        }));
      }
    }
  }, [puzzleData]);

  const handleOrderBook = data => {
    setOrderBookId(data?.book_id ? data.book_id : data.course_id);
    orderBook(data);
  };

  useEffect(() => {
    const orderStatusCheck = async () => {
      const bookOrder = await getLocalStorage('book_order');
      const payload = JSON.parse(bookOrder);
      if (payload?.order_id) {
        setOrderId(payload.order_id);
        orderStatusTimer = setInterval(() => {
          orderBookStatus(payload);
        }, 1000);
      } else {
        setOrderId('');
      }
    };
    if (route?.params?.order_id) {
      orderStatusCheck();
    }
  }, []);

  useEffect(() => {
    // Simulate payment processing
    let paymentProcessingInterval;
    if (!!orderId && currentOrderStatus === 'pending') {
      paymentProcessingInterval = setInterval(() => {
        setProgress(prevProgress => prevProgress + 1);
        if (progress >= 100) {
          clearInterval(paymentProcessingInterval);
        }
      }, 1000);
    } else {
      clearInterval(paymentProcessingInterval);
    }
    return () => {
      clearInterval(paymentProcessingInterval);
    };
  }, [progress, orderId]);

  const tabs = useMemo(
    () => [
      // {
      //   key: 'private',
      //   label: 'My Books',
      //   component: () => (
      //     <ScrollView
      //       style={{padding: isMobileOrTab ? 0 : 20}}
      //       showsVerticalScrollIndicator={false}>
      //       <Spacer spacing={vs(36)} />
      //       <Box
      //         {...(!isMobileOrTab && {type: 'row-center-between'})}
      //         // type="row-center-between"
      //         style={isMobileOrTab ? {flexDirection: 'column'} : {}}>
      //         <Box
      //           // type="row-flex-start"
      //           {...(!isMobileOrTab && {type: 'row-flex-start'})}
      //           style={isMobileOrTab ? {flexDirection: 'column'} : {}}>
      //           <CCText fontSize={FontSizes[24]} color={CCColors.Primary.Dark}>
      //             My Books
      //           </CCText>
      //           <Spacer spacing={vs(36)} horizontal />
      //           <CCTextInput
      //             icon={search}
      //             fontSize={14}
      //             value={searchTerm}
      //             style={{
      //               width: 300,
      //               height: 40,
      //               backgroundColor: CCColors.White,
      //               overflow: 'hidden',
      //             }}
      //             autoFocus={searchTerm?.length}
      //             placeholder="Search a Book"
      //             onChangeText={text => {
      //               setSearchTerm(text);
      //               onSearchBook(text);
      //             }}
      //             placeholderTextColor={CCColors.TextColor.Grey}
      //           />
      //         </Box>
      //         {isMobileOrTab && <Spacer spacing={10} />}
      //         <Box type={isMobileOrTab ? 'row-center-between' : 'row-flex-start'}>
      //           <CCButton
      //             icon={upload}
      //             titleStyle={{color: CCColors.TextColor.Dark}}
      //             style={{backgroundColor: CCColors.White}}
      //             onPress={() => {
      //               setOpenDrawer(true);
      //             }}>
      //             Upload
      //           </CCButton>
      //           <Spacer spacing={vs(10)} horizontal />
      //           <CCButton
      //             icon={discover}
      //             onPress={() => setBookType('books')}
      //             titleStyle={{color: CCColors.TextColor.Dark}}>
      //             Discover Books
      //           </CCButton>
      //         </Box>
      //       </Box>
      //       <Spacer spacing={vs(36)} />
      //       {booksList?.length ? (
      //         <Box type="row-flex-start" flexWrap="wrap" gap={20}>
      //           {booksList?.map((book, i) => (
      //             <BookCard
      //               {...book}
      //               id={book?.id}
      //               book={book?.name}
      //               paid={!Number(book?.price) ? true : Number(book?.price)}
      //               book_price={book?.price}
      //               book_mrp={book?.mrp}
      //               type="circlechess"
      //               progress={0}
      //             />
      //           ))}
      //         </Box>
      //       ) : (
      //         <Empty
      //           imgSrc={searchTerm?.length ? emptyResult : emptyBook}
      //           title={searchTerm?.length ? 'No results found' : 'No books found'}
      //           description={
      //             searchTerm?.length
      //               ? `We couldn’t find any book matching ”${searchTerm}”
      //           Try again with other book name.`
      //               : 'You can discover and buy from our collection of chess books or upload your own with the .pgn format'
      //           }>
      //           <Box type="row-flex-start">
      //             <CCButton
      //               icon={upload}
      //               titleStyle={{color: CCColors.TextColor.Dark}}
      //               style={{backgroundColor: CCColors.Primary.lightBrown}}
      //               onPress={() => {
      //                 setOpenDrawer(true);
      //               }}>
      //               Upload
      //             </CCButton>
      //             <Spacer spacing={vs(10)} horizontal />
      //             <CCButton
      //               icon={discover}
      //               onPress={() => {
      //                 setBookType('books');
      //               }}
      //               titleStyle={{color: CCColors.TextColor.Dark}}>
      //               Discover Books
      //             </CCButton>
      //           </Box>
      //         </Empty>
      //       )}
      //       <SlideModal
      //         type="side"
      //         width={0.3}
      //         modalHeaderTitle={'Upload Book'}
      //         visible={openDrawer}
      //         onClose={() => {
      //           setOpenDrawer(false);
      //         }}>
      //         <UploadBook
      //           importSuccess={importSuccess}
      //           uploadType={
      //             bookType === 'user_assignment' ? 'assignment' : 'book'
      //           }
      //           buttonName={
      //             bookType === 'user_assignment'
      //               ? 'Upload Assignment'
      //               : 'Upload Book'
      //           }
      //         />
      //       </SlideModal>
      //     </ScrollView>
      //   ),
      // },
      // {
      //   key: 'books',
      //   label: 'Circlechess Store',
      //   component: () => (
      //     <ScrollView style={{padding: 20}}>
      //       <Spacer spacing={vs(36)} />
      //       <Box type={isMobileOrTab ? '' : 'row-center-between'}>
      //         <CCText fontSize={24} color={CCColors.Primary.Dark}>
      //           Circlechess Books
      //         </CCText>
      //         {isMobileOrTab && <Spacer spacing={10} />}
      //         <CCTextInput
      //           icon={search}
      //           fontSize={14}
      //           value={searchTerm}
      //           style={{
      //             width: isMobileOrTab ? 290 : 300,
      //             height: 40,
      //             backgroundColor: CCColors.White,
      //             overflow: 'hidden',
      //           }}
      //           placeholder="Search a Book"
      //           onChangeText={text => {
      //             setSearchTerm(text);
      //             onSearchBook(text);
      //           }}
      //           placeholderTextColor={CCColors.TextColor.Grey}
      //         />
      //       </Box>
      //       <Spacer spacing={vs(36)} />
      //       {booksList?.length ? (
      //         <Box type="row-flex-start" flexWrap="wrap" gap={20}>
      //           {booksList.map((book, i) => (
      //             <BookCard
      //               {...book}
      //               type="circlechess"
      //               userData={userData}
      //               userId={bookResponse['user_id']}
      //               orderBook={handleOrderBook}
      //             />
      //           ))}
      //         </Box>
      //       ) : (
      //         <Empty
      //           imgSrc={searchTerm?.length ? emptyResult : emptyBook}
      //           title={searchTerm?.length ? 'No results found' : 'No books found'}
      //           description={
      //             searchTerm?.length
      //               ? `We couldn’t find any book matching ”${searchTerm}”
      //       Try again with other book name.`
      //               : 'You can discover and buy from our collection of chess books or upload your own with the .pgn format'
      //           }>
      //           <Box type="row-flex-start">
      //             <CCButton
      //               icon={upload}
      //               titleStyle={{color: CCColors.TextColor.Dark}}
      //               style={{backgroundColor: CCColors.Primary.lightBrown}}
      //               onPress={() => {
      //                 setOpenDrawer(true);
      //               }}>
      //               Upload
      //             </CCButton>
      //             <Spacer spacing={vs(10)} horizontal />
      //             <CCButton
      //               icon={discover}
      //               onPress={() => {
      //                 setBookType('books');
      //               }}
      //               titleStyle={{color: CCColors.TextColor.Dark}}>
      //               Discover Books
      //             </CCButton>
      //           </Box>
      //         </Empty>
      //       )}
      //     </ScrollView>
      //   ),
      // },
      {
        key: 'user_assignment',
        label: 'My Assignments',
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{height: '80vh'}}>
            <Assignment
              search={search}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              onSearch={onSearchBook}
              booksList={booksList}
              emptyResult={emptyResult}
              emptyBook={emptyBook}
              upload={upload}
              setBookType={setBookType}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              importSuccess={importSuccess}
              userData={userData}
              userId={bookResponse['user_id']}
              orderBook={handleOrderBook}
              // refetch={handleRefetch}
              // inFocus={bookType === 'user_assignment'}
            />
          </ScrollView>
        ),
      },
      {
        key: 'video_courses',
        label: 'Video Courses',
        component: () =>
          VideoCourses({
            courseTerm,
            setCourseTerm,
            onVideoSearch,
            videoCourses,
            userData,
            handleOrderBook,
          }),
      },
      {
        key: 'meeting',
        label: 'Meetings',
        component: () => <Meetings userKey={userData?.user_key} />,
      },
      {
        key: 'subscription',
        label: 'Subscription',
        component: () => <SubscriptionTab />,
      },
      {
        key: 'ccLeague',
        label: 'CircleChess Leagues',
        isHidden: !userData?.is_staff,
        component: () => <CCLeague />,
      },
    ],
    [
      search,
      searchTerm,
      setSearchTerm,
      onSearchBook,
      booksList,
      emptyResult,
      emptyBook,
      upload,
      setBookType,
      openDrawer,
      setOpenDrawer,
      importSuccess,
      userData,
      handleOrderBook,
      videoCourses,
    ],
  );

  const updateUrl = useCallback(params => {
    navigation.setParams(params);
  }, []);

  useEffect(() => {
    const settingBookType = async () => {
      const routeParams = navigation
        .getState()
        .routes.find(r => r.name === ScreenName?.Books)?.params;
      const urlParams = new URLSearchParams(window.location.search);
      const tabFromUrl = urlParams.get('tab');
      const isEvaluate = await getLocalStorage('isEvaluate');

      if (isEvaluate) {
        setIsEvaluateRedirect(true);
        if (routeParams?.bookType) {
          setBookType(routeParams.bookType);
          return;
        }
      }

      if (tabFromUrl && tabs.some(t => t.key === tabFromUrl)) {
        setBookType(tabFromUrl);
      } else if (
        routeParams &&
        routeParams.tab &&
        tabs.some(t => t.key === routeParams.tab)
      ) {
        setBookType(routeParams.tab);
        updateUrl(routeParams.tab);
      } else if (!isEvaluate) {
        setBookType('meeting');
        updateUrl({tab: 'meeting'});
      }
      setIsEvaluateLoader(false);
    };
    settingBookType();
  }, []);

  const handleTabChange = ({route}) => {
    setBookType(route.key);
    updateUrl({tab: route.key});
  };

  useEffect(() => {
    if (isEvaluateRedirect && bookType === 'user_assignment') {
      handleRefetch?.({product_type: 2});
    } else if (bookType === 'user_assignment') {
      handleRefetch?.({product_type: 1, book_id: selectedBook});
    }
  }, [bookType, isEvaluateRedirect]);

  const handleBackPress = () => {
    navigation.navigate(ScreenName?.Books, {tab: bookType});
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const {
    data: loginData,
    loading,
    isUserLoggedIn,
    error,
  } = useLoginState(reloadLoginState);

  function handleOnCancel() {
    setShowPopup(false);
    setDeleteShowPopup(false);
  }

  function onLoginStateChange() {
    setReloadLoginState(prev => !prev);
    window.location.href = '';
  }

  if (isEvaluateLoader) {
    return <Loader loading bgWhite />;
  }

  return (
    <View
      style={{
        backgroundColor: '#FAF6EB',
        padding: selectedBook ? 0 : isMobileOrTab ? 5 : 20,
        flex: 1,
      }}>
      {/* {isLoading && <Loader loading />} */}
      {!isEvaluateRedirect && selectedBook && (
        <Box
          type="row-flex-start"
          style={{
            backgroundColor: updateColorWithOpacity(
              CCColors?.Primary?.Brown,
              0.8,
            ),
            borderRadius: 8,
            padding: 8,
            margin: 8,
            width: 'fit-content',
          }}
          onPress={() =>
            navigation.navigate(ScreenName.Books, {tab: bookType})
          }>
          <Ionicons name={'arrow-back'} size={30} color={CCColors.White} />
          <CCText fontSize={FontSizes[18]} color={CCColors.White}>
            Back
          </CCText>
        </Box>
      )}
      {showPopup && (
        <SlideModal
          visible={showPopup}
          type="center"
          customStyle={styles.levelModalCustom}>
          <PopUp
            confirm={async () => {
              await handleLogout();
              onLoginStateChange();
            }}
            cancel={handleOnCancel}
            text={'Are you sure you want to logout?'}
            buttonConfirm="Logout"
          />
        </SlideModal>
      )}
      {isEvaluateRedirect && selectedBook && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <Box
            type="row-flex-start"
            style={{
              backgroundColor: updateColorWithOpacity(
                CCColors?.Primary?.Brown,
                0.8,
              ),
              borderRadius: 8,
              padding: 8,
              margin: 8,
              width: 'fit-content',
            }}
            onPress={() => setShowPopup(true)}>
            <CCText fontSize={FontSizes[18]} color={CCColors.White}>
              Logout
            </CCText>
            <Ionicons name={'arrow-forward'} size={30} color={CCColors.White} />
          </Box>
        </View>
      )}
      {selectedVideo && (
        <Box
          type="row-flex-start"
          style={{
            backgroundColor: updateColorWithOpacity(
              CCColors?.Primary?.Brown,
              0.8,
            ),
            borderRadius: 8,
            padding: 8,
            margin: 8,
            width: 'fit-content',
          }}
          onPress={handleBackPress}>
          <Ionicons name={'arrow-back'} size={30} color={CCColors.White} />
          <CCText fontSize={FontSizes[18]} color={CCColors.White}>
            Back
          </CCText>
        </Box>
      )}
      {selectedVideo ? (
        <Box flexDirection="row" flex={1}>
          <Box style={{flex: 0.7}}>
            <Loader loading={videoLoading} />
            <VideoPlayerWeb
              selectedVideo={selectedVideo}
              onLoad={() => setVideoLoading(false)}
            />
            {/* <Video
              // ref={video}
              style={{width: '100%', height: '50%', flex: 1}}
              videoStyle={{width: '100%'}}
              posterStyle={{width: '100%'}}
              source={{
                uri: selectedVideo.s3_path,
              }}
              onLoad={() => setVideoLoading(false)}
              usePoster={videoLoading}
              posterSource={{
                uri: selectedVideo.thumbnail,
              }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
              // isLooping
              // onPlaybackStatusUpdate={status => setStatus(() => status)}
            /> */}
          </Box>
          <ScrollView style={{flex: 0.5}}>
            <Box type="row-center-between" style={{padding: 30}}>
              <Box type="row-flex-start">
                <Image
                  source={bookIcon}
                  style={{width: 80, height: 50}}
                  alt="Book Icon"
                />
                <View>
                  <CCText fontSize={20} style={{textTransform: 'capitalize'}}>
                    {selectedVideo.title}
                  </CCText>
                </View>
              </Box>
              {/* <Box type="row-flex-start" onPress={() => setShowChapters(true)}>
               <CCText fontSize={16} color={CCColors.Green}>
                 Change Chapter
               </CCText>
               <Spacer spacing={vs(12)} horizontal />
               <img src={chapterIcon} style={{width: 20}} alt="chapter icon" />
             </Box> */}
            </Box>
            <Divider />
            {selectedVideo.content && (
              <Box style={{padding: 30}}>
                {selectedVideo.content.topic && (
                  <Box>
                    <CCText fontSize={20} style={{textTransform: 'capitalize'}}>
                      Topics
                    </CCText>
                    <Spacer spacing={vs(20)} />
                    {selectedVideo.content.topic.map(topic => (
                      <Box type="row-flex-start" style={{marginVertical: 5}}>
                        <AntDesign
                          name="checkcircle"
                          size={20}
                          color={CCColors.Primary.Grey}
                          style={{marginRight: 20}}
                        />
                        <CCText
                          fontSize={16}
                          style={{textTransform: 'capitalize'}}>
                          {topic}
                        </CCText>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
            {selectedVideo?.content?.description && (
              <Box>
                <Divider />
                <Box style={{padding: 30}}>
                  <CCText fontSize={20} style={{textTransform: 'capitalize'}}>
                    Description
                  </CCText>
                  <Spacer spacing={vs(20)} />
                  {selectedVideo.content?.description?.heading && (
                    <CCText fontSize={16}>
                      {selectedVideo.content?.description?.heading}
                    </CCText>
                  )}
                  {selectedVideo.content?.description?.content.map(c => (
                    <Box style={{marginVertical: 5}}>
                      {/* <AntDesign
                            name="checkcircle"
                            size={20}
                            color={CCColors.Primary.Dark}
                            style={{marginRight: 20}}
                          /> */}
                      <CCText
                        fontSize={16}
                        style={{textTransform: 'capitalize'}}>
                        {c.id}. {c.title}:
                      </CCText>
                      <CCText fontSize={14}>{c.summary}</CCText>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </ScrollView>
        </Box>
      ) : selectedBook && selectedItem ? (
        <Box flexDirection="row" flex={1}>
          {!isEvaluateRedirect && <Leaderboard bookId={selectedBook} />}
          <Box
            style={
              isMobileOrTab
                ? styles.chessboardContainer
                : styles.chessboardContainerWebBooks
            }>
            <ChessBoardLoader isLoading={isLoading || !selectedItem.id}>
              <ChessboardWithArrow
                position={selectedItem.currentPosition}
                // onPieceDropExtension={handlePieceDrop}
                handleNewFen={handlePieceDrop}
                lastMoveFen={selectedItem.previousFen}
                boardOrientation={selectedItem.orientation}
              />
            </ChessBoardLoader>
            <Animated.View
              style={[
                resultOpacityAnimStyle,
                {
                  backgroundColor: result.isSuccess
                    ? CCColors.Primary.lightBrown
                    : CCColors.Red,
                },
              ]}>
              <CCText
                textAlign="center"
                style={[
                  styles.puzzleSolutionText,
                  {color: result.isSuccess ? CCColors.Green : CCColors.White},
                ]}>
                {result.text}
              </CCText>
            </Animated.View>
            {isEvaluateRedirect && (
              <SlideModal
                modalHeaderTitle="Overall Assessment Summary"
                visible={isModalVisible}
                type="center">
                <View style={styles.modalContainer}>
                  <CCText fontSize={20} style={filterStyles.containerHeading}>
                    Accuracy:{' '}
                    {data?.data?.summary?.correct / data?.data?.summary?.total}%
                  </CCText>
                  <Spacer spacing={vs(20)} />
                  <CCText fontSize={20} style={filterStyles.containerHeading}>
                    Incorrect Attempts:{' '}
                    {data?.data?.summary?.total - data?.data?.summary?.correct}
                  </CCText>
                  <Spacer spacing={vs(20)} />
                  <CCText fontSize={20} style={filterStyles.containerHeading}>
                    {data?.data?.summary?.result_text}
                  </CCText>
                  <Spacer spacing={vs(20)} />
                  <CCText
                    fontSize={16}
                    color={CCColors.Blue}
                    style={{alignSelf: 'center'}}
                    onPress={async () => {
                      await handleLogout();
                      onLoginStateChange();
                    }}>
                    Back to Home
                    <Ionicons
                      name={'arrow-forward'}
                      size={16}
                      color={CCColors.Blue}
                    />
                  </CCText>
                </View>
              </SlideModal>
            )}
          </Box>
          <ScrollView style={{flex: 0.6}}>
            <Box type="row-center-between" style={{padding: 30}}>
              <Box type="row-flex-start">
                <img src={bookIcon} style={{width: 80}} alt="Book Icon" />
                <View>
                  <CCText fontSize={20} style={{textTransform: 'capitalize'}}>
                    {bookName}
                  </CCText>
                  <Spacer spacing={vs(5)} />
                  <ProgressBar
                    style={{
                      height: 6,
                      backgroundColor: 'rgba(38, 35, 34, 0.1)',
                    }}
                    progress={
                      (selectedItem.chapter_index || 1) / chapters?.length
                    }
                    fillStyle={{backgroundColor: '#3DAB9E'}}
                    text={undefined}
                    icon={undefined}
                  />
                  <Spacer spacing={vs(5)} />
                  <CCText>
                    {selectedItem.chapter_index || 1}/{chapters?.length}
                  </CCText>
                </View>
              </Box>
              <Box type="row-flex-start" onPress={() => setShowChapters(true)}>
                <CCText fontSize={16} color={CCColors.Green}>
                  Change Chapter
                </CCText>
                <Spacer spacing={vs(12)} horizontal />
                <img src={chapterIcon} style={{width: 20}} alt="chapter icon" />
              </Box>
            </Box>
            <Divider />
            <View style={{padding: 40}}>
              <View>
                {!!selectedItem.chapter && (
                  <Box>
                    <CCText style={filterStyles.containerHeading}>
                      Chapter: {selectedItem.chapter}
                    </CCText>
                    <Spacer spacing={vs(12)} />
                  </Box>
                )}
                <CCText fontSize={20} style={filterStyles.containerHeading}>
                  {selectedItem.chapter_index}. {selectedItem.sub_chapter}
                </CCText>
                <Spacer spacing={vs(20)} />
                {selectedItem?.comments && (
                  <CCText style={{whiteSpace: 'normal', lineHeight: 30}}>
                    {selectedItem.comments}
                  </CCText>
                )}
                {answer && (
                  <>
                    <Spacer spacing={vs(20)} />
                    <CCText style={filterStyles.containerHeading}>
                      Answer:
                    </CCText>
                    <Spacer spacing={vs(10)} />
                    <CCText>{answer}</CCText>
                  </>
                )}
                <Divider marginTop={40} marginBottom={40} />
                <Box type="row-center-between">
                  <View style={{width: 150}}>
                    {selectedItem.chapter_index > 1 && (
                      <>
                        <CCButton
                          isRounded={false}
                          type="none"
                          titleStyle={{textAlign: 'center'}}
                          style={{
                            backgroundColor: CCColors.White,
                            borderRadius: 8,
                            width: 90,
                            border: `1px solid ${CCColors.Border}`,
                            padding: 10,
                          }}
                          onPress={() =>
                            onChapterChange(selectedItem.chapter_index - 1)
                          }
                          disabled={selectedItem.chapter_index === 1}>
                          Previous
                        </CCButton>
                        <Spacer spacing={vs(20)} />
                        <CCText numberOfLines={2}>
                          {
                            chapters.find(
                              c =>
                                c?.chapter_index ===
                                selectedItem?.chapter_index - 1,
                            )?.sub_chapter
                          }
                        </CCText>
                      </>
                    )}
                  </View>
                  <View style={{width: 150}}>
                    {selectedItem.chapter_index < chapters.length && (
                      <>
                        <CCButton
                          isRounded={false}
                          type="none"
                          titleStyle={{textAlign: 'center'}}
                          onPress={() => {
                            onChapterChange(selectedItem.chapter_index + 1);

                            // onChapterChange(selectedItem.id + 1)
                          }}
                          style={{
                            backgroundColor: CCColors.White,
                            borderRadius: 8,
                            width: 90,
                            alignSelf: 'flex-end',
                            border: `1px solid ${CCColors.Border}`,
                            padding: 10,
                          }}>
                          Next
                        </CCButton>
                        <Spacer spacing={vs(20)} />
                        <CCText numberOfLines={2} style={{textAlign: 'right'}}>
                          {
                            chapters.find(
                              c =>
                                c?.chapter_index ===
                                selectedItem?.chapter_index + 1,
                            )?.sub_chapter
                          }
                        </CCText>
                      </>
                    )}
                  </View>
                </Box>
              </View>
            </View>
          </ScrollView>
          <SlideModal
            type="side"
            width={0.3}
            modalHeaderTitle={'Chapters List'}
            visible={showChapters}
            onClose={() => {
              setShowChapters(false);
            }}>
            <ChapterList
              chapters={chapters}
              onChapterChange={onChapterChange}
              setShowChapters={setShowChapters}
              selectedItem={selectedItem}
              isChapterHover={isChapterHover}
              setChapterHover={setChapterHover}
            />
          </SlideModal>
        </Box>
      ) : (
        <CCTabs
          key={bookType}
          tabIndex={tabs.findIndex(t => t.key === bookType)}
          tabItems={tabs.filter(t => !t?.isHidden)}
          handleTabChange={handleTabChange}
        />
      )}
      {!!orderId && (
        <SlideModal
          type="center"
          width={0.3}
          modalHeaderTitle={
            <CCText fontSize={24}>
              {orderStatusResponse?.status === 'pending'
                ? 'Payment Pending'
                : orderStatusMapping[currentOrderStatus].title}
            </CCText>
          }
          visible={!!orderId}>
          <View style={{margin: 24}}>
            <CCText fontSize={16}>
              {orderStatusMapping[currentOrderStatus].content}
            </CCText>
            {currentOrderStatus === 'pending' && (
              <>
                <ProgressBar
                  style={{height: 6, backgroundColor: 'rgba(38, 35, 34, 0.1)'}}
                  progress={progress / 100}
                  fillStyle={{backgroundColor: '#3DAB9E'}}
                  text={undefined}
                  icon={undefined}
                />
                <Spacer spacing={vs(15)} />
                <CCText fontSize={14} color={CCColors.TextColor.Grey}>
                  {orderStatusMapping[currentOrderStatus].time}
                </CCText>
              </>
            )}
            {currentOrderStatus === 'failure' &&
              orderStatusResponse?.data?.error_message && (
                <CCText textAlign="center" fontSize={18} color={CCColors.Error}>
                  Reason: {orderStatusResponse?.data?.error_message}
                </CCText>
              )}
            <Spacer spacing={vs(10)} />
            <CCText fontSize={16}>
              {orderStatusMapping[currentOrderStatus].subcontent}
            </CCText>
            <Spacer spacing={vs(30)} />
            <CCText fontSize={16}>
              {orderStatusMapping[currentOrderStatus].feedback}
            </CCText>
            {orderStatusResponse?.status &&
              orderStatusResponse?.status !== 'success' && (
                <Box flexDirection="row" justifyContent="center">
                  <CCButton
                    onPress={() => {
                      setOrderId('');
                      removeLocalStorage('book_order');
                    }}>
                    {orderStatusResponse?.status === 'pending'
                      ? 'Close'
                      : 'Try Again'}
                  </CCButton>
                </Box>
              )}
          </View>
        </SlideModal>
      )}
    </View>
  );
};

const VideoCourses: React.FC<any> = ({
  courseTerm,
  setCourseTerm,
  onVideoSearch,
  videoCourses,
  userData,
  handleOrderBook,
}) => {
  const {isMobileOrTab} = useMediaQuery();
  const [openVideoUploadDrawer, setOpenVideoUploadDrawer] = useState(false);
  const [importSuccess, setImportSuccess] = useState(null);
  const [message, setMessage] = useState('');

  const uploadType = 'video';

  const handleImportSuccess = (status: boolean) => {
    setImportSuccess(status);
  };

  useEffect(() => {
    if (!openVideoUploadDrawer && importSuccess !== null) {
      setOpenVideoUploadDrawer(false);
      const messageText = importSuccess
        ? 'Video uploaded successfully!'
        : 'Video upload failed.';
      setMessage(messageText);

      const timer = setTimeout(() => {
        setMessage('');
        setImportSuccess(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [openVideoUploadDrawer, importSuccess]);

  return (
    <ScrollView style={{padding: 20}}>
      <Spacer spacing={vs(16)} />
      <Box type={isMobileOrTab ? '' : 'row-center-between'}>
        <CCText fontSize={24} color={CCColors.Primary.Dark}>
          Video Courses
        </CCText>
        {isMobileOrTab && <Spacer spacing={10} />}
        <CCTextInput
          key="video_courses"
          icon={search}
          fontSize={14}
          value={courseTerm}
          autoFocus={courseTerm.length}
          style={{
            width: isMobileOrTab ? 290 : 300,
            height: 40,
            backgroundColor: CCColors.White,
            overflow: 'hidden',
          }}
          placeholder="Search a course by name or tags"
          onChangeText={text => {
            setCourseTerm(text);
            onVideoSearch(text);
          }}
          placeholderTextColor={CCColors.TextColor.Grey}
        />
        {userData.is_staff && (
          <Box type={isMobileOrTab ? 'row-center-between' : 'row-flex-start'}>
            <CCButton
              icon={upload}
              titleStyle={{color: CCColors.TextColor.Dark}}
              style={{backgroundColor: CCColors.White}}
              onPress={() => {
                setOpenVideoUploadDrawer(true);
              }}>
              Upload Video
            </CCButton>
          </Box>
        )}
      </Box>
      <Spacer spacing={vs(20)} />

      {message && (
        <View
          style={{
            position: 'fixed',
            bottom: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: importSuccess
              ? CCColors.Payment.Success
              : CCColors.Error,
            padding: 16,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}>
          <CCText fontSize={16} color={CCColors.White}>
            {message}
          </CCText>
        </View>
      )}

      {videoCourses.length ? (
        <Box>
          {videoCourses.map((video, i) => (
            <Box style={{marginVertical: 20}}>
              <CCText fontSize={18} color={CCColors.Primary.Dark}>
                {video.category}
              </CCText>
              <Spacer spacing={vs(10)} />
              <Box type="row-flex-start" flexWrap="wrap" gap={20}>
                {video?.courses?.map(course => (
                  <VideoCard
                    {...course}
                    userData={userData}
                    orderBook={handleOrderBook}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Empty
          imgSrc={courseTerm?.length ? emptyResult : emptyBook}
          title={
            courseTerm?.length ? 'No results found' : 'No video courses found'
          }
          description={
            courseTerm?.length
              ? `We couldn’t find any courses matching ”${courseTerm}” Try again with other course name or tag.`
              : 'You can discover books'
          }></Empty>
      )}
      <SlideModal
        type="side"
        width={0.3}
        modalHeaderTitle={'Upload Video Course'}
        visible={openVideoUploadDrawer}
        onClose={() => setOpenVideoUploadDrawer(false)}>
        <UploadVideo
          buttonName={'Upload Video Course'}
          uploadType={uploadType}
          importSuccess={importSuccess}
          setImportSuccess={handleImportSuccess}
          onClose={() => setOpenVideoUploadDrawer(false)}
        />
      </SlideModal>
    </ScrollView>
  );
};

export default Books;
