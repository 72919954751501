import React, {useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity, Text, Linking} from 'react-native';
import CCText from '@/Atoms/CCText';
import Box from '@/Atoms/Box';
import AntDesign from 'react-native-vector-icons/AntDesign';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import CCColors from '@/Utils/CCColors';
import CCButton from '@/Atoms/CCButton';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {isPhone} from '@/Utils/Dimensions';
import useGetPlanApi from '@/Hooks/SubscriptionApi/useGetPlan';
import useGeneratePaymentApi from '@/Hooks/SubscriptionApi/useGeneratePayment';
import useGetUserActivePlanApi from '@/Hooks/UserActivePlanApi/useGetUserActivePlan';
import {
  useUserPlanStore,
  useNonPersistedUserPlanStore,
} from '@/Store/userPlanStore';
import ConditionalRender from '@/Atoms/ConditionalRender';
import SlideModal from '@/Atoms/SlideModal';
import {formatCurrency} from '@/Utils/CommonUtils';
import Spacer from '@/Atoms/Spacer';

interface SubscriptionModalProps {
  visible?: boolean;
  onClose?: () => void;
  setVisible?: () => void;
  user?: any;
  planMessage?: string;
  currentPlan?: string;
}

const SubscriptionModal = ({
  visible,
  onClose,
  setVisible,
  user,
  planMessage,
  currentPlan,
}: SubscriptionModalProps) => {
  const [tier, setTier] = useState('');
  const navigation = useNavigation();
  const [allPlans, setAllPlans] = useState([]);
  const {isMobileOrTab} = useMediaQuery();
  const {subscriptionModalOpen = false, setSubscriptionModalOpen = () => {}} =
    useNonPersistedUserPlanStore();
  const store = useUserPlanStore();
  const [planArray, setPlanArray] = useState([]);
  const [presentPlan, setPresentPlan] = useState('');

  const handleOnSuccessGetPlan = data => {
    setAllPlans(data?.data?.available_plans);

    setPresentPlan(
      data?.data?.user_subscription_plan_name.split(' ').slice(0, 2).join(' '),
    );

    setPlanArray(
      data?.data?.available_plans.filter(
        plan => plan.plan_id === 5 || plan.plan_id === 4,
      ),
    );
  };
  const handleOnErrorGetPlan = () => {};

  const {mutate: getActivePlan, data: planData} = useGetUserActivePlanApi(
    handleOnSuccessGetPlan,
    handleOnErrorGetPlan,
  );

  const handleOnSuccessPlan = data => {
    console.log('data', data, user);
    if (data?.data?.plan_id) {
      generatePayment({
        customer_id: user?.user_id,
        mobile_number: user?.mobile_number,
        email: user?.email,
        subscription_plan_id: data?.data?.plan_id,
      });
    }
  };

  const handleOnErrorPlan = error => {
    console.log('error while fetching plan', error);
  };

  const {
    mutate: getPlan,
    data,
    error,
    isLoading,
  } = useGetPlanApi(handleOnSuccessPlan, handleOnErrorPlan);

  useEffect(() => {
    if (subscriptionModalOpen) {
      getActivePlan();
    }
  }, [subscriptionModalOpen]);

  const handleOnSuccessGeneratePayment = data => {
    console.log('successfully generated payment link', data);
  };

  const handleOnErrorGeneratePayment = error => {
    console.log('error while generating payment', error);
  };

  const handleCloseModal = () => {
    onClose?.();
    setSubscriptionModalOpen?.(false);
  };

  const {
    mutate: generatePayment,
    data: paymentUrlData,
    error: paymentUrlDataError,
    isLoading: paymentLoading,
  } = useGeneratePaymentApi(
    handleOnSuccessGeneratePayment,
    handleOnErrorGeneratePayment,
  );

  useEffect(() => {
    if (isMobileOrTab) {
      if (paymentUrlData?.data?.content?.payment_links?.web) {
        Linking.openURL(paymentUrlData?.data?.content?.payment_links?.web);
      }
    } else {
      if (paymentUrlData?.data?.content?.payment_links?.web) {
        // @ts-ignore
        window.location.href =
          paymentUrlData?.data?.content?.payment_links?.web;
      }
    }
  }, [paymentUrlData]);

  return (
    <SlideModal visible={subscriptionModalOpen} onClose={handleCloseModal}>
      {/* <Box flexDirection="row" style={styles.overlay}> */}
      <Box style={styles.modalContainer}>
        <Box
          justifyContent="center"
          alignItems="center"
          style={{
            width: isMobileOrTab ? 40 : 72,
            height: isMobileOrTab ? 40 : 72,
            borderRadius: '50%',
            backgroundColor: CCColors.Pink,
            marginTop: 10,
            marginBottom: 10,
          }}>
          <FontAwesome
            name="diamond"
            size={isMobileOrTab ? 20 : 40}
            color={CCColors.White}
          />
        </Box>

        <CCText
          fontSize={isMobileOrTab ? 10 : 18}
          fontWeight="regular"
          textAlign="center"
          style={{width: '85%', marginLeft: 'auto', marginRight: 'auto'}}>
          {planMessage}
        </CCText>
        <CCText
          fontSize={isMobileOrTab ? 10 : 18}
          fontWeight="regular"
          textAlign="center"
          style={{width: '70%', marginLeft: 'auto', marginRight: 'auto'}}>
          Current plan: {presentPlan}
        </CCText>

        <CCText
          fontSize={isMobileOrTab ? 10 : 14}
          fontWeight="regular"
          textAlign="center"
          style={{
            width: '70%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 5,
          }}>
          {user?.source === 'chessklub'
            ? `You have reached limit of your account. For upgradation please reach out to ${user?.source} admin of your account.`
            : 'Upgrade now to unlock and boost your daily limit for accessing our premium features'}
        </CCText>

        {user?.source !== 'chessklub' ? (
          <>
            <Box
              flexDirection={!isMobileOrTab ? 'row' : 'column'}
              gap={isMobileOrTab ? 10 : 20}
              style={{flexWrap: 'wrap', justifyContent: 'center'}}
              marginTop={20}>
              {presentPlan !== 'Aspiring Learners' &&
                allPlans
                  ?.filter(i => i?.tenure?.toUpperCase() !== 'LIFETIME')
                  .map((i, index) => (
                    <Box
                      key={index}
                      onPress={() => setTier(i?.name)}
                      gap={10}
                      style={{
                        borderWidth: tier === i?.name ? 2 : 1,
                        borderColor:
                          tier === i?.name
                            ? CCColors.Red
                            : CCColors.BorderBottom,
                        borderRadius: 8,
                        padding: 20,
                        paddingRight: 70,
                        // flexBasis: '28%',
                        maxWidth: !isMobileOrTab ? '30%' : '100%',
                        marginBottom: !isMobileOrTab ? 20 : 10,
                      }}>
                      <CCText
                        fontSize={isMobileOrTab ? 10 : 14}
                        fontWeight="medium"
                        textAlign="center">
                        {i?.name}
                      </CCText>
                      <Box flexDirection="row" gap={5} alignItems="flex-end">
                        <CCText
                          fontSize={isMobileOrTab ? 18 : 24}
                          fontWeight="semibold">
                          {formatCurrency(i?.price, i?.currency)}
                        </CCText>
                        <CCText fontWeight="regular" fontSize={12}>
                          {i?.tenure}
                        </CCText>
                      </Box>
                    </Box>
                  ))}
              {presentPlan === 'Aspiring Learners' &&
                planArray
                  ?.filter(i => i?.tenure?.toUpperCase() !== 'LIFETIME')

                  .map((i, index) => (
                    <Box
                      key={index}
                      onPress={() => {
                        setTier(i?.name);
                      }}
                      gap={20}
                      style={{
                        borderWidth: tier === i?.name ? 2 : 1,
                        borderColor:
                          tier === i?.name
                            ? CCColors.Red
                            : CCColors.BorderBottom,
                        borderRadius: 8,
                        padding: 20,
                        paddingRight: 70,
                        // flexBasis: '28%',
                        maxWidth: !isMobileOrTab ? '40%' : '100%',
                        marginBottom: !isMobileOrTab ? 20 : 10,
                      }}>
                      <CCText
                        fontSize={isMobileOrTab ? 10 : 14}
                        fontWeight="medium"
                        textAlign="center">
                        {i?.name}
                      </CCText>
                      <Box flexDirection="row" gap={5} alignItems="flex-end">
                        <CCText
                          fontSize={isMobileOrTab ? 18 : 24}
                          fontWeight="semibold">
                          {formatCurrency(i?.price, i?.currency)}
                        </CCText>
                        <CCText fontWeight="regular" fontSize={12}>
                          {i?.tenure}
                        </CCText>
                      </Box>
                    </Box>
                  ))}
            </Box>

            <TouchableOpacity
              onPress={() => {
                if (
                  tier === 'aspiringLearners' ||
                  tier === 'aspiringChampions'
                ) {
                  store.setChoosenPlan(
                    tier === 'aspiringLearners'
                      ? 'aspiringLearners'
                      : 'aspiringChampions',
                  );
                  store.setTenure('yearly');
                  getPlan({
                    plan_type: tier === 'aspiringLearners' ? 'casual' : 'PRO',
                    tenure: 'yearly',
                  });
                } else {
                  navigation.navigate(ScreenName.Subscription);
                }
                setSubscriptionModalOpen(false);
                if (setVisible) {
                  setVisible(false);
                }
              }}
              style={{
                backgroundColor: CCColors.Primary.Default,
                width: '80%',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: 32,
                alignItems: 'center',
                paddingVertical: 16,
                marginVertical: 20,
                marginBottom: 40,
              }}>
              <CCText>
                {tier === 'aspiringLearners' || tier === 'aspiringChampions'
                  ? 'Upgrade Now'
                  : 'See More Details and Upgrade Now'}
              </CCText>
            </TouchableOpacity>
          </>
        ) : (
          <Spacer spacing={40} />
        )}
      </Box>
      {/* </Box> */}
    </SlideModal>
  );
};

export default SubscriptionModal;

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: CCColors.Modal.Background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CCColors.White,
    borderRadius: 10,
    padding: 10,
    margin: 0,
    width: isPhone ? '70%' : '70vw',
  },
  closeButton: {
    marginTop: 20,
    color: CCColors.Blue,
  },
});
