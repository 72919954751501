import CCColors from '@/Utils/CCColors';
import {faEquals} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Image} from 'react-native';
import chevronDoubleDown from '../../../../Assets/Images/Svgs/chevronDoubleDown.svg';
import chevronDoubleUp from '../../../../Assets/Images/Svgs/chevronDoubleUp.svg';

export const renderStatusIcon = status => {
  if (status === 'no change') {
    return (
      <FontAwesomeIcon
        icon={faEquals}
        style={{
          width: 12,
          height: 12,
          color: CCColors.TextColor.Grey,
        }}
      />
    );
  }

  return (
    <Image
      style={{
        height: 16,
        width: 16,
        backgroundColor: CCColors.White,
        borderRadius: '50%',
      }}
      source={status === 'increased' ? chevronDoubleUp : chevronDoubleDown}
    />
  );
};
