import {
  FontSizes,
  hs,
  isPhone,
  vs,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
} from '../../Utils/Dimensions';

import CCColors from '../../Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  logo: {
    width: 200,
    margin: 'auto',
  },
  formHalf: {
    margin: 'auto',
    marginTop: '5%',
    padding: vs(64),
  },
  formHalfMobile: {
    padding: vs(10),
  },
  testimonialSection: {
    justifyContent: 'space-evenly',
  },
  loginHeader: {
    width: 320,
    marginHorizontal: vs(10),
    color: '#806234',
    borderBottomColor: CCColors.Grey.Shade200,
    borderBottomWidth: 0.5,
    alignSelf: 'center',
  },
  loginSubheader: {
    marginTop: vs(24),
    color: '#806234',
  },
  signInTypo: {
    textAlign: 'right',
    left: '50%',
    top: 48,
    color: CCColors?.Primary?.Default,
    position: 'absolute',
  },
  labelText: {
    shadowColor: '#080808',
  },
  buttonStyles: {
    fontSize: FontSizes[9],
    paddingVertical: vs(3),
    color: CCColors.Texts.Regular,
  },
  buttonWrapper: {
    backgroundColor: CCColors.Buttons.Background,
    borderRadius: 32,
    width: 320,
    margin: 'auto',
  },
  buttonWrapperMobile: {
    width: '70%',
  },
  container: {
    height: WINDOW_HEIGHT,
    width: WINDOW_WIDTH,
  },
  textBoxes: {
    height: 48,
    flex: 0.85,
    width: vs(320),
    // ...(isPhone && {width: '90%'}),
    borderRadius: 8,
    borderColor: CCColors.TextBoxes.Borders,
    borderWidth: 1,
  },
  textHeaders: {
    color: CCColors.TextColor.Primary,
    fontSize: 24,
  },
  rightlabelText: {
    paddingLeft: vs(120),
    flex: 0.5,
    marginTop: '6%',
  },
  uspImage: {
    width: vs(100),
    height: vs(100),
    margin: 30,
  },
  uspText: {
    justifyContent: 'center',
    width: 125,
    textAlign: 'center',
    marginTop: 4,
    color: '#4D3F37',
  },
  testimonialText: {
    width: '100%',
    padding: 16,
    letterSpacing: 2,
  },
  testimonialAuthor: {
    width: '100%',
    padding: 16,
    letterSpacing: 2,
    fontWeight: 'bold',
  },
  testimonialView: {
    width: vs(200),
    backgroundColor: 'white',
    alignSelf: 'baseline',
    margin: vs(8),
    shadowColor: 'grey',
    shadowOpacity: 0.1,
    shadowRadius: 6,
    shadowOffset: {width: 2, height: 2},
  },
  LeftTop: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  LeftHeading: {
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    color: '#262322',
  },
  LeftSubtitle: {
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    marginTop: 32,
    color: '#4D3F37',
  },
  icon: {
    height: 35,
    width: 35,
    color: CCColors.BlueAqua,
  },
  modal: {
    backgroundColor: 'white',
    zIndex: 5,
    overflow: 'hidden',
    bottom: 0,
    width: '100%',
    maxHeight: '80%',
    position: 'absolute',
  },
});
