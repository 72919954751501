import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import {Colors} from '@/styles';
import CCColors from '@/Utils/CCColors';
import React from 'react';
import {View, Image, ScrollView} from 'react-native';
const LeagueList = ({
  leagues = [],
  isMobileOrTab = false,
  selectedLeague,
  handleSelectedLeague,
  squares,
}) => {
  return isMobileOrTab ? (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      {leagues.map(league => (
        <Box
          key={league.id}
          onPress={() => handleSelectedLeague(league)}
          style={{
            marginRight: 8,
            paddingVertical: 10,
            backgroundColor:
              selectedLeague?.id === league.id
                ? Colors.primaryDark
                : CCColors.White,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            borderRadius: 8,
            shadowColor: '#4D3F37', // Shadow color
            shadowOffset: {
              width: 0, // Horizontal shadow offset
              height: 4, // Vertical shadow offset
            },
            shadowOpacity: 0.15, // Shadow transparency
            shadowRadius: 8, // How blurry the shadow is
            elevation: 4,
          }}>
          <Box
            flexDirection="row"
            marginVertical={8}
            justifyContent="center"
            marginHorizontal={10}
            gap={8}>
            <Image
              style={{
                height: 35,
                width: 35,
              }}
              source={league.image}
            />
            <Box flexDirection="column" justifyContent="center">
              <CCText
                color={
                  selectedLeague?.id === league.id
                    ? CCColors.White
                    : CCColors.Black
                }
                fontSize={12}
                fontWeight="bold">
                {league.name}
              </CCText>
            </Box>
          </Box>
          {selectedLeague?.id === league.id ? (
            <Image
              style={{
                height: '100%',
                width: 50,
                position: 'absolute',
                zIndex: 100,
                right: 0,
              }}
              source={squares}
            />
          ) : null}
        </Box>
      ))}
    </ScrollView>
  ) : (
    leagues.map(league => (
      <Box
        key={league.id}
        onPress={() => handleSelectedLeague(league)}
        style={{
          backgroundColor:
            selectedLeague?.id === league.id
              ? Colors.primaryDark
              : CCColors.White,
          flexDirection: 'row',
          position: 'relative',
          borderRadius: 8,
          shadowColor: '#4D3F37', // Shadow color
          shadowOffset: {
            width: 0, // Horizontal shadow offset
            height: 4, // Vertical shadow offset
          },
          shadowOpacity: 0.15, // Shadow transparency
          shadowRadius: 8, // How blurry the shadow is
          elevation: 4,
        }}>
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          marginHorizontal={10}
          style={{
            paddingVertical: 15,
          }}
          gap={20}>
          <Image
            style={{
              height: 30,
              width: 30,
            }}
            source={league.image}
          />
          <Box flexDirection="column" justifyContent="center" flexWrap="wrap">
            <CCText
              color={
                selectedLeague?.id === league.id
                  ? CCColors.White
                  : CCColors.Black
              }
              fontSize={16}
              fontWeight="bold">
              {league.name}
            </CCText>
            <CCText
              color={
                selectedLeague?.id === league.id
                  ? CCColors.White
                  : CCColors.Black
              }
              fontSize={12}>
              {league.players_count}{' '}
              {league.players_count > 1 ? 'members' : 'member'}
            </CCText>
          </Box>
        </Box>
        {selectedLeague?.id === league.id ? (
          <Image
            style={{
              height: '100%',
              width: 50,
              position: 'absolute',
              zIndex: 100,
              right: 0,
            }}
            source={squares}
          />
        ) : null}
      </Box>
    ))
  );
};

export default LeagueList;
