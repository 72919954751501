import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCColors from '@/Utils/CCColors';
import {FontSizes} from '@/Utils/Dimensions';
import React, {useState} from 'react';
import {ScrollView, StyleSheet} from 'react-native';

const TncContext = ({terms, onAgree}) => {
  const isMobileOrTab = useMediaQuery();

  const [hasReadEnough, setHasReadEnough] = useState(false);

  const handleScroll = event => {
    const {contentOffset, contentSize, layoutMeasurement} = event.nativeEvent;

    // Calculate the scroll percentage
    const totalScrollableHeight = contentSize.height - layoutMeasurement.height;
    const currentScrollPosition = contentOffset.y;
    const percentage = (currentScrollPosition / totalScrollableHeight) * 100;

    if (percentage >= 30 && !hasReadEnough) {
      setHasReadEnough(true);
    }
  };
  const [isPressed, setIsPressed] = useState(false);
  const handleAgree = () => {
    if (onAgree) {
      onAgree();
      setIsPressed(true);
    }
  };
  const getCommonStyles = isMobileOrTab => ({
    paddingLeft: isMobileOrTab ? 12 : 5,
    fontSize: FontSizes[13],
  });
  return (
    <Box style={{flex: 1, position: 'relative'}}>
      <ScrollView showsVerticalScrollIndicator={false} onScroll={handleScroll}>
        <Box
          flexDirection="column"
          gap={isMobileOrTab ? 8 : 5}
          style={{paddingRight: 15}}>
          <>
            <CCText fontWeight={500} fontSize={FontSizes[13]}>
              Welcome to CircleChess! By using our platform and services, you
              agree to comply with the following Terms and Conditions. Please
              read them carefully before proceeding.
            </CCText>
            <CCText fontWeight={600} fontSize={FontSizes[14]}>
              Authorization for Data Collection from Third Parties:
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • The User explicitly authorizes CircleChess to access and collect
              data from third-party chess platforms, including but not limited
              to Chess.com and Lichess, to provide analytics and insights as
              requested by the User.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • CircleChess will access and collect data from third-party chess
              platforms only upon explicit request from the User.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • CircleChess will cease data collection immediately upon
              receiving a request from the User to stop such activities.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • The User confirms that they hold the necessary permissions to
              authorize such data collection.
            </CCText>
            <CCText fontWeight={600} fontSize={FontSizes[14]}>
              Data Privacy and Security:
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • CircleChess is committed to protecting user data in accordance
              with applicable privacy laws.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • User data will be collected, stored, and used as described in
              our Privacy Policy. By using the platform, you consent to these
              practices.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • CircleChess will ensure that data is stored securely and will
              not be shared with unauthorized third parties without explicit
              consent.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • CircleChess guarantees that user data will not be sold or shared
              with any third parties for marketing or other purposes without the
              User’s explicit consent.
            </CCText>
            <CCText fontWeight={600} fontSize={FontSizes[14]}>
              Account Registration:
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • Users are required to register for an account to access certain
              features of CircleChess. You must provide accurate and complete
              information during registration.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • You are responsible for maintaining the confidentiality of your
              account credentials and for any activities conducted under your
              account.
            </CCText>
            <CCText fontWeight={600} fontSize={FontSizes[14]}>
              Service Description:
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • CircleChess offers chess-related services, including analytics,
              training tools, and community engagement features.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • The scope and functionality of services may be updated or
              modified at CircleChess’s discretion.
            </CCText>
            <CCText fontWeight={600} fontSize={FontSizes[14]}>
              User Conduct:
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • Users agree to use CircleChess in a lawful and respectful
              manner.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • Prohibited activities include but are not limited to: spreading
              misinformation, engaging in hate speech, or attempting to hack or
              disrupt the platform.
            </CCText>
            <CCText fontWeight={600} fontSize={FontSizes[14]}>
              Payment and Refund Policy:
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • Certain features or services on CircleChess may require payment.
              Users agree to pay all applicable fees as specified during the
              subscription or purchase process.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • Refunds will be issued at CircleChess’s discretion and in
              accordance with our refund policy.
            </CCText>
            <CCText fontWeight={600} fontSize={FontSizes[14]}>
              Intellectual Property:
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • All content provided by CircleChess, including analytics,
              training tools, and visual designs, is the intellectual property
              of CircleChess and is protected under copyright laws.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • Users are granted a limited, non-transferable license to use
              CircleChess’s content for personal or non-commercial purposes.
            </CCText>
            <CCText fontWeight={600} fontSize={FontSizes[14]}>
              Limitation of Liability:
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • CircleChess is not liable for any direct, indirect, incidental,
              or consequential damages arising from the use of the platform.
            </CCText>
            <CCText style={getCommonStyles(isMobileOrTab)}>
              • The platform is provided "as is," and CircleChess makes no
              warranties, express or implied, regarding its functionality or
              availability.
            </CCText>
            <Spacer spacing={10} />
            <Box
              style={{
                paddingRight: isMobileOrTab ? 30 : 20,
              }}>
              {terms === 2 ? (
                <CCText fontWeight={500} fontSize={FontSizes[13]}>
                  By signing up for CircleChess, you acknowledge that you have
                  read, understood, and agreed to these Terms and Conditions.
                </CCText>
              ) : (
                <CCText fontWeight={500} fontSize={FontSizes[13]}>
                  By signing up for CircleChess, you acknowledge that you have
                  read, understood, and agreed to these Terms and Conditions,
                  including their retrospective application to any prior use of
                  CircleChess services.
                </CCText>
              )}
            </Box>
          </>
        </Box>
        <Spacer spacing={isMobileOrTab ? 30 : 25} />
      </ScrollView>
      {hasReadEnough && (
        <Box style={styles.button}>
          <CCButton
            onPress={handleAgree}
            contentContainerStyle={{
              paddingHorizontal: 15,
              backgroundColor: isPressed
                ? CCColors.DarkBlueAqua
                : CCColors.BlueAqua,
            }}>
            <CCText
              fontWeight={600}
              color={CCColors.White}
              fontSize={FontSizes[14]}>
              Agree
            </CCText>
          </CCButton>
        </Box>
      )}
    </Box>
  );
};

export default TncContext;

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    zIndex: 100,
    right: 2,
    bottom: 2,
    borderRadius: '40%',
    shadowColor: '#000', // Shadow color
    shadowOffset: {width: 0, height: 3}, // Shadow offset
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 2, // Shadow radius
    elevation: 5,
  },
});
