import CryptoJS from 'crypto-js';
import ENV from '../../environment';
import {Platform} from 'react-native';
// import RNFetchBlob from "rn-fetch-blob";
import {ScreenName} from '../navigators/StackConstants';
import dayjs from 'dayjs';
import localForage from 'localforage';
// import {useNavigation} from '@react-navigation/native';
import {Chess} from 'chess.js';
import {CommonActions, useNavigation, useRoute} from '@react-navigation/native';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

let RNFetchBlob;

if (Platform.OS !== 'web') {
  RNFetchBlob = require('rn-fetch-blob').default;
}

const emailRegex = /^([a-zA-Z0-9]+)@([a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*)$/;

export const isValidEmail = (email: string) => {
  return emailRegex.test(email);
};

export const downloadPDF = (url: string) => {
  if (Platform.OS !== 'web') {
    // Native platform-specific code
    let dirs = RNFetchBlob.fs.dirs;
    RNFetchBlob.config({
      fileCache: true,
      addAndroidDownloads: {
        useDownloadManager: true,
        notification: true,
        mime: 'application/pdf',
        description: 'File downloaded by download manager.',
        path: `${dirs.DownloadDir}/your-file-name.pdf`,
      },
    })
      .fetch('GET', url)
      .then(res => {
        console.log('The file is saved to ', res.path());
      })
      .catch(e => {
        console.error(e);
      });
  } else {
    console.log(url, 'url');
    // Web-specific code
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Network response was not ok');
      })
      .then(blob => {
        // Create blob link to download
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'your-file-name.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
      })
      .catch(e => {
        console.error('Fetch error:', e);
      });
  }
};

export function capitalizeFirstLetter(input: string): string {
  if (!input) return ''; // Return an empty string if input is falsy
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function getScreenName(name: string) {
  if (!name) return '';
  return ScreenName[name];
}

export const encryptData = (data: any) => {
  return CryptoJS.AES.encrypt(data, ENV.API_KEY);
};

export const setLocalStorage = async (
  name: string,
  value: any,
  isEncrypted: boolean = false,
  callbackFunction?: () => void,
) => {
  if (value && name) {
    await localForage.setItem(name, value).then(() => {
      callbackFunction?.();
    });
  }
};

export const getUserDetails = async () => {
  const result = await localForage.getItem('user_details');
  return result;
};

export const getLocalStorage = async (name: string) => {
  if (name) {
    const result = await localForage.getItem(name);
    return result;
  }
};

export const removeLocalStorage = async (name: string) => {
  if (name) {
    await localForage.removeItem(name);
    return true;
  }
  return false;
};

export const isUserLoggedIn = () => {
  return getLocalStorage('user-key')
    ?.then(data => {
      console.log('user-key', data);
      if (data) return true;
      return false;
    })
    .catch(() => false);
  // if (getLocalStorage("user-key")) {
  //   console.log(getLocalStorage("user-key"), "user-key");
  //   return true;
  // }
  // return false;
};

export const handleLogout = async (navigateToFunc?: () => void) => {
  await removeLocalStorage('third_party_mobile');
  await removeLocalStorage('access_token');
  await removeLocalStorage('refresh_token');
  await removeLocalStorage('user_details');
  localStorage.setItem('logoutEvent', Date.now());
  navigateToFunc?.();
  if (!navigateToFunc) {
    // const navigation = useNavigation<any>();
    // navigation?.navigate(ScreenName?.Home);
  }
};

export function formatDateToYYYYMMDD(dateString) {
  // Parse the given date string into a Date object
  const date = new Date(dateString);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() returns 0 for January, 11 for December
  const day = date.getDate();

  // Format month and day to ensure they are in 'MM' and 'DD' format
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  // Construct the formatted date string
  return `${year}-${formattedMonth}-${formattedDay}`;
}
export function formatDateToDDMMYYYY(dateString) {
  // Parse the given date string into a Date object
  const date = new Date(dateString);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() returns 0 for January, 11 for December
  const day = date.getDate();

  // Format month and day to ensure they are in 'MM' and 'DD' format
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  // Construct the formatted date string
  return `${formattedDay}-${formattedMonth}-${year}`;
}

/**
 * Formats a datetime string into a specific format.
 *
 * @param {string} inputDateTime - The datetime string in ISO format ("YYYY-MM-DDTHH:MM:SS").
 * @returns {string} The formatted datetime string in the format "DDth MMM ‘YY @ hh:mm A".
 *
 * @example
 * formatDate("2023-08-25T12:30:00")
 * // returns "23rd Aug ‘23 @ 12:30 PM"
 */

export function formatDate(inputDateTime: string | undefined): string {
  if (!inputDateTime) return '';
  // const date = dayjs(inputDateTime);

  // Convert the input UTC datetime to the user's local time zone
  const date = dayjs.utc(inputDateTime);

  const day = date.date();
  let dayOrdinal = day.toString();
  if (day % 10 === 1 && day !== 11) {
    dayOrdinal += 'st';
  } else if (day % 10 === 2 && day !== 12) {
    dayOrdinal += 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    dayOrdinal += 'rd';
  } else {
    dayOrdinal += 'th';
  }

  // Get the month name
  const monthName = date.format('MMM');

  // Get the last two digits of the year
  const year = date.format('YY');

  // Get the time in 12-hour format with AM/PM
  const timeStr = date.format('hh:mm A');

  // Compose the final date-time string
  const formattedDate = `${dayOrdinal} ${monthName} ‘${year} @ ${timeStr}`;

  return formattedDate;
}

let timeout: NodeJS.Timeout | null = null;

export function debounce<F extends (...args: any[]) => any>(
  func: F,
  wait: number,
): F {
  // @ts-ignore
  // @ts-ignore
  return function (this: any, ...args: Parameters<F>): ReturnType<F> {
    const later = () => {
      timeout = null;
      return func.apply(this, args);
    };

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
  } as F;
}

export function areObjectsEqual(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (
    a == null ||
    typeof a !== 'object' ||
    b == null ||
    typeof b !== 'object'
  ) {
    return false;
  }

  let keysA: string[] = Object.keys(a),
    keysB: string[] = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let key of keysA) {
    if (!keysB.includes(key) || !areObjectsEqual(a[key], b[key])) {
      return false;
    }
  }
  return true;
}

export function transformObjectToCategoryStr(obj: {
  [key: string]: string | number;
}): string[] {
  let result: string[] = [];

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result.push(`INR ${obj[key]} / ${key}`);
    }
  }

  return result;
}

export function convertToReadableString(key: string) {
  if (key && key.indexOf('_') >= 0) {
    let tokens = key.split('_');
    for (var i = 0; i < tokens.length; i++) {
      tokens[i] =
        tokens[i].charAt(0).toUpperCase() + tokens[i].slice(1).toLowerCase();
    }
    return tokens.join(' ');
  } else {
    return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
  }
}

export function convertGameScoreToTextSummary(o) {
  const obj = o?.data;
  console.log('Obj = ', obj);
  if (obj === null) return {};

  const reviewSummary = {};

  const blunders_less_than_ten_secs = obj['blunder_less_than_ten_secs'];
  const blunders_less_than_two_mins = obj['blunder_less_than_two_mins'];
  const total_blunders = obj['blunders'];
  const solved_puzzles = obj['solved_puzzles'];
  const unsolved_puzzles = obj['unsolved_puzzles'];
  const accuracy = obj['accuracy'];

  if (total_blunders >= 3) {
    reviewSummary['focus'] =
      'You committed ' +
      total_blunders +
      ' blunders. Spend more time solving tactical positions and check more than ' +
      'once when playing a game';
  } else if (total_blunders == 0) {
    reviewSummary['focus'] =
      'Bravo! You have committed no blunders. You are focussed. Keep it up.';
  } else {
    reviewSummary['focus'] =
      'You have committed ' +
      total_blunders +
      ' blunders in this game. Be more attentive when playing a game with no distractions.';
  }

  if (total_blunders === blunders_less_than_two_mins) {
    reviewSummary['think_time'] =
      'You committed ' +
      total_blunders +
      ' blunders, all of them making moves for which you have taken less than 2 minutes. ' +
      'On ' +
      blunders_less_than_ten_secs +
      ' moves, you have made blunders thinking less than 10 seconds. You must play with more attention.';
  } else if (blunders_less_than_ten_secs >= 1) {
    reviewSummary['think_time'] =
      'You have committed ' +
      blunders_less_than_ten_secs +
      ' blunders having spent less than 10 seconds. This ' +
      ' needs improvement. Make sure you do not make moves without spending time on the position.';
  } else if (
    blunders_less_than_ten_secs === 0 ||
    blunders_less_than_two_mins >= 1
  ) {
    reviewSummary['think_time'] =
      'You have committed ' +
      blunders_less_than_two_mins +
      ' blunders having spent less than 2 minutes. This ' +
      ' needs improvement. Make sure you do not make moves without spending time on the position.';
  }

  if (unsolved_puzzles > 0) {
    reviewSummary['review_status'] =
      'You still have ' +
      unsolved_puzzles +
      ' unsolved positions out of ' +
      (unsolved_puzzles + solved_puzzles) +
      ' positions. You have a scope for ' +
      5 * unsolved_puzzles +
      ' more points if you solve remaining positions.';
  } else {
    reviewSummary['review_status'] =
      'Well done! You have reviewed all positions from the game. Keep it up!';
  }

  if (accuracy > 80) {
    reviewSummary['accuracy'] =
      'Job well done! You have had an accuracy of ' +
      accuracy +
      '% in this game.';
  } else if (accuracy > 60) {
    reviewSummary['accuracy'] =
      'You have done well. Your accuracy in this game was ' +
      accuracy +
      '%. Target to reach accuracy of 80% next.';
  } else {
    reviewSummary['accuracy'] =
      'Your accuracy was ' + accuracy + '%. Keep focus and be consistent. ';
  }

  return reviewSummary;
}
export function subtractDaysFromDate(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export const getFormattedTimeInMinutesAndSeconds = (m, s) => {
  const time = `${m > 9 ? m : `0${m}`} : ${s > 9 ? s : `0${s}`}`;
  return time;
};

export function openInNewTab(url: string) {
  if (!url) return;
  window.open(url, '_blank', 'noopener,noreferrer');
}

interface GameMetadata {
  userid: number;
  user_key: string;
  tournament_id: number;
  round_index: number;
  platform: string;
  username: string;
  url: string | null;
  color: string;
  time_control: string;
  end_time: string | null;
  rated: number;
  uuid: string | null;
  time_class: string | null;
  result: string;
  reason: string | null;
  rating: number;
  opponent_rating: number;
  opponent_profile: string | null;
  opponent_username: string;
  opening: string | null;
  category: string;
  game_uuid: string;
}

interface MoveData {
  san: string; // Standard Algebraic Notation
  fen: string; // Forsyth-Edwards Notation
}

export const STARTING_FEN =
  'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
// Function to generate PGN from game metadata and moves
export function generatePGN(
  gameMetadata: GameMetadata,
  moves: MoveData[],
): string {
  const {player_name, opponent_name, round_result, tournament_name, game_uuid} =
    gameMetadata;
  let pgn = `[Event "${tournament_name}"]\n`;
  pgn += `[Site "OTB"]\n`;
  pgn += `[Date "${new Date().toISOString().split('T')[0]}"]\n`; // Assuming game date as today; adjust as needed
  pgn += `[Round "${gameMetadata?.round_id}"]\n`;
  pgn += `[White "${
    gameMetadata?.round_color === 'white' ? player_name : opponent_name
  }"]\n`;
  pgn += `[Black "${
    gameMetadata?.round_color === 'black' ? player_name : opponent_name
  }"]\n`;
  pgn += `[Result "${round_result}"]\n`;
  pgn += `[GameUUID "${game_uuid}"]\n\n`; // Custom tag for Game UUID

  // Append moves
  moves?.forEach((move, index) => {
    // Adding move number for White's moves
    if (index % 2 === 0) {
      pgn += `${index / 2 + 1}`;
      pgn += `. `;
    } else {
      pgn += `${(index - 1) / 2 + 1}`;
      pgn += `... `;
    }
    pgn += `${move?.move} `;
  });

  console.log('pgn for the game is ', pgn);

  // Append game result at the end
  pgn += `${round_result}`;

  return pgn;
}

/**
 * Removes metadata (tag pairs) from a PGN string.
 *
 * @param {string} pgn - The PGN string containing metadata and move text.
 * @returns {string} - The PGN string without metadata.
 */
export function removeMetaData(pgn) {
  if (!pgn || typeof pgn !== 'string') {
    throw new Error('Invalid PGN data provided.');
  }

  // Regex to match all [Tag "Value"] patterns and any trailing whitespace
  const metadataRegex = /\[[^\]]+\]\s*/g;

  // Replace metadata with an empty string
  return pgn.replace(metadataRegex, '');
}

/**
 * Generates move data from a PGN string using pgn-parser and chess.js.
 *
 * @param {string} startingFen - The starting FEN string.
 * @param {string} pgn - The PGN string containing the game data.
 * @returns {Array} - An array of move data objects.
 */
export function generateMoveData(startingFen: string, pgn: string): Array<any> {
  if (!pgn) return [];

  const chess = new Chess(startingFen);
  const moves = pgn
    .split(/\d+\./)
    .join('')
    .split(/\s+/)
    .filter(move => move && !['1-0', '0-1', '1/2-1/2'].includes(move));

  const movesData = [];
  let cumulativePgn = '';
  let moveCount = 1;

  for (let move of moves) {
    const lastFen = chess.fen(); // Get the FEN before the move
    const moveObj = chess.move(move, {sloppy: true}); // Apply the move in sloppy mode to accept various notations

    if (!moveObj) {
      console.error(`Invalid move: ${move}`);
      continue; // Skip invalid moves and continue processing
    }

    const currentFen = chess.fen(); // Get the FEN after the move

    // Create a formatted move string with move numbers
    if (chess.turn() === 'w') {
      cumulativePgn += `${moveCount}. ${move} `;
      moveCount++;
    } else {
      cumulativePgn += `${move} `;
    }

    movesData.push({
      lastFen: lastFen,
      newFen: currentFen,
      fen: currentFen,
      move: move,
      san: move,
      pgn: cumulativePgn.trim(),
    });
  }

  // Add the game result if known, assuming game result is always at the end of the PGN string
  const result = pgn.match(/(1-0|0-1|1\/2-1\/2)$/);
  if (result) {
    cumulativePgn += result[0];
  }

  return movesData;
}
/**
 * Cleans the PGN string by replacing escaped newline characters with actual newlines.
 *
 * @param {string} pgn - The PGN string that may contain escaped newline characters.
 * @returns {string} - The cleaned PGN string with actual newline characters.
 */
export function cleanPgn(pgn) {
  if (!pgn || typeof pgn !== 'string') {
    throw new Error('Invalid PGN data provided.');
  }

  // Remove evaluation symbols like $5, $1, etc.
  pgn = pgn.replace(/\$\d+/g, '');

  // Replace escaped newline characters (\\n) with actual newlines (\n)
  return pgn.replace(/\\n/g, '\n');
}

/**
 * Extracts the starting FEN from a PGN string.
 * If no FEN is specified, returns the standard starting position.
 *
 * @param {string} pgn - The PGN string containing the game data.
 * @returns {string} - The starting FEN of the game.
 */
export function getStartingFenFromPgn(pgn) {
  if (!pgn || typeof pgn !== 'string') {
    throw new Error('Invalid PGN data provided.');
  }

  // Regular expression to match the [FEN "…"] tag
  const fenRegex = /\[FEN\s+"([^"]+)"\]/i;
  const match = pgn.match(fenRegex);

  if (match && match[1]) {
    return match[1];
  } else {
    // Default to standard chess starting position if FEN is not specified
    return 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
  }
}

export function generatePGNMovesOnly(
  // gameMetadata: GameMetadata,
  moves: MoveData[],
): string {
  let pgn = '';
  // let pgn = `[Event "${gameMetadata.tournament_id}"]\n`;
  // pgn += `[Site "${gameMetadata.platform}"]\n`;
  // pgn += `[Date "${new Date().toISOString().split('T')[0]}"]\n`; // Assuming game date as today; adjust as needed
  // pgn += `[Round "${gameMetadata.round_index}"]\n`;
  // pgn += `[White "${
  //   gameMetadata.color === 'white'
  //     ? gameMetadata.username
  //     : gameMetadata.opponent_username
  // }"]\n`;
  // pgn += `[Black "${
  //   gameMetadata.color === 'black'
  //     ? gameMetadata.username
  //     : gameMetadata.opponent_username
  // }"]\n`;
  // pgn += `[Result "${gameMetadata.result}"]\n`;
  // pgn += `[GameUUID "${gameMetadata.game_uuid}"]\n\n`; // Custom tag for Game UUID

  // Append moves
  moves?.forEach((move, index) => {
    // Adding move number for White's moves
    if (index % 2 === 0) {
      pgn += `${Math.floor(index / 2) + 1}. `;
    }
    pgn += `${move?.san} `;
  });

  console.log('PGN for the game is:', pgn);

  // Append game result at the end
  // pgn += `${gameMetadata?.result}`;

  return pgn;
}
/**
 * Generates an array of move objects containing fen, lastFen, and san for each move.
 * @param movesArray Array of SAN moves (e.g., ["e4", "e5", "Nf3"])
 * @returns Array of move objects with fen, lastFen, and san.
 */
export function generateMovesWithFen(
  movesArray: string[],
  startingFen: 'start',
): Array<{fen: string; lastFen: string; san: string}> {
  const chess = new Chess(startingFen);
  const movesWithFen: Array<{fen: string; lastFen: string; san: string}> = [];

  movesArray.forEach((san, index) => {
    // Save the last FEN before the move
    const lastFen = chess.fen();
    // Make the move
    const moveResult = chess.move(san);
    if (moveResult) {
      movesWithFen.push({
        fen: chess.fen(), // Current FEN after the move
        lastFen: lastFen, // FEN before the move
        san: moveResult.san, // The SAN representation of the move
      });
    } else {
      console.error('Invalid move:', san);
    }
  });

  return movesWithFen;
}

// const navigation = useNavigation();

export const goToDashBoard = navigation => {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{name: ScreenName.Dashboard}],
    }),
  );

  return;
};

export function formatCurrency(amount: number, currencyCode: string = 'INR') {
  if (!Number(amount)) return amount;
  const locale = currencyCode === 'USD' ? 'en-US' : 'en-IN';
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).format(amount);
}

export function removeEmptyKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (value !== null && value !== undefined && value !== '') {
      if (typeof value === 'object' && !Array.isArray(value)) {
        acc[key] = removeEmptyKeys(value);
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {});
}

export function reverseObject(obj) {
  const reversed = Object.entries(obj).reduce((acc, [key, value]) => {
    acc[value] = key; // Last key wins
    return acc;
  }, {});
  return reversed;
}
export function flattenMoves(moves) {
  if (!moves) return [];
  const flatMoves = [];

  function traverse(moveList) {
    for (const move of moveList) {
      // Push the current move into the flat array
      flatMoves.push({...move, child_moves: undefined}); // Remove the child_moves field

      // If the move has child_moves, traverse them
      if (move.child_moves && move.child_moves.length > 0) {
        traverse(move.child_moves);
      }
    }
  }

  // Start traversing from the root moves array
  traverse(moves);

  return flatMoves;
}

import WithTheIdea from '@/Assets/Annotation/with_the_idea.svg';
import WithCompensation from '@/Assets/Annotation/with_compensation.svg';
import TimeTrouble from '@/Assets/Annotation/time_trouble.svg';
import CounterPlay from '@/Assets/Annotation/counter_play.svg';
import Attack from '@/Assets/Annotation/attack.svg';
import Initiative from '@/Assets/Annotation/initiative.svg';
import Development from '@/Assets/Annotation/development.svg';
import BlackIsWinning from '@/Assets/Annotation/black_is_winning.svg';
import WhiteIsWinning from '@/Assets/Annotation/white_is_winning.svg';
import BlackIsBetter from '@/Assets/Annotation/black_is_better.svg';
import WhiteIsBetter from '@/Assets/Annotation/white_is_better.svg';
import BlackIsSlightlyBetter from '@/Assets/Annotation/black_is_slightly_better.svg';
import WhiteIsSlightlyBetter from '@/Assets/Annotation/white_is_slightly_better.svg';
import UnclearPosition from '@/Assets/Annotation/unclear_position.svg';
import EqualPosition from '@/Assets/Annotation/equal_position.svg';
import Zugzwand from '@/Assets/Annotation/zugzwand.svg';
import OnlyMove from '@/Assets/Annotation/only_move.svg';
import DubiousMove from '@/Assets/Annotation/dubious_move.svg';
import InterestingMove from '@/Assets/Annotation/interesting_move.svg';
import Blunder from '@/Assets/Annotation/blunder.svg';
import Mistake from '@/Assets/Annotation/mistake.svg';
import GoodMove from '@/Assets/Annotation/good_move.svg';
import WithTheIdea_white from '@/Assets/Annotation/white-color/with_the_idea_white.svg';
import WithCompensation_white from '@/Assets/Annotation/white-color/with_compensation_white.svg';
import TimeTrouble_white from '@/Assets/Annotation/white-color/time_trouble_white.svg';
import CounterPlay_white from '@/Assets/Annotation/white-color/counter_play_white.svg';
import Attack_white from '@/Assets/Annotation/white-color/attack_white.svg';
import Initiative_white from '@/Assets/Annotation/white-color/initiative_white.svg';
import Development_white from '@/Assets/Annotation/white-color/development_white.svg';
import BlackIsWinning_white from '@/Assets/Annotation/white-color/black_is_winning_white.svg';
import WhiteIsWinning_white from '@/Assets/Annotation/white-color/white_is_winning_white.svg';
import BlackIsBetter_white from '@/Assets/Annotation/white-color/black_is_better_white.svg';
import WhiteIsBetter_white from '@/Assets/Annotation/white-color/white_is_better_white.svg';
import BlackIsSlightlyBetter_white from '@/Assets/Annotation/white-color/black_is_slightly_better_white.svg';
import WhiteIsSlightlyBetter_white from '@/Assets/Annotation/white-color/white_is_slightly_better_white.svg';
import UnclearPosition_white from '@/Assets/Annotation/white-color/unclear_position_white.svg';
import EqualPosition_white from '@/Assets/Annotation/white-color/equal_position_white.svg';
import Zugzwand_white from '@/Assets/Annotation/white-color/zugzwand_white.svg';
import OnlyMove_white from '@/Assets/Annotation/white-color/only_move_white.svg';
import DubiousMove_white from '@/Assets/Annotation/white-color/dubious_move_white.svg';
import InterestingMove_white from '@/Assets/Annotation/white-color/interesting_move_white.svg';
import Blunder_white from '@/Assets/Annotation/white-color/blunder_white.svg';
import Mistake_white from '@/Assets/Annotation/white-color/mistake_white.svg';
import GoodMove_white from '@/Assets/Annotation/white-color/good_move_white.svg';
export const moveAnnotations = [
  {name: 'With the idea', icon: WithTheIdea, white_icon: WithTheIdea_white},
  {
    name: 'With compensation',
    icon: WithCompensation,
    white_icon: WithCompensation_white,
  },
  {name: 'Time trouble', icon: TimeTrouble, white_icon: TimeTrouble_white},
  {name: 'Counter-play', icon: CounterPlay, white_icon: CounterPlay_white},
  {name: 'Attack', icon: Attack, white_icon: Attack_white},
  {name: 'Initiative', icon: Initiative, white_icon: Initiative_white},
  {name: 'Development', icon: Development, white_icon: Development_white},
  {
    name: 'Black is winning',
    icon: BlackIsWinning,
    white_icon: BlackIsWinning_white,
  },
  {
    name: 'White is winning',
    icon: WhiteIsWinning,
    white_icon: WhiteIsWinning_white,
  },
  {
    name: 'Black is better',
    icon: BlackIsBetter,
    white_icon: BlackIsBetter_white,
  },
  {
    name: 'White is better',
    icon: WhiteIsBetter,
    white_icon: WhiteIsBetter_white,
  },
  {
    name: 'Black is slightly better',
    icon: BlackIsSlightlyBetter,
    white_icon: BlackIsSlightlyBetter_white,
  },
  {
    name: 'White is slightly better',
    icon: WhiteIsSlightlyBetter,
    white_icon: WhiteIsSlightlyBetter_white,
  },
  {
    name: 'Unclear position',
    icon: UnclearPosition,
    white_icon: UnclearPosition_white,
  },
  {
    name: 'Equal position',
    icon: EqualPosition,
    white_icon: EqualPosition_white,
  },
  {name: 'Zugzwang', icon: Zugzwand, white_icon: Zugzwand_white},
  {name: 'Only move', icon: OnlyMove, white_icon: OnlyMove_white},
  {name: 'Dubious move', icon: DubiousMove, white_icon: DubiousMove_white},
  {
    name: 'Interesting move',
    icon: InterestingMove,
    white_icon: InterestingMove_white,
  },
  {name: 'Blunder', icon: Blunder, white_icon: Blunder_white},
  {name: 'Mistake', icon: Mistake, white_icon: Mistake_white},
  {name: 'Good move', icon: GoodMove, white_icon: GoodMove_white},
];

export const moveAnnotationsObjToDisplay = {
  'With the idea': {icon: WithTheIdea, white_icon: WithTheIdea_white},
  'With compensation': {
    icon: WithCompensation,
    white_icon: WithCompensation_white,
  },
  'Time trouble': {icon: TimeTrouble, white_icon: TimeTrouble_white},
  'Counter-play': {icon: CounterPlay, white_icon: CounterPlay_white},
  Attack: {icon: Attack, white_icon: Attack_white},
  Initiative: {icon: Initiative, white_icon: Initiative_white},
  Development: {icon: Development, white_icon: Development_white},
  'Black is winning': {
    icon: BlackIsWinning,
    white_icon: BlackIsWinning_white,
  },
  'White is winning': {
    icon: WhiteIsWinning,
    white_icon: WhiteIsWinning_white,
  },
  'Black is better': {icon: BlackIsBetter, white_icon: BlackIsBetter_white},
  'White is better': {icon: WhiteIsBetter, white_icon: WhiteIsBetter_white},
  'Black is slightly better': {
    icon: BlackIsSlightlyBetter,
    white_icon: BlackIsSlightlyBetter_white,
  },
  'White is slightly better': {
    icon: WhiteIsSlightlyBetter,
    white_icon: WhiteIsSlightlyBetter_white,
  },
  'Unclear position': {
    icon: UnclearPosition,
    white_icon: UnclearPosition_white,
  },
  'Equal position': {icon: EqualPosition, white_icon: EqualPosition_white},
  Zugzwang: {icon: Zugzwand, white_icon: Zugzwand_white},
  'Only move': {icon: OnlyMove, white_icon: OnlyMove_white},
  'Dubious move': {icon: DubiousMove, white_icon: DubiousMove_white},
  'Interesting move': {
    icon: InterestingMove,
    white_icon: InterestingMove_white,
  },
  Blunder: {icon: Blunder, white_icon: Blunder_white},
  Mistake: {icon: Mistake, white_icon: Mistake_white},
  'Good move': {icon: GoodMove, white_icon: GoodMove_white},
};

/**
 * Ensures there is a blank line between the headers and the move text in a PGN string.
 * @param {string} pgn - The original PGN string.
 * @returns {string} - The formatted PGN string with a blank line after headers.
 */
export function ensureBlankLineAfterHeaders(pgn) {
  const lines = pgn.split('\n');
  let lastHeaderIndex = -1;

  // Find the last header line (lines starting with '[')
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('[')) {
      lastHeaderIndex = i;
    } else if (lines[i].trim() === '') {
      // Blank line found, no need to insert
      lastHeaderIndex = -1;
      break;
    } else {
      break;
    }
  }

  if (lastHeaderIndex !== -1 && lastHeaderIndex < lines.length - 1) {
    // Insert a blank line after the last header
    lines.splice(lastHeaderIndex + 1, 0, '');
  }

  return lines.join('\n');
}
