import React from 'react';
import {View, Text, Linking, ScrollView, StyleSheet} from 'react-native';
import {Table, Row, Rows} from 'react-native-table-component';
import {Picker} from '@react-native-picker/picker';
import CCText from '../CCText';
import Box from '../Box';
import CCColors from '@/Utils/CCColors';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Divider from '../Divider/Divider';

// Adjusted CustomCell component to handle different types.
const CustomCell = ({item}) => {
  switch (item?.type) {
    case 'text':
      return (
        <CCText
          fontSize={14}
          showEllipses={true}
          style={{padding: 5}}
          ellipsizeMode="tail">
          {item?.name}
        </CCText>
      );
    case 'dropdown':
      return (
        <Picker selectedValue={item.name} style={{height: 50, width: 150}}>
          {item?.options?.map((option, index) => (
            <Picker.Item key={index} label={option} value={option} />
          ))}
        </Picker>
      );
    case 'link':
      return (
        <Text
          style={{color: 'blue'}}
          onPress={() => item?.url && Linking.openURL(item?.url)}>
          {item?.name}
        </Text>
      );
    case 'button':
      return item?.name;
    default:
      return <Text>{item?.name}</Text>;
  }
};

// Main table component
const CCPackageTableNew = ({
  headers,
  tableData,
  rowProps,
  flexArr,
  color,
  separator = false,
  customHeader,
}) => {
  // Mapping each row data to a component array for Rows component

  const {isMobileOrTab} = useMediaQuery();
  const formattedData = tableData?.map((rowData, rowIndex) =>
    rowData.map((cellData, cellIndex) => (
      <View
        key={`row-${rowIndex}-cell-${cellIndex}`}
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CustomCell item={cellData} />
      </View>
    )),
  );

  if (isMobileOrTab) {
    return (
      <ScrollView
        style={[styles.container, isMobileOrTab && styles.containerMobile]}>
        {tableData?.map((rowData, rowIndex) => (
          <Box
            key={`row-box-${rowIndex}`}
            style={[
              styles.rowBox,
              {backgroundColor: color || CCColors.Primary.lightBrown},
            ]}>
            {rowData.map((cellData, cellIndex) => (
              <Box
                key={`cell-box-${rowIndex}-${cellIndex}`}
                style={[
                  styles.cellBox,
                  cellData?.type === 'button' ? styles.cellBoxButton : {},
                ]}>
                <Text style={[styles.cellHeader, customHeader]}>
                  {headers[cellIndex]}
                </Text>
                <CustomCell item={cellData} />
              </Box>
            ))}
            {separator && <Divider style={{width: '95%'}} />}
          </Box>
        ))}
      </ScrollView>
    );
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false} style={{marginBottom: 20}}>
      <Table
      // {...rest}
      // borderStyle={{borderWidth: 1, borderColor: CCColors.TextColor.Grey}}
      >
        <Row
          data={headers}
          style={styles.head}
          textStyle={styles.text}
          flexArr={flexArr}
          {...rowProps}
        />
        {/* Rows for data */}
        {formattedData?.length > 0 ? (
          <>
            {formattedData?.map((rowData, index) => (
              <Row
                {...rowProps}
                key={`data-row-${index}`}
                data={rowData}
                flexArr={flexArr}
                style={[
                  styles.row,
                  {flexDirection: 'row'},
                  index % 2 === 0
                    ? {backgroundColor: CCColors.Primary.lightYellow}
                    : {},
                ]}
                // textStyle={styles.text}
                textStyle={styles.dataText}
              />
            ))}
          </>
        ) : (
          <CCText style={{paddingVertical: 20}} textAlign="center">
            No data available
          </CCText>
        )}
      </Table>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    margin: 10,
    // borderColor: CCColors.TextColor.Grey,
    borderRadius: 8,
  },
  head: {
    minHeight: 40,
    backgroundColor: CCColors.Primary.Light,
    // borderBottomWidth: 1,
    // borderColor: CCColors.TextColor.Grey,
    textAlign: 'center',
  },
  text: {
    margin: 6,
    color: CCColors.TextColor.Dark,
    fontWeight: '500',
    textAlign: 'center',
  },
  row: {
    // borderBottomWidth: 1,
    // borderBottomColor: CCColors.Primary,
    height: 50,
  },
  container: {
    flex: 1,
    width: '96%',
    padding: 10,
  },
  containerMobile: {
    width: '100%',
  },
  rowBox: {
    marginBottom: 10,
    // borderWidth: 1,
    // borderColor: CCColors.TextColor.Grey,
    borderRadius: 8,
    padding: 10,

    flexDirection: 'row', // Set the row box to horizontal layout
    flexWrap: 'wrap', // Allow wrapping content if more than 2 columns
  },
  cellBoxButton: {
    width: '100%', // Each cell box takes up half the row box width
  },
  cellBox: {
    width: '50%', // Each cell box takes up half the row box width
    borderColor: 'white',
    marginBottom: 5,
  },
  cellHeader: {
    fontWeight: 'bold',
    color: CCColors.TextColor.Dark,
    marginBottom: 2,
    fontSize: 12,
  },
  dataText: {
    fontSize: 12,
    color: 'red',
  },
});

export default CCPackageTableNew;
