import thumsup from '@/Assets/Images/Svgs/thumsup.svg';
import thumsupActive from '@/Assets/Images/Svgs/thumsupActive.svg';
import thumsdown from '@/Assets/Images/Svgs/thumsdown.svg';
import thumsdownActive from '@/Assets/Images/Svgs/thumsdownActive.svg';
import Box from '@/Atoms/Box';
import {Animated, View, Image, TouchableOpacity} from 'react-native';
import StockfishComponent from '@/Components/StockfishEngine/StockfishComponent';
import Spacer from '@/Atoms/Spacer';
import CCButton from '@/Atoms/CCButton';
import Divider from '@/Atoms/Divider/Divider';
import CCText from '@/Atoms/CCText';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import InfoIcon, {CustomInfoHoverBox} from '@/Atoms/InfoIcon';
import {styles, filterStyles} from './styles';
import caissaAvatar from '@/Assets/Images/Svgs/caissaAvatar.svg';
import incorrectMove from '@/Assets/Images/Svgs/IncorrectMove.svg';
import CCColors from '@/Utils/CCColors';
import {WINDOW_WIDTH} from '@/Utils/Dimensions';
import {useMemo, useState} from 'react';
import {Ionicons} from '@expo/vector-icons';

const NextJSXComponent = ({
  result,
  puzzle,
  Hint,
  Hint2,
  result2,
  manualMoves,
  answerIndex,
  startFen,
  setManualMoves,
  setShowNextModal,
  setPreviousFen,
  setPuzzle,
  setResult,
  handleSubmit,
  setShowChange,
  exhausted,
  showChange,
  goodFeedback,
  badFeedback,
  setGoodFeedback,
  setBadFeedback,
  handleNext,
  resultOpacityAnimStyle,
  disabledTabs,
}) => {
  const {isMobile, isTablet} = useMediaQuery();
  const isMobileOrTab = useMemo(
    () => isMobile || isTablet,
    [isMobile, isTablet],
  );

  return (
    <Box>
      <View style={styles.modalNewContainer}>
        <Box style={{height: 115}}>
          {result?.isSuccess && (
            <StockfishComponent
              customStyle={filterStyles.switchBox}
              fen={puzzle.currentPosition}
            />
          )}
        </Box>

        <Box
          style={{
            marginHorizontal: 'auto',
            alignItems: 'center',
            marginTop: 10,
          }}>
          <Image source={caissaAvatar} style={{width: 90, height: 90}} />
        </Box>

        <Spacer spacing={20} />

        <CCButton
          onPress={handleNext}
          style={{width: '90%', marginVertical: 4, marginHorizontal: 'auto'}}>
          <CCText fontSize={16} fontWeight="medium">
            Next
          </CCText>
        </CCButton>

        <Spacer spacing={20} />
        <Divider />
        <Spacer spacing={20} />

        {result.isSuccess && (
          <CCButton
            style={{
              width: '90%',
              backgroundColor: 'white',
              marginVertical: 4,
              marginHorizontal: 'auto',
            }}
            onPress={() => {
              setShowNextModal(false);
              setPuzzle(prev => {
                setPreviousFen(startFen);
                return {...prev, currentPosition: prev?.originalPosition};
              });
              setResult({text: 'Solved!', isSuccess: true});
              const newMoves = manualMoves.slice(0, answerIndex + 1);
              setManualMoves(newMoves);
            }}>
            <CCText fontSize={16} fontWeight="medium" color="black">
              Restore Position
            </CCText>
          </CCButton>
        )}

        <Spacer spacing={20} />

        <Box style={{marginHorizontal: 'auto'}}>
          <CCText
            fontSize={18}
            fontWeight="semibold"
            style={{marginHorizontal: 'auto'}}>
            Hurray! That's the correct move
          </CCText>

          <Spacer spacing={20} />

          <Animated.View
            style={[
              resultOpacityAnimStyle,
              {backgroundColor: CCColors.Primary.lightBrown},
            ]}>
            <Box flexDirection="column" alignItems="center" flex={1}>
              {exhausted ? (
                <CCText
                  color={CCColors.Black}
                  textAlign="center"
                  style={[styles.puzzleSolutionText, {flex: 1}]}>
                  You have solved all positions, please apply new filters!
                </CCText>
              ) : (
                <>
                  <Box
                    style={{
                      backgroundColor: CCColors.White,
                      padding: 30,
                      borderRadius: 8,
                    }}>
                    <CCText
                      textAlign="left"
                      color={CCColors.Black}
                      fontSize={18}
                      fontWeight="medium"
                      style={{marginBottom: 10}}>
                      Solution
                    </CCText>

                    <CCText
                      textAlign="justify"
                      style={[
                        styles.puzzleSolutionNext,
                        {color: CCColors.Black, width: '90%', flex: 1},
                      ]}>
                      {result.text === 'Solved!' || result.text === 'Correct!'
                        ? Hint.trim()
                        : result.text}
                    </CCText>
                  </Box>

                  {!disabledTabs && (
                    <Box
                      style={{
                        backgroundColor: CCColors.White,
                        padding: 30,
                        borderRadius: 8,
                        marginTop: 10,
                      }}>
                      <Box style={{paddingBottom: 10}}>
                        <Box
                          flexDirection="row"
                          gap={5}
                          alignItems="center"
                          style={{marginBottom: 10}}>
                          <CCText
                            textAlign="left"
                            color={CCColors.Black}
                            fontSize={18}
                            fontWeight="medium">
                            Caissa Suggestion
                          </CCText>
                          <InfoIcon
                            text="Work in progress. Please help us improve with an upvote or downvote."
                            style={{color: CCColors.Green}}
                          />
                        </Box>

                        <CCText
                          textAlign="justify"
                          style={[
                            styles.puzzleSolutionNext,
                            {color: CCColors.Black, width: '90%', flex: 1},
                          ]}>
                          {result.text === 'Solved!' ||
                          result.text === 'Correct!'
                            ? Hint2.trim()
                            : result2.text}
                        </CCText>
                      </Box>

                      <Divider />
                      <Spacer spacing={18} />

                      {showChange && (
                        <Box flexDirection="row" justifyContent="center">
                          <CCText fontSize={14} style={{color: '#4D3F37CC'}}>
                            Thank you for providing feedback
                          </CCText>
                        </Box>
                      )}

                      {!showChange && (
                        <Box flexDirection="row" gap={7} flexWrap="wrap">
                          <CustomInfoHoverBox
                            text="Good Response"
                            customView={
                              <Image
                                source={
                                  goodFeedback === 1 ? thumsupActive : thumsup
                                }
                                onClick={() => {
                                  setGoodFeedback(1);
                                  setBadFeedback(0);
                                  handleSubmit(1);
                                  setTimeout(() => setShowChange(true), 3000);
                                }}
                                style={{width: 30, height: 30}}
                              />
                            }
                          />
                          <CustomInfoHoverBox
                            text="Bad Response"
                            customView={
                              <Image
                                source={
                                  badFeedback === 2
                                    ? thumsdownActive
                                    : thumsdown
                                }
                                onClick={() => {
                                  setBadFeedback(2);
                                  setGoodFeedback(0);
                                  handleSubmit(2);
                                  setTimeout(() => setShowChange(true), 3000);
                                }}
                                style={{width: 30, height: 30}}
                              />
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Animated.View>

          <Spacer spacing={5} />
        </Box>
      </View>
    </Box>
  );
};

export default NextJSXComponent;

export const IncorrectJSXComponent = ({
  result,
  result2,
  setShowIncorrectHintModal,
  handleSubmit,
  exhausted,
  showChange,
  setShowChange,
  goodFeedback,
  badFeedback,
  setGoodFeedback,
  setBadFeedback,
  resultOpacityAnimStyle,
  disabledTabs,
}) => {
  const {isMobile, isTablet} = useMediaQuery();
  const isMobileOrTab = useMemo(
    () => isMobile || isTablet,
    [isMobile, isTablet],
  );
  return (
    <Box>
      <View style={[styles.modalNewContainer, {paddingBottom: 24}]}>
        <Box>
          <Box
            style={{
              alignItems: 'flex-end',
            }}>
            <TouchableOpacity onPress={() => setShowIncorrectHintModal(false)}>
              <Box type="center" style={styles.icon}>
                <Ionicons name="close-outline" size={24} color="black" />
              </Box>
            </TouchableOpacity>
          </Box>

          <Box
            style={{
              marginHorizontal: 'auto',
              marginBottom: '2%',
            }}>
            <img
              src={incorrectMove}
              style={{
                width: '90%',
                height: '100%',
              }}
            />
          </Box>
          <Box>
            <Animated.View
              style={[
                resultOpacityAnimStyle,
                {
                  backgroundColor: CCColors.Primary.lightBrown,
                },
              ]}>
              {
                <Box
                  flexDirection="column"
                  alignItems="center"
                  // overflow='scroll'
                  flex={1}>
                  {exhausted && (
                    <CCText
                      color={CCColors.Black}
                      textAlign="center"
                      style={[styles.puzzleSolutionText, {flex: 1}]}>
                      You have solved all positions, please apply new filters!
                    </CCText>
                  )}
                  {!exhausted && (
                    <Box
                      textAlign={'justify'}
                      style={[
                        styles.puzzleSolutionText,
                        {
                          color: CCColors.Black,
                          marginBottom: 30,
                        },
                        {flex: 1},
                      ]}>
                      <Box>
                        <CCText
                          style={{
                            marginHorizontal: 'auto',
                            marginBottom: 10,
                          }}
                          color={CCColors.Red}
                          fontSize={20}
                          fontWeight="medium">
                          Incorrect Move!
                        </CCText>
                        <Box
                          style={{
                            backgroundColor: CCColors.White,
                            padding: 30,
                            borderRadius: 8,
                          }}>
                          <CCText
                            textAlign="left"
                            style={{
                              marginBottom: 10,
                            }}
                            color={CCColors.Black}
                            fontSize={18}
                            fontWeight={600}>
                            Solution
                          </CCText>
                          <CCText fontSize={14}>
                            {result?.text
                              ? !isMobileOrTab
                                ? result.text.split(' ').slice(1).join(' ')
                                : result.text
                              : !isMobileOrTab
                              ? result.split(' ').slice(1).join(' ')
                              : result}
                          </CCText>
                        </Box>
                      </Box>

                      {!disabledTabs && (
                        <Box
                          style={{
                            backgroundColor: CCColors.White,
                            padding: 30,
                            borderRadius: 8,
                            marginTop: 10,
                          }}>
                          <Box>
                            <Box
                              style={{
                                paddingBottom: 10,
                              }}>
                              <Box
                                flexDirection="row"
                                gap={5}
                                alignItems="center"
                                style={{marginBottom: 10}}>
                                <CCText
                                  textAlign="left"
                                  color={CCColors.Black}
                                  fontSize={18}
                                  fontWeight={600}>
                                  Caissa Suggestion
                                </CCText>

                                <InfoIcon
                                  text={
                                    'Work in progress. Please help us improve with an upvote or downvote.'
                                  }
                                  style={{
                                    color: CCColors.Red,
                                  }}
                                />
                              </Box>
                              <CCText fontSize={14}>
                                {result2?.text
                                  ? !isMobileOrTab
                                    ? result2.text.split(' ').slice(1).join(' ')
                                    : result2.text
                                  : !isMobileOrTab
                                  ? result2.split(' ').slice(1).join(' ')
                                  : result2}
                              </CCText>
                            </Box>
                            <Box>
                              <Divider />
                              <Spacer spacing={18} />
                              {showChange && (
                                <Box
                                  flexDirection="row"
                                  justifyContent="center">
                                  <CCText
                                    alignText="center"
                                    fontSize={14}
                                    style={{
                                      color: '#4D3F37CC',
                                    }}>
                                    Thank you for providing feedback
                                  </CCText>
                                </Box>
                              )}
                              <Box flexDirection="row" gap={7} flexWrap="wrap">
                                {!showChange && (
                                  <Box justifyContent="center">
                                    <CCText
                                      style={{
                                        color: '#4D3F37CC',
                                        fontSize:
                                          420 < WINDOW_WIDTH &&
                                          WINDOW_WIDTH < 920
                                            ? 12
                                            : 14,

                                        letterSpacing:
                                          420 < WINDOW_WIDTH &&
                                          WINDOW_WIDTH < 920
                                            ? -1.1
                                            : 0,
                                      }}>
                                      Is this AI-generated hint useful to you?
                                    </CCText>
                                  </Box>
                                )}

                                {!showChange && (
                                  <Box
                                    gap={8}
                                    style={{
                                      flexDirection: 'row',
                                      justifyContent: 'flex-end',
                                    }}>
                                    <CustomInfoHoverBox
                                      text="Good Response"
                                      customView={
                                        <img
                                          src={
                                            goodFeedback === 1
                                              ? thumsupActive
                                              : thumsup
                                          }
                                          onClick={() => {
                                            setGoodFeedback(1);
                                            setBadFeedback(0);
                                            handleSubmit(1);
                                            setTimeout(() => {
                                              setShowChange(true);
                                            }, 3000);
                                          }}
                                          style={{
                                            width: 30,
                                            height: 30,
                                          }}
                                        />
                                      }
                                    />
                                    <CustomInfoHoverBox
                                      text="Bad Response"
                                      customView={
                                        <img
                                          src={
                                            badFeedback === 2
                                              ? thumsdownActive
                                              : thumsdown
                                          }
                                          onClick={() => {
                                            setBadFeedback(2);
                                            setGoodFeedback(0);
                                            handleSubmit(2);
                                            setTimeout(() => {
                                              setShowChange(true);
                                            }, 3000);
                                          }}
                                          style={{
                                            width: 30,
                                            height: 30,
                                          }}
                                        />
                                      }
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              }
            </Animated.View>
          </Box>
        </Box>
      </View>
    </Box>
  );
};
export const HintForIncorrectAttempt = ({
  result,
  result2,
  disabled,
  setShowIncorrectHintModal,
  handleSubmit,
  showChange,
  setShowChange,
  goodFeedback,
  badFeedback,
  setGoodFeedback,
  setBadFeedback,
  width,
  padding,
  title,
  borderColor,
  infoiconcolor,
  position,
  top,
  right,
}) => {
  const {isMobile, isTablet} = useMediaQuery();
  const isMobileOrTab = useMemo(
    () => isMobile || isTablet,
    [isMobile, isTablet],
  );

  return (
    <Box
      style={{
        height: '40%',
        width: width || '85%',
        position: position,
        top: top,

        zIndex: 1000,
        // borderRadius: 100,
        backgroundColor: CCColors.White,
        borderColor: borderColor || 'none', // Border color
        borderWidth: 1, // Border width
        borderStyle: 'solid',
      }}>
      {result && (
        <Box
          style={{
            backgroundColor: CCColors.White,
            padding: padding || 10,
            // borderRadius: 8,
          }}>
          <Box
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center">
            <CCText
              textAlign="left"
              color={CCColors.Black}
              fontSize={18}
              fontWeight={600}>
              {title || 'Solution'}
            </CCText>

            <TouchableOpacity onPress={() => setShowIncorrectHintModal(false)}>
              <Box type="center" style={styles.iconct}>
                <Ionicons name="close-outline" size={18} color="black" />
              </Box>
            </TouchableOpacity>
          </Box>
          <CCText fontSize={14}>
            {/* {!isMobileOrTab ? result.split(' ').slice(1).join(' ') : result} */}
            {result}
          </CCText>
        </Box>
      )}
      <Spacer spacing={5} />

      {!disabled && (
        <>
          {result && result2 && <Divider />}

          {result2 && (
            <Box
              style={{
                backgroundColor: CCColors.White,
                padding: padding || 10,
                // borderRadius: 8,
                marginTop: 10,
              }}>
              <Box>
                <Box
                  style={{
                    paddingBottom: 10,
                  }}>
                  <Box
                    flexDirection="row"
                    gap={5}
                    alignItems="center"
                    style={{marginBottom: 10}}>
                    <CCText
                      textAlign="left"
                      color={CCColors.Black}
                      fontSize={18}
                      fontWeight={600}>
                      Caissa Suggestion
                    </CCText>

                    <InfoIcon
                      text={
                        'Work in progress. Please help us improve with an upvote or downvote.'
                      }
                      style={{
                        color: infoiconcolor || CCColors.Red,
                      }}
                    />
                  </Box>
                  <CCText fontSize={14}>
                    {/* {!isMobileOrTab ? result2.split(' ').slice(1).join(' ') : result2} */}
                    {result2}
                  </CCText>
                </Box>
                <Box>
                  {showChange && (
                    <Box flexDirection="row" justifyContent="center">
                      <CCText
                        alignText="center"
                        fontSize={14}
                        style={{
                          color: '#4D3F37CC',
                        }}>
                        Thank you for providing feedback
                      </CCText>
                    </Box>
                  )}
                  <Box flexDirection="row" gap={7} flexWrap="wrap">
                    {!showChange && (
                      <Box justifyContent="center">
                        <CCText
                          style={{
                            color: '#4D3F37CC',
                            fontSize:
                              420 < WINDOW_WIDTH && WINDOW_WIDTH < 920
                                ? 12
                                : 14,
                          }}>
                          Is this AI-generated hint useful to you?
                        </CCText>
                      </Box>
                    )}

                    {!showChange && (
                      <Box
                        gap={8}
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}>
                        <CustomInfoHoverBox
                          text="Good Response"
                          customView={
                            <img
                              src={goodFeedback === 1 ? thumsupActive : thumsup}
                              onClick={() => {
                                setGoodFeedback(1);
                                setBadFeedback(0);
                                handleSubmit(1);
                                setTimeout(() => {
                                  setShowChange(true);
                                }, 3000);
                              }}
                              style={{
                                width: 30,
                                height: 30,
                              }}
                            />
                          }
                        />
                        <CustomInfoHoverBox
                          text="Bad Response"
                          customView={
                            <img
                              src={
                                badFeedback === 2 ? thumsdownActive : thumsdown
                              }
                              onClick={() => {
                                setBadFeedback(2);
                                setGoodFeedback(0);
                                handleSubmit(2);
                                setTimeout(() => {
                                  setShowChange(true);
                                }, 3000);
                              }}
                              style={{
                                width: 30,
                                height: 30,
                              }}
                            />
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
