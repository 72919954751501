import React, {useEffect, useRef, useState} from 'react';
import {styles} from './styles';

import {Image, Pressable, View, TouchableOpacity} from 'react-native';
import CCText from '@/Atoms/CCText';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Feather from 'react-native-vector-icons/Feather';

import {Colors} from '@/styles';
import {DrawerActions, useNavigation} from '@react-navigation/native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {
  getLocalStorage,
  handleLogout,
  removeLocalStorage,
  setLocalStorage,
} from '@/Utils/CommonUtils';
import Entypo from 'react-native-vector-icons/Entypo';
import {useUserPlanStore} from '@/Store/userPlanStore';

import Animated, {
  useSharedValue,
  useAnimatedStyle,
  interpolateColor,
  interpolate,
  withTiming,
} from 'react-native-reanimated';

import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import {useShallow} from 'zustand/react/shallow';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import CCButton from '../CCButton';
import useAnimation from '@/Hooks/useAnimation';
import {ScreenName} from '@/navigators/StackConstants';
import useSound from 'use-sound';

import notificationSoundMp3 from '../../Assets/Sounds/notification.mp3';
import successSound from '../../Assets/Sounds/success.mp3';
import wrongSound from '../../Assets/Sounds/wrong.mp3';
import levelupSound from '../../Assets/Sounds/levelup.mp3';
import ConditionalRender from '../ConditionalRender';
import Box from '../Box';
import GamePlayClient from '@/Api/GameClient';
// import ChallengeComponent from '@/Components/ChallengeComponent';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Streak from './Components/Streak';
import SlideModal from '../SlideModal';
import {PopUp} from '@/Screens/Visualisation/components/LevelModal';
import Spacer from '../Spacer';
import {FontSizes} from '@/Utils/Dimensions';
import {useSocket} from '@/Hooks/UseSocket';
import UserAssistance from '@/Components/UserAssistance';
import NotificationModal from './Components/NotificationModal';
import {useNotificationStore} from '@/Store/useNotificationStore';
// const schema = require('/Users/whynew/Circlechess/cc-client-sdk/lib/schema.js');

// const schema = '';
// const schema = require('/home/sk/sources/cc-client-sdk/lib/schema.js');
// const schema = require('/home/administrator/Desktop/SDK client/cc-client-sdk/lib/schema.js');
// const schema = '';
// const schema = require('/home/sk/sources/cc-client-sdk/lib/schema.js');

function menu(triggerComponent, navigate, onLoginStateChange) {
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setDeleteShowPopup] = useState(false);
  const [open, setOpen] = React.useState<boolean>(false);
  // const notifications = useNotificationStore(state => state.notifications);

  const removeNotification = useNotificationStore(
    state => state.removeNotification,
  );

  const [openNotificationsModal, setOpenNotificationsModal] =
    React.useState(false);
  const notifications = useNotificationStore(state => state.notifications);

  const hasNewNotifications = useNotificationStore(state =>
    state.hasNewNotifications(),
  );
  const handleDeleteNotification = (id: string) => {
    removeNotification(id);
    // setNotifications(prev => prev.filter(notif => notif.id !== id));
  };
  const markAllAsRead = useNotificationStore(state => state.markAllAsRead);
  function handleOnCancel() {
    setShowPopup(false);
    setDeleteShowPopup(false);
  }

  const items = [
    {
      key: 'Notifications',
      title: 'Notifications',
      icon: 'bell',
      onPress: () => {
        setOpenNotificationsModal(true);
        markAllAsRead();
        // navigate(ScreenName.Settings);
      },
    },
    {
      key: 'account',
      title: 'Account',
      icon: 'user',
      onPress: () => {
        navigate(ScreenName.Settings);
      },
    },
    {
      key: 'logout',
      title: 'Logout',
      icon: 'sign-out-alt',
      onPress: () => {
        setShowPopup(true);
      },
    },
    {
      key: 'Report an issue',
      title: 'Report an issue',
      icon: 'exclamation-triangle',
      onPress: async () => {
        navigate(ScreenName.ReportAnIssue);
      },
    },
  ];

  return (
    <>
      {showPopup && (
        <SlideModal
          visible={showPopup}
          type="center"
          customStyle={styles.levelModalCustom}>
          <PopUp
            confirm={async () => {
              await handleLogout();
              onLoginStateChange();
            }}
            cancel={handleOnCancel}
            text={'Are you sure you want to logout?'}
            buttonConfirm="Logout"
          />
        </SlideModal>
      )}
      <NotificationModal
        open={openNotificationsModal}
        handleClose={() => setOpenNotificationsModal(false)}
        notifications={notifications}
        onDeleteNotification={handleDeleteNotification}
      />

      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger style={{outlineStyle: 'none'}} asChild>
          {triggerComponent}
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content>
            <View style={styles.userMenu}>
              {items.map(item => (
                <DropdownMenu.Item key={item.key} asChild>
                  <CCButton
                    onPress={() => {
                      item.onPress();
                      setOpen(false);
                    }}
                    style={styles.userMenuItem}
                    contentContainerStyle={styles.userMenuItemContent}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <Icon
                        solid
                        style={styles.userMenuItemIcon}
                        name={item.icon}
                        size={16}
                        color={Colors.primaryDark}
                      />
                      <CCText style={styles.userMenuItemText}>
                        {item.title}
                      </CCText>
                      {item?.title === 'Notifications' &&
                        hasNewNotifications && (
                          <View
                            style={{
                              width: 6,
                              height: 6,
                              borderRadius: 25,
                              backgroundColor: CCColors.Red,
                              position: 'absolute',
                              top: -1,
                              left: 9,
                            }}
                          />
                        )}
                    </View>
                  </CCButton>
                </DropdownMenu.Item>
              ))}
            </View>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
}

export function useAnimatedColor({
  start = 'rgba(255, 255, 255, 0)',
  end,
  duration = 200,
}) {
  const sv = useSharedValue(0);
  const style = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(sv.value, [0, 1], [start, end]),
    };
  });

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const setAnimationValue = value => {
    setIsFocused(isFocused => {
      if (!isFocused) {
        // console.log('zzz', sv.value);
        sv.value = withTiming(value, {duration: duration});
      }
      return isFocused;
    });
  };

  function setFocused(isFocused) {
    setIsFocused(isFocused);
  }

  return [style, setAnimationValue, setFocused];
}

export default function Header({
  userData,
  avatar,
  isMobile,
  navigationRef,
  onLoginStateChange,
  route,
}) {
  const navigation = useNavigation();
  const lastScore = useRef();

  const userPlanStore = useUserPlanStore();

  const [screenName, setScreenName] = useState<string>('Dashboard');

  const [accessToken, setAccessToken] = useState('');

  const socket = useSocket();

  const setAuthorizationToken = async () => {
    const token = await getLocalStorage('access_token');
    if (token) {
      setAccessToken(`Bearer ${token}`);
    }
  };

  const [client, setClient] = useState(null);

  const {isMobileOrTab} = useMediaQuery();

  // const CCApplicationClient = () => {
  //   console.log('User Data = ', userData);
  //   if (userData.user_id) {
  //     console.log(
  //       'Async .. Logging in with username: ',
  //       userData.user_id,
  //       accessToken,
  //     );

  //     const cc_client: any = socket;
  //     // if (cc_client) {
  //     //   if (challengeRequestCallback) {
  //     //     console.log('Inside challenge Request Check .. ');
  //     //     cc_client.registerMessageType(
  //     //       'challenge_request_check',
  //     //       schema.challengeGameMsgSchema,
  //     //       function (socket, data) {
  //     //         console.log('Inside challenge_request_check .... ');
  //     //         challengeRequestCallback(socket, data);
  //     //       },
  //     //     );
  //     //   }

  //     //   if (challengeRejectedCallback) {
  //     //     cc_client.registerMessageType(
  //     //       'challenge_reject',
  //     //       schema.challengeGameMsgSchema,
  //     //       function (socket, data) {
  //     //         challengeRejectedCallback(socket, data);
  //     //       },
  //     //     );
  //     //   }

  //     //   if (challengeExpiredCallback) {
  //     //     cc_client.registerMessageType(
  //     //       'challenge_request_expire',
  //     //       schema.challengeGameMsgSchema,
  //     //       function (socket, data) {
  //     //         challengeExpiredCallback(socket, data);
  //     //       },
  //     //     );
  //     //   }

  //     //   if (challengeCancelCallback) {
  //     //     cc_client.registerMessageType(
  //     //       'challenge_cancel',
  //     //       schema.challengeGameMsgSchema,
  //     //       function (socket, data) {
  //     //         challengeCancelCallback(socket, data);
  //     //       },
  //     //     );
  //     //   }

  //     //   if (registerChallengeRequest) {
  //     //     cc_client.registerMessageType(
  //     //       'challenge_game_request',
  //     //       schema.challengeGameMsgSchema,
  //     //       function (socket, data) {
  //     //         console.log('About to call challenge response message handler ... ');
  //     //         registerChallengeRequest(socket, data);
  //     //       },
  //     //     );
  //     //   }
  //     // }
  //     setClient(cc_client);

  //     function challengeResponseMessageHandler(socket, data) {
  //       console.log('...challenge_cancel message data...', data);
  //       setShowChallengeCancel(true);
  //       setShowChallengeRequest(false);
  //     }

  //     function challengeCancelCallback(socket, data) {
  //       console.log('Challenge Cancel Callback ... ', data, cc_client);
  //       if (cc_client || client) {
  //         cc_client.username = userData?.user_id;
  //         setShowChallengeCancel(true);
  //         setShowChallengeRequest(false);
  //       }
  //     }

  //     function challengeRequestCallback(socket, data) {
  //       console.log('Challenge Request Callback v2 ... ', data, cc_client);
  //       if (!cc_client && !client) {
  //         return;
  //       }
  //       console.log(
  //         '...challenge_request_check message data... v Header',
  //         data.userId,
  //         userData.user_id,
  //         userData.user_id === data.userId,
  //       );
  //       console.log('User Data = ', userData);
  //       if (
  //         data.userId == userData.user_id ||
  //         data.userId + '' === userData.user_id + ''
  //       ) {
  //         setShowChallengeRequest(true);
  //         setChallengeOpponent(data.opponent);
  //         setChallengeVariantId(data.variantId);
  //         setChallengeMoveTimer(data.playerMoveTimer);
  //         setChallengeIncrement(data.moveTimeIncrement);
  //         setChallengePosition(data.gameState);
  //         setGamePlayMode(data.gamePlayMode);
  //         setChallengeWhite(data.white);
  //       }
  //     }

  //     function challengeRejectedCallback(socket, data) {
  //       console.log('Challenge Reject Callback ... ', data, cc_client);
  //       if (!cc_client && !client) {
  //         return;
  //       }
  //       console.log('...challenge_reject message data...', data);
  //       setShowChallengeReject(true);
  //     }

  //     function challengeExpiredCallback(socket, data) {
  //       console.log('Challenge Expired Callback ... ', data, cc_client);
  //       if (!cc_client && !client) {
  //         return;
  //       }
  //       console.log('...challenge_reject message data...', data);
  //       setShowChallengeExpire(true);
  //       setShowChallengeRequest(false);
  //     }

  //     function registerChallengeRequest(socket, data) {
  //       console.log(
  //         '... Registering Challenge Request from within Header ...',
  //         data,
  //       );
  //       setShowChallengeCancel(true);
  //       setShowChallengeRequest(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   console.log('Changed Screen name - ', screenName);
  //   if (userData.user_id && accessToken && screenName !== ScreenName.PlayChess && socket)
  //     CCApplicationClient();
  // }, [screenName, accessToken, socket]);

  // useEffect(() => {
  //   if (!accessToken) {
  //     setAuthorizationToken();
  //   }
  //   if (userData.user_id && accessToken && screenName !== ScreenName.PlayChess)
  //     CCApplicationClient();
  // }, [accessToken]);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  function toggleHamburger() {
    navigationRef.current.dispatch(DrawerActions.openDrawer());
  }

  function navigate(args) {
    navigationRef.navigate(args);
  }

  const [gemsAnimValue, setGemsAnimValue] = useAnimation({
    prop: 'backgroundColor',
    start: 'rgba(255, 255, 255, 0)',
    end: CCColors.Green,
    isColor: true,
  });

  const [userIconAnimStyle, setUserIconAnimValue] = useAnimation({
    prop: 'borderColor',
    start: 'rgba(255, 255, 255, 0)',
    end: CCColors.Primary.Brown,
    isColor: true,
  });

  const [rightSound] = useSound(successSound);
  const [notificationSound] = useSound(notificationSoundMp3);
  const [incorrectSound] = useSound(wrongSound);
  const [nextlevelSound] = useSound(levelupSound);
  const isThirdPartyUser = useLoggedInUserStore(
    state => state.isThirdPartyUser,
  );
  const [gems, pointsToNextLevel, level, netScore] = useLoggedInUserStore(
    useShallow(state => [
      state.gems,
      state.pointsToNextLevel,
      state.level,
      state.netScore,
    ]),
  );
  const [switchToDesktopBannerToggle, setSwitchToDesktopBannerToggle] =
    useState(true);
  // const [ChallengeOpponent, setChallengeOpponent] = useState('');
  // const [showChallengeRequest, setShowChallengeRequest] = useState(false);
  // const [ChallengeMoveTimer, setChallengeMoveTimer] = useState(0);
  // const [ChallengeIncrement, setChallengeIncrement] = useState(0);
  // const [ChallengeVariantId, setChallengeVariantId] = useState(1);
  // const [ChallengePosition, setChallengePosition] = useState(
  //   'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  // );
  // const [gamePlayMode, setGamePlayMode] = useState('Play');
  // const [ChallengeWhite, setChallengeWhite] = useState('');

  // const [showChallengeReject, setShowChallengeReject] = useState(false);
  // const [showChallengeExpire, setShowChallengeExpire] = useState(false);
  // const [showChallengeCancel, setShowChallengeCancel] = useState(false);

  // const handleChallengeResponse = async accepted => {
  //   let packet = {};
  //   packet.requestId = new Date().getTime().toString();
  //   packet.variantId = ChallengeVariantId;
  //   packet.userId = '' + userData.user_id;
  //   packet.opponent = ChallengeOpponent;
  //   packet.playerMoveTimer = ChallengeMoveTimer;
  //   packet.moveTimeIncrement = ChallengeIncrement;
  //   packet.gameState = ChallengePosition;
  //   packet.white = ChallengeWhite;
  //   packet.black = 'Black';
  //   packet.gamePlayMode = gamePlayMode;

  //   let timecontrol = '';

  //   if (accepted) {
  //     timecontrol =
  //       (ChallengeMoveTimer / 1000).toString() +
  //       ' + ' +
  //       (ChallengeIncrement / 1000).toString();

  //     packet.type = 'challenge_accept_response';
  //   } else {
  //     packet.type = 'challenge_reject';
  //   }
  //   console.log('Packet = ', packet);

  //   setShowChallengeRequest(false);

  //   await setLocalStorage('challengePacket', packet);

  //   navigation.navigate(ScreenName.PlayChess);
  // };

  useEffect(() => {
    const tempSwitchToDesktopBannerFunc = async () => {
      const tempSwitchToDesktopBanner = await getLocalStorage(
        'switchToDesktopBannerShow',
      );
      console.log(tempSwitchToDesktopBanner, 'tempSwitchToDesktopBanner');
      if (tempSwitchToDesktopBanner !== false) {
        setSwitchToDesktopBannerToggle(true);
      }
    };
    tempSwitchToDesktopBannerFunc();
  }, []);

  const handleSwitchToDesktopBannerClose = async () => {
    await setLocalStorage('switchToDesktopBannerShow', false);
    setSwitchToDesktopBannerToggle(false);
  };

  const hasNewNotifications = useNotificationStore(state =>
    state.hasNewNotifications(),
  );

  useEffect(() => {
    if (hasNewNotifications) {
      notificationSound();
    }
  }, [hasNewNotifications]);

  return (
    <>
      {isMobileOrTab && switchToDesktopBannerToggle && (
        <Box
          type="row-center"
          style={{
            backgroundColor: updateColorWithOpacity(CCColors.Red, 0.2),
            paddingHorizontal: 10,
            paddingVertical: 5,
          }}>
          <Feather
            name="info"
            size={14}
            color={CCColors.Red}
            style={{marginRight: 5}}
          />
          <CCText
            textAlign="center"
            fontSize={FontSizes[12]}
            // color={CCColors.Red}
          >
            For best experience switch to desktop
          </CCText>
          <Spacer spacing={15} horizontal />
          <Box
            onPress={handleSwitchToDesktopBannerClose}
            type="row-center"
            hitSlop={{
              top: 40,
              bottom: 40,
              left: 40,
              right: 40,
            }}
            style={{
              position: 'absolute',
              right: 8,
            }}>
            <Ionicons name={'close'} size={12} color={CCColors.Red} />
          </Box>
        </Box>
      )}

      <View style={styles.header}>
        {/* <ChallengeComponent
          challenger={ChallengeOpponent}
          visible={showChallengeRequest}
          handleChallengeResponse={handleChallengeResponse}
          timeControl={
            (ChallengeMoveTimer / 60000).toString() +
            ' + ' +
            (ChallengeIncrement / 1000).toString()
          }
        /> */}

        <Box type="row-flex-start">
          <ConditionalRender
            condition={isMobile}
            childrenA={
              <Pressable style={styles.hamburgerIcon} onPress={toggleHamburger}>
                <Entypo name="menu" size={24} color={CCColors?.Primary.Brown} />
                {/* <OctiIcon name="three-bars" size={24} color="#fff" /> */}
              </Pressable>
            }
            childrenB={<></>}
          />
          <Box flexDirection={isMobileOrTab ? 'column' : 'row'}>
            {isThirdPartyUser ? (
              <Spacer spacing={10} horizontal />
            ) : (
              <Pressable
                onPress={() => {
                  navigation.navigate(ScreenName.Dashboard);
                }}>
                {!isMobileOrTab ? (
                  <Image
                    resizeMode="contain"
                    style={[styles.logo, isMobileOrTab && styles.logoMobile]}
                    source={require('@/Assets/Images/Svgs/cc-logo-light.svg')}
                  />
                ) : (
                  <Box
                    style={{
                      flexDirection: 'row',
                      paddingBottom: 6,
                    }}>
                    <Image
                      style={styles.iconlogo}
                      source={require('@/Assets/Images/Svgs/ccIcon.svg')}
                    />
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      style={{paddingHorizontal: 6}}>
                      <CCText fontSize={14} color={CCColors.Primary.Dark}>
                        Circle
                      </CCText>
                      <CCText
                        fontWeight={600}
                        fontSize={14}
                        color={CCColors.Primary.Dark}>
                        Chess
                      </CCText>
                    </Box>
                  </Box>
                )}
              </Pressable>
            )}
            {/* <Box
              onPress={() => {
                navigation.navigate(ScreenName.Subscription);
              }}
              style={{
                backgroundColor: `${CCColors.Rewards.TextRed}16`,
                borderRadius: 4,
                marginRight: 10,
                marginBottom: isMobile && 5,
              }}>
              <CCText
                textAlign="center"
                color={CCColors.Rewards.TextRed}
                style={{padding: isMobile ? 2 : 10}}
                fontSize={isMobileOrTab ? 10 : 14}
                fontWeight="semibold">
                {userPlanStore?.activePlan === 'aspiringLearners'
                  ? 'Aspiring Learners'
                  : userPlanStore?.activePlan === 'aspiringChampions'
                  ? 'Aspiring Champions'
                  : `Novice Plan`}
              </CCText>
            </Box> */}

            <Box
              onPress={() => {
                window.open(
                  'https://youtu.be/p-j-NS-nXyo?feature=shared',
                  '_blank',
                );
              }}
              style={{
                backgroundColor: `${CCColors.Rewards.TextRed}16`,
                borderRadius: 4,
                width: isMobile && '93%',
              }}>
              <CCText
                textAlign="center"
                color={CCColors.Rewards.TextRed}
                style={{padding: isMobile ? 3 : 10}}
                fontSize={isMobileOrTab ? 10 : 14}
                fontWeight="semibold">
                View Caissa Demo
              </CCText>
            </Box>
          </Box>
        </Box>

        <View style={styles.headerRightItems}>
          {!isMobileOrTab && (
            <>
              <UserAssistance />
              <Spacer spacing={15} horizontal />
              <Streak />
            </>
          )}
          {isMobileOrTab && (
            <View style={styles.userDetails}>
              <CCText showEllipses style={styles.userLevels}>
                {netScore} points
              </CCText>
              <Box style={{flexDirection: 'row', display: 'flex'}}>
                <Spacer spacing={5} horizontal />
                <Streak />
              </Box>
            </View>
          )}
          {menu(
            <Pressable
              onHoverIn={() => setUserIconAnimValue(1)}
              onHoverOut={() => setUserIconAnimValue(0)}>
              <Animated.View
                style={[isMobileOrTab ? styles.userMobile : styles.user]}>
                {!isMobileOrTab && (
                  <View style={styles.userDetails}>
                    <CCText showEllipses style={styles.userName}>
                      {userData.first_name || userData.last_name
                        ? `${userData.first_name} ${userData.last_name}`
                        : userData.username}
                    </CCText>

                    <CCText showEllipses style={styles.userLevels}>
                      {netScore} points
                    </CCText>
                  </View>
                )}

                <Animated.View
                  style={[styles.userAvatarContainer, userIconAnimStyle]}>
                  <Icon
                    style={styles.userAvatar}
                    name={avatar.avatarDP}
                    size={30}
                    color={Colors.primaryDark}
                  />
                  {hasNewNotifications && (
                    <View
                      style={{
                        width: 13,
                        height: 13,
                        borderRadius: 25,
                        backgroundColor: CCColors.Red,
                        position: 'absolute',
                        top: -3,
                        right: 2,
                      }}
                    />
                  )}
                </Animated.View>
              </Animated.View>
            </Pressable>,
            navigate,
            onLoginStateChange,
          )}
        </View>
      </View>
    </>
  );
  // }
}
