import {FontSizes, vs} from '../../Utils/Dimensions';

import CCColors from '../../Utils/CCColors';
import {StyleSheet} from 'react-native';

const cardPadding = 24;

export const styles = StyleSheet.create({
  mobileCard: {
    flexDirection: 'column',
  },
  circleContainer: {
    backgroundColor: CCColors.Primary.Brown,
    height: 35,
    width: 35,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  reportsStrip: {
    width: '100%',
    backgroundColor: CCColors.Primary.lightBrown,
    borderRadius: 25,
    padding: 5,
    marginHorizontal: 5,
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  trainerBox: {},
  gameplayCards: {
    flexBasis: '100%',
    flexDirection: 'row',
  },
  gameplayCardsMobile: {
    flexBasis: '100%',
    flexDirection: 'column',
  },
  container: {
    backgroundColor: CCColors.Primary.Light,
    padding: 24,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // flex: 1,
    // height: '100%',
  },
  containerMobile: {
    padding: 8,
  },
  hoverCard: {
    shadowRadius: 20,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.2,
  },
  card: {
    margin: 8,
    padding: cardPadding,
    backgroundColor: '#fff',
    borderRadius: 8,
    shadowColor: CCColors.Primary.Dark,
    shadowRadius: 8,
    overflow: 'hidden',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.05,
    overflow: 'hidden',
  },
  masterGameCard: {
    flex: 0.5,
  },
  masterGameCardMobile: {
    width: '100%',
  },
  masterGameAction: {
    borderRadius: 100,
  },
  masterGameContent: {
    borderColor: CCColors.BorderLight,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  masterGameContentMobile: {
    justifyContent: 'space-between',
  },
  masterGamePlayer: {
    flex: 0.33,
    justifyContent: 'center',
    alignItems: 'center',
  },
  masterGamePlayerMobile: {
    flex: 0.48,
    marginVertical: 10,
  },
  masterGamePlayerPhotoContainer: {
    borderWidth: 2,
    borderColor: CCColors.Primary.Brown,
    width: 84,
    height: 84,
    borderRadius: 100,
    overflow: 'hidden',
    marginBottom: 8,
  },
  masterGamePlayerPhoto: {
    width: 80,
    height: 80,
    borderRadius: 100,
    borderWidth: 2,
    borderColor: '#fff',
  },
  masterGamePlayerTitle: {
    position: 'absolute',
    backgroundColor: CCColors.Primary.Action,
    fontWeight: '600',
    fontSize: 10,
    bottom: 0,
    alignSelf: 'center',
    paddingHorizontal: 32,
    paddingVertical: 2,
  },
  masterGamePlayerName: {
    fontSize: 14,
    lineHeight: 24,
  },
  masterGamePlayerRating: {
    color: CCColors.TextColor.Grey,
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '500',
  },

  masterGameTournament: {
    color: CCColors.TextColor.Grey,
    alignSelf: 'center',
    marginTop: 20,
    fontSize: 14,
    lineHeight: 18,
  },

  masterGameBoard: {
    flex: 0.34,
  },
  masterGameBoardMobile: {
    flex: 1,
  },
  PlayChessBG1: {
    height: '65%',
    width: '47%',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  PlayChessBG: {
    height: '80%',
    width: '38%',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  PlayChessBG2: {
    height: '70%',
    width: '34%',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  PlayChessBG3: {
    height: '33%',
    width: '50%',
    position: 'absolute',
    zIndex: -1,
    right: 0,
    bottom: 0,
  },
  reviewGameCard: {
    flex: 0.5,
  },
  reviewGameCardMobile: {
    flex: 1,
    flexBasis: '100%',
  },
  reviewGameDetails: {
    flex: 0.5,
    paddingLeft: 32,
    // flex: 0.5,
    paddingRight: 8,
  },
  reviewGameDetailsMobile: {
    flex: 1,
    paddingRight: 8,
    paddingLeft: 5,
  },

  reviewGameAction: {
    borderRadius: 100,
    // alignSelf: 'flex-end',
  },
  reviewGameActionMobile: {
    width: '100%',
    // alignSelf: 'flex-end',
  },

  reviewGameActionText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
    paddingRight: 16,
    // color: ''
  },
  reviewGameActionCoinsContainer: {
    backgroundColor: CCColors.Primary.Light,
    borderRadius: 100,
    padding: 4,
    paddingHorizontal: 16,
    // flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // height: 32
  },

  cardHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  cardHeadingIcon: {
    // borderRadius: 100,
    // paddingVertical: 8,
    // paddingHorizontal: 10,
    // height: 40,
    // width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',

    borderRadius: '50%',
    padding: 7,
    // display: 'flex',
  },

  cardHeadingTextContainer: {
    flexDirection: 'column',
    width: 'fit-content',
  },

  cardHeadingText: {
    fontWeight: '500',

    paddingVertical: 12,
    marginLeft: 12,
    // display: 'flex',
  },
  cardHeadingSubText: {
    fontSize: 14,
    lineHeight: 16,
    marginLeft: 12,
    color: CCColors.TextColor.Grey,
  },

  cardHeadingSubTextMobile: {
    width: '80%',
  },

  reviewGameText: {
    lineHeight: 20,
    // width: '70%',
    marginBottom: 16,
  },
  reviewGameStat: {
    marginVertical: 16,
  },
  reviewGameStatHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  reviewGameStatIcon: {
    marginRight: 8,
  },

  reviewGameStatHeadingText: {
    fontSize: 14,
    color: CCColors.TextColor.Grey,
    lineHeight: 24,
  },
  intuitionTrainerHeadingText: {
    fontSize: 16,
    color: CCColors.TextColor.Grey,
    lineHeight: 24,
  },

  reviewGameStatText: {
    fontSize: 14,
    lineHeight: 24,
  },

  reviewGameScoreStat: {
    flexDirection: 'row',
  },
  top12: {
    marginTop: vs(16),
  },
  reviewGameScoreStatHeadingText: {
    width: '80%',
    fontSize: 14,
    color: CCColors.TextColor.Grey,
    lineHeight: 24,
  },

  reviewGameScoreStatText: {
    fontSize: 14,
    lineHeight: 24,
  },
  signInTypo: {
    textAlign: 'right',
    left: '50%',
    top: 48,
    color: CCColors?.Primary?.Default,
    position: 'absolute',
  },
  labelText: {
    width: '90%',
  },
  labelTextMobile: {
    width: '100%',
  },
  buttonStyles: {
    fontSize: FontSizes[9],
    paddingVertical: vs(3),
  },
  buttonWrapper: {},

  buttonWrapperMobile: {
    width: '70%',
    margin: 'auto',
  },

  scoreboardCard: {
    flex: 1,
  },

  scoreboardTable: {
    flex: 1,
  },
  scoreboardReviewButton: {
    borderRadius: 100,
  },

  tableHeader: {
    paddingVertical: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: CCColors.BorderLight,
    paddingHorizontal: cardPadding,
    marginHorizontal: -cardPadding,
  },
  headerColText: {
    color: CCColors.TextColor.Grey,
    lineHeight: 28,
    marginLeft: 16,
  },
  metaHeaderColText: {
    borderBottomWidth: 1,
    borderBottomColor: CCColors.Grey,
    borderStyle: 'dashed',
  },
  // goals
  goalsCard: {
    flex: 1,
    flexDirection: 'row',
  },
  goalSection: {
    flex: 0.7,
    borderRightWidth: 1,
    paddingRight: 24,
    // borderRightWidth: 1,
    borderColor: CCColors.BorderLight,
  },
  goalSectionMobile: {
    flex: 0.63,
    // borderRightWidth: 1,
    borderColor: CCColors.BorderLight,
  },
  mL16: {
    marginLeft: 16,
  },
  mL2: {
    marginLeft: 5,
  },
  assessmentSection: {
    flex: 0.3,
    paddingLeft: 24,
    justifyContent: 'space-between',
  },
  assessmentSectionMobile: {
    flex: 0.3,
    marginTop: 10,
    paddingLeft: 0,
    justifyContent: 'space-between',
  },

  // goalIcon: {},

  goalsContainer: {
    marginTop: 16,
    borderTopWidth: 1,
    borderColor: CCColors.Border,
    flexDirection: 'row',
  },
  goalsMobileContainer: {
    marginTop: 16,
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottomWidth: 1,
    borderColor: CCColors.Border,
    paddingBottom: 16,
  },
  goal: {
    borderColor: CCColors.Border,
    flexBasis: '46%',
    flexShrink: '45%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 0.25,
    borderRightWidth: 1,
    paddingTop: 24,
    paddingHorizontal: 20,
  },
  goalMobile: {
    backgroundColor: CCColors.Primary.lightBrown,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'transparent',
    margin: 5,
    flexBasis: '46%',
    flexShrink: '45%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 0.5,
    padding: 10,
  },
  goalNumber: {
    fontWeight: '500',
    fontSize: 28,
    lineHeight: 36,
  },
  goalTitle: {
    fontWeight: '500',
    flexBasis: '95%',
  },
  goalSubTitle: {
    color: CCColors.TextColor.Grey,
    marginTop: 1,
    fontSize: 12,
  },
  goalSubTitleMobile: {
    width: '80%',
  },
  goalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  goalIcon: {
    borderRadius: 10,
    // backgroundColor: CCColors.Primary.lightBrown,
    padding: 8,
    // flexDirection: 'row',
    // justifyContent: 'flex-end',
  },
  goalAction: {
    borderRadius: 100,
  },

  goalInfoIcon: {},

  assessmentScore: {
    fontSize: 30,
    fontWeight: '500',
    textAlign: 'center',
  },

  assessmentScoreContainer: {
    flexDirection: 'row',
    borderColor: CCColors.Border,
    borderBottomWidth: 1,
    paddingBottom: 24,
    paddingTop: 12,
  },
  assessmentMainScore: {
    flexDirection: 'column',
    flex: 0.33,
    justifyContent: 'center',
    // paddingRight: 16,
    borderColor: CCColors.Border,
    borderRightWidth: 1,
  },
  assessmentMainScore2: {
    flexDirection: 'column',
    flex: 0.33,
    justifyContent: 'center',
    // paddingRight: 16,
    borderColor: CCColors.Border,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  assessmentBottomLabel: {
    justifyContent: 'center',
    fontSize: 12,
    width: '100%',
    textAlign: 'center',
  },
  assessmentSubScore: {
    paddingVertical: 4,
    // alignItems: 'center',
    // width: 320,
    // flexBasis: 80,
    // flex: 0.25,
  },
  upperBox: {
    width: '90%',
    height: '50%',
    overflow: 'hidden',
    position: 'absolute',
    top: 10,
    left: 10,
    paddingLeft: 10,
  },
  upperBoxMobile: {
    width: '100%',
    height: '40%',
    paddingLeft: 10,
  },
  rating: {
    height: '40%',
    width: '50%',
    overflow: 'hidden',
    position: 'absolute',
    top: '55%',
    left: 10,
    paddingLeft: 10,
  },
  ratingnum: {
    backgroundColor: '#3DAB9E',
    width: '40%',
    height: '60%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  bullseye: {
    borderRadius: '50%',
    backgroundColor: CCColors.Pink,
  },
  bullseyeicon: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: [{translateX: '50%'}, {translateY: '-50%'}],
  },
  speedometer: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: [{translateX: '50%'}, {translateY: '-50%'}],
    height: 20,
    width: 20,
  },
});
