import Box from '../../Atoms/Box';
import CCText from '../../Atoms/CCText';
import CCButton from '../../Atoms/CCButton';
import React, {useEffect, useRef, useState} from 'react';
import SafeAreaWrapper from '../../Atoms/SafeViewWrapper';
import Spacer from '@/Atoms/Spacer';
import {hs, vs, FontSizes, isPhone} from '../../Utils/Dimensions';
import {styles} from './styles';
import CCTextInput from '@/Atoms/CCTextInput';
import useLogin, {sendOtp, verifyOtp} from '@/Hooks/LoginApi/loginApi';
import ConditionalRender from '@/Atoms/ConditionalRender';
import OtpInput from 'react-otp-input';
import {
  isValidEmail,
  setLocalStorage,
  getFormattedTimeInMinutesAndSeconds,
  removeLocalStorage,
} from '@/Utils/CommonUtils';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '../../navigators/StackConstants';
import logo from '../../Assets/Images/Svgs/cc_logo.png';
import {Linking, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import CCColors from '@/Utils/CCColors';
import PersonalisedLearning from '../../Assets/Images/Svgs/learning/personalized-content.svg';
import GamifiedExperience from '../../Assets/Images/Svgs/learning/gamified-experience.svg';
import LearnFromMasters from '../../Assets/Images/Svgs/learning/master-games.svg';
import Assessment from '../../Assets/Images/Svgs/learning/assessment.svg';
import {Image} from 'react-native';
import frame_background from '../../Assets/Images/Svgs/background_frames.svg';
import countryCodes from './countryCodes.json';
import CCSelect from '@/Atoms/CCSelect';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCCheckbox from '@/Atoms/CCCheckbox';
import SlideModal from '@/Atoms/SlideModal';
import TncContext from './components/TncContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileContract} from '@fortawesome/free-solid-svg-icons';

let timer;
function Login({onLoginStateChange, isEvaluate = false}) {
  const navigation = useNavigation<any>();

  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [counter, setCounter] = useState<string>('');
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [onboardingMessage, setOnboardingMessage] = useState<string>('');

  const [enableRegistration, setEnableRegistration] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState('+91');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const {isMobileOrTab} = useMediaQuery();
  const timerRef = useRef(null);
  const [tnc, setTnc] = useState<number>(0);
  const [loginData, setLoginData] = useState<{
    username: string;
    password: string;
    confirmpassword: string;
    email: string;
    isLogin: boolean;
    mobile: string;
    otp: string;
  }>({
    username: '',
    password: '',
    confirmpassword: '',
    email: '',
    isLogin: true,
    mobile: '',
    otp: '',
  });

  // console.log('countryCodes', countryCodes);
  const sortedCountryCodes = [...countryCodes].sort(
    (b, a) =>
      parseInt(b.dial_code.replace('+', ''), 10) -
      parseInt(a.dial_code.replace('+', ''), 10),
  );

  let codesArray = sortedCountryCodes.map(item => ({
    label: item.dial_code,
    value: item.dial_code,
  }));

  const handleOnSuccess = async (apiData: any) => {
    await setLocalStorage('refresh_token', apiData?.refresh_token, true);
    await setLocalStorage('access_token', apiData?.access_token, true);
    await setLocalStorage('userkey', apiData?.user_key, true);
    setLoggedIn(true);
    onLoginStateChange(true);
  };

  const handleOnError = (apiData: any) => {
    setLoggedIn(false);
    setLoginError(true);
    setErrorMsg(apiData.error);
  };

  const getFormattedTimeInMinutesAndSeconds = (min, sec) => {
    return `${min.toString().padStart(2, '0')}:${sec
      .toString()
      .padStart(2, '0')}`;
  };
  const startTimer = () => {
    let sec = 0;
    let min = 1;
    setCounter(getFormattedTimeInMinutesAndSeconds(min, sec));

    timerRef.current = setInterval(() => {
      if (sec === 0 && min === 0) {
        clearInterval(timerRef.current);
        setCounter('');
      } else {
        if (sec === 0) {
          min -= 1;
          sec = 60;
        }
        sec -= 1;
        setCounter(getFormattedTimeInMinutesAndSeconds(min, sec));
      }
    }, 1000);
  };
  const sendOtpSuccess = data => {
    setIsOtpSent(true);
    if (data) {
      setTnc(data.tnc);
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    startTimer();
  };

  const {mutate: sendOtpApi, isLoading: isSendOtpLoading} = sendOtp(
    sendOtpSuccess,
    handleOnError,
  );

  const {mutate: verifyOtpApi, isLoading: isVerifyOtpLoading} = verifyOtp(
    handleOnSuccess,
    handleOnError,
  );

  const handleLoginDataChange = (text: string, field: string) => {
    setLoginData(prev => ({...prev, [field]: text}));
  };

  useEffect(() => {
    setLoginError(false);
    if (!isLogin) validateRegistration();
  }, [loginData]);

  useEffect(() => {
    const handleEvaluate = async () => {
      if (isEvaluate) {
        await setLocalStorage('isEvaluate', isEvaluate);
      } else {
        await removeLocalStorage('isEvaluate');
      }
    };
    handleEvaluate();
  }, [isEvaluate]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const validateRegistration = () => {
    if (
      loginData.username === '' ||
      loginData.username?.trim() === '' ||
      loginData.username?.length < 4
    ) {
      setLoginError(true);
      setErrorMsg('Choose a username more than 4 characters');
      setEnableRegistration(false);
      return;
    }
    if (
      loginData.email === '' ||
      loginData.email?.trim() === '' ||
      !isValidEmail(loginData.email)
    ) {
      setLoginError(true);
      setErrorMsg('Share a valid email address');
      setEnableRegistration(false);
      return;
    }
    if (
      loginData.password === '' ||
      loginData.confirmpassword === '' ||
      loginData.password !== loginData.confirmpassword
    ) {
      setLoginError(true);
      setErrorMsg('Check your password fields please, make sure both match!');
      setEnableRegistration(false);
      return;
    }

    setEnableRegistration(true);
  };

  // const doLogin = () => {
  //   try {
  //     const {username, password, confirmpassword, email} = loginData;
  //     setLoginError(false);
  //     setErrorMsg('');
  //     if (isLogin) {
  //       const data = login({username, password, email, isLogin});
  //     } else {
  //       if (
  //         password === '' ||
  //         confirmpassword === '' ||
  //         username === '' ||
  //         email === ''
  //       ) {
  //         setLoginError(true);
  //         setErrorMsg('Fill in all registration details to proceed');
  //       } else if (password !== confirmpassword) {
  //         setLoginError(true);
  //         setErrorMsg('Passwords do not match');
  //       } else {
  //         const data = login({username, password, email, isLogin});
  //       }
  //     }
  //   } catch (e) {
  //     console.log('Error occurred while trying to login .. ', e);
  //   }
  // };

  const sendOtpRequest = () => {
    try {
      const {mobile} = loginData;
      let completeMobile = countryCode + mobile;
      console.log('completeMobile is : ', completeMobile);

      completeMobile = completeMobile.replace(/\+/g, '');

      console.log('completeMobile is : ', completeMobile);
      setLoginError(false);
      setErrorMsg('');
      // sendOtpApi({mobile: completeMobile});
      setOnboardingMessage('');

      sendOtpApi(
        {mobile: completeMobile, countryCode, source: 'learn'},
        {
          onSuccess: response => {
            if (
              response.success === false &&
              response.error === 'Onboarding halted'
            ) {
              setOnboardingMessage('Onboarding halted');
              setIsOtpSent(false);
            } else {
              sendOtpSuccess();
            }
          },
          onError: error => {
            console.log('Error occurred while trying to login: ', error);
            setLoginError(true);
            setErrorMsg('An error occurred during the login process.');
          },
        },
      );
    } catch (e) {
      console.log('Error occurred while trying to login .. ', e);
    }
  };

  const verifyOtpRequest = () => {
    try {
      let {mobile, otp} = loginData;
      let completeMobile = countryCode + mobile;
      completeMobile = completeMobile.replace(/\+/g, '');

      setLoginError(false);
      setErrorMsg('');
      mobile = completeMobile;
      verifyOtpApi({
        mobile,
        countryCode,
        otp,
        terms: checkboxValue,
      });
    } catch (e) {
      console.log('Error occurred while trying to login .. ', e);
    }
  };

  const loginWithOtp = (
    <>
      <Box
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{paddingTop: vs(12)}}>
        <ConditionalRender
          condition={!isOtpSent}
          childrenA={<Text style={styles.textHeaders}>Login/Register</Text>}
          childrenB={<Text style={styles.textHeaders}>OTP Verification</Text>}
        />
      </Box>
      <ConditionalRender
        condition={!isOtpSent}
        childrenA={
          <Box
            flexDirection={isMobileOrTab ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            style={{
              paddingTop: vs(24),
              borderBlockColor: 'yellow',
              // borderWidth: 1,
              borderColor: CCColors.TextBoxes.Borders,
            }}>
            <Box
              style={{
                borderWidth: 1,
                borderColor: CCColors.Border,
                borderRadius: 10,
                padding: 5,
              }}
              flexDirection="row"
              // justifyContent="center"
              alignItems="center">
              <CCSelect
                hideBorder
                style={{
                  backgroundColor: CCColors.White,
                  flex: 0.15,
                  // width: !isMobileOrTab ? hs(38) : hs(50),
                  height: vs(50),
                  borderColor: CCColors.White,
                }}
                label=""
                placeholder="+91"
                selectedValue={countryCode}
                items={codesArray}
                onValueChange={code => setCountryCode(code)}
              />
              <CCTextInput
                outlineColor={CCColors.White}
                outlineActiveColor={CCColors.White}
                labelActiveColor=""
                inputType="phone"
                label="Enter Mobile Number"
                style={styles.textBoxes}
                value={loginData.mobile}
                fontSize={14}
                // staticPrefix="+91"
                onChangeText={(text: string) => {
                  handleLoginDataChange(text, 'mobile');
                }}
                onSubmitEditing={sendOtpRequest}
                returnKeyType="done"
              />
            </Box>
          </Box>
        }
        childrenB={
          <Box
            flexDirection={isMobileOrTab ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            style={{paddingTop: vs(24)}}>
            <OtpInput
              value={loginData.otp}
              onChange={text => handleLoginDataChange(text, 'otp')}
              numInputs={4}
              shouldAutoFocus
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              inputStyle={{
                ...styles.textBoxes,
                width: 30,
                height: 30,
                outline: 'none',
              }}
              renderInput={props => <input {...props} />}
            />
          </Box>
        }
      />
      <Spacer spacing={48} />
      <ConditionalRender
        condition={loginError}
        childrenA={
          <CCText
            style={{
              flex: 0.1,
              textAlign: 'center',
              marginBottom: 6,
              color: 'red',
            }}>
            {errorMsg}
          </CCText>
        }
        childrenB={<></>}
      />
      <Box
        flexDirection={isMobileOrTab ? 'column' : 'row'}
        justifyContent="center">
        <Box flexDirection="column" style={{display: 'flex'}}>
          <ConditionalRender
            condition={isOtpSent}
            childrenA={
              <>
                {tnc > 0 && (
                  <>
                    <Box
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <CCCheckbox
                        label={
                          tnc === 2 ? (
                            <Box>
                              <CCText fontSize={FontSizes[14]}>
                                By continuing you agree to CircleChess'{' '}
                                <span
                                  style={{
                                    color: CCColors.BlueAqua,
                                    textDecoration: 'underline',
                                  }}>
                                  terms and conditions.
                                </span>
                              </CCText>
                            </Box>
                          ) : tnc === 1 ? (
                            <Box>
                              <CCText fontSize={FontSizes[14]}>
                                Please agree to CircleChess'{' '}
                                <span
                                  style={{
                                    color: CCColors.BlueAqua,
                                    textDecoration: 'underline',
                                  }}>
                                  terms and conditions.
                                </span>
                              </CCText>
                            </Box>
                          ) : null
                        }
                        value={checkboxValue}
                        onValueChange={(label, newValue) => {
                          setCheckboxValue(newValue);
                        }}
                        customStyle={{
                          alignItems: 'center',
                        }}
                        onLabelPress={() => setShowPopup(true)}
                        customFont={12}
                      />
                    </Box>
                    <Spacer spacing={20} />
                  </>
                )}
                <CCButton
                  style={[
                    styles?.buttonWrapper,
                    isMobileOrTab && styles.buttonWrapperMobile,
                  ]}
                  titleStyle={styles.buttonStyles}
                  onPress={verifyOtpRequest}
                  disabled={tnc > 0 ? !checkboxValue : isVerifyOtpLoading}>
                  <CCText fontSize={FontSizes[12]}>Verify Otp</CCText>
                </CCButton>
              </>
            }
            childrenB={
              <CCButton
                style={[
                  styles?.buttonWrapper,
                  isMobileOrTab && styles.buttonWrapperMobile,
                ]}
                titleStyle={styles.buttonStyles}
                onPress={sendOtpRequest}
                disabled={isSendOtpLoading}>
                <CCText fontSize={FontSizes[12]}> Submit </CCText>
              </CCButton>
            }
          />

          {onboardingMessage && (
            <CCText
              style={{
                fontSize: 16,
                color: CCColors?.Red,
                marginTop: 8,
                marginLeft: 20,
              }}>
              {onboardingMessage}
            </CCText>
          )}
        </Box>
      </Box>
    </>
  );

  return (
    <SafeAreaWrapper>
      <Box style={styles.container} flexDirection="row">
        {showPopup && (
          <SlideModal
            type="center"
            contentPadding={15}
            visible={showPopup}
            minWidth={200}
            closeIconSize={22}
            dynamicHeight={30}
            dynamicWidth={30}
            onClose={() => setShowPopup(false)}
            customStyle={{width: '45%', height: '60%'}}
            customMobileStyle={[
              styles.modal,
              {
                borderRadius: !isMobileOrTab && 12,

                ...(isMobileOrTab && {
                  borderTopLeftRadius: 12,
                  borderTopRightRadius: 12,
                }),
              },
            ]}
            customMargin={0}
            modalHeaderTitle={
              <Box flexDirection="row" gap={15} style={{alignItems: 'center'}}>
                <FontAwesomeIcon icon={faFileContract} style={styles.icon} />
                <CCText fontSize={FontSizes[18]} fontWeight={600}>
                  Terms and Conditions for CircleChess Portal
                </CCText>
              </Box>
            }>
            <TncContext
              terms={tnc}
              onAgree={() => {
                setCheckboxValue(true);
              }}
            />
          </SlideModal>
        )}
        {!isMobileOrTab && (
          <Box
            flex={0.5}
            style={{
              borderWidth: 0.5,
              padding: vs(64),
              borderColor: '#FAF6EB',
              backgroundColor: '#FAF6EB',
              justifyContent: 'center',
            }}
            flexDirection="column">
            <Image
              source={frame_background}
              style={{
                width: '75%',
                height: '75%',
                position: 'absolute',
                bottom: 0,
                right: 0,
                opacity: 0.5,
              }}
            />
            <CCText fontSize={FontSizes[48]} style={styles.LeftHeading}>
              Play, Enjoy & Learn
            </CCText>
            {/* <CCText fontSize={FontSizes[20]} style={styles.LeftSubtitle}>
              Only if you want to level up your game!
            </CCText> */}
            <Box flex={0.5} flexDirection="row" style={styles.LeftTop}>
              <Box flexDirection="column" style={{alignItems: 'center'}}>
                <Image source={PersonalisedLearning} style={styles.uspImage} />
                <CCText fontSize={FontSizes[14]} style={styles.uspText}>
                  Game Play & Review
                </CCText>
              </Box>

              <Box flexDirection="column" style={{alignItems: 'center'}}>
                <Image source={GamifiedExperience} style={styles.uspImage} />
                <CCText fontSize={FontSizes[14]} style={styles.uspText}>
                  Detailed Assessments
                </CCText>
              </Box>

              <Box flexDirection="column" style={{alignItems: 'center'}}>
                <Image source={Assessment} style={styles.uspImage} />
                <CCText fontSize={FontSizes[14]} style={styles.uspText}>
                  Rules & Parental Controls
                </CCText>
              </Box>

              <Box flexDirection="column" style={{alignItems: 'center'}}>
                <Image source={LearnFromMasters} style={styles.uspImage} />
                <CCText fontSize={FontSizes[14]} style={styles.uspText}>
                  Improvement Tracking
                </CCText>
              </Box>
            </Box>
            <Box flex={0.4} flexDirection="row" style={styles.LeftTop}>
              <Box flexDirection="column" style={{alignItems: 'center'}}>
                <Image source={PersonalisedLearning} style={styles.uspImage} />
                <CCText fontSize={FontSizes[14]} style={styles.uspText}>
                  Personalized Trainer
                </CCText>
              </Box>

              <Box flexDirection="column" style={{alignItems: 'center'}}>
                <Image source={GamifiedExperience} style={styles.uspImage} />
                <CCText fontSize={FontSizes[14]} style={styles.uspText}>
                  Redeem Rewards
                </CCText>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          flex={isMobileOrTab ? 1 : 0.5}
          style={[
            styles.labelText,
            styles.formHalf,
            isMobileOrTab && styles.formHalfMobile,
          ]}>
          <img src={logo} alt="Circlechess" style={styles.logo} />
          <Spacer spacing={12} />
          <CCText fontWeight="semibold" style={styles.loginHeader}>
            &nbsp;
          </CCText>
          <Spacer spacing={72} />
          <ConditionalRender condition={true} childrenA={loginWithOtp} />
          <Spacer spacing={48} />
          {isOtpSent && (
            <Box flexDirection="row" style={{justifyContent: 'space-evenly'}}>
              <ConditionalRender
                condition={counter.length > 0}
                childrenA={
                  <CCText>Didn't received code? Retry after {counter}</CCText>
                }
                childrenB={
                  <CCText
                    style={{color: '#3DAB9E', marginLeft: 4}}
                    onPress={sendOtpRequest}>
                    Resend Now
                  </CCText>
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </SafeAreaWrapper>
  );
}

export default Login;
