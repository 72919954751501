import {create} from 'zustand';

type streak_config_type = {
  id: number;
  name: string;
  description: string;
  target_count: number;
  user_count: number;
};

type streakDetailsSummaryType = {
  streak_count: number;
  last_streak_date: string | null;
};

interface UserState {
  mobile: string;
  email: string;
  userId: string;
  avatar: string;
  icon: string;
  gems: Number;
  isThirdPartyUser: boolean;
  level: Number;
  progress: Number;
  pointsToNextLevel: Number;
  netScore: Number;
  noOfPuzzlesSolved: number;
  dropDownClose: boolean;
  onboardingState: Number;
  streakConfig: streak_config_type[] | null;
  streakDetailsSummary: streakDetailsSummaryType | null;
}
// {
//   "id": 1,
//   "name": "Puzzles",
//   "description": "Solve 5 endgame puzzles",
//   "target_count": 5
// }
interface UserActions {
  setMobile: (mobile: string) => void;
  setEmail: (email: string) => void;
  setUserId: (userId: string) => void;
  setGems: (gems: Number) => void;
  setLevel: (level: Number) => void;
  setIsThirdPartyUser: (isThirdPartyUser: Boolean) => void;
  setAvatar: (avatar: string) => void;
  setIcon: (icon: string) => void;
  setProgress: (progress: Number) => void;
  setNoOfPuzzlesSolved: (noOfPuzzlesSolved: number) => void;
  setDropDownClose: (value: boolean) => void;
  setOnboardingState: (onboardingState: number) => void;
  setStreakConfig: (streakConfig: streak_config_type[]) => void;
  setStreakDetailsSummary: (
    streakDetailsSummary: streakDetailsSummaryType,
  ) => void;
}

const useLoggedInUserStore = create<UserState & UserActions>(set => ({
  mobile: '',
  email: '',
  isThirdPartyUser: false,
  userId: '',
  gems: 0,
  level: 0,
  avatar: '',
  icon: '',
  progress: 0,
  netScore: 0,
  streakDetailsSummary: null,
  noOfPuzzlesSolved: 0,
  dropDownClose: false,
  learning_onboarding_status: 0,
  streakConfig: null,
  setMobile: mobile => set({mobile: mobile}),
  setIsThirdPartyUser: isThirdPartyUser =>
    set({isThirdPartyUser: isThirdPartyUser}),
  setEmail: email => set({email: email}),
  setUserId: userId => set({userId: userId}),
  setGems: gems => set({gems: gems}),
  setLevel: level => set({level: level}),
  setAvatar: avatar => set({avatar: avatar}),
  setIcon: icon => set({icon: icon}),
  setProgress: progress => set({progress: progress}),
  setNoOfPuzzlesSolved: noOfPuzzlesSolved => set({noOfPuzzlesSolved}),
  setDropDownClose: value => set({dropDownClose: value}),
  setOnboardingState: onboardingState => set({onboardingState}),
  setStreakConfig: streakConfig => set({streakConfig}),
  setStreakDetailsSummary: streakDetailsSummary => set({streakDetailsSummary}),
}));

export default useLoggedInUserStore;
