import Box from '@/Atoms/Box';
import ChipList from '@/Atoms/ChipList/ChipList';
import usePuzzlesApi from '@/Hooks/PuzzlesApi/puzzlesApi';
import {Puzzle, PuzzleFields} from '@/Hooks/PuzzlesApi/types';
import React, {useEffect, useState} from 'react';
import {capitalizeFirstLetter} from '../../../../../Utils/CommonUtils';
import style from '../../../styles';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import inaccurateIcon from '../../../../../Assets/Images/Svgs/learning/inaccuracy-icon.svg';
import blunderIcon from '../../../../../Assets/Images/Svgs/learning/blunder-icon.svg';
import mistakesIcon from '../../../../../Assets/Images/Svgs/learning/mistakes-icon.svg';

import {Text, View, ScrollView, StyleSheet, Image} from 'react-native';

import useLoggedInUserStore from '@/Store/loggedInUserstore';
import StockfishComponent from '@/Components/StockfishEngine/StockfishComponent';
import CCLoader from '@/Atoms/CCLoader';
type PuzzelsType = {
  handleFen: (tempFen: string) => {};
  gameKey: string;
  preselectPuzzle: any;
  refresh: boolean;
  handleNextPuzzlReset: () => void;
  currentPlayingFen: string;
};
function Puzzels(props: PuzzelsType) {
  const {
    handleFen = (fen, isPuzzle, puzzleId, lastFen) => {},
    gameKey = null,
    preselectPuzzle = null,
    refresh = false,
    handleNextPuzzlReset = () => {},
    currentPlayingFen,
  } = props;

  const [allPuzzlesToShow, setallPuzzlesToShow] = useState([]);
  const [selectedPuzzels, setSelectedPuzzels] = useState<any>(null);
  const [noOfPuzzels, setNoOfPuzzels] = useState(0);
  const noOfPuzzlesSolved = useLoggedInUserStore(
    state => state.noOfPuzzlesSolved,
  );
  const setNoOfPuzzlesSolved = useLoggedInUserStore(
    state => state.setNoOfPuzzlesSolved,
  );

  const getInaccuracy = (puzzle: Puzzle) => {
    if (puzzle?.fields?.inaccuracy_itg === 1) return 'inaccuracy';
    else if (puzzle?.fields?.mistake_itg === 1) return 'mistake';
    else if (puzzle?.fields?.blunder_itg === 1) return 'blunder';
    else return 'wrong move';
  };

  const getPuzzleKey = (puzzle: Puzzle) => {
    let retVal = '';
    let move_index = '';
    if (puzzle?.fields?.color_itg === 'white') {
      move_index = puzzle?.fields?.move_index_itg + '. ';
    } else {
      move_index = puzzle?.fields?.move_index_itg + '. .. ';
    }
    if (puzzle?.fields?.area.toUpperCase() === 'MISTAKE') {
      retVal +=
        'You played ' +
        move_index +
        puzzle?.fields?.last_move_played +
        ' last. Find opponent response.';
    } else {
      retVal +=
        'You played ' +
        move_index +
        puzzle?.fields?.actual_move_played_itg +
        '. Instead, find the best move.';
    }
    return retVal;
  };

  const handleOnSuccess = data => {
    console.log('handleOnSuccess', data);
    setNoOfPuzzels(data?.data?.length);
    // if (noOfPuzzlesSolved > 0) {
    //   setNoOfPuzzlesSolved(noOfPuzzlesSolved - 1);
    // } else {
    //   setNoOfPuzzlesSolved(noOfPuzzlesSolved + 1);
    // }
    setNoOfPuzzlesSolved(noOfPuzzlesSolved + 1);
    // const tempOptions = [];
    // for (let index = 0; index < data?.data?.length; index++) {
    //   let i = data?.data[index];
    //   // @ts-ignore
    //   tempOptions.push({
    //     key: getPuzzleKey(i),
    //     value: i?.pk,
    //     moveDetails: i,
    //     isOver: i?.fields?.solved,
    //     overClickable: true,
    //     prefix:
    //       i?.fields?.color_to_move === 'white' ? (
    //         <Box style={style.whiteBox} />
    //       ) : (
    //         <Box style={style.BrownBox} />
    //       ),
    //     postfix: i?.fields?.solved ? null : (
    //       <CCText color={CCColors.Primary.Default}>+5pts</CCText>
    //     ),
    //   });
    // }
    const tempOptions =
      data?.data?.map(i => ({
        key: getPuzzleKey(i),
        value: i?.pk,
        moveDetails: i,
        isOver: i?.fields?.solved,
        overClickable: true,
        prefix:
          i?.fields?.color_to_move === 'white' ? (
            <Box style={style.whiteBox} />
          ) : (
            <Box style={style.BrownBox} />
          ),

        type:
          i?.fields?.inaccuracy_itg == 1 ? (
            <Image source={inaccurateIcon} style={{width: 25, height: 20}} />
          ) : i?.fields?.mistake_itg == 1 ? (
            <Image source={mistakesIcon} style={{width: 25, height: 20}} />
          ) : (
            <Image source={blunderIcon} style={{width: 25, height: 20}} />
          ),

        postfix: i?.fields?.solved ? (
          i?.fields?.no_of_attempts == 1 ? (
            <CCText color="green">+20pts</CCText>
          ) : (
            <CCText color="red">{`${i?.fields?.no_of_attempts} Attempts`}</CCText>
          )
        ) : (
          <CCText color={CCColors.Primary.Default}>+20pts</CCText>
        ),
      })) || [];

    if (preselectPuzzle) {
      // key: getPuzzleKey(i),
      // value: i?.pk,
      // moveDetails: i,
      // isOver: i?.fields?.solved,
      // overClickable: true,
      // prefix:
      //   i?.fields?.color_to_move === 'white' ? (
      //     <Box style={style.whiteBox} />
      //   ) : (
      //     <Box style={style.BrownBox} />
      //   ),
      // postfix: i?.fields?.solved ? null : (
      //   <CCText color={CCColors.Primary.Default}>+5pts</CCText>
      // ),
      const tempSelectedPuzzle = tempOptions?.find(
        i => i?.moveDetails?.fields?.fen === preselectPuzzle?.fen,
      );
      if (tempSelectedPuzzle) {
        console.log('Setting selected puzzles .. ', tempSelectedPuzzle);
        // setSelectedPuzzels({
        //   key: getPuzzleKey(tempSelectedPuzzle),
        //   value: tempSelectedPuzzle,
        // });

        handlePuzzelsSelection(tempSelectedPuzzle);
      }
    } else {
      // handlePuzzelsSelection(tempOptions?.find(i => !i?.isOver));
    }
    setOptions(tempOptions);
    setallPuzzlesToShow(data?.data);
  };

  const handleOnError = () => {};
  const {
    mutate: getPuzzles,
    data: allPuzzels,
    error: allPuzzelsError,
    isLoading,
  } = usePuzzlesApi(handleOnSuccess, handleOnError);

  useEffect(() => {
    if (gameKey) {
      getPuzzles({game_key: gameKey});
    }
  }, [gameKey, refresh]);

  // useEffect(() => {
  //   if (allPuzzlesToShow && allPuzzlesToShow.length > 0) {
  //     const tempSelectedPuzzle = allPuzzlesToShow?.[0];
  //     // setSelectedPuzzels({
  //     //   key: getPuzzleKey(tempSelectedPuzzle),
  //     //   value: tempSelectedPuzzle,
  //     // });
  //   }
  // }, [refresh]);

  // useEffect(() => {
  //   if (preselectPuzzle) {
  //     console.log(allPuzzlesToShow, 'allPuzzlesToShow');
  //     debugger;
  //     // setSelectedPuzzels({
  //     //   key: getPuzzleKey(tempSelectedPuzzle),
  //     //   value: tempSelectedPuzzle,
  //     // });
  //   }
  // }, [preselectPuzzle]);

  const handlePuzzelsSelection = (tempSelect: {
    key: string;
    value: Puzzle;
    moveDetails: Puzzle;
  }) => {
    // debugger;
    handleNextPuzzlReset?.();
    setSelectedPuzzels(tempSelect);
    const temp = {fen: tempSelect?.moveDetails?.fields.previous_fen};
    handleFen(
      tempSelect?.moveDetails?.fields,
      true,
      tempSelect?.moveDetails?.pk,
      temp,
      (currentPuzzleId: any) => {
        // setSelectNextPuzzle(true);
        // const nextPuzzle = options?.filter(
        //   i => !i?.isOver && currentPuzzleId !== i?.value,
        // )?.[0];
        // console.log(selectedPuzzels);
        // // const tempnextPuzzle = options?.filter(i => !i?.isOver)?.[0];
        // debugger;
        // if (nextPuzzle) {
        //   handlePuzzelsSelection(nextPuzzle);
        // }
      },
    );
  };
  const [options, setOptions] = useState([]);
  // let options = [];

  if (isLoading) {
    return <CCLoader loading={isLoading} isComponentLoader={true} />;
  }
  return (
    <Box margin={10}>
      {selectedPuzzels?.isOver ? (
        <StockfishComponent fen={currentPlayingFen} />
      ) : (
        <></>
      )}

      <CCText style={{margin: 12, marginTop: 4, textAlign: 'center'}}>
        {noOfPuzzels === 0
          ? 'No position generated from the game'
          : 'Attempt these positions from the game again:'}
      </CCText>
      <ChipList
        onChange={element => {
          handlePuzzelsSelection(element);
        }}
        value={selectedPuzzels?.value}
        options={options}
        type={2}
        horizontal={false}
      />
    </Box>
  );
}

export default Puzzels;
