/* eslint-disable react-native/no-inline-styles */
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {evaluateMove, generateSummary} from '../../utils';
import moveIconsAndColors from '../../helper';
import styles from '../../styles';
import customStyles from './styles';
import Feather from 'react-native-vector-icons/Feather';
import Spacer from '@/Atoms/Spacer';
import {
  Pressable,
  ScrollView,
  TouchableOpacity,
  Text,
  View,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MoveList from '../MoveList';
import {FontSizes, WINDOW_WIDTH, vs} from '@/Utils/Dimensions';
// import Tabs from './Tabs';
import CCButton from '@/Atoms/CCButton';
import Puzzels from './Puzzels';
import CCColors from '@/Utils/CCColors';
import {useRoute} from '@react-navigation/native';
import KeyEvent from 'react-native-keyevent';
import ProgressBar from '@/Atoms/ProgressBar';
import useGameAnalysisScoreApi from '@/Hooks/StreamGameAnalysis/useGameAnalysisScoreApi';
import {convertGameScoreToTextSummary} from '@/Utils/CommonUtils';
import {AccordionSection} from '@/Atoms/CCAccordion';
import GameOverview, {ChessGameType} from '../GameOverview';
import {throttle} from 'lodash';
import SlideModal from '@/Atoms/SlideModal';
import CCTextInput from '@/Atoms/CCTextInput';
import useSaveGameMoveAnnotation from '@/Hooks/SaveGameMoveAnnotation/useSaveGameMoveAnnotation';
import Tooltip from '@/Atoms/Tooltip';
import {FavStar} from '@/Screens/Games';
import useMarkFavouriteGameApi from '@/Hooks/MarkFavouriteGameApi/markFavouriteGameApi';
import useGetUserGamesApi from '@/Hooks/GetUserGamesApi/useGetUserGamesApi';
import MovesList from '@/Atoms/MovesList';
import {ChessAnalysisTabs} from './Tabs';
import {TabView} from 'react-native-tab-view';
import {renderTabBar} from '@/Components/TabBar';
import CCTabs, {renderBoxTabBar, renderUnderlineTabBar} from '@/Atoms/CCTabs';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCLoader from '@/Atoms/CCLoader';
import {HintForIncorrectAttempt} from '@/Screens/Puzzles/next';
import usePuzzleFeedback from '@/Hooks/FeedbackApi/useFeedback';
import {Chess} from 'chess.js';

// import {LineChart} from 'react-native-chart-kit';
// import ChessGraph from './chessGraph';

type AnalysisRightComponentTypes = {
  handleFen: Function;
  manualMoves: any;
  handleSelectMoveInReview: Function;
  handleSaveAlternateMove: Function;
  gameMoves: any;
  puzzleSummary: any;
  currentPlayingFen: string;
  players: any;
  gameReviewedByUser: boolean;
  result: string;
  refreshPuzzles: boolean;
  analysisInProgress: boolean;
  handleNextPuzzlReset?: Function;
  tabOpen: tabsType;
  selectedManualMove?: any;
  gameOverviewDetails?: ChessGameType;
  handleSelectedTabChecks?: (tab: string) => void;
  fen2?: string;
  isFenFromOutside?: boolean;
  setIsFenFromOutside?: () => {};
  fullHint?: string;
  fullHint2?: string;
  puzzleId?: number;
  isStaff?: boolean;
  attemptId?: number;
  infocolor?: string;
  viewgame?: boolean;
  gameid?: string;
  SetPlayerColor?: () => {};
};

export type tabsType = 'OVERVIEW' | 'GAME REVIEW' | 'ANALYSIS' | 'POSITIONS';
const tabsToDisplay = ['OVERVIEW', 'GAME REVIEW', 'POSITIONS'];
function AnalysisRightComponent(props: AnalysisRightComponentTypes) {
  const route = useRoute();
  const params = route?.params?.game_id;
  const {
    manualMoves = [],
    handleFen = () => {},
    selectedManualMove = {},
    tabOpen,
    gameMoves = [],
    currentPlayingFen = '',
    handleSelectMoveInReview = () => {},
    puzzleSummary = {},
    players = {},
    gameReviewedByUser = false,
    result = 'Loading',
    refreshPuzzles = false,
    analysisInProgress,
    gameOverviewDetails,
    handleSelectedTabChecks = () => {},
    handleNextPuzzlReset = () => {},
    fen2,
    isFenFromOutside,
    fullHint2,
    fullHint,
    setIsFenFromOutside,
    puzzleId,
    isStaff,
    attemptId,
    infocolor,
    gameid,
    viewgame = false,
    SetPlayerColor,
  } = props;
  const [summaryDetails, setSummaryDetails] = useState(null);
  const [selectedMoveType, setSelectedMoveType] = useState('');
  const [selectedMoves, setSelectedMoves] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState<tabsType>('GAME REVIEW');
  const [activeMove, setActiveMove] = useState<any>(null);
  const [preselectPuzzle, setPreselectPuzzle] = useState('');
  const [index, setIndex] = React.useState(1);
  const {isMobileOrTab} = useMediaQuery();
  const [routes] = React.useState(
    [
      {key: 'OVERVIEW', title: 'Overview'},
      {key: 'GAME_REVIEW', title: 'Game review'},
      ...(viewgame ? [] : [{key: 'POSITIONS', title: 'Positions'}]),
      // { key: "previousTournaments", title: "Previous Tournaments" },
    ]?.filter(i => i),
  );
  const [gameOverviewGames, setGameOverviewGames] = useState({});
  const [goodFeedback, setGoodFeedback] = useState<number>(0);
  const [badFeedback, setBadFeedback] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [showChange, setShowChange] = useState(false);
  const [showIncorrectHintModal, setShowIncorrectHintModal] =
    useState<boolean>(false);
  const [disabledTabs, setDisabledTabs] = useState<boolean>(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [games, setGames] = useState([]);
  const [isFav, setIsFav] = useState(false);
  const [moveList, setMoveList] = useState<string[]>([]);
  const [parsedMoves, setParsedMoves] = useState([]);

  useEffect(() => {
    if (fullHint || fullHint2) {
      setShowIncorrectHintModal(true);
    }
    setShowChange(false);
    setGoodFeedback(0);
    setBadFeedback(0);
  }, [fullHint, fullHint2]);
  useEffect(() => {
    setShowChange(false);
    setGoodFeedback(0);
    setBadFeedback(0);
  }, [infocolor]);
  const {
    mutate: feedBack,
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = usePuzzleFeedback();

  async function handleSubmit(selectedFeedback: number) {
    try {
      await feedBack({
        feedback: selectedFeedback,
        puzzle_id: puzzleId,
        userid: userId,
        attempt_id: attemptId,
      });
    } catch (error) {
      console.error(error);
    }
  }
  const {
    mutate: markFavourite,
    data: markFavouriteData,
    error: markFavouriteError,
  } = useMarkFavouriteGameApi(
    data => {
      console.log('favourite star marked');
    },
    error => {
      console.log('favourite star error', error);
    },
  );
  const [pgn, setPgn] = useState('');
  const [playerColor, setPlayerColor] = useState('');
  const [username, setUsername] = useState('');
  const handleOnSuccessGames = data => {
    console.log('gamesData=', data?.data?.data);
    setGameOverviewGames(data?.data?.data);
    setPgn(data?.data?.data?.pgn);
    setPlayerColor(data?.data?.data?.color);
    SetPlayerColor(data?.data?.data?.color);
    setUsername(data?.data?.data?.username);
    setGames(data?.data?.data);
    setUserId(data?.data?.data?.map(i => i.userid)[0]);
  };

  function parsePGNToGameMoves(pgn, playerColor = 'White', username = 'Me') {
    const chess = new Chess(); // Initialize the chess board

    // Extract time control (if present)
    const timeControlRegex = /\[TimeControl\s+"(\d+)\s+\+\s+(\d+)"\]/;
    const timeControlMatch = pgn.match(timeControlRegex);
    const initialTime = timeControlMatch
      ? parseInt(timeControlMatch[1]) * 60
      : 0; // Convert minutes to seconds
    const increment = timeControlMatch ? parseInt(timeControlMatch[2]) : 0;

    // Extract initial FEN (if present)
    const fenTagRegex = /\[FEN\s+"([^"]+)"\]/;
    const fenMatch = pgn.match(fenTagRegex);
    const initialFen = fenMatch
      ? fenMatch[1]
      : 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
    chess.load(initialFen); // Set the initial position on the board

    function mask(str: string): string {
      return str.replace(/\\/g, '\\');
    }

    let newlineChar = '\r?\n';

    const headerRegex = new RegExp(
      '^(\\[((?:' +
        mask(newlineChar) +
        ')|.)*\\])' +
        '((?:\\s*' +
        mask(newlineChar) +
        '){2}|(?:\\s*' +
        mask(newlineChar) +
        ')*$)',
    );

    // If no header given, begin with moves.
    const headerRegexResults = headerRegex.exec(pgn);
    const headerString = headerRegexResults
      ? headerRegexResults.length >= 2
        ? headerRegexResults[1]
        : ''
      : '';

    let pgnString = pgn.replace(headerString, '');

    console.log('pgn string is :', pgnString);

    // Split the PGN into lines and moves
    const moveStartRegex =
      /\d+\.(?:\s*[^\s{]+(?:\s*\{\s*\[%clk\s+\d+:\d+:\d+\]\s*\})?)?(?:\.\.\.\s*[^\s{]+(?:\s*\{\s*\[%clk\s+\d+:\d+:\d+\]\s*\})?)?/;

    // Find the first match for the moves section
    const moveStartMatch = pgnString.match(moveStartRegex);

    if (!moveStartMatch) {
      throw new Error('No moves found in PGN.');
    }

    // Get the start index of the moves section
    const moveSectionStart = pgnString.indexOf(moveStartMatch[0]);
    if (moveSectionStart === -1) throw new Error('No moves found in PGN.');
    const moveSection = pgnString.slice(moveSectionStart);

    // Regex to match each move, time left, and move number
    const moveRegex =
      /(\d+)\.+\s*([kqrnpKQRBNPabcdefghO][^\s{]+)(?:\s*\{\s*\[%clk\s+(\d+):(\d+):(\d+(?:\.\d+)?)\]\s*\})?\s*(\d+?\.\.\.\s*([kqrnpKQRBNPabcdefghO][^\s{]+)(?:\s*\{\s*\[%clk\s+(\d+):(\d+):(\d+(?:\.\d+)?)\]\s*\}))?/g;

    let match;
    const gameMoves = [];
    let currentColor =
      playerColor.toLowerCase() === 'white' ? 'white' : 'black';

    while ((match = moveRegex.exec(moveSection)) !== null) {
      console.log('match :', match);
      const moveNumber = parseInt(match[1]);

      // Extract White's move
      if (match[2]) {
        chess.move(match[2]); // Play the move on the board
        const whiteMove = {
          move_index: moveNumber,
          move: match[2],
          color: 'white',
          player: currentColor === 'white' ? username : 'opponent',
          time_left_on_clock:
            match[3] && match[4] && match[5]
              ? parseInt(match[3]) * 3600 +
                parseInt(match[4]) * 60 +
                parseInt(match[5])
              : initialTime,
          fen: chess.fen(), // FEN before playing the move
        };

        gameMoves.push(whiteMove);
      }

      // Extract Black's move
      if (match[6]) {
        chess.move(match[7]); // Play the move on the board
        const blackMove = {
          move_index: moveNumber,
          move: match[7],
          color: 'black',
          player: currentColor === 'black' ? username : 'opponent',
          time_left_on_clock:
            match[8] && match[9] && match[10]
              ? parseInt(match[8]) * 3600 +
                parseInt(match[9]) * 60 +
                parseInt(match[10])
              : initialTime,
          fen: chess.fen(), // FEN before playing the move
        };

        gameMoves.push(blackMove);
      }
    }

    return gameMoves;
  }

  useEffect(() => {
    if (pgn && playerColor && username) {
      const moves = parsePGNToGameMoves(pgn, playerColor);
      setParsedMoves(moves);
    }
  }, [games, pgn, playerColor, username]);
  const handleOnFailedGames = error => {
    console.log('error while fetching games', error);
  };

  const {mutate: fetchGames, gamesData} = useGetUserGamesApi(
    handleOnSuccessGames,
    handleOnFailedGames,
  );

  useEffect(() => {
    setSelectedTab(routes?.[index]?.key?.replace('_', ' '));
  }, [index]);

  useEffect(() => {
    if (games.length > 0) {
      let filteredGame = games.find(
        game => game.uuid === route?.params?.game_id,
      );
      setIsFav(filteredGame?.is_favourite);
    }
  }, [games]);

  useEffect(() => {
    if (viewgame) {
      fetchGames({page: route?.params?.page, game_id: gameid});
    } else {
      fetchGames({page: route?.params?.page});
    }
  }, []);

  useEffect(() => {
    if (tabOpen !== selectedTab) {
      setSelectedTab(tabOpen?.toUpperCase());
    }
  }, [tabOpen]);

  const handleChessMove = (
    eachMove,
    isPuzzle = false,
    puzzleId = '',
    lastFen: any = '',
    nextPuzzleFunc = () => {},
  ) => {
    console.log(
      eachMove,
      isPuzzle,
      puzzleId,
      lastFen,
      'eachMove, isPuzzle, puzzleId, lastFen',
    );
    handleFen(eachMove, isPuzzle, puzzleId, lastFen, nextPuzzleFunc);
    setActiveMove(eachMove);
    setIsFenFromOutside(false);
    handleSelectMoveInReview?.(eachMove);
    setShowIncorrectHintModal(false);
  };

  const handleGameScoreOnSuccess = (data: any) => {
    console.log('handleGameScoreOnSuccess: success = ', data);
    const reviewTextSummary = convertGameScoreToTextSummary(data);
    setReviewSummary(reviewTextSummary);
  };
  const handleGameScoreOnError = (data: any) => {
    console.log('handleGameScoreOnSuccess: success = ', data);
  };

  const handleAnnotationSavedOnSuccess = data => {
    if (data?.success) {
      handleAnnotationClose?.();
    }
  };

  const handleAnnotationSavedOnError = data => {};

  const {
    mutate: saveGameMoveAnnotation,
    data: gameMoveAnnotationData,
    error: gameMoveAnnotationError,
  } = useSaveGameMoveAnnotation(
    handleAnnotationSavedOnSuccess,
    handleAnnotationSavedOnError,
  );

  const {
    mutate: getGameAnalysisScore,
    data: scoreData,
    error: scoreError,
  } = useGameAnalysisScoreApi(handleGameScoreOnSuccess, handleGameScoreOnError);

  const handleSaveAnnotation = async () => {
    if (openNotes?.gameMoveId && noteVal?.[openNotes?.gameMoveId]?.trim()) {
      await saveGameMoveAnnotation({
        gameMoveId: openNotes?.gameMoveId,
        annotation: noteVal?.[openNotes?.gameMoveId]?.trim(),
      });
      handleAnnotationClose();
    }
  };
  useEffect(() => {
    if (gameMoves) {
      setSummaryDetails(generateSummary(gameMoves));
    }
  }, [gameMoves]);

  const MOVES_TO_SCROLL_AFTER = 8;

  const [reviewSummary, setReviewSummary] = useState<any>();

  useEffect(() => {
    handleSelectedTabChecks?.(selectedTab);
    if (selectedTab !== 'ANALYSIS') {
      // This is not the analysis tab, so we are good without having to call the server.
    } else {
      console.log('Params = ', params);
      getGameAnalysisScore(params);
    }
  }, [selectedTab]);

  const handleMove = throttle((moveType: 'next' | 'prev') => {
    // setSelectedTab('GAME REVIEW');

    switch (moveType) {
      case 'next':
        let index = 0;
        try {
          index = manualMoves?.[activeMove?.fen].findIndex(
            element => element.newFen === selectedManualMove?.fen,
          );
        } catch (e) {
          index = 0;
        }

        if (
          manualMoves?.[activeMove?.fen]?.length > 0 &&
          selectedManualMove?.fen &&
          manualMoves?.[activeMove?.fen]?.length > index + 1
        ) {
          //  index = manualMoves?.[activeMove?.fen].findIndex(
          //   element => element.newFen === selectedManualMove?.fen,
          // );
          const tempNewMove =
            manualMoves?.[activeMove?.fen]?.length > index + 1
              ? manualMoves?.[activeMove?.fen]?.[index + 1]
              : manualMoves?.[activeMove?.fen]?.[index];
          handleFen(
            {fen: tempNewMove?.newFen},
            false,
            '',
            {
              fen: tempNewMove?.lastFen,
            },
            () => {},
            true,
          );
        } else if (activeMove?.move_index <= Math.ceil(gameMoves?.length / 2)) {
          if (activeMove?.color === 'white') {
            if (!gameMoves?.[activeMove?.move_index * 2 - 1]) return;
            handleChessMove(
              gameMoves?.[activeMove?.move_index * 2 - 1],
              false,
              '',
              gameMoves?.[activeMove?.move_index * 2 - 2],
            );
          } else {
            if (!gameMoves?.[activeMove?.move_index * 2]) return;
            handleChessMove(
              gameMoves?.[activeMove?.move_index * 2],
              false,
              '',
              gameMoves?.[activeMove?.move_index * 2 - 1],
            );
          }
        }
        //  else {
        //   handleChessMove(gameMoves?.[0]);
        // }
        break;
      case 'prev':
        index = 0;
        try {
          index = manualMoves?.[activeMove?.fen].findIndex(
            element => element.newFen === selectedManualMove?.fen,
          );
        } catch (e) {
          index = 0;
        }
        if (
          manualMoves?.[activeMove?.fen]?.length > 0 &&
          selectedManualMove?.fen &&
          index - 1 >= 0
        ) {
          // const index = manualMoves?.[activeMove?.fen].findIndex(
          //   element => element.newFen === selectedManualMove?.fen,
          // );
          const tempNewMove =
            index - 1 >= 0
              ? manualMoves?.[activeMove?.fen]?.[index - 1]
              : manualMoves?.[activeMove?.fen]?.[index];
          handleFen(
            {fen: tempNewMove?.newFen},
            false,
            '',
            {
              fen: tempNewMove?.lastFen,
            },
            () => {},
            true,
          );
        } else if (activeMove?.move_index > 0) {
          if (activeMove?.color === 'white') {
            handleChessMove(
              gameMoves?.[(activeMove?.move_index - 1) * 2 - 1],
              false,
              '',
              gameMoves?.[(activeMove?.move_index - 1) * 2 - 2],
            );
          } else {
            handleChessMove(
              gameMoves?.[(activeMove?.move_index - 1) * 2],
              false,
              '',
              gameMoves?.[(activeMove?.move_index - 1) * 2 - 1],
            );
          }
        }
        break;

      default:
        break;
    }

    if (activeMove && activeMove.move_index) {
      movesScrollView?.scrollTo({
        x: 0,
        y: moveCoords[activeMove.move_index - MOVES_TO_SCROLL_AFTER],
      });
    }
  }, 300);
  const handleMoveSelection = (selectedType: string) => {
    setSelectedMoveType(selectedType);
    setSelectedMoves(gameMoves?.filter(i => evaluateMove(i) === selectedType));
  };

  const handleTabChange = (tempTab: tabsType) => {
    setSelectedTab(tempTab);
  };
  const handleRetry = move => {
    setSelectedTab('POSITIONS');
    setPreselectPuzzle(move);
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.keyCode === 37) {
        handleMove('prev');
      } else if (event.keyCode === 39) {
        handleMove('next');
      }
    };

    // Attach the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleMove]);

  const [moveCoords, setMoveCoords] = useState([]);
  const [movesScrollView, setMovesScrollView] = useState<ScrollView>();
  const [rightPaneHeight, setRightPaneHeight] = useState<number>();
  const [noteVal, setNoteVal] = useState({});
  const [isNotesEditable, setIsNotesEditable] = useState(false);
  const [openNotes, setOpenNotes] = useState({
    data: {},
    gameMoveId: '',
    flag: false,
  });
  const getAnalysisTabv2 = () => {
    return (
      <>
        <Box type="center">
          <Box
            flexDirection="column"
            justifyContent="space-between"
            style={{width: '95%'}}>
            <Box style={customStyles?.reviewSummary}>
              <AccordionSection
                keepOpen
                // key={filterName}
                title={'Focus'}
                // selectedCount={filtersSelected?.[filterName]?.length}
                renderContent={() => <CCText>{reviewSummary?.focus}</CCText>}
              />
              <AccordionSection
                keepOpen
                // key={filterName}
                title={'Thinking Time'}
                renderContent={() => (
                  <CCText style={customStyles.reviewSummaryDescription}>
                    {reviewSummary?.think_time}
                  </CCText>
                )}
              />
              <AccordionSection
                keepOpen
                // key={filterName}
                title={'Review Status'}
                renderContent={() => (
                  <CCText style={customStyles.reviewSummaryDescription}>
                    {reviewSummary?.review_status}
                  </CCText>
                )}
              />
              <AccordionSection
                keepOpen
                // key={filterName}
                title={'Accuracy'}
                renderContent={() => (
                  <CCText style={customStyles.reviewSummaryDescription}>
                    {reviewSummary?.accuracy}
                  </CCText>
                )}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const convertToStandardEval = (amIWhite: boolean, score: number) => {
    if (isNaN(score)) return '';
    if (amIWhite) {
      if (score > 0) {
        if (score % 100 === 0) {
          return 'M' + score / 100;
        } else return '+' + score;
      } else if (score === 0) {
        return 0;
      } else {
        if (score % 100 === 0) {
          return '-M' + score / 100;
        } else return '' + score;
      }
    } else {
      if (score > 0) {
        if (score % 100 === 0) {
          return '-M' + score / 100;
        } else return '-' + score;
      } else if (score === 0) {
        return 0;
      } else {
        if (score % 100 === 0) {
          return 'M' + (-1 * score) / 100;
        } else return '+' + -1 * score;
      }
    }
  };

  const getAnalysisTabv1 = () => {
    return (
      <>
        <Box type="center">
          <Box
            flexDirection="row"
            justifyContent="space-between"
            style={{width: '60%'}}>
            <Box style={customStyles?.playerScore}>
              {summaryDetails &&
                Object.keys(
                  summaryDetails[Object.keys(summaryDetails)?.[0]],
                )?.map((i, index) => (
                  <Box
                    key={index}
                    marginBottom={10}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <CCText style={customStyles?.playerScoreEach}>
                      {summaryDetails[Object.keys(summaryDetails)?.[0]]?.[i]}
                    </CCText>
                    <Box
                      style={
                        {
                          //   width: '38%',
                        }
                      }
                      justifyContent="flex-start"
                      flexDirection="row"
                      alignItems="center">
                      <CCText
                        onPress={() => handleMoveSelection(i)}
                        style={styles?.name}>
                        {moveIconsAndColors[i]?.icon}
                      </CCText>
                      <Spacer spacing={3} horizontal />
                      <CCText
                        onPress={() => handleMoveSelection(i)}
                        textAlign="left"
                        color={moveIconsAndColors[i]?.color}
                        style={styles?.name}>
                        {i}
                      </CCText>
                    </Box>
                    <CCText style={customStyles?.playerScoreEach}>
                      {summaryDetails[Object.keys(summaryDetails)?.[1]]?.[i]}
                    </CCText>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
        <CCText
          style={[
            customStyles.rightReviewLeftHeader,
            {
              backgroundColor: moveIconsAndColors[selectedMoveType]?.color,
            },
          ]}>
          {`${selectedMoveType}`}
        </CCText>
        <Spacer spacing={3} />
        <Box type="center">
          <Box
            flexDirection="row"
            justifyContent="space-between"
            style={{width: '60%'}}>
            <Box style={customStyles?.playerScore}>
              {selectedMoves?.map((move, index) => (
                <Box
                  marginBottom={10}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  onPress={() =>
                    handleChessMove(move, false, '', selectedMoves?.[index - 1])
                  }
                  style={
                    activeMove?.fen === move?.fen && customStyles?.selectedMove
                  }>
                  <CCText
                    style={customStyles?.playerScoreEach}
                    onPress={() =>
                      handleChessMove(
                        move,
                        false,
                        '',
                        selectedMoves?.[index - 1],
                      )
                    }>
                    {index % 2 !== 0 ? move.move : '--'}
                  </CCText>
                  <Box
                    justifyContent="flex-start"
                    flexDirection="row"
                    alignItems="center">
                    <CCText textAlign="left" style={styles?.name}>
                      {' '}
                    </CCText>
                  </Box>
                  <CCText
                    style={customStyles?.playerScoreEach}
                    onPress={() =>
                      handleChessMove(
                        move,
                        false,
                        '',
                        selectedMoves?.[index - 1],
                      )
                    }>
                    {' '}
                    {index % 2 === 0 ? move.move : '--'}
                  </CCText>
                </Box>
              ))}

              {/* <MoveList moves={selectedMoves} /> */}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const handleAnnotationClose = () => {
    setIsNotesEditable(false);
    setOpenNotes({
      data: {},
      gameMoveId: '',
      flag: false,
    });
  };

  const handleAddAnnotation = e => {
    const currentTime = new Date().getTime();
    const delay = 300; // Define your double-click delay here (in milliseconds)

    console.log(
      'inside handleAddAnnotation',
      currentTime,
      lastClickTime,
      delay,
    );

    if (currentTime - lastClickTime > delay) {
      setLastClickTime(currentTime);
      console.log('Single click event occurred!');
      return;
    }

    setLastClickTime(currentTime);

    setOpenNotes({
      data: e?.annotation,
      gameMoveId: e?.id,
      flag: true,
    });
    if (!noteVal?.[e?.id] && !e?.annotation) {
      setIsNotesEditable(true);
    } else {
      setIsNotesEditable(false);
    }
    setNoteVal(prev => ({
      ...prev,
      [e?.id]: prev?.[e?.id] || e?.annotation,
    }));
  };

  // const renderScene = (rendererSceneProps: any) => {
  //   const { route } = rendererSceneProps;
  //   switch (route.key) {
  //     case "upcomingTournaments":
  //       return (
  //         <Box marginHorizontal={"5%"}>
  //           <UserTournaments tournamentType="upcoming" />
  //         </Box>
  //       );
  //     case "previousTournaments":
  //       return (
  //         <Box marginHorizontal={"5%"}>
  //           <UserTournaments tournamentType="previous" />
  //         </Box>
  //       );
  //     case "playerDetails":
  //       return (
  //         <Box marginHorizontal={"5%"}>
  //           <PlayerDetailsComponent />
  //         </Box>
  //       );
  //     case "paymentDetails":
  //       return (
  //         <Box marginHorizontal={"5%"}>
  //           <PaymentDetails />
  //         </Box>
  //       );
  //   }
  // };

  // const allTabs = [
  //   {
  //     key: 0,
  //     label: 'Overview',
  //     component: () => <GameOverview gameData={gameOverviewDetails} />,
  //   },
  //   // {
  //   //   key: 1,
  //   //   label: 'Analysis',
  //   //   component: getAnalysisTabv2,
  //   // },
  //   {
  //     key: 1,
  //     label: 'Game review',
  //     component: fen2 => (
  //       <>
  //         <Box type="center">
  //           {manualMoves?.[activeMove?.fen]?.length > 0 && (
  //             <>
  //               <CCText>Move logs</CCText>

  //               <Box
  //                 type="row-flex-start"
  //                 // style={{width: '70%'}}
  //                 style={styles.manualMovesContainer}>
  //                 {manualMoves?.[activeMove?.fen]?.map(i => (
  //                   <>
  //                     <CCText
  //                       style={
  //                         selectedManualMove?.fen === i?.newFen
  //                           ? styles?.selectedManualMove
  //                           : {}
  //                       }
  //                       onPress={() => {
  //                         handleFen(
  //                           {fen: i?.newFen},
  //                           false,
  //                           '',
  //                           {
  //                             fen: i?.lastFen,
  //                           },
  //                           () => {},
  //                           true,
  //                         );

  //                         // handleChessMove({fen: i?.newFen}, false, '', {
  //                         //   lastFen: i?.lastFen,
  //                         // });
  //                       }}>
  //                       {i?.san}
  //                     </CCText>
  //                     <CCText>, </CCText>
  //                   </>
  //                 ))}
  //                 <Spacer spacing={10} />
  //               </Box>
  //             </>
  //           )}
  //           {(noteVal?.[activeMove?.id] || activeMove?.annotation) && (
  //             <>
  //               <CCText>Notes</CCText>
  //               <Spacer spacing={10} />
  //               <Box
  //                 type="row-flex-start"
  //                 // style={{width: '70%'}}
  //                 style={styles.manualMovesContainer}>
  //                 <CCText>
  //                   {noteVal?.[activeMove?.id] || activeMove?.annotation}
  //                 </CCText>
  //               </Box>
  //               <Spacer spacing={10} />
  //             </>
  //           )}
  //           <View
  //             flexDirection="row"
  //             justifyContent="space-between"
  //             style={{width: '90%'}}
  //             onLayout={e => {
  //               setRightPaneHeight(e.nativeEvent.layout.height);
  //             }}>
  //             <MovesList
  //               fen2={fen2}
  //               gameMoves={gameMoves}
  //               activeMove={activeMove}
  //               handleChessMove={handleChessMove}
  //               handleAddAnnotation={handleAddAnnotation}
  //               convertToStandardEval={convertToStandardEval}
  //               evaluateMove={evaluateMove}
  //               customStyles={customStyles}
  //               moveIconsAndColors={moveIconsAndColors}
  //               moveCoords={moveCoords}
  //               useFeather={true}
  //             />
  //           </View>
  //         </Box>
  //       </>
  //     ),
  //   },
  //   {
  //     key: 2,
  //     label: 'Puzzles',
  //     component: () => (
  //       <ScrollView style={{flex: 1}}>
  //         <Puzzels
  //           handleFen={handleChessMove}
  //           gameKey={params}
  //           preselectPuzzle={preselectPuzzle}
  //           refresh={refreshPuzzles}
  //           handleNextPuzzlReset={handleNextPuzzlReset}
  //           currentPlayingFen={currentPlayingFen}
  //         />
  //       </ScrollView>
  //     ),
  //   },
  // ];

  const renderScene = (rendererSceneProps: any) => {
    const {route: tempRoute} = rendererSceneProps;
    let returnJSX = <></>;
    switch (tempRoute.key) {
      case 'OVERVIEW':
        returnJSX = (
          <ScrollView
            style={{height: '75vh'}}
            ref={ref => setMovesScrollView(ref)}
            showsVerticalScrollIndicator={false}>
            <GameOverview
              gameData={!viewgame ? gameOverviewDetails : gameOverviewGames}
            />
          </ScrollView>
        );
        break;
      case 'ANALYSIS':
        returnJSX = getAnalysisTabv2();
        break;
      case 'GAME_REVIEW':
        if (!Array.isArray(gameMoves) && gameMoves !== 'undefined') {
          returnJSX = <CCLoader loading isComponentLoader={true} />;
        } else {
          returnJSX = (
            <>
              <Box type="center">
                {manualMoves?.[activeMove?.fen]?.length > 0 && (
                  <>
                    <CCText>Move logs</CCText>

                    <Box
                      type="row-flex-start"
                      // style={{width: '70%'}}
                      style={styles.manualMovesContainer}>
                      {manualMoves?.[activeMove?.fen]?.map(i => (
                        <>
                          <CCText
                            style={
                              selectedManualMove?.fen === i?.newFen
                                ? styles?.selectedManualMove
                                : {}
                            }
                            onPress={() => {
                              handleFen(
                                {fen: i?.newFen},
                                false,
                                '',
                                {
                                  fen: i?.lastFen,
                                },
                                () => {},
                                true,
                              );

                              // handleChessMove({fen: i?.newFen}, false, '', {
                              //   lastFen: i?.lastFen,
                              // });
                            }}>
                            {i?.san}
                          </CCText>
                          <CCText>, </CCText>
                        </>
                      ))}
                      <Spacer spacing={10} />
                    </Box>
                  </>
                )}

                <View
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{width: '90%', height: '80vh'}}
                  onLayout={e => {
                    setRightPaneHeight(e.nativeEvent.layout.height);
                  }}>
                  <MovesList
                    isFenFromOutside={isFenFromOutside}
                    setIsFenFromOutside={setIsFenFromOutside}
                    fen2={fen2}
                    gameMoves={viewgame ? parsedMoves : gameMoves}
                    activeMove={activeMove}
                    handleChessMove={handleChessMove}
                    handleAddAnnotation={handleAddAnnotation}
                    convertToStandardEval={convertToStandardEval}
                    evaluateMove={evaluateMove}
                    customStyles={customStyles}
                    moveIconsAndColors={moveIconsAndColors}
                    moveCoords={moveCoords}
                    useFeather={true}
                    disableArrowMove={false}
                  />
                </View>
              </Box>
            </>
          );
        }

        break;
      case 'POSITIONS':
        returnJSX = (
          // <></>
          <ScrollView
            style={{height: '75vh'}}
            ref={ref => setMovesScrollView(ref)}
            showsVerticalScrollIndicator={false}>
            <Puzzels
              handleFen={handleChessMove}
              gameKey={params}
              preselectPuzzle={preselectPuzzle}
              refresh={refreshPuzzles}
              handleNextPuzzlReset={handleNextPuzzlReset}
              currentPlayingFen={currentPlayingFen}
            />
          </ScrollView>
        );

        break;
      default:
        break;
    }
    return returnJSX;
    // return (
    //   <ScrollView
    //     style={{height: '75vh'}}
    //     ref={ref => setMovesScrollView(ref)}
    //     showsVerticalScrollIndicator={false}>
    //     {returnJSX}
    //   </ScrollView>
    // );
  };
  //   [
  //     selectedTab,
  //     gameOverviewDetails,
  //     getAnalysisTabv2,
  //     gameMoves,
  //     handleChessMove,
  //     params,
  //     preselectPuzzle,
  //     refreshPuzzles,
  //     handleNextPuzzlReset,
  //     activeMove?.fen,
  //     moveCoords,
  //   ],
  // );

  const {solved, unsolved} = puzzleSummary;

  let progress = 0;
  let progressText = 'Game review in progress ..';
  if (gameReviewedByUser) {
    progressText = 'Game already reviewed by you!';
    progress = 1;
  } else {
    progress = (solved * 1) / (solved + unsolved);
    if (solved + unsolved == 0 && analysisInProgress) {
      progressText = 'Please wait while the game is being analyzed';
    } else {
      if (solved > 0 && unsolved > 0) {
        progressText =
          'Game Review Progress .. Solved ' +
          solved +
          ' of ' +
          (solved + unsolved) +
          ' positions';
      } else if (unsolved == 0) {
        progressText =
          'Game Review Finished .. Solved all ' + solved + ' positions';
      }
    }
  }

  return (
    <Box style={{backgroundColor: 'white', height: '100%'}}>
      {(fullHint || fullHint2) && showIncorrectHintModal && (
        <HintForIncorrectAttempt
          result={fullHint}
          result2={fullHint2}
          position={'absolute'}
          disabled={!isStaff}
          setShowIncorrectHintModal={setShowIncorrectHintModal}
          handleSubmit={handleSubmit}
          showChange={showChange}
          setShowChange={setShowChange}
          goodFeedback={goodFeedback}
          badFeedback={badFeedback}
          setGoodFeedback={setGoodFeedback}
          setBadFeedback={setBadFeedback}
          width={'100%'}
          padding={undefined}
          title={undefined}
          borderColor={CCColors.White}
          infoiconcolor={infocolor}
        />
      )}
      <ConditionalRender
        condition={isMobileOrTab}
        childrenA={
          <TabView
            // lazy
            //   style={styles.contentContainerStyle}
            // @ts-ignore
            navigationState={{index: index, routes}}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={setIndex}
            initialLayout={{width: WINDOW_WIDTH / 3}}
            // style={{height: '75vh'}}
          />
        }
        childrenB={
          <TabView
            lazy
            //   style={styles.contentContainerStyle}
            // @ts-ignore
            navigationState={{index: index, routes}}
            renderScene={renderScene}
            // style={{overflow: 'hidden'}}
            renderTabBar={renderUnderlineTabBar}
            onIndexChange={setIndex}
            // initialLayout={{width: WINDOW_WIDTH / 3}}
            // style={{height: '75vh'}}
          />
        }
      />

      <SlideModal
        type="center"
        modalHeaderTitle={<CCText fontSize={FontSizes[14]}>Notes</CCText>}
        onClose={handleAnnotationClose}
        visible={openNotes?.flag}>
        <Box margin={24}>
          <Spacer spacing={10} />
          {isNotesEditable ? (
            <>
              <CCTextInput
                fontSize={FontSizes[14]}
                style={styles?.NotesBox}
                value={noteVal?.[openNotes?.gameMoveId]}
                autoFocus
                onChangeText={text => {
                  setNoteVal(prev => ({
                    ...prev,
                    [openNotes?.gameMoveId]: text,
                  }));
                }}
              />
              <Spacer spacing={10} />
              <CCButton type="fill" onPress={handleSaveAnnotation}>
                Save
              </CCButton>
            </>
          ) : (
            <>
              <CCText>{noteVal?.[openNotes?.gameMoveId]}</CCText>
              <Spacer spacing={10} />
              <CCButton type="fill" onPress={() => setIsNotesEditable(true)}>
                Edit
              </CCButton>
            </>
          )}
        </Box>
      </SlideModal>
    </Box>
  );
}
const Memorize = React.memo(AnalysisRightComponent);
export default Memorize;
