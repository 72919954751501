import Box from '@/Atoms/Box';
import React, {useEffect, useMemo, useState} from 'react';
import {styles} from '../../../Puzzles/styles';
import CCText from '@/Atoms/CCText';
import squares from '../../../../Assets/Images/Svgs/squares.svg';
import points from '../../../../Assets/Images/Svgs/points.svg';
import logoIcon from '../../../../Assets/Images/Svgs/logoicon.svg';
import chevronDoubleDown from '../../../../Assets/Images/Svgs/chevronDoubleDown.svg';
import chevronDoubleUp from '../../../../Assets/Images/Svgs/chevronDoubleUp.svg';
import CCSelect from '@/Atoms/CCSelect';
import {Image, TouchableOpacity, ScrollView} from 'react-native';
import CCColors from '@/Utils/CCColors';
import challengeIcon from '../../../../Assets/Images/Svgs/challengehover.svg';
import {FontSizes, vs, WINDOW_WIDTH} from '@/Utils/Dimensions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import Spacer from '@/Atoms/Spacer';
import goldMedal from '../../../../Assets/Images/Svgs/goldMedal.svg';
import silverMedal from '../../../../Assets/Images/Svgs/silverMedal.svg';
import bronzeMedal from '../../../../Assets/Images/Svgs/bronzeMedal.svg';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCLoader from '@/Atoms/CCLoader/CCLoader';
import {LinearGradient} from 'expo-linear-gradient';
import {TabBar, TabView} from 'react-native-tab-view';
import useGetStandingsApi from '@/Hooks/LeaguesApi/getStandingsApi';
import useGetLeaguesApi from '@/Hooks/LeaguesApi/getLeaguesApi';
import LeagueList from './LeagueList';
import StandingsRow from './StandingsRow';
import CreateLeagueModal from './CreateLeagueModal';
import useGetLeagueRulesApi from '@/Hooks/LeaguesApi/getLeagueRules';
import dayjs from 'dayjs';

const CCLeague: React.FC<any> = () => {
  const {isMobileOrTab} = useMediaQuery();

  const [hoveredRow, setHoveredRow] = useState(null);
  const [standings, setStandings] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [leagueRules, setLeagueRules] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const handleOnSuccessStandingsDetails = data => {
    setStandings(data);
  };

  const handleOnSuccessLeaguesDetails = data => {
    setLeagues(data);
    setSelectedLeague(data[0]);
    getStandingsDetails({
      leagueId: data[0].id,
      start_date: getDateByKey(''),
      end_date: dayjs().format('YYYY-MM-DD'),
    });
  };

  const handleGetRules = data => {
    setLeagueRules(data);
  };

  const {mutate: getStandingsDetails, isLoading: standingsLoading} =
    useGetStandingsApi(handleOnSuccessStandingsDetails);
  const {mutate: getLeaguesDetails, isLoading: leaguesLoading} =
    useGetLeaguesApi(handleOnSuccessLeaguesDetails);
  const {mutate: getLeagueRules} = useGetLeagueRulesApi(handleGetRules);

  const getDateByKey = key => {
    switch (key) {
      case 'year':
        return dayjs().subtract(1, 'y').format('YYYY-MM-DD');
      case 'week':
        return dayjs().subtract(1, 'w').format('YYYY-MM-DD');
      case 'month':
        return dayjs().subtract(1, 'M').format('YYYY-MM-DD');
      default:
        return dayjs().subtract(1, 'd').format('YYYY-MM-DD');
    }
  };
  useEffect(() => {
    getLeaguesDetails();
    getLeagueRules();
  }, []);

  const [index, setIndex] = useState(0);

  const routes = useMemo(
    () =>
      [
        {key: 'Standings', title: `Standings(${standings.length})`},
        {key: 'Rules', title: `Rules(${leagueRules.length})`},
      ].filter(i => i),
    [standings, leagueRules],
  );

  const data = [
    'Challenge 5 people from league',
    'Challenge 10 people from league',
    'Challenge 3 people from league',
    'Complete 5 tasks to earn points',
    'Invite 10 people to join the league',
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const cardWidth = 200; // Example width in px
  const gap = 16; // Gap between cards
  const containerWidth = cardWidth + gap;
  const [duration, setDuration] = useState(['today']);
  const handleDurationChange = text => {
    setDuration(text);
    if (selectedLeague?.id)
      getStandingsDetails({
        leagueId: selectedLeague.id,
        start_date: getDateByKey(text[0]),
        end_date: dayjs().format('YYYY-MM-DD'),
      });
  };
  // Function to handle chevron left click
  const handleLeftChevronClick = () => {
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : data.length - 1,
    );
  };

  // Function to handle chevron right click
  const handleRightChevronClick = () => {
    setCurrentIndex(prevIndex =>
      prevIndex < data.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const handleSelectedLeague = league => {
    setSelectedLeague(league);
    // Fetch standings details only if the selected league changes
    if (selectedLeague?.id !== league.id) {
      getStandingsDetails({
        leagueId: league.id,
        start_date: getDateByKey(duration[0]),
        end_date: dayjs().format('YYYY-MM-DD'),
      });
    }
  };

  const [openModal, setOpenModal] = useState(false);
  // const [challenge,setChallenge] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  return (
    <Box key="league" style={{flex: 1}}>
      <ScrollView style={{flex: 1}} contentContainerStyle={{flexGrow: 1}}>
        <Box style={{width: '100%'}}>
          {/* <CcBanner /> */}
          <Image
            source="https://cc-leagues.s3.ap-south-1.amazonaws.com/banner.jpg"
            style={{
              width: '100%',
              height: '25vw',
              marginTop: 10,
              resizeMode: 'cover',
              borderRadius: 15,
            }}
          />
        </Box>
        <Box
          style={{
            flex: 1,
            flexDirection: isMobileOrTab ? 'column' : 'row',
            width: '100%',
            gap: 25,
            marginTop: 10,
          }}>
          <Box
            // flex={isMobileOrTab ? 0.2 : 0.5}
            flex={isMobileOrTab ? 'none' : 0.5}
            flexDirection={isMobileOrTab ? 'row' : 'column'}
            gap={8}>
            <LeagueList
              leagues={leagues}
              isMobileOrTab={isMobileOrTab}
              selectedLeague={selectedLeague}
              handleSelectedLeague={handleSelectedLeague}
              logoIcon={logoIcon} // Update path
              squares={squares} // Update path
            />
            {/* {!isMobileOrTab && (
              <TouchableOpacity onPress={handleOpen}>
                <Box
                  style={{
                    backgroundColor: CCColors.White,
                    borderRadius: 30,
                    padding: 15,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 8,
                    shadowColor: '#4D3F37', // Shadow color
                    shadowOffset: {
                      width: 0, // Horizontal shadow offset
                      height: 4, // Vertical shadow offset
                    },
                    shadowOpacity: 0.15, // Shadow transparency
                    shadowRadius: 8, // How blurry the shadow is
                    elevation: 4,
                  }}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{
                      width: 14,
                      height: 14,
                      color: CCColors.BlueAqua,
                    }}
                  />
                  <CCText color={CCColors.BlueAqua} fontSize={FontSizes[12]}>
                    Create My League
                  </CCText>
                </Box>
              </TouchableOpacity>
            )}
            <CreateLeagueModal
              openModal={openModal}
              handleClose={handleClose}
            /> */}
          </Box>
          <Box flex={2} flexDirection="column" style={{}}>
            <Box
              style={{
                backgroundColor: CCColors.White,
                borderRadius: 10,
                padding: 10,
                // width: 818,
                // height: 167,
              }}>
              <Box flexDirection="column">
                <LinearGradient
                  colors={['#FFF4D3', '#FAF6EB', '#FBE4D4']} // Gradient colors
                  start={{x: 0, y: 0}} // Start point (top-left)
                  end={{x: 1, y: 1}} // End point (bottom-right)
                  style={{
                    borderRadius: 10,
                    padding: 20,
                    paddingBottom: 10,
                  }}>
                  {selectedLeague && (
                    <Box
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap={20}>
                      <Box
                        type="center"
                        justifyContent="center"
                        style={{
                          width: 50,
                          height: 50,
                          backgroundColor: CCColors.White,
                          borderRadius: '50%',
                          shadowColor: 'rgb(77, 63, 55)', // Shadow color
                          shadowOffset: {
                            width: 0, // Horizontal shadow offset
                            height: 3.43, // Vertical shadow offset
                          },
                          shadowOpacity: 0.15, // Shadow transparency
                          shadowRadius: 6.86, // How blurry the shadow is
                          elevation: 4,
                        }}>
                        <Image
                          style={{
                            height: 25,
                            width: 25,
                          }}
                          source={selectedLeague?.image}
                        />
                      </Box>
                      <Box flexDirection="column" justifyContent="center">
                        <CCText fontSize={FontSizes[20]} fontWeight={500}>
                          {selectedLeague ? selectedLeague?.name : leagueName}
                        </CCText>
                        <CCText fontSize={FontSizes[12]} color=" #4D3F37CC">
                          League {selectedLeague?.key}/{leagues?.length}
                        </CCText>
                      </Box>
                    </Box>
                  )}
                  <Spacer spacing={vs(10)} />

                  <Box
                    style={{
                      borderTopColor: '#26232233',
                      borderTopWidth: 1,
                      paddingVertical: 10,
                    }}
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap">
                    <Box
                      type="center"
                      flexDirection="row"
                      justifyContent="center"
                      gap={8}>
                      <Box
                        type="center"
                        style={{
                          width: 30,
                          height: 30,
                          backgroundColor: CCColors.White,
                          borderRadius: '50%',
                        }}>
                        <Image
                          style={{
                            height: 15,
                            width: 15,
                          }}
                          source={chevronDoubleUp}
                        />
                      </Box>
                      <CCText fontWeight={400} fontSize={FontSizes[12]}>
                        Earn 1,000 CC points to qualify for League 25
                      </CCText>
                    </Box>

                    {!isMobileOrTab && (
                      <Box
                        type="center"
                        flexDirection="row"
                        marginVertical={10}
                        justifyContent="center"
                        gap={8}>
                        <Box
                          type="center"
                          style={{
                            backgroundColor: CCColors.White,
                            borderRadius: '50%',
                            width: 30,
                            height: 30,
                          }}>
                          <Image
                            style={{
                              height: 15,
                              width: 15,
                            }}
                            source={chevronDoubleDown}
                          />
                        </Box>
                        <CCText fontWeight={400} fontSize={FontSizes[12]}>
                          Underperforming in the league will lead to demotion to
                          League 25
                        </CCText>
                      </Box>
                    )}
                  </Box>
                </LinearGradient>
                <Box
                  flexDirection={isMobileOrTab ? 'column' : 'row'}
                  justifyContent="space-between"
                  style={{
                    width: isMobileOrTab ? '100%' : '95%',
                    //   marginVertical: '3%',
                    paddingTop: 20,
                    marginHorizontal: isMobileOrTab ? 0 : 28,
                  }}>
                  <Box
                    style={{
                      width: isMobileOrTab ? '100%' : '50%',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    }}>
                    <TabView
                      style={{
                        width: '68%',
                        paddingBottom: 10,
                      }}
                      lazy
                      navigationState={{index: index, routes}}
                      renderScene={() => {}}
                      renderTabBar={props => (
                        <TabBar
                          {...props}
                          activeColor="white"
                          indicatorStyle={{
                            width: '50%',
                            height: '100%',
                            backgroundColor: CCColors.Primary.Brown,
                            //   borderRadius: 8,
                            borderTopLeftRadius: index === 0 ? 8 : 0, // Rounded for first tab
                            borderBottomLeftRadius: index === 0 ? 8 : 0,
                            borderTopRightRadius:
                              index === routes.length - 1 ? 8 : 0, // Rounded for last tab
                            borderBottomRightRadius:
                              index === routes.length - 1 ? 8 : 0,
                          }}
                          inactiveColor="black"
                          style={{
                            backgroundColor: CCColors.White,
                            width: '100%',
                            height: WINDOW_WIDTH < 920 ? '5vh' : '65%',
                            borderRadius: 8,
                            // paddingBottom: 20,
                          }}
                          labelStyle={{paddingTop: 0, paddingBottom: 20}}
                          renderLabel={({route, focused, ...rest}) => {
                            return (
                              <CCText
                                fontWeight={500}
                                fontSize={14}
                                style={[
                                  styles.boxTabBarLabel,
                                  focused ? styles.boxIndicator : {},
                                  {
                                    color: focused
                                      ? CCColors?.White
                                      : CCColors?.TextColor?.Primary,
                                  },
                                ]}>
                                {route.title}
                              </CCText>
                            );
                          }}
                        />
                      )}
                      onIndexChange={setIndex}
                    />
                  </Box>
                  <Box
                    style={{
                      width: !isMobileOrTab ? '20%' : '100%',
                    }}>
                    <CCSelect
                      ccSelectHeight={isMobileOrTab ? 45 : 55}
                      ccSelectWidth="100%"
                      singleSelectDropdown={true}
                      showModal={false}
                      items={[
                        {label: 'Today', value: 'today'},
                        {label: 'This Week', value: 'week'},
                        {label: 'This Month', value: 'month'},
                        {label: 'Last Year', value: 'year'},
                      ]}
                      calendar={true}
                      multiSelect={false}
                      selectedValues={duration}
                      onValueChange={text => handleDurationChange(text)}
                    />
                  </Box>
                </Box>

                <Box
                  style={{
                    zIndex: -2,
                    backgroundColor: isMobileOrTab ? CCColors.White : '',
                    borderRadius: isMobileOrTab ? 8 : '',
                  }}>
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{
                      width: '100%',
                      position: isMobileOrTab && 'relative',
                      zIndex: isMobileOrTab && -1,
                    }}>
                    <Box
                      style={{
                        paddingVertical: 20,
                        paddingHorizontal: isMobileOrTab ? 0 : 30,
                      }}>
                      {routes[index].key === 'Standings' ? (
                        <>
                          {standingsLoading ? (
                            <CCLoader loading isComponentLoader bgWhite />
                          ) : standings.length > 0 ? (
                            standings?.map((standing, index) => (
                              <>
                                <StandingsRow
                                  standing={standing}
                                  index={index}
                                  standings={standings}
                                  selectedRow={selectedRow}
                                  hoveredRow={hoveredRow}
                                  setSelectedRow={setSelectedRow}
                                  setHoveredRow={setHoveredRow}
                                  goldMedal={goldMedal}
                                  silverMedal={silverMedal}
                                  bronzeMedal={bronzeMedal}
                                  challengeIcon={challengeIcon}
                                  isMobileOrTab={isMobileOrTab}
                                  leagueId={selectedLeague?.id}
                                />

                                {/* {selectedRow === index &&
                                  standing.player_name === 'You' && (
                                    <Box
                                      flexDirection="column"
                                      style={{
                                        borderRight:
                                          selectedRow === index
                                            ? '1px solid #26232233'
                                            : null,
                                        borderLeft:
                                          selectedRow === index
                                            ? '1px solid #26232233'
                                            : null,
                                        borderBottom: '1px solid #26232233',
                                      }}>
                                      <Box
                                        flexDirection="row"
                                        style={{
                                          paddingVertical: 30,
                                          paddingLeft: isMobileOrTab ? 15 : 30,
                                          paddingRight: isMobileOrTab ? 0 : 30,
                                        }}
                                        justifyContent="space-between">
                                        <CCText
                                          fontWeight={400}
                                          fontSize={FontSizes[18]}>
                                          Complete these task and earn points
                                        </CCText>
                                        {!isMobileOrTab && (
                                          <Box
                                            flexDirection="row"
                                            gap={10}
                                            style={{
                                              marginRight: 30,
                                            }}>
                                            <Box
                                              onPress={handleLeftChevronClick}>
                                              <FontAwesomeIcon
                                                icon={faChevronLeft}
                                                style={{
                                                  width: 10,
                                                  height: 10,
                                                  color: CCColors.Black,
                                                }}
                                              />
                                            </Box>
                                            <Box
                                              onPress={handleRightChevronClick}>
                                              <FontAwesomeIcon
                                                icon={faChevronRight}
                                                style={{
                                                  width: 10,
                                                  height: 10,
                                                  color: CCColors.Black,
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        )}
                                      </Box>
                                      <Box
                                        flexDirection="row"
                                        style={{
                                          position: 'relative',
                                          overflow: 'hidden',
                                          width: '95%', // Show 3 cards in the visible area
                                          position: 'relative',
                                          paddingHorizontal: !isMobileOrTab
                                            ? 35
                                            : 10,
                                          marginTop: -20,
                                          marginBottom: 16,
                                        }}>
                                        {isMobileOrTab ? (
                                          <ScrollView
                                            horizontal
                                            showsHorizontalScrollIndicator={
                                              false
                                            }>
                                            <CardCarousel
                                              data={data}
                                              currentIndex={currentIndex}
                                              setCurrentIndex={setCurrentIndex}
                                              containerWidth={containerWidth}
                                              pointsIcon={points}
                                            />
                                          </ScrollView>
                                        ) : (
                                          <CardCarousel
                                            data={data}
                                            currentIndex={currentIndex}
                                            setCurrentIndex={setCurrentIndex}
                                            containerWidth={containerWidth}
                                            pointsIcon={points}
                                          />
                                        )}
                                      </Box>
                                      {!isMobileOrTab && (
                                        <Box
                                          style={{
                                            position: 'absolute',
                                            right: 0, // Align it to the right
                                            top: 0,
                                            bottom: 0,
                                            width: 100, // Adjust width for shadow size
                                            background:
                                              'linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0) 100%)',
                                            pointerEvents: 'none', // Prevent the gradient from interfering with clicks
                                          }}
                                        />
                                      )}
                                    </Box>
                                  )} */}
                              </>
                            ))
                          ) : (
                            <CCText
                              color={CCColors.Grey.Grey}
                              textAlign="center"
                              style={{marginVertical: 30}}>
                              No data available
                            </CCText>
                          )}
                        </>
                      ) : (
                        <Box type="row-flex-start" flexWrap="wrap" gap={20}>
                          {leagueRules?.map((rule, index) => (
                            <Box
                              style={{
                                width: isMobileOrTab ? '100%' : '32%',
                                height: 180,
                                backgroundColor: CCColors.White,
                                padding: 20,
                                borderRadius: 8,
                                border: `1px solid ${CCColors.Border}`,
                              }}>
                              <Box
                                type="row-center-between"
                                style={{marginBottom: 20}}>
                                <Image
                                  source={points}
                                  style={{height: 40, width: 40}}
                                />

                                <CCText
                                  color={CCColors.Rewards.TextGreen}
                                  fontWeight={400}
                                  fontSize={FontSizes[12]}>
                                  Max: {rule.max_points} pts
                                </CCText>
                              </Box>
                              <CCText>{rule.name}</CCText>
                              <CCText fontSize={FontSizes[10]}>
                                {rule.description}
                              </CCText>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                    {/* {isMobileOrTab && (
                      <Box
                        onPress={handleOpen}
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          right: 0,
                          zIndex: 100,
                          borderRadius: '50%',
                          backgroundColor: CCColors.Primary.Default,
                          height: 40,
                          width: 40,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{
                            height: 18,
                            width: 18,
                          }}
                        />
                      </Box>
                    )} */}
                  </ScrollView>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ScrollView>
    </Box>
  );
};
export default React.memo(CCLeague);
