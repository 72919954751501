import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  Image,
  Pressable,
} from 'react-native';
// import {Chessboard} from 'react-chessboard';
import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
// import {gameMoves} from './helper';
import {FontSizes, vs, WINDOW_HEIGHT, WINDOW_WIDTH} from '@/Utils/Dimensions';
import Spacer from '@/Atoms/Spacer';
import {useStreamGameAnalysis} from '@/Hooks/StreamGameAnalysis/useStreamGameAnalysis';
import {useNavigation, useRoute} from '@react-navigation/native';
import {tap} from 'rxjs/operators';
import {streamGameAnalysis} from './streamGameAnalysisApi';
import styles from './styles';
import ChessboardComponent from 'chessboard-package';
import WinningBar from './component/WinningBar';
import AnalysisRightComponent from './component/AnalysisRightComponent';
import {
  calculateErrors,
  calculateErrorsByColor,
  calculateWinningChances,
  convertTimeControlToMins,
} from './utils';
import useVerifyPuzzlesApi from '@/Hooks/PuzzlesApi/verifyPuzzlesApi';
// import {gameMoves} from './helper';
import {setEngine} from 'crypto';
import ProgressBar from '@/Atoms/ProgressBar';
import {Chess} from 'chess.js';
import CCText from '@/Atoms/CCText';
import blunderIcon from '../../Assets/Images/Svgs/learning/blunder-icon.svg';
import mistakesIcon from '../../Assets/Images/Svgs/learning/mistakes-icon.svg';
import inaccurateIcon from '../../Assets/Images/Svgs/learning/inaccuracy-icon.svg';
import CCColors from '@/Utils/CCColors';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import SlideModal from '@/Atoms/SlideModal';
import {ScreenName} from '@/navigators/StackConstants';
import Loader from '@/Atoms/CCLoader/CCLoader';
import {logEvent} from '@/Utils/GAEvents';
import useGAPageView from '@/navigators/useGAPageView';
import GameScoreTable from './component/GameScoreTable';
import {capitalizeFirstLetter} from '@/Utils/CommonUtils';
import wrongSound from '../../Assets/Sounds/wrong.mp3';

import useSound from 'use-sound';
import SuccessModal from './component/SuccessModal';
import useSaveAlternateMoves from '@/Hooks/SaveAlternateMovesApi/useSaveAlternateMoves';
import {Chessboard} from 'react-chessboard';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import BottomSheet from '@/Atoms/BottomSheet';
import ConditionalRender from '@/Atoms/ConditionalRender';
import SafeAreaWrapper from '@/Atoms/SafeViewWrapper';
import {MOBILE_HEADER_HEIGHT} from '@/Utils/Constants';
import useAnimation from '@/Hooks/useAnimation';
import Animated from 'react-native-reanimated';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import {useNonPersistedUserPlanStore} from '@/Store/userPlanStore';
import Tooltip from '@/Atoms/Tooltip';
import ChessboardWithArrow from '@/Components/ChessboardWithArrow';

function extractMoves(chessNotation: string): string[] {
  // Split the string by spaces to get individual components
  const tokens = chessNotation?.split(' ');

  // Filter out the move numbers (anything that ends with ".")
  const moves = tokens.filter(token => !token.endsWith('.'));
  if (moves?.[0]?.includes('...')) {
    const resultMoves = moves?.[0]?.split('...')?.[1];
    return [resultMoves, ...moves.splice(1)];
  } else {
    return moves;
  }
}

function ChessAnalysis() {
  const route = useRoute();
  const {isMobile, isTablet} = useMediaQuery();
  const isMobileOrTab = useMemo(
    () => isMobile || isTablet,
    [isMobile, isTablet],
  );
  const chessboardColorTheme = useChessboardColorTheme();
  const navigate = useNavigation();
  const gameId = route.params?.game_id;

  const timeControl = route.params?.time_control;
  const timeClass = route.params?.time_class;
  const setStreakDetailsSummary = useLoggedInUserStore(
    state => state.setStreakDetailsSummary,
  );
  const [attemptId, setAttemptId] = useState(0);
  const isStaff = route.params?.isUser;
  const opponent = route.params?.opponent;
  const player = route.params?.player;
  const [playerColor, setPlayerColor] = useState('');
  const opponentRating = route.params?.opponent_rating;
  const playerRating = route.params?.player_rating;
  const [viewgame, setViewgame] = useState(route.params?.viewgame);
  const setStreakConfig = useLoggedInUserStore(state => state.setStreakConfig);
  const [isLoading, setIsLoading] = useState(false);
  const [gameOverviewDetails, setGameOverview] = useState(null);
  function generateNewFen(currentFen, move) {
    console.log(currentFen, move);
    const chess = new Chess(currentFen);

    // `move` should be an object like { from: 'e2', to: 'e4' }
    // or a string like 'e2e4' or 'e7e8q' (for pawn promotion)
    const result = chess.move(move);

    if (result === null) {
      // throw new Error('Invalid move');
      return currentFen;
    }

    return chess.fen();
  }

  const [tabOpen, setTabOpen] = useState('GAME REVIEW');
  const [openSuccessModal, setOpenSuccessModal] = useState({
    flag: false,
    correctSeq: [],
  });
  const [showError, setShowError] = useState(false);
  const handleMoveSound = () => {
    console.log('sounf');
  };
  const [fen, setFen] = useState({
    fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  });

  const [fen2, setFen2] = useState({
    fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    flag: false,
  });

  const [tableData, setTableData] = useState([]);
  const [isPuzzleScreen, setIsPuzzleScreen] = useState({
    flag: false,
    id: '',
  });
  const streamGameAnalysisMutation = useStreamGameAnalysis();
  const [puzzleResult, setPuzzleResult] = useState('None');
  const [puzzleHint, setPuzzleHint] = useState('None');
  const [puzzleDescription, setPuzzleDescription] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [resetChessboard, setResetChessboard] = useState(false);
  const [gameScoreDetails, setGameScoreDetails] = useState(null);
  const [selectedManualMove, setSelectedManualMove] = useState(null);
  const [moveSelectedInReview, setMoveSelectedInReview] = useState('');
  const [manualMoves, setManualMoves] = useState([]);
  const setUserGems = useLoggedInUserStore(state => state.setGems);
  const setUserLevel = useLoggedInUserStore(state => state.setLevel);
  const [chessboardHeight, setChessboardHeight] = useState(0);
  const [rightcolumnWidth, setrightcolumnWidth] = useState(0);
  const [correspondingFen, setCorrespondingFen] = useState<string>('start');
  const [isFenFromOutside, setIsFenFromOutside] = useState<boolean>(true);
  const {setSubscriptionModalOpen} = useNonPersistedUserPlanStore();
  const onChessboardLayout = event => {
    const {height} = event.nativeEvent.layout;
    setChessboardHeight(height);
  };
  const onRightColumnLayout = event => {
    const {width} = event.nativeEvent.layout;
    setrightcolumnWidth(width);
  };
  const [engineInfo, setEngineInfo] = useState();
  const [puzzleId, setPuzzleId] = useState(0);
  const [incorrectSound] = useSound(wrongSound);
  const [fullHint, setFullHint] = useState('');
  const [infocolor, setInfocolor] = useState('');
  const [fullHint2, setFullHint2] = useState('');
  const handleSelectMoveInReview = tempMove => {
    if (tempMove?.alternate_moves && !manualMoves?.[tempMove?.fen]) {
      // setManualMoves({...tempMove?.alternate_moves, newFen:tempMove?.alternate_moves?.fen, lastFen:tempMove?.alternate_moves?.previous_fen})
      setManualMoves(prev => ({
        ...prev,
        [tempMove?.fen]: tempMove?.alternate_moves?.map(i => ({
          ...i,
          newFen: i?.fen,
          lastFen: i?.previous_fen,
        })),
      }));
    }
    setMoveSelectedInReview(tempMove);
  };
  const handleSelectedTabChecks = (tab: string) => {
    setTabOpen(tab);
  };
  const handleOnSuccessfulVerification = async data => {
    setIsLoading(false);
    // if()
    if (data?.is_permitted === false) {
      setSubscriptionModalOpen(true);
      incorrectSound();
      setPuzzleResult('False');
      // if (data?.hint) {
      //   setPuzzleHint(data?.hint);
      //   setPuzzleDescription(
      //     'Incorrect! Opponent will respond with ' +
      //       data?.resp_move +
      //       '. Try moving the ' +
      //       data?.hint +
      //       '.' +
      //       data?.hint_response,
      //   );
      // } else {
      //   setPuzzleDescription(
      //     'Incorrect! Opponent will respond with ' +
      //       data?.resp_move +
      //       '. Try Again.',
      //   );
      // }

      handleFen(fen);
      setTimeout(() => {
        setPuzzleResult('None');
        setPuzzleHint('None');
        // setPuzzleDescription('None');
      }, 10000);
      setResetChessboard(prev => !prev);
    } else if (data?.success) {
      // console.log(
      //   extractMoves(data?.best_possible_response),
      //   'extractMoves(data?.best_possible_response)',
      // );
      setStreakDetailsSummary(data?.streak_details_summary);
      setStreakConfig(data?.streak_details);

      // setOpenSuccessModal({
      //   flag: false,
      //   correctSeq: extractMoves(data?.best_possible_response),
      // });
      // setTimeout(() => setPuzzleResult('None'), 2000);
      setRefresh(!refresh);
      if (data?.points) {
        useLoggedInUserStore.setState({
          gems: data?.points.points,
          pointsToNextLevel: data?.points.points_to_next_level,
          level: data?.points.level,
          netScore: data?.net_score,
        });
      }
      setPuzzleResult('True');
      setPuzzleDescription('Correct Answer!');
      // Reset both hints first
      setFullHint('');
      setFullHint2('');
      if (data?.hint_response) {
        setFullHint(`Hurray that's the correct move! ${data?.hint_response}`);
      }
      if (data?.hint_response2) {
        setFullHint2(`Hurray that's the correct move! ${data?.hint_response2}`);
      }
      setAttemptId(data?.attempt_id);
      setInfocolor(CCColors.Green);
      // setFen2(prev => ({
      //   fen: selectedManualMove?.newFen,
      //   flag: !prev?.flag,
      //   lastFen: {
      //     fen: selectedManualMove?.lastFen,
      //   },
      // }));
      handleFen({
        ...fen,
        fen: selectedManualMove?.newFen,
        ...selectedManualMove,
      });

      setTimeout(() => {
        setPuzzleResult('None');
        setPuzzleHint('None');
        // setPuzzleDescription('None');
      }, 10000);

      // handleFen(
      //   {fen: selectedManualMove?.newFen, ...selectedManualMove},
      //   false,
      //   null,
      //   {
      //     fen: selectedManualMove?.lastFen,
      //   },
      //   () => {},
      // );
    } else {
      incorrectSound();
      setAttemptId(data?.attempt_id);
      setPuzzleResult('False');
      // Reset both hints first
      setFullHint('');
      setFullHint2('');
      if (data?.hint) {
        setPuzzleHint(data?.hint);
        setPuzzleDescription(
          data?.resp_move
            ? 'Incorrect! Opponent will respond with ' +
                data?.resp_move +
                '. Try moving the ' +
                data?.hint +
                '.'
            : 'Incorrect Move!',
        );

        if (data?.hint_response) {
          setPuzzleDescription(data?.hint_response);
          setFullHint(`Incorrect move! ${data?.hint_response}`);
          setInfocolor(CCColors.Red);
        }
        if (data?.hint_response2) {
          setFullHint2(`Incorrect move! ${data?.hint_response2}`);
        }
      } else {
        setPuzzleDescription(
          data?.resp_move
            ? 'Incorrect! Opponent will respond with ' +
                data?.resp_move +
                '. Try Again.'
            : 'Incorrect Move!',
        );
      }

      handleFen(fen);
      setTimeout(() => {
        setPuzzleResult('None');
        setPuzzleHint('None');
        // setPuzzleDescription('None');
      }, 10000);
      setResetChessboard(prev => !prev);
    }
  };
  const handleOnFailedVerification = () => {
    setIsLoading(false);
  };
  const {
    mutate: verifyPuzzle,
    data: verifyPuzzleResponse,
    error: verifyPuzzleError,
  } = useVerifyPuzzlesApi(
    handleOnSuccessfulVerification,
    handleOnFailedVerification,
  );
  const {
    mutate: saveAlternateMoves,
    data: saveAlternateMovesData,
    error: saveAlternateMovesError,
  } = useSaveAlternateMoves(
    () => {},
    () => {},
  );

  const playerDetails = ({playerType}: {playerType: 'black' | 'white'}) => {
    const isWhite = playerType === 'white';
    const tempErrors = calculateErrorsByColor(streamedMoves);
    console.log('>>>>>..... players: ', players);
    return (
      <Box style={styles.eachPlayerDetails} flex={0.25}>
        <Box style={styles.eachPlayerName}>
          <Box style={isWhite ? styles.whiteBox : styles.BrownBox} />
          <CCText fontSize={FontSizes[12]} fontWeight="bold" showEllipses>
            {'  '}
            {isWhite
              ? players?.white || (playerColor == 'White' ? player : opponent)
              : players?.black || (playerColor == 'Black' ? player : opponent)}
          </CCText>
        </Box>
        <CCText fontSize={FontSizes[11]} color={CCColors.Grey.Shade100}>
          #
          {isWhite
            ? players?.white_rating || playerColor == 'White'
              ? playerRating
              : opponentRating
            : players?.black_rating || playerColor == 'Black'
            ? playerRating
            : opponentRating}
        </CCText>
        <Spacer spacing={10} />
        <Box flexDirection="row">
          <Image source={blunderIcon} style={{width: 25, height: 20}} />
          <Spacer spacing={10} horizontal />

          <CCText>{tempErrors?.[playerType]?.blunders} blunder</CCText>
        </Box>
        <Spacer spacing={10} />
        <Box flexDirection="row">
          <Image source={mistakesIcon} style={{width: 25, height: 20}} />
          <Spacer spacing={10} horizontal />

          <CCText>{tempErrors?.[playerType]?.mistakes} mistakes</CCText>
        </Box>
        <Spacer spacing={10} />
        <Box flexDirection="row">
          <Image source={inaccurateIcon} style={{width: 25, height: 20}} />
          <Spacer spacing={10} horizontal />

          <CCText>{tempErrors?.[playerType]?.inaccuracies} inaccuracy</CCText>
        </Box>
      </Box>
    );
  };

  const handleFen = (
    tempFen: any,
    isPuzzle?: boolean,
    puzzleId?: string,
    lastFen: any = {},
    nextPuzzle: () => {},
    isManualMove: boolean,
  ) => {
    // setRefresh(!refresh);
    // handleNextPuzzle();
    setFen2(prev => ({fen: tempFen?.fen, flag: !prev?.flag, lastFen: lastFen}));
    setFen({...tempFen, lastFen: lastFen});
    if (isPuzzle && puzzleId) {
      setIsPuzzleScreen({flag: true, id: puzzleId});
      setSelectedManualMove(null);
      // verifyPuzzle({puzzleId, move: });
    }
    if (isManualMove) {
      setSelectedManualMove(tempFen);
    }
  };
  const [chessNew] = useState(new Chess());

  const handlePieceDrop = (from, to) => {
    if (
      isPuzzleScreen?.flag &&
      isPuzzleScreen?.id &&
      tabOpen === 'POSITIONS' &&
      puzzleResult !== 'True'
    ) {
      // setFen2(prev => ({
      //   fen: generateNewFen(prev?.fen, {from, to}),
      //   flag: !prev?.flag,
      // }));
      setIsPuzzleScreen(prev => ({...prev, prevMove: fen}));
      // setTimeout(() => {

      setIsLoading(true);
      verifyPuzzle({puzzleId: isPuzzleScreen?.id, move: from + to});
      // }, 200);
      // return false;
      return false;
    } else {
      if (tabOpen?.toLowerCase() !== 'GAME REVIEW') {
        setTabOpen('GAME REVIEW');
        setPuzzleResult('None');
      }
      return false;
      // return true;
    }
  };

  const handleSaveAlternateMove = ({
    gameMoveId,
    alternateMoves,
  }: {
    gameMoveId: number;
    alternateMoves: any;
  }) => {
    if (tabOpen?.toUpperCase() !== 'POSITIONS') {
      saveAlternateMoves({
        gameMoveId,
        alternateMoves,
      });
    }
  };

  const handlePositionChange = tempPosition => {
    setCorrespondingFen(tempPosition?.newFen);
    setIsFenFromOutside(true);
    if (tabOpen !== 'POSITIONS' || puzzleResult !== 'True') {
      // console.log(manualMoves, 'manualMoves');
      setManualMoves(prev => {
        // Check if the current FEN already has an entry in the state
        const currentFEN = moveSelectedInReview?.fen;
        if (!currentFEN) return prev; // Or handle this case as needed

        let existingMovesForFEN = prev[currentFEN] || []; // Get existing moves for this FEN, or an empty array if none
        if (!existingMovesForFEN || existingMovesForFEN?.length === 0) {
          existingMovesForFEN = [
            {
              ...fen,
              newFen: fen2?.fen,
              lastFen: fen2?.lastFen?.fen,
              san: fen?.move,
            },
          ];
        }
        const indexOfPrev = existingMovesForFEN?.findIndex(
          element => element.newFen === tempPosition?.lastFen,
        );
        const updatedMovesForFEN = [
          ...existingMovesForFEN?.splice(0, indexOfPrev + 1),
          // ...existingMovesForFEN,
          tempPosition,
        ]; // Add the new move to the array
        // console.log(
        //   {
        //     ...prev, // Spread the previous state to maintain moves for other FEN strings
        //     [currentFEN]: [...updatedMovesForFEN], // Update moves for the current FEN string
        //   },
        //   'updatedMovesForFEN',
        // );
        handleSaveAlternateMove({
          gameMoveId: moveSelectedInReview?.id,
          alternateMoves: updatedMovesForFEN?.map(i => ({
            lastFen: i?.lastFen || '',
            newFen: i?.newFen,
            san: i?.san,
          })),
        });
        return {
          ...prev, // Spread the previous state to maintain moves for other FEN strings
          [currentFEN]: [...updatedMovesForFEN], // Update moves for the current FEN string
        };
      });
      // tempFen: any,
      // isPuzzle?: boolean,
      // puzzleId?: string,
      // lastFen: any = {},
      // nextPuzzle: () => {},
      // isManualMove: boolean,
      // debugger;

      setSelectedManualMove(tempPosition);
    }
  };
  useGAPageView(ScreenName.ChessAnalysis);
  const [streamedMoves, setStreamedMoves] = useState<any>([]);
  const [puzzleSummary, setPuzzleSummary] = useState({});
  const [players, setPlayers] = useState({});
  const [gameDetails, setGameDetails] = useState({});
  const [gameDetailsQuery, setGameDetailsQuery] = useState<string[]>([
    timeControl,
    timeClass,
  ]);
  const [gameReviewedByUser, setGameReviewedByUser] = useState(false);
  const [result, setResult] = useState('Loading');
  const [engineAnalyzed, setEngineAnalyzed] = useState(false);
  const [analysisProgress, setAnalysisProgress] = useState(0);
  const [arrows, setArrows] = useState([]);

  const [resultOpacityAnimStyle, startResultOpacityAnim] = useAnimation({
    prop: 'opacity',
    start: 1,
    end: 1,
    repeat: true,
    numberOfReps: 2,
    inputRange: [0, 0.1],
    duration: 1000,
  });
  const handlePress = () => {
    setViewgame(false);
    navigation.setParams({
      viewgame: false,
      game_id: gameId,
      isUser: isStaff,
    });
  };

  const handleArrowDrawn = useCallback(newArrows => {
    console.log(newArrows, 'newArrows');
    setArrows(newArrows);
  }, []);

  useEffect(() => {
    if (puzzleResult !== 'True') {
      setStreamedMoves([]);
    }
    setShowError(false);

    if (!viewgame) {
      setIsLoading(true);
      const subscription = streamGameAnalysis(gameId)
        .pipe(
          tap(data => {
            setIsLoading(false);
            console.log('data: ', data);
            if (data?.players) {
              setPlayers(data?.players);
              setGameDetails(data?.game_details);
            } else if (data?.hasOwnProperty('game_reviewed_by_user')) {
              setGameReviewedByUser(data?.game_reviewed_by_user);
            } else if (data?.game_result) {
              // setIsLoading(false);
              setResult(data?.game_result);
            } else if (data?.puzzle_summary) {
              setPuzzleSummary(data?.puzzle_summary);
            } else if (data?.status === 'error') {
              // setIsLoading(false);
              setShowError(true);
            } else if (data?.game_overview) {
              setGameOverview(data?.game_overview);
            } else if (data?.game_score_details) {
              setGameScoreDetails(data?.game_score_details);
            } else if (data?.status === 'Game already analyzed') {
              // setIsLoading(false);
              setShowError(false);
              if (puzzleResult !== 'True') {
                setStreamedMoves(data.game_moves);
                console.log('>>>>>Set Streamed Moves', data?.game_moves);
              }
            } else if (data?.status === 'starting_analysis') {
              setGameReviewedByUser(false);
              setAnalysisProgress(0);
            } else if (data?.status === 'starting_analysis') {
              setAnalysisProgress(0);
            } else if (data?.game_engine_analyzed) {
              setEngineAnalyzed(data?.game_engine_analyzed);
            } else if (data?.user_points) {
              // debugger;
              useLoggedInUserStore.setState({
                gems: data?.user_points?.points?.points,
                pointsToNextLevel:
                  data?.user_points?.points?.points_to_next_level,
                level: data?.user_points?.points?.level,
                netScore: data?.user_points?.net_score,
              });
              //   {
              //     "user_points": {
              //         "points": {
              //             "points_to_next_level": 25,
              //             "points": 6,
              //             "level": 10
              //         },
              //         "net_score": 708
              //     }
              // }
            } else if (data?.is_permitted === false) {
              setSubscriptionModalOpen(true);
            } else {
              // setIsLoading(false);
              if (data.game_engine_analyzed === false) {
              } else if (data) {
                // debugger;
                setAnalysisProgress((data?.move_index * 2) / data?.total_moves);
                if (puzzleResult !== 'True') {
                  if (
                    !streamedMoves?.find(i => i?.fen === data?.analysis?.fen)
                  ) {
                    setStreamedMoves(prevMoves => {
                      console.log(
                        [...prevMoves, {...data, ...data?.analysis}],
                        'setStreamedMoves',
                      );
                      return [...prevMoves, {...data, ...data?.analysis}];
                    });
                  }
                  setFen2(prev => ({
                    ...prev,
                    fen: data?.analysis?.fen,
                  }));
                }
              }
            }
          }),
        )
        .subscribe();

      return () => subscription.unsubscribe();
    } // Cleanup: unsubscribe from observable on component unmount
  }, [gameId, refresh]);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const handleNextPuzzle = () => {
    setPuzzleResult('None');
    setPuzzleDescription('');
    // setRefresh(!refresh);
    setOpenSuccessModal({flag: false, correctSeq: []});
  };
  const navigation = useNavigation();
  const leftGameDetailsComponent = (
    <Box style={styles.Leftcontainer}>
      <Box flex={0.1}>
        <CCText
          style={styles.gameCategoryTitle}
          fontWeight="bold"
          fontSize={FontSizes[14]}>
          {convertTimeControlToMins(gameDetails?.time_control)} .{' '}
          {capitalizeFirstLetter(gameDetails?.type)}
        </CCText>
      </Box>
      {!viewgame
        ? (streamedMoves?.[0]?.player === 'me' &&
            streamedMoves?.[0]?.color === 'white') ||
          (streamedMoves?.[0]?.player !== 'me' &&
            streamedMoves?.[0]?.color !== 'white' &&
            streamedMoves?.[0] &&
            Object.keys(streamedMoves?.[0]).length !== 0) ||
          (streamedMoves?.[1]?.player !== 'me' &&
            streamedMoves?.[1]?.color !== 'white' &&
            streamedMoves?.[1])
          ? playerDetails({playerType: 'black'})
          : playerDetails({playerType: 'white'})
        : playerColor == 'White'
        ? playerDetails({playerType: 'black'})
        : playerDetails({playerType: 'white'})}
      {!viewgame
        ? (streamedMoves?.[0]?.player === 'me' &&
            streamedMoves?.[0]?.color === 'white') ||
          (streamedMoves?.[0]?.player !== 'me' &&
            streamedMoves?.[0]?.color !== 'white' &&
            streamedMoves?.[0] &&
            Object.keys(streamedMoves?.[0]).length !== 0) ||
          (streamedMoves?.[1]?.player !== 'me' &&
            streamedMoves?.[1]?.color !== 'white' &&
            streamedMoves?.[1])
          ? playerDetails({playerType: 'white'})
          : playerDetails({playerType: 'black'})
        : playerDetails({playerType: playerColor.toLowerCase()})}
      <Box flex={0.3}>
        {gameScoreDetails ? (
          <GameScoreTable gameScoreDetails={gameScoreDetails} />
        ) : (
          <div>Loading insights...</div>
        )}
      </Box>
    </Box>
  );
  const leftGameDetailsComponentView = (
    <Box style={styles.Leftcontainer}>
      <Box flex={0.1}>
        <CCText
          style={styles.gameCategoryTitle}
          fontWeight="bold"
          fontSize={FontSizes[14]}>
          {gameDetailsQuery?.[0]} . {gameDetailsQuery?.[1]}
        </CCText>
      </Box>
      {!viewgame
        ? (streamedMoves?.[0]?.player === 'me' &&
            streamedMoves?.[0]?.color === 'white') ||
          (streamedMoves?.[0]?.player !== 'me' &&
            streamedMoves?.[0]?.color !== 'white' &&
            streamedMoves?.[0] &&
            Object.keys(streamedMoves?.[0]).length !== 0) ||
          (streamedMoves?.[1]?.player !== 'me' &&
            streamedMoves?.[1]?.color !== 'white' &&
            streamedMoves?.[1])
          ? playerDetails({playerType: 'black'})
          : playerDetails({playerType: 'white'})
        : playerColor == 'White'
        ? playerDetails({playerType: 'black'})
        : playerDetails({playerType: 'white'})}
      {!viewgame
        ? (streamedMoves?.[0]?.player === 'me' &&
            streamedMoves?.[0]?.color === 'white') ||
          (streamedMoves?.[0]?.player !== 'me' &&
            streamedMoves?.[0]?.color !== 'white' &&
            streamedMoves?.[0] &&
            Object.keys(streamedMoves?.[0]).length !== 0) ||
          (streamedMoves?.[1]?.player !== 'me' &&
            streamedMoves?.[1]?.color !== 'white' &&
            streamedMoves?.[1])
          ? playerDetails({playerType: 'white'})
          : playerDetails({playerType: 'black'})
        : playerDetails({playerType: playerColor.toLowerCase()})}
    </Box>
  );
  const chessboardComponentJSX = (
    <Box
      style={styles.chessboard}
      // {...(!isMobileOrTab ? {overflow: 'scroll'} : {})}
    >
      {/* <ScrollView showsVerticalScrollIndicator={false}> */}
      {/* <Box flexDirection="column"> */}
      <Box
        // overflow="hidden"
        style={[
          // styles.chessboardTable,
          isMobileOrTab ? styles.chessboardTableMobile : styles.chessboardTable,
        ]}>
        {!isMobileOrTab && !viewgame && (
          <Box
            style={[
              styles.winningBar,
              {height: chessboardHeight},
              isMobileOrTab && styles.winningBarMobile,
            ]}>
            <WinningBar
              whiteWinRatio={calculateWinningChances(fen?.score)?.white / 100}
              blackWinRatio={calculateWinningChances(fen?.score)?.black / 100}
              reverse={
                (!viewgame
                  ? (streamedMoves?.[0]?.player === 'me' &&
                      streamedMoves?.[0]?.color === 'white') ||
                    (streamedMoves?.[0]?.player !== 'me' &&
                      streamedMoves?.[0]?.color !== 'white' &&
                      streamedMoves?.[0] &&
                      Object.keys(streamedMoves?.[0]).length !== 0) ||
                    (streamedMoves?.[1]?.player !== 'me' &&
                      streamedMoves?.[1]?.color !== 'white' &&
                      streamedMoves?.[1])
                    ? 'white'
                    : 'black'
                  : playerColor.toLowerCase()) !== 'white'
              }
            />
          </Box>
        )}
        <View style={styles.chessboard}>
          <View onLayout={onChessboardLayout}>
            <ChessboardWithArrow
              setEngineInfo={setEngineInfo}
              resetChessboard={resetChessboard}
              position={fen2?.fen}
              onPieceDropExtension={handlePieceDrop}
              lastMoveFen={
                fen2?.lastFen?.fen
                //  ||
                // 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
              }
              handleNewFen={handlePositionChange}
              // getPositionObject={handlePositionChange}
              boardOrientation={
                !viewgame
                  ? (streamedMoves?.[0]?.player === 'me' &&
                      streamedMoves?.[0]?.color === 'white') ||
                    (streamedMoves?.[0]?.player !== 'me' &&
                      streamedMoves?.[0]?.color !== 'white' &&
                      streamedMoves?.[0] &&
                      Object.keys(streamedMoves?.[0]).length !== 0) ||
                    (streamedMoves?.[1]?.player !== 'me' &&
                      streamedMoves?.[1]?.color !== 'white' &&
                      streamedMoves?.[1])
                    ? 'white'
                    : 'black'
                  : playerColor.toLowerCase()
              }
              colorTheme={chessboardColorTheme}
              // customArrows={arrows}
              // onArrowsChange={handleArrowDrawn}
            />
          </View>
          {puzzleDescription && (
            <Animated.View
              style={[
                resultOpacityAnimStyle,
                {
                  backgroundColor:
                    puzzleResult === 'True' ||
                    puzzleDescription === 'Correct Answer!'
                      ? CCColors.Primary.lightBrown
                      : CCColors.Red,
                },
              ]}>
              <Box flexDirection="row" alignItems="center" flex={1}>
                {puzzleDescription.length > 20 && (
                  <Image
                    resizeMethod="resize"
                    source={require('@/Assets/Images/Svgs/hint.svg')}
                    alt="hint"
                    style={{width: 24, height: 24, marginLeft: 10}}
                  />
                )}
                <CCText
                  textAlign={
                    puzzleDescription.length > 20 ? 'justify' : 'center'
                  }
                  style={[
                    {
                      padding: isMobileOrTab ? 5 : 10,
                      fontSize: isMobileOrTab ? 12 : 15,
                      fontWeight: '500',
                    },
                    {
                      color:
                        puzzleResult === 'True'
                          ? CCColors.Green
                          : CCColors.White,
                    },
                    {flex: 1},
                  ]}>
                  {puzzleDescription.length > 70
                    ? `Incorrect Move!`
                    : puzzleDescription === 'None'
                    ? `${playerToPlay} to play`
                    : puzzleDescription}
                </CCText>
              </Box>
            </Animated.View>
          )}
        </View>
      </Box>
      {/* </Box> */}
      {/* </ScrollView> */}
      {/* <Spacer spacing={vs(10)} /> */}

      {/* <Box>Best Move: {gameMoves[moveIndex].best_move}</Box> */}
    </Box>
  );
  return (
    <SafeAreaWrapper>
      {/* {console.log(gameOverview, 'gameOverview')} */}
      <SlideModal
        type="center"
        modalHeaderTitle={
          <CCText fontSize={FontSizes[14]}>Patience is a Chess Virtue!</CCText>
        }
        visible={false}>
        <Box margin={24}>
          <CCText fontSize={FontSizes[14]} style={{margin: 12}}>
            ♟️ Oops! Already analyzing one of your games. Let's not double-rook
            it. Back in 2! 🕒
          </CCText>
          <Box flexDirection="row" justifyContent="flex-end">
            <CCButton
              onPress={() => {
                navigate.navigate(ScreenName?.Games);
              }}>
              Ok
            </CCButton>
          </Box>
        </Box>
      </SlideModal>
      <Loader loading={isLoading} />
      <View style={isMobileOrTab ? styles.mobileContainer : styles.container}>
        {!isMobileOrTab && !viewgame
          ? leftGameDetailsComponent
          : leftGameDetailsComponentView}
        {/* <Box style={{width: '70%'}}> */}
        {!isMobileOrTab && chessboardComponentJSX}
        {isMobileOrTab && (
          <View onLayout={onChessboardLayout}>
            <ChessboardComponent
              setEngineInfo={setEngineInfo}
              resetChessboard={resetChessboard}
              position={fen2?.fen}
              onPieceDropExtension={handlePieceDrop}
              lastMoveFen={
                fen2?.lastFen?.fen
                //  ||
                // 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
              }
              handleNewFen={handlePositionChange}
              // getPositionObject={handlePositionChange}
              boardOrientation={
                !viewgame
                  ? (streamedMoves?.[0]?.player === 'me' &&
                      streamedMoves?.[0]?.color === 'white') ||
                    (streamedMoves?.[0]?.player !== 'me' &&
                      streamedMoves?.[0]?.color !== 'white' &&
                      streamedMoves?.[0] &&
                      Object.keys(streamedMoves?.[0]).length !== 0) ||
                    (streamedMoves?.[1]?.player !== 'me' &&
                      streamedMoves?.[1]?.color !== 'white' &&
                      streamedMoves?.[1])
                    ? 'white'
                    : 'black'
                  : playerColor.toLowerCase()
              }
              colorTheme={chessboardColorTheme}
              // customArrows={arrows}
              // onArrowsChange={handleArrowDrawn}
            />
            {!viewgame && (
              <Box
                style={[
                  styles.winningBar,
                  isMobileOrTab && styles.winningBarMobile,
                ]}>
                <WinningBar
                  whiteWinRatio={
                    calculateWinningChances(fen?.score)?.white / 100
                  }
                  blackWinRatio={
                    calculateWinningChances(fen?.score)?.black / 100
                  }
                  reverse={
                    (!viewgame
                      ? (streamedMoves?.[0]?.player === 'me' &&
                          streamedMoves?.[0]?.color === 'white') ||
                        (streamedMoves?.[0]?.player !== 'me' &&
                          streamedMoves?.[0]?.color !== 'white' &&
                          streamedMoves?.[0] &&
                          Object.keys(streamedMoves?.[0]).length !== 0) ||
                        (streamedMoves?.[1]?.player !== 'me' &&
                          streamedMoves?.[1]?.color !== 'white' &&
                          streamedMoves?.[1])
                        ? 'white'
                        : 'black'
                      : playerColor.toLowerCase()) !== 'white'
                  }
                />
              </Box>
            )}
          </View>
        )}
        {/* <Box style={styles.movesList}> */}
        <ConditionalRender
          condition={isMobileOrTab}
          childrenA={
            <BottomSheet
              minHeight={
                WINDOW_HEIGHT - chessboardHeight - MOBILE_HEADER_HEIGHT
              }>
              <AnalysisRightComponent
                infocolor={infocolor}
                attemptId={attemptId}
                isStaff={isStaff}
                puzzleId={Number(isPuzzleScreen.id)}
                fullHint2={fullHint2}
                fullHint={fullHint}
                isFenFromOutside={isFenFromOutside}
                setIsFenFromOutside={setIsFenFromOutside}
                fen2={correspondingFen}
                setPuzzleDescription={setPuzzleDescription}
                handleFen={handleFen}
                gameMoves={streamedMoves}
                puzzleSummary={puzzleSummary}
                players={players}
                handleNextPuzzlReset={handleNextPuzzle}
                gameReviewedByUser={gameReviewedByUser}
                result={result}
                refreshPuzzles={refresh}
                analysisInProgress={analysisProgress < 1}
                gameOverviewDetails={gameOverviewDetails}
                handleSelectedTabChecks={handleSelectedTabChecks}
                manualMoves={manualMoves}
                currentPlayingFen={fen2?.fen}
                selectedManualMove={selectedManualMove}
                handleSelectMoveInReview={handleSelectMoveInReview}
                handleSaveAlternateMove={handleSaveAlternateMove}
                tabOpen={tabOpen}
                viewgame={viewgame}
                gameid={gameId}
                SetPlayerColor={setPlayerColor}
              />
            </BottomSheet>
          }
          childrenB={
            <Box
              onLayout={onRightColumnLayout}
              style={[
                styles.movesList,
                {width: rightcolumnWidth},
                isMobileOrTab && styles.movesListMobile,
              ]}>
              <AnalysisRightComponent
                infocolor={infocolor}
                attemptId={attemptId}
                isStaff={isStaff}
                puzzleId={Number(isPuzzleScreen.id)}
                fullHint2={fullHint2}
                fullHint={fullHint}
                isFenFromOutside={isFenFromOutside}
                setIsFenFromOutside={setIsFenFromOutside}
                fen2={correspondingFen}
                handleFen={handleFen}
                gameMoves={streamedMoves}
                puzzleSummary={puzzleSummary}
                players={players}
                handleNextPuzzlReset={handleNextPuzzle}
                gameReviewedByUser={gameReviewedByUser}
                result={result}
                refreshPuzzles={refresh}
                analysisInProgress={analysisProgress < 1}
                gameOverviewDetails={gameOverviewDetails}
                handleSelectedTabChecks={handleSelectedTabChecks}
                manualMoves={manualMoves}
                currentPlayingFen={fen2?.fen}
                selectedManualMove={selectedManualMove}
                handleSelectMoveInReview={handleSelectMoveInReview}
                handleSaveAlternateMove={handleSaveAlternateMove}
                tabOpen={tabOpen}
                viewgame={viewgame}
                gameid={gameId}
                SetPlayerColor={setPlayerColor}
              />
            </Box>
          }
        />

        <SuccessModal
          fen={fen2?.fen}
          // fen={
          //   'r1b1kbnr/ppppqpp1/2n4p/4P3/2B5/1QN2N2/PP3PPP/R1B2RK1 w kq - 3 10'
          // }
          // lastMove={fen?.lastFen?.fen}
          boardOrientation={
            !viewgame
              ? (streamedMoves?.[0]?.player === 'me' &&
                  streamedMoves?.[0]?.color === 'white') ||
                (streamedMoves?.[0]?.player !== 'me' &&
                  streamedMoves?.[0]?.color !== 'white' &&
                  streamedMoves?.[0] &&
                  Object.keys(streamedMoves?.[0]).length !== 0) ||
                (streamedMoves?.[1]?.player !== 'me' &&
                  streamedMoves?.[1]?.color !== 'white' &&
                  streamedMoves?.[1])
                ? 'white'
                : 'black'
              : playerColor.toLowerCase()
          }
          autoPlayMoves={openSuccessModal?.correctSeq}
          open={openSuccessModal?.flag}
          // open={true}
          handleClose={() => {
            handleNextPuzzle();
          }}
        />
        {/* <CCPackageTable headers={['White', 'Black']} tableData={tableData} /> */}
        {/* </Box> */}
      </View>
    </SafeAreaWrapper>
  );
}

export default ChessAnalysis;
