import CCColors from '../../Utils/CCColors';

import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  streakContainer: {
    position: 'relative',
    // width: 'min-content',
  },
  streakContainerMobile: {
    width: 'min-content',
    // width: 'min-content',
  },
  streakWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: CCColors?.Primary?.lightBrown,
    borderRadius: 25,
    paddingHorizontal: 16,
    paddingVertical: 5,
  },
  streakWrapperMobile: {
    // paddingRight: 10,
  },
  textWrapper: {
    // margin: 5,
  },
});

export default styles;
