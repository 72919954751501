import Box from '@/Atoms/Box';
import CCLoader from '@/Atoms/CCLoader';
import CCPackageTable from '@/Atoms/CCPackageTable';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import useGetSubscriptionDetailsApi from '@/Hooks/SubscriptionDetails/useSubscriptionDetails';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Pagination from '@/Screens/Games/Pagination';

import CCColors from '@/Utils/CCColors';
import {FontSizes, vs} from '@/Utils/Dimensions';
import React, {useEffect, useState} from 'react';
import {ScrollView} from 'react-native';
import styles from './styles';

const tabHeaders = [
  'Order ID',
  'Player ID',
  'Mentor',
  'Plan Details',
  'Plan Status',
  'Start Date',
  'End Date',
  'Date Created',
];

const SubscriptionTab = () => {
  const {isMobileOrTab} = useMediaQuery();
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const handleOnError = (error: any) => {
    console.log('Error fetching subscription details:', error);
  };
  const handleOnSuccessSubscriptionDetails = (data: any) => {
    console.log('Subscription details = ', data);
    setSubscriptionDetails(data?.csoc_list);
  };
  const {mutate: getSubscriptionDetails} = useGetSubscriptionDetailsApi(
    handleOnSuccessSubscriptionDetails,
    handleOnError,
  );
  useEffect(() => {
    getSubscriptionDetails();
  }, []);
  const paginate = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      getSubscriptionDetails();
    }
  };
  const [tableData, setTableData] = useState<
    Array<
      Array<{
        name: string;
        type: 'text' | 'link' | 'dropdown';
        url?: string;
        options?: string[];
      }>
    >
  >([]);
  function formatDate(dateOnly) {
    // Split into year, month, and day
    const [year, month, day] = dateOnly.split('-');

    // Month names array
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    function getOrdinalSuffix(day) {
      const dayNumber = parseInt(day, 10);
      if (dayNumber >= 11 && dayNumber <= 13) return 'th';
      switch (dayNumber % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }
    // Convert month number to month name
    const monthName = monthNames[parseInt(month, 10) - 1];
    const ordinalSuffix = getOrdinalSuffix(day);
    // Format to dd MMM yy
    return `${day}${ordinalSuffix} ${monthName} '${year.slice(2)}`;
  }

  useEffect(() => {
    if (subscriptionDetails && Array.isArray(subscriptionDetails)) {
      const itemsPerPage = 10;
      const totalItems = subscriptionDetails.length;

      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      const paginatedData = subscriptionDetails.slice(startIndex, endIndex);
      const formattedData = paginatedData.map(item => {
        console.log('subscription is .... : ', item);
        const order_id = item?.order_id;
        const player_id = item?.player_id;
        const mentor = item?.mentor;
        const batch_details = item?.batch_details.split(' ')[0];
        const start_date = formatDate(item?.start_date);
        const end_date = formatDate(item?.end_date);
        const status = item?.status;
        const date_created = formatDate(item?.date.split('T')[0]);
        const class_details = item?.class_details;

        let itemValues: any = [];
        itemValues.push({
          name: isMobileOrTab ? (
            <Box flexDirection="row" style={styles.tableStyle}>
              <CCText fontSize={FontSizes[15]} fontWeight={600}>
                Order ID:
              </CCText>
              <CCText fontSize={FontSizes[15]}> {order_id}</CCText>
            </Box>
          ) : (
            <Box style={styles.tableStyle}>
              <Box type="row-flex-start" style={styles.rowFlex}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {order_id}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box flexDirection="row" style={styles.tableStyle}>
              <CCText fontSize={FontSizes[15]} fontWeight={600}>
                Player ID:
              </CCText>
              <CCText fontSize={FontSizes[15]}> {player_id}</CCText>
            </Box>
          ) : (
            <Box style={styles.tableStyle}>
              <Box type="row-flex-start" style={styles.rowFlex}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {player_id}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box flexDirection="row" style={styles.tableStyle}>
              <CCText fontSize={FontSizes[15]} fontWeight={600}>
                Mentor:
              </CCText>
              <CCText fontSize={FontSizes[15]}> {mentor}</CCText>
            </Box>
          ) : (
            <Box style={styles.tableStyle}>
              <Box type="row-flex-start" style={styles.rowFlex}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {mentor}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box flexDirection="column" style={styles.tableStyle}>
              <Box flexDirection="row">
                <CCText fontSize={FontSizes[15]} fontWeight={600}>
                  Batch:{' '}
                </CCText>
                <CCText fontSize={FontSizes[15]}>{batch_details}</CCText>
              </Box>
              {class_details !== '' && (
                <Box flexDirection="row">
                  <CCText fontSize={FontSizes[15]} fontWeight={600}>
                    Class:{' '}
                  </CCText>
                  <CCText fontSize={FontSizes[15]}> {class_details}</CCText>
                </Box>
              )}
            </Box>
          ) : (
            <Box style={styles.tableStyle}>
              <Box type="column-flex-start" style={styles.rowFlex}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  Batch: {batch_details}
                </CCText>
                {class_details !== '' ? (
                  <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                    Class: {class_details}
                  </CCText>
                ) : (
                  <Spacer spacing={12} />
                )}
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box flexDirection="row" style={styles.tableStyle}>
              <CCText fontSize={FontSizes[15]} fontWeight={600}>
                Plan status:{' '}
              </CCText>
              <CCText fontSize={FontSizes[15]}> {status}</CCText>
            </Box>
          ) : (
            <Box style={styles.tableStyle}>
              <Box type="row-flex-start" style={styles.rowFlex}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {status}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box flexDirection="row" style={styles.tableStyle}>
              <CCText fontSize={FontSizes[15]} fontWeight={600}>
                Start date:{' '}
              </CCText>
              <CCText fontSize={FontSizes[15]}>{start_date}</CCText>
            </Box>
          ) : (
            <Box style={styles.tableStyle}>
              <Box type="row-flex-start" style={styles.rowFlex}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {start_date}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box flexDirection="row" style={styles.tableStyle}>
              <CCText fontSize={FontSizes[15]} fontWeight={600}>
                End date:{' '}
              </CCText>
              <CCText fontSize={FontSizes[15]}>{end_date}</CCText>
            </Box>
          ) : (
            <Box style={styles.tableStyle}>
              <Box type="row-flex-start" style={styles.rowFlex}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {end_date}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });
        itemValues.push({
          name: isMobileOrTab ? (
            <Box flexDirection="row" style={styles.tableStyle}>
              <CCText fontSize={FontSizes[15]} fontWeight={600}>
                Date created:{' '}
              </CCText>
              <CCText fontSize={FontSizes[15]}>{date_created}</CCText>
            </Box>
          ) : (
            <Box style={styles.tableStyle}>
              <Box type="row-flex-start" style={styles.rowFlex}>
                <CCText color={CCColors.Grey.Grey} fontSize={FontSizes[13]}>
                  {date_created}
                </CCText>
              </Box>
            </Box>
          ),
          type: 'text',
        });

        return itemValues;
      });
      setTableData(formattedData || []);
    }
  }, [subscriptionDetails, currentPage, isMobileOrTab]);
  return (
    <ScrollView style={{padding: 20}}>
      <Spacer spacing={vs(16)} />
      <Box
        type={isMobileOrTab ? 'center' : 'row-center-between'}
        style={{
          marginLeft: isMobileOrTab ? 0 : 30,
        }}>
        <CCText fontSize={24} color={CCColors.Primary.Dark}>
          Subscription Details
        </CCText>
        {isMobileOrTab && <Spacer spacing={10} />}
      </Box>
      <Spacer spacing={vs(20)} />
      <Box
        style={{
          backgroundColor: isMobileOrTab ? CCColors.White : '',
          borderRadius: isMobileOrTab ? 8 : 0,
        }}>
        <Box>
          <CCPackageTable
            flexArr={[2, 1.2, 1.2, 2, 1.2, 1.2, 1.2, 1.2]}
            headers={tabHeaders}
            tableData={tableData}
            isMargin={false}
          />
        </Box>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
        />
      </Box>
    </ScrollView>
  );
};

export default SubscriptionTab;
