import React, {ReactNode} from 'react';
import {Pressable, StyleProp, View, ViewStyle} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Tooltip from '../Tooltip';
import CCColors from '@/Utils/CCColors';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

export function InfoIcon({text, style, size = 20, color}) {
  const {isMobileOrTab} = useMediaQuery();
  const handlePress = (toggle: () => void) => {
    toggle(true);
    setTimeout(() => {
      toggle(false);
    }, 3000);
  };

  return (
    <Tooltip
      text={text}
      children={toggle => {
        return (
          <Pressable
            onPress={() => handlePress(toggle)}
            onHoverIn={isMobileOrTab ? null : toggle}
            onHoverOut={isMobileOrTab ? null : toggle}>
            <FeatherIcon
              style={style}
              name="info"
              size={size}
              color={color || CCColors.TextColor.Grey}
            />
          </Pressable>
        );
      }}
    />
  );
}

export default InfoIcon;
interface CustomInfoBoxProps {
  text: string;
  customView: ReactNode;
  style?: StyleProp<ViewStyle>;
}

export function CustomInfoBox({text, customView, style}: CustomInfoBoxProps) {
  const {isMobileOrTab} = useMediaQuery();
  const handlePress = (toggle: () => void) => {
    toggle(true);
    setTimeout(() => {
      toggle(false);
    }, 3000);
  };
  return (
    <Tooltip
      text={text}
      children={toggle => {
        return (
          <Pressable
            onPress={() => handlePress(toggle)}
            onHoverIn={isMobileOrTab ? null : toggle}
            onHoverOut={isMobileOrTab ? null : toggle}>
            <View>{customView}</View>
          </Pressable>
        );
      }}
    />
  );
}
export function CustomInfoHoverBox({
  text,
  customView,
  style,
}: CustomInfoBoxProps) {
  return (
    <Tooltip
      text={text}
      children={toggle => {
        return (
          <Pressable onHoverIn={toggle} onHoverOut={toggle}>
            <View>{customView}</View>
          </Pressable>
        );
      }}
    />
  );
}
