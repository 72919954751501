import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {FontSizes} from '@/Utils/Dimensions';
import React, {useState} from 'react';
import {Pressable, View} from 'react-native';
import LottieView from 'react-native-web-lottie';
import {styles} from './styles';

function GamePlayStripComponent({
  hoveredItemForGameplayCard,
  itemDetails,
  navigation,
}) {
  const [moveHover, setMoveHover] = useState(false);
  const {isMobileOrTab} = useMediaQuery();
  const handleHoverIn = () => {
    setMoveHover(true);
  };
  const handleHoverOut = () => {
    setMoveHover(false);
  };
  return (
    <Pressable
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      // onHoverIn={() => setHoveredItemForGameplayCard(i?.id)}
      // onHoverOut={() => setHoveredItemForGameplayCard(0)}
      disabled={itemDetails?.disabled}
      onPress={() => {
        if (itemDetails?.screenName) {
          navigation.navigate(
            itemDetails?.screenName,
            itemDetails?.screenProps,
          );
        }
      }}
      style={[
        styles.trainerBox,
        // moveHover && styles.hoverCard,
        {
          paddingVertical: isMobileOrTab ? 15 : 8,
          // flex: 0.2,
          // flexDirection: 'row',
          // justifyContent: 'center',
          opacity: itemDetails?.disabled
            ? 0.5
            : hoveredItemForGameplayCard === itemDetails?.id
            ? 0.6
            : 1,
        },
      ]}>
      {!isMobileOrTab ? (
        <>
          <Box
            type="center"
            style={{
              backgroundColor: CCColors.Primary.lightYellow,
              margin: 'auto',
              height: 'fit-content',
              borderRadius: 4,
            }}>
            <View
              style={{
                // height: 40,
                // width: 40,
                margin: 8,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {itemDetails?.icon ? (
                itemDetails?.icon?.(moveHover)
              ) : (
                <LottieView
                  source={require('../../../../Assets/Animation/Bullet.json')}
                  isPlaying={hoveredItemForGameplayCard === itemDetails?.id}
                  // autoPlay
                  height={55}
                  width={55}
                  autoPlay={false}
                  loop={true}
                  style={{
                    height: 55,
                    width: 55,
                  }}
                  // loop={hoveredItemForGameplayCard === itemDetails?.id}
                  // hover
                />
              )}
            </View>
          </Box>
          <Spacer spacing={15} />
          <View
            style={{
              margin: isMobileOrTab ? 5 : 'auto',
              height: 'fit-content',
            }}>
            <CCText
              textAlign="center"
              fontSize={FontSizes[16]}
              fontWeight="bold">
              {itemDetails?.header}
            </CCText>
            <Spacer spacing={5} />
            <CCText
              textAlign="center"
              fontSize={FontSizes[14]}
              color={updateColorWithOpacity(CCColors.TextColor.Primary, 0.8)}>
              {itemDetails?.desc}
            </CCText>
          </View>
        </>
      ) : (
        <Box
          flexDirection="row"
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Box
            // type="center"

            style={{
              backgroundColor: CCColors.Primary.lightYellow,
              marginHorizontal: 20,

              height: 'fit-content',
              borderRadius: 4,
            }}>
            <View
              style={{
                // height: 40,
                // width: 40,
                margin: 8,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {itemDetails?.icon ? (
                itemDetails?.icon?.(moveHover)
              ) : (
                <LottieView
                  source={require('../../../../Assets/Animation/Bullet.json')}
                  isPlaying={hoveredItemForGameplayCard === itemDetails?.id}
                  // autoPlay
                  height={55}
                  width={55}
                  autoPlay={false}
                  loop={true}
                  style={{
                    height: 55,
                    width: 55,
                  }}
                  // loop={hoveredItemForGameplayCard === itemDetails?.id}
                  // hover
                />
              )}
            </View>
          </Box>
          <Box
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start">
            <CCText
              textAlign="center"
              fontSize={FontSizes[16]}
              fontWeight="bold">
              {itemDetails?.header}
            </CCText>
            <Spacer spacing={5} />
            <CCText
              textAlign="center"
              fontSize={FontSizes[14]}
              color={updateColorWithOpacity(CCColors.TextColor.Primary, 0.8)}>
              {itemDetails?.desc}
            </CCText>
          </Box>
        </Box>
      )}
    </Pressable>
  );
}
export default GamePlayStripComponent;
