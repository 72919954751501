import ChessRestClient from '@/Api/ChessRestClient';
import {useMutation} from '@tanstack/react-query';
import {autoCompletionResponse} from './types';

export default function useGetAutoCompletion(
  handleOnSuccess?: (data) => void,
  handleOnError?: (data) => void,
) {
  const mutation = useMutation<autoCompletionResponse>({
    mutationFn: async ({
      text,
      color,
      white_player_name,
      black_player_name,
      opening_name,
    }) => {
      const params = new URLSearchParams();
      if (text) {
        params.append(`${color}_player_name`, text);
      }
      if (color) {
        params.append('color', color);
      }
      if (opening_name) {
        params.append('opening_name', opening_name);
      }

      if (white_player_name) {
        params.append('white_player_name', white_player_name);
      }
      if (black_player_name) {
        params.append('black_player_name', black_player_name);
      }

      const response = await ChessRestClient.get(
        `/game/autocomplete/player?${params.toString()}`,
      );

      return response.data;
    },
    onSuccess: data => {
      if (handleOnSuccess) handleOnSuccess(data);
    },
    onError: error => {
      if (handleOnError) handleOnError(error);
    },
  });

  return mutation;
}
