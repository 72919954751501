import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import Tooltip from '@/Atoms/Tooltip';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCColors from '@/Utils/CCColors';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Pressable, View, Text, Image, Linking, Animated} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import styles from '../../styles';
import {useUserPlanStore} from '@/Store/userPlanStore';

interface Props {
  displayLimit?: number;
  headers?: string[];
  data?: string[];
  tier?: string;
}

const CompareTableMobile: React.FC<Props> = ({
  displayLimit,
  headers,
  data,
  tier,
}) => {
  const {isMobileOrTab} = useMediaQuery();
  const userPlanDetails = useUserPlanStore();
  const planTaken = useMemo(
    () => ({
      name: userPlanDetails?.planDetails?.plan_details?.fields?.display_name,
      tenure: userPlanDetails?.planDetails?.plan_details?.fields?.tenure,
      subscriptionId: userPlanDetails?.planDetails?.plan_details?.pk,
      planEndDate:
        userPlanDetails?.planDetails?.user_subscription_details?.fields
          ?.end_date,
    }),
    [userPlanDetails],
  );
  const customHeader = ['Novice', 'Aspiring Learners', 'Aspiring Champions'];
  const headerActiveTabIndex = customHeader?.indexOf(planTaken?.name);

  const [expandedRows, setExpandedRows] = useState({});
  const handlePress = (toggle: () => void) => {
    toggle(true);
    setTimeout(() => {
      toggle(false);
    }, 2000);
  };

  const toggleRow = index => {
    setExpandedRows(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const TooltipTextComponent = ({
    rowDetails,
    isMobileOrTab,
    handlePress,
    index,
  }) => {
    if (rowDetails.type !== 'text') {
      return null;
    }

    const getTooltipText = title => {
      const tooltipMapping = {
        'Games Assessment': 'Detailed Report Card of your Chess IQ',
        'Play against Caissa - Human like':
          'Practice any game/position against different ratings of our humanlike AI',
        'Parental Control':
          'Specific controls for parents to improve child discipline and behaviour',
        'Game Review': 'Propeitory game review to learn from your games',
        Gameplay: 'Privacy first, next level gameplay',
        'Square trainer':
          'Practice how well you remember your chess square notations',
        Resources: 'World Class Library of Chess courses',
        'Calculation trainer': 'Blindfold trainer to calculate moves ahead',
        Workbook: 'Your scratchpad to store ideas and variations',
        'Intuition trainer':
          'Replay grandmaster games and try to guess their moves',
        'Play Curated positions':
          'Practice from custom positions in opening/middlegame/endgame',
        'Positions Trainer':
          'Train on positions from your games with extensive filters',
        'Whatsapp Assistant':
          'Handy tool to get chess updates and parental controls',
        'Onboarding Assessment':
          'Your detailed Chess IQ report when you signup',
        'OTB game sync': 'Sync all your OTB (Offline) games and review them',
        'Custom Rewards': 'Parents can set custom rewards for their child',
      };

      return tooltipMapping[title] || '';
    };

    const tooltipText = getTooltipText(rowDetails.title);

    return (
      <Box
        flexDirection="row"
        gap={10}
        justifyContent="space-between"
        alignItems="center"
        style={styles.containerMobile}>
        <Box flexDirection="row" justifyContent="flex-start">
          <Box
            style={styles.tooltip}
            flexDirection="row"
            justifyContent="center"
            alignItems="center">
            <Tooltip
              text={tooltipText}
              children={toggle => (
                <Pressable
                  onPress={() => handlePress(toggle)}
                  onHoverIn={isMobileOrTab ? null : toggle}
                  onHoverOut={isMobileOrTab ? null : toggle}>
                  <MaterialCommunityIcons
                    name="information-outline"
                    color={CCColors.TextColor.Grey}
                    size={25}
                  />
                </Pressable>
              )}
            />
          </Box>
          <Box justifyContent="center" alignItems="center">
            <CCText
              color={CCColors.TextColor.Dark}
              fontSize={18}
              fontWeight="medium"
              showEllipses>
              {rowDetails.title}
            </CCText>
          </Box>
        </Box>
        <Pressable onPress={() => toggleRow(index)} style={styles.icon}>
          <Ionicons
            name={expandedRows[index] ? 'chevron-up' : 'chevron-down'}
            size={20}
            color={CCColors.Grey.Grey}
          />
        </Pressable>
      </Box>
    );
  };

  return (
    <Box flexDirection="column">
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{width: '100%'}}>
        {Array.isArray(headers) &&
          headers.slice(1).map((header, index) => {
            return (
              <Box key={index} style={styles.headerMobile}>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  style={{height: '100%'}}>
                  <CCText textAlign="center" fontWeight={600} fontSize={16}>
                    {index === 0
                      ? 'Novice'
                      : index === 1
                      ? 'Aspiring Learners'
                      : 'Aspiring Champions'}
                  </CCText>
                  {index === 1 && (
                    <View style={styles.bestSellerWrapper}>
                      <Text style={styles.bestSellerText}>Best seller</Text>
                    </View>
                  )}
                  {(tier === planTaken?.tenure.toLowerCase() ||
                    planTaken?.name === 'Novice') &&
                  index == headerActiveTabIndex ? (
                    <View style={styles.activeWrapper}>
                      <CCText style={styles.activeText}>Active Plan</CCText>
                    </View>
                  ) : null}
                </Box>
              </Box>
            );
          })}
      </Box>

      {data?.map((item, index) => {
        return (
          <Box
            key={index}
            justifyContent="flex-start"
            alignItems="center"
            style={styles.shadow}>
            <TooltipTextComponent
              rowDetails={item}
              isMobileOrTab={isMobileOrTab}
              handlePress={handlePress}
              index={index}
            />
            {expandedRows[index] && (
              <Box
                flexDirection="row"
                alignItems="center"
                style={{
                  width: '100%',
                }}>
                <Box style={styles.Novice}>
                  <CCText numberOfLines={1} fontSize={14}>
                    {item.Novice}
                  </CCText>
                </Box>
                <Box style={styles.aspLearner}>
                  <CCText numberOfLines={1} fontSize={14}>
                    {item['Aspiring Learners']}
                  </CCText>
                </Box>
                <Box style={styles.aspChampion}>
                  <CCText numberOfLines={1} fontSize={14}>
                    {item['Aspiring Champions']}
                  </CCText>
                </Box>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default CompareTableMobile;
