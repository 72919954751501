import React from 'react';
import useLoggedInUserStore from '@/Store/loggedInUserstore';

const useThirdPartyReplace = () => {
  const isThirdPartyUser = useLoggedInUserStore(
    state => state.isThirdPartyUser,
  );

  const replaceString = text => {
    if (isThirdPartyUser && typeof text === 'string') {
      return text.replace(/CircleChess/gi, match => {
        return match[0] === 'C' ? 'Platform' : 'platform';
      });
    }
    return text;
  };

  const replaceInJSX = (node, index = 0) => {
    if (typeof node === 'string') {
      return replaceString(node);
    }

    if (React.isValidElement(node)) {
      const {children, ...restProps} = node.props;

      if (typeof children === 'function') {
        return node;
      }

      return React.cloneElement(node, {
        ...restProps,
        children: React.Children.map(children, replaceInJSX),
      });
    }

    if (Array.isArray(node)) {
      return node.map((child, idx) =>
        React.createElement(
          React.Fragment,
          {key: idx},
          replaceInJSX(child, idx),
        ),
      );
    }

    return node;
  };

  return replaceInJSX;
};

export default useThirdPartyReplace;
