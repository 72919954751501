import React from 'react';
import {View, StyleSheet} from 'react-native';
import CCAccordion from '@/Atoms/CCAccordion'; // Adjust the path as necessary
import CCText from '@/Atoms/CCText';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {FontSizes, isPhone, vs} from '@/Utils/Dimensions';
import CCColors from '@/Utils/CCColors';
import Box from '@/Atoms/Box';

const FAQData = [
  {
    id: 1,
    question: 'How can I upgrade my current plan to a Aspiring Champions plan?',
    answer: 'You can change your plan by clicking on upgrade button any time.',
  },
  {
    id: 2,
    question: 'What are the benefits of the premium plan?',
    answer:
      'The premium plan offers advanced features, including unlimited access to exclusive content, priority support, and more customization options.',
  },
  // {
  //   id: 3,
  //   question: 'How do I cancel my subscription?',
  //   answer:
  //     "To cancel your subscription, go to the account settings page, select 'Subscription', and click on 'Cancel Subscription'. Follow the instructions to complete the process.",
  // },
  // {
  //   id: 4,
  //   question: 'Is there a free trial available?',
  //   answer:
  //     'Yes, we offer a 14-day free trial for new users. You can sign up for the trial on our website and explore all the premium features without any charges.',
  // },
  {
    id: 5,
    question: 'Can I switch between plans at any time?',
    answer:
      'Yes, you can switch between plans at any time by clicking on the subscription icon in the side menu. The changes will take effect immediately.',
  },
];

const FAQSections = FAQData.map(faq => {
  return {
    title: faq.question,
    renderContent: () => {
      return (
        <CCText fontSize={14} textAlign="justify">
          {faq.answer}
        </CCText>
      );
    },
  };
});

const FAQScreen = () => {
  const {isMobileOrTab} = useMediaQuery();
  return (
    <Box
      flexDirection={isMobileOrTab ? 'column' : 'row'}
      style={[styles.container]}>
      <Box>
        <CCText
          fontSize={isMobileOrTab ? 20 : 24}
          fontWeight="medium"
          textAlign={'left'}
          style={{
            margin: !isMobileOrTab ? 40 : 20,
            marginLeft: !isMobileOrTab ? 0 : 5,
            marginBottom: isMobileOrTab && 0,
          }}>
          {isMobileOrTab ? ' FAQ’s' : 'Frequently Asked Questions'}
        </CCText>
      </Box>
      <Box style={{width: isMobileOrTab ? '100%' : '60%'}}>
        <CCAccordion
          customSections={styles.customSections}
          sections={FAQSections}
          fontSize={isMobileOrTab ? 14 : 16}
          fontWeight="500"
          color={CCColors.Primary.lightBrown}
          paddingVertical={isMobileOrTab ? 5 : 30}
        />
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: !isPhone ? 16 : 4,
    width: '100%',
    justifyContent: 'space-between',
  },
  customSections: {
    width: '100%',

    marginVertical: vs(5),
    borderBottomColor: CCColors.BorderBottom,
    borderBottomWidth: 1,
  },
});

export default FAQScreen;
