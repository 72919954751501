import CCColors, {updateColorWithOpacity} from '../../Utils/CCColors';
import {
  PHONE_BREAKPOINT,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
} from '../../Utils/Dimensions';

import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  footerContainer: {
    position: 'absolute',
    bottom: 0,
    // right: 0,
    // flex: 0.1,
    // left: 0,
    padding: 10,
  },

  container: {
    // flex: 1,
    // backgroundColor: 'transparent',
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // zIndex: 2,
    // overflowX: "hidden",
  },
  centerContainer: {
    flex: 1,
    // justifyContent: 'center', // Align items vertically to the center
    // alignItems: 'center', // Align items horizontally to the center
    // backgroundColor: updateColorWithOpacity(CCColors.Black, 0.7), // Assuming you want the overlay effect
    borderRadius: 12,
    // position: 'absolute',
    // top: '35%',

    // width: '60%',
    // left: '-10%',
    // right: 0,
    // bottom: 0,
    // zIndex: 2,
  },
  overlay: {
    flex: 1,
    backgroundColor: updateColorWithOpacity(CCColors.Black, 0.7),
    width: WINDOW_WIDTH,
    height: WINDOW_HEIGHT,
  },
  modal: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // width: WINDOW_WIDTH <= PHONE_BREAKPOINT ? WINDOW_WIDTH : WINDOW_WIDTH / 2,
    // height: '100%',
    // // width: '75%',
    // // height: '100%',
    // backgroundColor: '#fff',
    // zIndex: 3,
  },
  modalContainer: {
    borderRadius: 12,
    // flex: 1,
  },
  modalHeader: {
    paddingHorizontal: 30,
    paddingTop: 20,
  },
  sideModalContent: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    backgroundColor: 'white',
    zIndex: 5,
    overflowY: 'auto',
    flex: 1,
    width: WINDOW_WIDTH / 3,
  },
  sideModalContentMobile: {
    width: WINDOW_WIDTH,
  },
  modalContent: {
    borderRadius: 12,
    backgroundColor: 'white',
    zIndex: 5,
    // flex: 1,
    // maxHeight: "80%", // Set a maximum height
    overflowY: 'auto',

    margin: 'auto',

    // he,
  },
  modalContentMobile: {
    borderRadius: 12,
    backgroundColor: 'white',
    zIndex: 5,
    // flex: 1,
    // maxHeight: "80%", // Set a maximum height
    overflowY: 'auto',

    // he,
  },

  modalBody: {
    flex: 1,
    padding: 30,
  },
  modalBodyMobile: {
    padding: 10,
  },
  modalText: {
    fontSize: 16,
  },
  tabGroupsWrapper: {
    margin: 21,
  },
  tabGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: -30,
    marginVertical: 20,
  },
  icon: {
    borderRadius: '50%',
    backgroundColor: CCColors.Primary.lightBrown,
  },
});

export default styles;
