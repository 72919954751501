import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Text, Animated, StyleSheet, Pressable, Linking} from 'react-native';
import useGetPlanApi from '@/Hooks/SubscriptionApi/useGetPlan';
import useGeneratePaymentApi from '@/Hooks/SubscriptionApi/useGeneratePayment';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCLoader from '@/Atoms/CCLoader';
import {canSubscribe} from '../../helper';
import {Image} from 'react-native';
import {FontSizes, WINDOW_WIDTH} from '@/Utils/Dimensions';
import {MaterialIcons} from '@expo/vector-icons';

interface SubscriptionComponentProps {
  selected?: boolean;
  planId?: number | string;
  subscribedPlanId?: number | string;
  isActivePlan?: boolean;
  title?: string;
  tier?: string;
  user: any;
  userPlan?: string;
  index?: number;
}

const PaymentButton: React.FC<SubscriptionComponentProps> = ({
  user = {},

  planId: planIdProp,
  subscribedPlanId,

  isActivePlan = false,

  title,

  tier,
  index,
  userPlan,
}) => {
  const {isMobileOrTab} = useMediaQuery();

  const planTaken = useMemo(
    () => userPlan?.planDetails?.plan_details?.fields?.name,
    [userPlan],
  );

  let plan_type;
  let tenure;
  let type = title?.split(' ')[0];
  if (type?.toLowerCase() === 'free') {
    plan_type = 'basic';
  } else if (type === 'aspiringLearners') {
    plan_type = 'aspiringLearners';
  } else {
    plan_type = 'aspiringChampions';
  }

  tenure = tier?.toLowerCase();

  const handleOnSuccessPlan = data => {
    if (data?.data?.plan_id) {
      generatePayment({
        customer_id: user?.user_id,
        mobile_number: user?.mobile_number,
        email: user?.email,
        subscription_plan_id: data?.data?.plan_id,
      });
    }
  };

  const handleOnErrorPlan = error => {
    console.log('error while fetching plan', error);
  };

  const {
    mutate: getPlan,
    data,
    error,
    isLoading,
  } = useGetPlanApi(handleOnSuccessPlan, handleOnErrorPlan);

  const handleOnSuccessGeneratePayment = data => {
    console.log('successfully generated payment link', data);
  };

  const handleOnErrorGeneratePayment = error => {
    console.log('error while generating payment', error);
  };

  const {
    mutate: generatePayment,
    data: paymentUrlData,
    error: paymentUrlDataError,
    isLoading: paymentLoading,
  } = useGeneratePaymentApi(
    handleOnSuccessGeneratePayment,
    handleOnErrorGeneratePayment,
  );

  useEffect(() => {
    if (isMobileOrTab) {
      if (paymentUrlData?.data?.content?.payment_links?.web) {
        Linking.openURL(paymentUrlData?.data?.content?.payment_links?.web);
      }
    } else {
      if (paymentUrlData?.data?.content?.payment_links?.web) {
        // @ts-ignore
        window.location.href =
          paymentUrlData?.data?.content?.payment_links?.web;
      }
    }
  }, [paymentUrlData]);

  if (isLoading || paymentLoading) {
    return <CCLoader loading={isLoading || paymentLoading} />;
  }

  return (
    <Box
      flexDirection="row"
      style={{
        paddingVertical: isMobileOrTab && 20,
        paddingHorizontal: isMobileOrTab && index === 1 ? 12 : 0,
        borderRightColor: isMobileOrTab && index == 1 && CCColors.Border,
        borderRightWidth: isMobileOrTab && index == 1 && 1,
        borderLeftColor: isMobileOrTab && index == 1 && CCColors.Border,
        borderLeftWidth: isMobileOrTab && index == 1 && 1,
      }}>
      <CCButton
        onPress={() => {
          // store.setChoosenPlan(title?.toLowerCase());
          // store.setTenure(tenure);
          getPlan({
            plan_type:
              title?.toLowerCase() === 'aspiring learners' ? 'casual' : 'PRO',
            tenure: tenure,
          });
        }}
        disabled={
          tier === 'Free' ||
          canSubscribe(planIdProp, subscribedPlanId) ||
          isActivePlan
        }
        style={{
          flex: 1,
          paddingHorizontal: !isMobileOrTab && WINDOW_WIDTH < 1100 ? 5 : 10,
          width: !isMobileOrTab ? '90%' : '50%',
          borderRadius: !isMobileOrTab ? 32 : '50%',
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor:
            tier === 'Free' ||
            canSubscribe(planIdProp, subscribedPlanId) ||
            isActivePlan
              ? CCColors.LightYellow
              : undefined,
        }}>
        <MaterialIcons
          name="arrow-forward"
          size={30}
          color={CCColors.Primary.Dark}
        />
      </CCButton>
    </Box>
  );
};

export default PaymentButton;
