import CCColors from '../../Utils/CCColors';
import SPColors, {updateColorWithOpacity} from '../../Utils/CCColors';
import {hs, vs} from '../../Utils/Dimensions';

import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  chipStyleType1: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: hs(14),
    paddingRight: hs(16),
    marginRight: hs(10),
    marginBottom: hs(3),
    borderRadius: 8,
    paddingVertical: vs(12),
    // borderWidth: StyleSheet.hairlineWidth,
    // borderColor: updateColorWithOpacity(SPColors.Primary.Default, 0.4),
  },
  chipStyleTypeSelected2: {
    borderColor: SPColors.Primary.Brown,
    backgroundColor: SPColors.Primary.lightYellow,
    borderWidth: StyleSheet.hairlineWidth,
  },
  overStyle: {
    textDecorationLine: 'line-through',
  },
  overSelected: {
    backgroundColor: SPColors.Primary.lightYellow,
  },
  chipStyleType2: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: hs(14),
    paddingRight: hs(16),
    marginRight: hs(10),
    marginBottom: hs(3),
    borderRadius: 8,
    paddingVertical: vs(12),
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: CCColors.White,
    borderColor: SPColors.Grey.Border,

    // textDecorationLine: 'line-through',
  },

  errorTxt: {
    marginLeft: 2,
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkWrapper: {
    backgroundColor: CCColors.Primary.Default,
    justifyContent: 'center',
    // marginLeft: 10,
    alignItems: 'center',
    borderRadius: '50%',
    // padding: 9,
    width: 22,
    height: 22,
    // paddingVertical: 2,
    // paddingHorizontal: 3,
  },
});

export default styles;
