/* eslint-disable react-native/no-inline-styles */
import {
  Animated,
  Dimensions,
  Platform,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  FontSizes,
  PHONE_BREAKPOINT,
  WINDOW_WIDTH,
} from '../../Utils/Dimensions';
import React, {useMemo, useRef} from 'react';
import {SlideInModalProps, eachMenuOption, menuDataProp} from './types';

import Box from '../Box';
import CCButton from '../CCButton';
import CCText from '../CCText';
import CCTextInput from '../CCTextInput';
// import CCButton from "../CCButton";
// import AntDesign from "react-native-vector-icons/AntDesign";
// import Box from '../../../../../Atoms/Box';
// import Divider from '../../../../../Atoms/Divider/Divider';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Spacer from '../Spacer';
// import SPColors from '../../../../../Utils/SPColors';
// import SPText from '../../../../../Atoms/SPText';
import styles from './styles';
import {useEffect} from 'react';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Modal from 'react-native-modal';

const SlideInModalInternal: React.FC<SlideInModalProps> = ({
  changeModalWidth = false,
  visible,
  onClose,
  modalHeaderTitle,
  children,
  type,
  footerComponent,
  customStyle,
  customHeaderStyle,
  showCloseIcon = true,
  customMobileStyle,
  customMargin = 'auto',
  contentPadding = 30,
  minWidth,
  closeIconSize,
  dynamicHeight,
  dynamicWidth,
  width = 0.5, // width of model to show i.e 0.5 is 50% of window screen
}) => {
  const {isMobileOrTab} = useMediaQuery();
  // const slideAnim = useRef(new Animated.Value(WINDOW_WIDTH + 10)).current;
  const [isAnimating, setIsAnimating] = React.useState(visible);
  const isCentered = useMemo(() => type === 'center', [type]);
  const isSideModal = useMemo(() => type === 'side', [type]);

  // useEffect(() => {
  //   if (visible) {
  //     if (Platform.OS === 'web') {
  //       // @ts-ignore
  //       document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  //     }
  //     setIsAnimating(true);
  //     Animated.timing(slideAnim, {
  //       toValue:
  //         (WINDOW_WIDTH <= PHONE_BREAKPOINT ? WINDOW_WIDTH : WINDOW_WIDTH) *
  //         (1 - (isCentered ? 0.5 + width / 2 : width)),
  //       duration: 250,
  //       useNativeDriver: false,
  //     }).start(() => {});
  //   } else {
  //     if (Platform.OS === 'web') {
  //       // @ts-ignore
  //       document.body.style.overflow = ''; // Re-enable scrolling on the body
  //     }
  //     Animated.timing(slideAnim, {
  //       toValue: WINDOW_WIDTH + 10,
  //       duration: 250,
  //       useNativeDriver: false,
  //     }).start(() => setIsAnimating(false));
  //   }
  // }, [visible, isCentered]);

  return (
    <Modal
      {...(isSideModal
        ? {
            animationIn: 'slideInRight',
            animationOut: 'slideOutRight',
          }
        : {})}
      isVisible={visible}
      onBackdropPress={onClose}
      style={{
        width:
          changeModalWidth && !isMobileOrTab
            ? '50%'
            : changeModalWidth && isMobileOrTab
            ? '80%'
            : undefined,
        marginLeft:
          changeModalWidth && !isMobileOrTab
            ? 'auto'
            : changeModalWidth && isMobileOrTab
            ? 'auto'
            : undefined,
        marginRight:
          changeModalWidth && !isMobileOrTab
            ? 'auto'
            : changeModalWidth && isMobileOrTab
            ? 'auto'
            : undefined,
        margin: isSideModal ? 0 : customMargin,
      }}>
      {/* <View style={styles.container}> */}
      {/* <Animated.View
          style={[
            isCentered ? styles.centerContainer : styles.container,
            // {transform: [{translateX: slideAnim}]},
          ]}>
          <Animated.View
            style={[
              isCentered ? styles.centerContainer : styles.modal,
              {
                width:
                  (WINDOW_WIDTH <= PHONE_BREAKPOINT ? 0 : WINDOW_WIDTH) * width,
              },
              customStyle,
            ]}> */}

      <View
        style={[
          isSideModal
            ? styles.sideModalContent
            : isMobileOrTab
            ? [styles.modalContentMobile, customMobileStyle]
            : [styles.modalContent, {minWidth: minWidth || WINDOW_WIDTH / 2}],
          customStyle,

          isMobileOrTab && styles.sideModalContentMobile,
        ]}>
        <Box
          style={[styles?.modalHeader, customHeaderStyle]}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center">
          <CCText
            fontSize={FontSizes[type === 'center' ? 14 : 20]}
            fontWeight="bold">
            {modalHeaderTitle}
          </CCText>
          {onClose && showCloseIcon && (
            <TouchableOpacity onPress={onClose}>
              <Box
                type="center"
                style={{
                  ...styles.icon,
                  width: dynamicWidth || 36,
                  height: dynamicHeight || 36,
                }}>
                <Ionicons
                  name="close-outline"
                  size={closeIconSize || 24}
                  color="black"
                />
              </Box>
            </TouchableOpacity>
          )}
        </Box>
        <View
          style={[
            styles?.modalBody,
            {padding: isSideModal ? 0 : isMobileOrTab ? 10 : contentPadding},
            isMobileOrTab && styles.modalBodyMobile,
          ]}>
          {children}
          {footerComponent && (
            <View style={styles.footerContainer}> {footerComponent}</View>
          )}
        </View>
      </View>

      {/* </Animated.View> */}
      {/* </Animated.View> */}
      {/* </View> */}
    </Modal>
  );
};

const SlideModal = SlideInModalInternal;

export default SlideModal;
