import CCColors from '../../Utils/CCColors';

import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  buttonGirlIcon: {
    // position: 'absolute',
    // right: 5,
    // width: '35%',
    // bottom: '-3%',
    // zIndex: 20,
    // height: '140%',
    width: 50,
    height: 50,
    backgroundColor: CCColors.Primary.Default,
    borderRadius: 25,
  },
  chabotBg: {
    borderRadius: 50,
    padding: 10,
    backgroundColor: CCColors.Red,
    position: 'absolute',
    bottom: 15,
    right: 15,
    flexDirection: 'row',

    alignItems: 'center',
  },
  chabotBgMobile: {
    borderRadius: 50,
    padding: 5,
    backgroundColor: CCColors.Red,
    position: 'absolute',
    bottom: 15,
    right: 15,
    flexDirection: 'row',

    alignItems: 'center',
  },
});

export default styles;
