import {
  Animated,
  LayoutChangeEvent,
  LayoutRectangle,
  NativeSyntheticEvent,
  Pressable,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  View,
} from 'react-native';
import CCColors, {updateColorWithOpacity} from '../../Utils/CCColors';
import {FontSizes, vs} from '../../Utils/Dimensions';
import React, {useEffect, useMemo, useRef, useState} from 'react';

import Box from '../Box';
import CCText from '../CCText';
import {CCTextInputProps} from './types';
import ExclamationIcon from '../../Assets/Images/Svgs/ExclamationIcon';
import Styles from './styles';
import fontWeightToFamily from '../../Assets/Fonts/fontWeightToFamily';

export const DEFAULT_HEIGHT = vs(50);
const DEFAULT_PADDING_H = 20;
const DEFAULT_TEXT_COLOR = '#303030';
const DEFAULT_OUTLINE_COLOR = updateColorWithOpacity('#7D7D7D', 0.2);
const DEFAULT_OUTLINE_ACTIVE_COLOR = CCColors.Primary.Default;
const DEFAULT_LABEL_COLOR = '#7D7D7D';
const DEFAULT_LABEL_ACTIVE_COLOR = CCColors.Primary.Default;
const DEFAULT_LABEL_BG_COLOR = 'Transparent';

const CCTextInput = ({
  defaultValue = '',
  fontSize = FontSizes[15],
  fontWeight,
  label,
  borderStyle = 'BOX',
  labelActiveColor = DEFAULT_LABEL_ACTIVE_COLOR,
  labelBgColor = DEFAULT_LABEL_BG_COLOR,
  labelColor = DEFAULT_LABEL_COLOR,
  labelScaleDown = 0.8,
  onChangeText,
  outlineActiveColor = DEFAULT_OUTLINE_ACTIVE_COLOR,
  outlineColor = DEFAULT_OUTLINE_COLOR,
  style,
  textColor = DEFAULT_TEXT_COLOR,
  value,
  onFocus,
  inputType = 'text',
  onBlur,
  isRequired,
  staticPostfix,
  staticPrefix,
  error,
  icon,
  iconStyle = {width: 20, height: 20},
  placeholder,
  ...rest
}: CCTextInputProps) => {
  const isControlled = value !== undefined ? true : false;
  const inputBoxBorderLine = borderStyle === 'LINE';
  const [uncontrolledValue, setUncontrolledValue] = useState<
    string | undefined
  >(isControlled ? value : defaultValue);

  const {height = DEFAULT_HEIGHT, paddingHorizontal = DEFAULT_PADDING_H} =
    StyleSheet.flatten(style) ?? {};
  const inputRef = useRef();

  const validInputValue = useMemo(
    () => (isControlled ? value : uncontrolledValue),
    [isControlled, value, uncontrolledValue],
  );

  useEffect(() => {
    if (value) {
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }).start();
    }
  }, [value]);

  const animatedValue = useState(
    new Animated.Value(validInputValue ? 1 : 0),
  )[0];
  const [prefixWidth, setPrefixWidth] = useState(0);

  const [isFocused, setIsFocused] = useState(false);

  const [labelRect, setLabelRect] = useState<LayoutRectangle>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const handleLayout = (e: LayoutChangeEvent) => {
    setLabelRect(e.nativeEvent.layout);
  };

  const labelOffsetY = -(height / 2);

  const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    onFocus?.(e);

    setIsFocused(true);

    Animated.timing(animatedValue, {
      toValue: 1,
      duration: 100,
      useNativeDriver: true,
    }).start();
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    onBlur?.(e);

    setIsFocused(false);

    if (validInputValue) {
      return;
    }

    Animated.timing(animatedValue, {
      toValue: 0,
      duration: 100,
      useNativeDriver: true,
    }).start();
  };

  const errorColor = CCColors?.Error;

  const handleChangeText = (_value: string) => {
    let formattedValue = _value;

    if (inputType === 'date') {
      // Remove any non-numeric characters
      formattedValue = formattedValue.replace(/[^0-9]/g, '');

      if (formattedValue.length >= 2 && formattedValue.length <= 3) {
        formattedValue =
          formattedValue.slice(0, 2) + '-' + formattedValue.slice(2);
      } else if (formattedValue.length >= 4 && formattedValue.length <= 5) {
        formattedValue =
          formattedValue.slice(0, 2) +
          '-' +
          formattedValue.slice(2, 4) +
          '-' +
          formattedValue.slice(4);
      } else if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 2) +
          '-' +
          formattedValue.slice(2, 4) +
          '-' +
          formattedValue.slice(4, 8);
      }

      // Limit to 10 characters (dd/mm/yyyy)
      formattedValue = formattedValue.slice(0, 10);
    }

    if (inputType === 'phone' || inputType === 'otp') {
      // Remove any non-numeric characters
      formattedValue = formattedValue.replace(/[^0-9]/g, '');

      // remove leading 0 for phone
      if (inputType === 'phone' && formattedValue.startsWith('0')) {
        formattedValue = formattedValue.slice(1);
      }

      // Limit to 10 characters for phone and 4 for otp
      formattedValue = formattedValue.slice(0, inputType === 'phone' ? 10 : 4);
    }

    if (!isControlled) {
      setUncontrolledValue(formattedValue);
    }

    onChangeText?.(formattedValue);
  };

  var keyboardType = 'default';
  if (inputType === 'date' || inputType === 'phone' || inputType === 'otp') {
    keyboardType = 'numeric';
  }

  return (
    <>
      <View
        style={[
          Styles.container,
          style,
          {
            height,
          },
          {...(rest?.disabled && {opacity: 0.6})},
          {
            ...(inputBoxBorderLine
              ? {
                  borderRadius: 0,
                  borderTopColor: '#fff',
                  borderLeftColor: '#fff',
                  borderRightColor: '#fff',
                  borderBottomColor: isFocused
                    ? outlineActiveColor
                    : error?.flag
                    ? errorColor
                    : outlineColor,
                }
              : {
                  borderColor: isFocused
                    ? outlineActiveColor
                    : error?.flag
                    ? errorColor
                    : outlineColor,
                }),
          },
        ]}>
        {icon && (
          <img
            src={icon}
            style={{position: 'relative', left: 10, ...iconStyle}}
            alt="icon"
          />
        )}
        {staticPrefix && (
          <CCText
            color={CCColors?.TextColor?.Primary}
            onLayout={event => {
              setPrefixWidth(event.nativeEvent.layout.width);
            }}
            style={{
              position: 'absolute',
              left: 10,
              top: '25%',
              backgroundColor: '#fff',
            }}>
            {staticPrefix}
          </CCText>
        )}

        <Animated.View
          onLayout={handleLayout}
          style={[
            Styles.labelContainer,
            {backgroundColor: labelBgColor},
            {...(staticPrefix ? {paddingLeft: vs(prefixWidth + 15)} : {})},
            {
              transform: [
                {
                  translateY: animatedValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, labelOffsetY],
                  }),
                },
                {
                  translateX: animatedValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [
                      0,
                      -(labelRect.width * ((1 - labelScaleDown) / 2)) +
                        (!!staticPrefix ? 0 : 5),
                    ],
                  }),
                },
                {
                  scale: animatedValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, labelScaleDown],
                  }),
                },
              ],
            },
          ]}>
          <CCText
            style={{
              fontSize,
              backgroundColor: CCColors?.Background,
              paddingHorizontal: 10,
            }}
            color={
              isFocused
                ? labelActiveColor
                : error?.flag
                ? errorColor
                : labelColor
            }>
            {label && (
              <Pressable
                // @ts-ignore
                onPress={() => inputRef?.current?.focus?.()}
                onFocus={() => inputRef?.current?.focus?.()}
                pressRetentionOffset={Infinity}>
                <CCText style={{opacity: 0.5}}>{label}</CCText>
              </Pressable>
            )}
            {isRequired && (
              <View>
                <CCText
                  color={
                    isFocused
                      ? labelActiveColor
                      : error?.flag
                      ? errorColor
                      : labelColor
                  }
                  style={Styles.superscript}>
                  {' '}
                  *
                </CCText>
              </View>
            )}
          </CCText>
        </Animated.View>
        {typeof staticPostfix === 'string' ? (
          <CCText
            color={CCColors?.TextColor?.Primary}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              position: 'absolute',
              right: 10,
              top: '25%',
              backgroundColor: '#fff',
            }}>
            {staticPostfix}
          </CCText>
        ) : staticPostfix ? (
          staticPostfix
        ) : null}
        {/* {staticPostfix && (
          <CCText
            color={CCColors?.TextColor?.Primary}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              position: 'absolute',
              right: 10,
              top: '25%',
              backgroundColor: '#fff',
            }}>
            {staticPostfix}
          </CCText>
        )} */}

        <TextInput
          // @ts-ignore
          placeholderTextColor={CCColors.Grey.Shade100}
          ref={inputRef}
          value={validInputValue}
          onChangeText={handleChangeText}
          keyboardType={keyboardType}
          onFocus={handleFocus}
          onBlur={handleBlur}
          cursorColor={CCColors.Primary.Default}
          style={[
            Styles.input,
            {
              color: textColor,
              fontSize,
              outline: 'none',
              fontFamily: fontWeightToFamily(fontWeight),
              paddingHorizontal,
              borderColor: '#fff',
            },
            {...(staticPrefix ? {paddingLeft: vs(prefixWidth + 25)} : {})},
          ]}
          placeholder={placeholder}
          {...rest}
        />
      </View>
      {error?.flag && (
        <Box style={Styles?.errorTxt}>
          <ExclamationIcon color={'#D64E59'} width={13} />
          <CCText color={errorColor} fontSize={11} style={{marginLeft: 6}}>
            {error?.msg}
          </CCText>
        </Box>
      )}
    </>
  );
};

export default CCTextInput;
