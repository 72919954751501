import CCColors from '../../../../Utils/CCColors';
import {StyleSheet} from 'react-native';
import {vs} from '@/Utils/Dimensions';

const styles = StyleSheet.create({
  tableStyle: {
    padding: vs(12),
    width: '100%',
    height: '100%',
  },
  rowFlex: {
    margin: 'auto',
    width: '100%',
    flexWrap: 'wrap',
  },
});
export default styles;
