import * as React from 'react';
import {
  Text,
  View,
  StyleSheet,
  Pressable,
  Image,
  ScrollView,
  Linking,
} from 'react-native';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import Entypo from 'react-native-vector-icons/Entypo';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import CCButton from '@/Atoms/CCButton';
import SubscriptionComponent from './components/SubscriptionComponent';
import {useEffect, useMemo, useRef, useState} from 'react';
import CCTable from '@/Atoms/CCTable';
import {plansData} from './components/ComparePlans/plansData';
import AntDesign from 'react-native-vector-icons/AntDesign';
import ConditionalRender from '@/Atoms/ConditionalRender';
import FAQ from './components/FAQs';
import Tooltip from '@/Atoms/Tooltip';
import SubscriptionModal from './components/Modals';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import casualPlayer from '@/Assets/Images/png/casual_player.png';
import {
  freePlayerList,
  casualPlayerList,
  tournamentPlayerList,
} from './FeaturesList';
import useGetPlanApi from '@/Hooks/SubscriptionApi/useGetPlan';
import useGeneratePaymentApi from '@/Hooks/SubscriptionApi/useGeneratePayment';
import useMakePaymentApi from '@/Hooks/SubscriptionApi/useMakePayment';
import {useRoute, useNavigation} from '@react-navigation/native';
import {useUserPlanStore} from '@/Store/userPlanStore';

import styles from './styles';
import CCLoader from '@/Atoms/CCLoader';
import useGetAllPlansApi from '@/Hooks/SubscriptionApi/useGetAllPlansApi';
import Spacer from '@/Atoms/Spacer';
import {formatCurrency, formatDate} from '@/Utils/CommonUtils';
import {FontSizes, WINDOW_WIDTH} from '@/Utils/Dimensions';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import CompareTableMobile from './components/ComparePlansMobile';
import PaymentButton from './components/ComparePlansMobile/PaymentButton';
import {canSubscribe} from './helper';
// import PaymentStatus from '../Payments';

interface componentNameProps {
  dashboardData: {};
}

const Subscription: React.FC<componentNameProps> = ({dashboardData}) => {
  const userPlanDetails = useUserPlanStore();
  const [selectedSubscription, setSelectedSubscription] = useState('Novice');
  const [tier, setTier] = useState('yearly');
  const [columnSelected, setColumnSelected] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [casualMoney, setCasualMoney] = useState(2999);
  const [tournamentMoney, setTournamentMoney] = useState(4999);
  // const [showPaymentStatus, setShowPaymentStatus] = useState<boolean>(false);
  const [activePlan, setActivePlan] = useState(
    userPlanDetails.activePlan || 'novice',
  );
  const [planType, setPlanType] = useState<string>('');
  const route = useRoute();
  const [apiPlanData, setApiPlanData] = useState([]);
  const [tableDataForPlanDetails, setTableDataForPlanDetails] = useState({});
  const [uniqueByPlanNames, setUniqueByPlanNames] = useState([]);
  const {isMobileOrTab} = useMediaQuery();
  const planTaken = useMemo(
    () => ({
      name: userPlanDetails?.planDetails?.plan_details?.fields?.display_name,
      tenure: userPlanDetails?.planDetails?.plan_details?.fields?.tenure,
      subscriptionId: userPlanDetails?.planDetails?.plan_details?.pk,
      planEndDate:
        userPlanDetails?.planDetails?.user_subscription_details?.fields
          ?.end_date,
    }),
    [userPlanDetails],
  );
  const userPlan = userPlanDetails;
  const upgradePlan = useMemo(
    () => userPlan?.planDetails?.plan_details?.fields?.name,
    [userPlan],
  );
  const scrollViewRef = useRef(null);
  const ccTableRef = useRef(null);

  // useEffect(() => {
  //   const queryParams = route.params;
  //   if (queryParams && queryParams?.status) {
  //     setShowPaymentStatus(true);
  //   }
  // }, [route.params]);

  function transformPlanDetailsData(plans) {
    const featureMap = [];
    const allFeaturesArry = [];
    const planTitles = [];
    // Iterate through each plan
    plans?.forEach(plan => {
      const planTitle = {
        key: plan?.title,
        render: (
          <Box
            // onPress={() => setColumnSelected(planTitle)}
            flexDirection="row"
            // gap={10}
            justifyContent="center"
            alignItems="center"
            style={{
              width: '100%',
              paddingVertical: 30,
              // paddingRight: 20,
            }}>
            {/* <Spacer spacing={32} /> */}
            <CCText
              color={CCColors.TextColor.Primary}
              fontSize={18}
              fontWeight="bold"
              showEllipses>
              {plan?.title}
            </CCText>
          </Box>
        ),
      };

      planTitles?.push(planTitle);

      // Iterate through each feature in the plan
      plan.features.forEach((feature, index) => {
        // debugger;
        const featureTitle = feature.name;
        // if (!featureMap[featureTitle]) {
        featureMap[index] = {
          ...(featureMap[index] || {}),
          type: feature?.type || 'text',
          title: featureTitle,
        };
        // }
        let tempCellVal = feature?.value;
        if (feature?.value?.toLowerCase() === 'check') {
          tempCellVal = (
            <Box style={styles?.checkStyle}>
              <AntDesign name="check" color={CCColors.Green} size={20} />
            </Box>
          );
        } else if (feature?.value?.toLowerCase() === 'cross') {
          tempCellVal = (
            <Box style={styles.crossStyle}>
              <AntDesign name="close" color={CCColors.Red} size={20} />
            </Box>
          );
        }
        // plan?.title
        featureMap[index] = {
          ...(featureMap[index] || {}),
          type: feature?.type || 'text',
          [plan?.title]: tempCellVal,
          // type: feature?.type || 'text',
          // title: featureTitle,
        };

        // featureMap[featureTitle] = {
        // type: feature?.type || 'text',
        // [plan?.title]: tempCellVal,
        // };
        // featureMap[featureTitle][planTitle] = tempCellVal;
        // featureMap[featureTitle][`is${planTitle}`] = true;
      });
      // allFeaturesArry?.[]
    });
    // debugger;
    return {
      data: Object.values(featureMap),
      headers: planTitles,
    };
  }

  const tierRef = useRef(tier);
  useEffect(() => {
    tierRef.current = tier;
    getAllPlans();
  }, [tier]);
  const subscribedPlanId = planTaken?.subscriptionId;

  const handlePress = (toggle: () => void) => {
    toggle(true);
    setTimeout(() => {
      toggle(false);
    }, 2000);
  };
  const handleOnSuccessGetPlans = data => {
    const allPlansTemp = data?.data;
    // const filteredSimilarPlans=allPlansTemp?.filter(())
    const uniqueByPlanNamesTemp = Array.from(
      allPlansTemp
        .reduce((map, obj) => map.set(obj.title, obj), new Map())
        .values(),
    );
    const planNames = Array.from(
      allPlansTemp,
      // .reduce((map, obj) => map.set(obj.title, obj), new Map())
      // .values(),
    );
    // const allPlanNames = uniqueByPlanNames?.map(i => i?.title);
    // debugger;

    setUniqueByPlanNames(uniqueByPlanNamesTemp);
    const isActivePlan =
      planTaken.name?.toLowerCase() === 'novice' &&
      planTaken?.tenure?.toLowerCase() === tier?.toLowerCase();
    const isActivePlanAs =
      planTaken.name?.toLowerCase() === 'aspiring champions' &&
      planTaken?.tenure?.toLowerCase() === tier?.toLowerCase();
    const isActivePlanAl =
      planTaken.name?.toLowerCase() === 'aspiring learners' &&
      planTaken?.tenure?.toLowerCase() === tier?.toLowerCase();
    setApiPlanData(
      allPlansTemp?.sort(
        (prev, curr) => prev.subscriptionId - curr?.subscriptionId,
      ),
    );
    //   {
    //     "type": "text",
    //     "title": "Gameplay",
    //     "free": "Unlimited",
    //     "isFree": true,
    //     "aspiringLearners": "Unlimited",
    //     "isAspiringLearners": true,
    //     "aspiringChampions": "Unlimited",
    //     "isAspiringChampions": true
    // }
    const {data: tempTableDataForPlanDetails, headers: planTitles} =
      transformPlanDetailsData(uniqueByPlanNamesTemp);
    // debugger;
    const tempHeaders = [
      {
        key: 'title',
        label: '',
        flex: 1,
        render: (value, rowDetails) => {
          if (rowDetails.type !== 'text') {
            return null;
          }
          let tooltipText;
          switch (rowDetails.title) {
            case 'Games Assessment':
              tooltipText = 'Detailed Report Card of your Chess IQ';
              break;
            case 'Play against Caissa - Human like':
              tooltipText =
                'Practice any game/position against different ratings of our humanlike AI';
              break;
            case 'Parental Control':
              tooltipText =
                'Specific controls for parents to improve child discipline and behaviour';
              break;
            case 'Game Review':
              tooltipText = 'Propeitory game review to learn from your games';
              break;
            case 'Gameplay':
              tooltipText = 'Privacy first, next level gameplay';
              break;
            case 'Square trainer':
              tooltipText =
                'Practice how well you remember your chess square notations';
              break;
            case 'Resources':
              tooltipText = 'World Class Library of Chess courses';
              break;
            case 'Calculation trainer':
              tooltipText = 'Blindfold trainer to calculate moves ahead';
              break;
            case 'Workbook':
              tooltipText = 'Your scratchpad to store ideas and variations';
              break;
            case 'Intuition trainer':
              tooltipText =
                'Replay grandmaster games and try to guess their moves';
              break;
            case 'Play Curated positions':
              tooltipText =
                'Practice from custom positions in opening/middlegame/endgame';
              break;
            case 'Positions Trainer':
              tooltipText =
                'Train on positions from your games with extensive filters';
              break;
            case 'Whatsapp Assistant':
              tooltipText =
                'Handy tool to get chess updates and parental controls';
              break;
            case 'Onboarding Assessment':
              tooltipText = 'Your detailed Chess IQ report when you signup';
              break;
            case 'OTB game sync':
              tooltipText = 'Sync all your OTB (Offline) games and review them';
              break;
            case 'Custom Rewards':
              tooltipText = 'Parents can set custom rewards for their child';
              break;
          }
          return (
            <Box
              flexDirection="row"
              gap={10}
              justifyContent="flex-start"
              alignItems="center"
              style={{
                width: '100%',
                borderWidth: 1,
                borderColor: `${CCColors.TextColor.Dark}20`,
                margin: 0,
                padding: 10,
              }}>
              <Box
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 25,
                }}
                flexDirection="row"
                justifyContent="center"
                alignItems="center">
                <Tooltip
                  text={tooltipText}
                  children={toggle => {
                    return (
                      <Pressable
                        onPress={() => handlePress(toggle)}
                        onHoverIn={isMobileOrTab ? null : toggle}
                        onHoverOut={isMobileOrTab ? null : toggle}>
                        {rowDetails.title ? (
                          <MaterialCommunityIcons
                            name={'information-outline'}
                            color={CCColors.TextColor.Grey}
                            size={20}
                          />
                        ) : null}
                      </Pressable>
                      // <View
                      //   onPointerEnter={toggleEvent}
                      //   onPointerLeave={toggleEvent}>

                      // </View>
                    );
                  }}
                />
              </Box>
              <CCText
                color={CCColors.TextColor.Dark}
                fontSize={16}
                fontWeight="medium"
                showEllipses>
                {rowDetails.title}
              </CCText>
            </Box>
          );
        },
      },
      ...planTitles.map(planTitle => ({
        key: planTitle?.key,
        label: planTitle?.render,
        flex: 1,
        render: (value, rowDetails) => {
          if (rowDetails.type === 'button') {
            return (
              <Box
                style={[
                  styles.buttonCell,
                  {
                    backgroundColor:
                      columnSelected === planTitle
                        ? CCColors.Grey.BG500
                        : undefined,
                  },
                ]}>
                <CCButton
                  disabled={rowDetails[planTitle] === 'Choose Plan'}
                  onPress={() => setModalVisible(true)}
                  style={{
                    width: '100%',
                    marginVertical: 10,
                  }}>
                  <CCText style={{padding: 5}} showEllipses>
                    {rowDetails[planTitle]}
                  </CCText>
                </CCButton>
              </Box>
            );
          }
          return (
            <Box
              onPress={() => setColumnSelected(planTitle?.key)}
              flexDirection="row"
              gap={10}
              justifyContent="flex-start"
              alignItems="center"
              style={[
                styles.cellStyle,
                {
                  backgroundColor:
                    columnSelected === planTitle?.key
                      ? CCColors.Grey.BG500
                      : undefined,
                },
              ]}>
              <ConditionalRender
                condition={rowDetails[`is${planTitle?.key}`]}
                childrenA={<Box style={styles?.dummySpacer}></Box>}
                childrenB={<Box style={styles.dummySpacer}></Box>}
              />
              <CCText
                color={CCColors.TextColor.Primary}
                fontSize={14}
                style={{width: '100%'}}
                fontWeight="regular"
                showEllipses>
                {rowDetails[planTitle?.key]}
              </CCText>
            </Box>
          );
        },
      })),
    ];

    if (!isMobileOrTab) {
      tempTableDataForPlanDetails.push({
        type: 'text',
        title: '',
        Novice: (
          <Box
            style={{
              marginHorizontal: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}>
            <CCButton
              contentContainerStyle={{
                paddingVertical: 6,
                paddingHorizontal: 35,
              }}
              disabled={canSubscribe(1, subscribedPlanId) || isActivePlan}
              style={{
                backgroundColor:
                  tierRef.current === 'Free' ||
                  canSubscribe(1, subscribedPlanId) ||
                  isActivePlan
                    ? CCColors.LightYellow
                    : undefined,
              }}>
              {!isActivePlan && canSubscribe(1, subscribedPlanId)
                ? upgradePlan === 'Novice'
                  ? 'Current Plan'
                  : 'Select Another Plan'
                : !isActivePlan && 'Upgrade Plan'}

              {isActivePlan && 'Current Plan'}
            </CCButton>
          </Box>
        ),
        'Aspiring Champions': (
          <Box
            style={{
              marginHorizontal: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}>
            <CCButton
              contentContainerStyle={{
                paddingVertical: 6,
                paddingHorizontal: 35,
              }}
              onPress={() => {
                // store.setChoosenPlan(title?.toLowerCase());
                // store.setTenure(tenure);
                getPlan({
                  plan_type: 'PRO',
                  tenure: tierRef.current,
                });
              }}
              disabled={
                canSubscribe(
                  tierRef.current === 'yearly' ? 4 : 5,
                  subscribedPlanId,
                ) || isActivePlanAs
              }
              style={{
                backgroundColor:
                  canSubscribe(
                    tierRef.current === 'yearly' ? 4 : 5,
                    subscribedPlanId,
                  ) || isActivePlanAs
                    ? CCColors.LightYellow
                    : undefined,
              }}>
              {!isActivePlanAs &&
              canSubscribe(
                tierRef.current === 'yearly' ? 4 : 5,
                subscribedPlanId,
              )
                ? upgradePlan === 'Novice'
                  ? 'Current Plan'
                  : 'Select Another Plan'
                : !isActivePlanAs && 'Upgrade Plan'}
              {isActivePlanAs && 'Current Plan'}
            </CCButton>
          </Box>
        ),
        'Aspiring Learners': (
          <Box
            style={{
              marginHorizontal: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}>
            <CCButton
              contentContainerStyle={{
                paddingVertical: 6,
                paddingHorizontal: 35,
              }}
              onPress={() => {
                // store.setChoosenPlan(title?.toLowerCase());
                // store.setTenure(tenure);
                getPlan({
                  plan_type: 'casual',
                  tenure: tierRef.current,
                });
              }}
              disabled={
                canSubscribe(
                  tierRef.current === 'yearly' ? 2 : 3,
                  subscribedPlanId,
                ) || isActivePlanAl
              }
              style={{
                backgroundColor:
                  canSubscribe(
                    tierRef.current === 'yearly' ? 2 : 3,
                    subscribedPlanId,
                  ) || isActivePlanAl
                    ? CCColors.LightYellow
                    : undefined,
              }}>
              {!isActivePlanAl &&
              canSubscribe(
                tierRef.current === 'yearly' ? 2 : 3,
                subscribedPlanId,
              )
                ? upgradePlan === 'Novice'
                  ? 'Current Plan'
                  : 'Select Another Plan'
                : !isActivePlanAl && 'Upgrade Plan'}
              {isActivePlanAl && 'Current Plan'}
            </CCButton>
          </Box>
        ),
      });
    }

    setTableDataForPlanDetails({
      tableData: tempTableDataForPlanDetails,
      headers: tempHeaders,
    });
    // console.log(data?.data, uniqueByPlanNames, 'data?.data');
    // setPlans(data?.data);
  };

  const handleOnErrorGetPlans = error => {
    console.log('Error while fetching plans', error);
  };

  const {mutate: getAllPlans, isLoading: plansLoading} = useGetAllPlansApi(
    handleOnSuccessGetPlans,
    handleOnErrorGetPlans,
  );

  useEffect(() => {
    getAllPlans();
  }, []);

  const scrollToTier = tierRef => {
    if (tierRef.current) {
      tierRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    } else {
      console.error('Ref not set properly');
    }
  };

  const freePlanIcon = () => {
    return (
      <Box
        style={{
          width: 48,
          height: 48,
          backgroundColor: CCColors.Pink,
          borderRadius: 25,
        }}
        alignItems="center"
        justifyContent="center">
        <Entypo name="price-tag" size={24} color={CCColors.White} />
      </Box>
    );
  };
  const casualPlanIcon = () => {
    return (
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        style={{
          width: 48,
          height: 48,
          backgroundColor: CCColors.Green,
          borderRadius: 25,
        }}>
        <Image
          resizeMethod="resize"
          source={{uri: casualPlayer}}
          alt="aspiringLearners"
          style={{width: 24, height: 24}}
        />
      </Box>
    );
  };
  const tournamentPlanIcon = () => {
    return (
      <Box
        style={{
          width: 48,
          height: 48,
          backgroundColor: CCColors.Blue,
          borderRadius: 25,
        }}
        alignItems="center"
        justifyContent="center">
        <EvilIcons name="trophy" size={28} color={CCColors.White} />
      </Box>
    );
  };

  const planIconsToShow = [freePlanIcon, casualPlanIcon, tournamentPlanIcon];

  const freeComponent = () => {
    return (
      <Box
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          width: '100%',
          // paddingTop: 20,
          // paddingBottom: 10,
          paddingHorizontal: 10,
          paddingVertical: 10,
        }}>
        <Box
          flexDirection="row"
          alignItems="center"
          gap={10}
          style={{height: 40}}>
          <CCText fontWeight="semibold" fontSize={16}>
            Free
          </CCText>
          <ConditionalRender
            condition={activePlan.toLowerCase() === 'free'}
            childrenA={
              <CCText
                showEllipses
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 6,
                  marginTop: 5,
                  marginRight: 5,
                  backgroundColor: CCColors.Primary.lightBrown,
                  borderRadius: 4,
                }}
                textAlign="center"
                fontWeight="semibold"
                fontSize={14}
                color={CCColors.TextColor.Dark}>
                Current Plan
              </CCText>
            }
          />
        </Box>

        <CCText fontSize={14}>Plan for everyone!</CCText>
      </Box>
    );
  };

  const casualComponent = () => {
    return (
      <Box
        style={{
          width: '100%',
          // paddingTop: 20,
          // paddingBottom: 10,
          paddingHorizontal: 10,
          paddingVertical: 10,
        }}
        justifyContent="flex-start"
        alignItems="flex-start">
        <Box
          flexDirection="row"
          alignItems="center"
          gap={10}
          style={{height: 40}}>
          <CCText fontWeight="semibold" fontSize={16}>
            Aspiring Learners
          </CCText>
          <ConditionalRender
            condition={activePlan === 'aspiringLearners'}
            childrenA={
              <CCText
                showEllipses
                style={{
                  paddingHorizontal: 7,
                  paddingVertical: 2,
                  marginTop: 5,
                  marginRight: 5,
                  backgroundColor: CCColors.Primary.lightBrown,
                  borderRadius: 4,
                }}
                textAlign="center"
                fontWeight="semibold"
                fontSize={14}
                color={CCColors.TextColor.Dark}>
                Current Plan
              </CCText>
            }
            childrenB={
              <CCText
                showEllipses
                style={{
                  paddingHorizontal: 7,
                  paddingVertical: 2,
                  marginTop: 5,
                  marginRight: 5,
                  backgroundColor: CCColors.Red,
                  borderRadius: 4,
                }}
                textAlign="center"
                fontWeight="semibold"
                fontSize={14}
                color={CCColors.White}>
                Best Seller
              </CCText>
            }
          />
        </Box>
        <CCText fontSize={14}>If you aspire to be a great learner!</CCText>
      </Box>
    );
  };

  const tournamentComponent = () => {
    return (
      <Box
        style={{
          width: '100%',
          // paddingTop: 20,
          // paddingBottom: 10,
          paddingHorizontal: 10,
          paddingVertical: 10,
        }}
        justifyContent="flex-start"
        alignItems="flex-start">
        <Box
          style={{height: 40}}
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start">
          <CCText fontWeight="semibold" fontSize={16} showEllipses>
            Aspiring Champions
          </CCText>
          <ConditionalRender
            condition={activePlan === 'aspiringChampions'}
            childrenA={
              <CCText
                showEllipses
                style={{
                  paddingHorizontal: 12,
                  paddingVertical: 6,
                  marginTop: 5,
                  marginRight: 5,
                  backgroundColor: CCColors.Primary.lightBrown,
                  borderRadius: 4,
                }}
                textAlign="center"
                fontWeight="semibold"
                fontSize={14}
                color={CCColors.TextColor.Dark}>
                Active Plan
              </CCText>
            }
          />
        </Box>
        <CCText fontSize={14}>If you aspire to be a Chess Champion!</CCText>
      </Box>
    );
  };

  const handleOnSuccessPlan = data => {
    if (data?.data?.plan_id) {
      generatePayment({
        customer_id: dashboardData?.user?.user_id,
        mobile_number: dashboardData?.user?.mobile_number,
        email: dashboardData?.user?.email,
        subscription_plan_id: data?.data?.plan_id,
      });
    }
  };

  const handleOnErrorPlan = error => {
    console.log('error while fetching plan', error);
  };

  const {
    mutate: getPlan,
    data,
    error,
    isLoading,
  } = useGetPlanApi(handleOnSuccessPlan, handleOnErrorPlan);

  const handleOnSuccessGeneratePayment = data => {
    console.log('successfully generated payment link', data);
  };

  const handleOnErrorGeneratePayment = error => {
    console.log('error while generating payment', error);
  };

  const {
    mutate: generatePayment,
    data: paymentUrlData,
    error: paymentUrlDataError,
    isLoading: paymentLoading,
  } = useGeneratePaymentApi(
    handleOnSuccessGeneratePayment,
    handleOnErrorGeneratePayment,
  );

  useEffect(() => {
    if (isMobileOrTab) {
      if (paymentUrlData?.data?.content?.payment_links?.web) {
        Linking.openURL(paymentUrlData?.data?.content?.payment_links?.web);
      }
    } else {
      if (paymentUrlData?.data?.content?.payment_links?.web) {
        // @ts-ignore
        window.location.href =
          paymentUrlData?.data?.content?.payment_links?.web;
      }
    }
  }, [paymentUrlData]);

  if (isLoading || paymentLoading) {
    return <CCLoader loading={isLoading || paymentLoading} />;
  }
  const swapLastTwoValues = tableData => {
    // Guard clause for undefined or null input
    if (!tableData) return tableData;

    // Safely handle headers
    let headers = tableData.headers;
    if (Array.isArray(headers) && headers.length >= 2) {
      headers = [...headers];
      const lastIndex = headers.length - 1;
      [headers[lastIndex], headers[lastIndex - 1]] = [
        headers[lastIndex - 1],
        headers[lastIndex],
      ];
    }

    // Safely handle table data
    let newTableData = tableData.tableData;
    if (Array.isArray(newTableData)) {
      newTableData = newTableData.map(row => {
        if (Array.isArray(row) && row.length >= 2) {
          const newRow = [...row];
          const lastIndex = newRow.length - 1;
          [newRow[lastIndex], newRow[lastIndex - 1]] = [
            newRow[lastIndex - 1],
            newRow[lastIndex],
          ];
          return newRow;
        }
        return row;
      });
    }

    return {
      ...tableData,
      headers: headers,
      tableData: newTableData,
    };
  };
  const updatedTableData = swapLastTwoValues(tableDataForPlanDetails);
  const headerKeys = updatedTableData?.headers?.map(header => header?.key);
  return (
    <Box style={{backgroundColor: CCColors.Primary.lightBrown}}>
      <CCText
        fontSize={24}
        textAlign="center"
        color={CCColors.TextColor.Dark}
        fontWeight="medium"
        style={{marginBottom: 10, marginTop: 40}}>
        Plans & Pricing
      </CCText>

      <Box justifyContent="center" alignItems="center">
        <CCText
          fontWeight="regular"
          style={{lineHeight: 24, width: !isMobileOrTab ? '30%' : '90%'}}
          color={CCColors.Primary.Brown}
          fontSize={!isMobileOrTab ? 16 : 14}
          textAlign="center">
          Discover the ideal chess plan for you, whether you're a beginner,
          intermediate, or seasoned pro
        </CCText>
      </Box>
      {planTaken?.planEndDate && planTaken?.subscriptionId > 1 && (
        <CCText
          fontSize={FontSizes[14]}
          textAlign="center"
          color={CCColors?.Red}>
          Plan expiring on: {formatDate(planTaken?.planEndDate)}
        </CCText>
      )}
      <Box
        flexDirection="row"
        alignContent="center"
        justifyContent="center"
        marginTop={20}>
        <Pressable
          onPress={() => {
            setCasualMoney(299);
            setTournamentMoney(499);
            setTier('monthly');
          }}>
          <CCText
            style={{
              backgroundColor:
                tier === 'monthly'
                  ? CCColors.TextColor.Primary
                  : CCColors.White,
              paddingVertical: 11.5,
              paddingHorizontal: 20,
              borderBottomLeftRadius: 8,
              borderTopLeftRadius: 8,
            }}
            color={
              tier === 'monthly' ? CCColors.White : CCColors.TextColor.Dark
            }
            fontSize={16}
            fontWeight="medium">
            Pay Monthly
          </CCText>
        </Pressable>
        <Box
          style={{
            backgroundColor:
              tier === 'yearly' ? CCColors.TextColor.Primary : CCColors.White,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
          }}
          flexDirection="row"
          alignItems="center"
          justifyContent="center">
          <Pressable
            onPress={() => {
              setTier('yearly');
              setCasualMoney(2999);
              setTournamentMoney(4999);
            }}>
            <CCText
              color={
                tier === 'yearly' ? CCColors.White : CCColors.TextColor.Dark
              }
              fontSize={16}
              fontWeight="medium"
              style={{
                paddingVertical: 11.5,
                paddingHorizontal: 24,
              }}>
              Pay Yearly
            </CCText>
          </Pressable>
        </Box>
      </Box>

      <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
        <Box
          style={{
            paddingHorizontal: isMobileOrTab ? 20 : 0,
            marginTop: !isMobileOrTab ? 60 : 40,
            paddingTop: 30,
            overflowX: isMobileOrTab && 'auto',
            overflowY: isMobileOrTab && 'hidden',
            WebkitOverflowScrolling: isMobileOrTab && 'touch', // For smooth scrolling on iOS
            scrollBehavior: isMobileOrTab && 'smooth',
            paddingBottom: isMobileOrTab ? 20 : 0,
          }}
          flexDirection="row"
          justifyContent={!isMobileOrTab ? 'center' : 'flex-start'}
          alignItems="center"
          overflow="visible"
          gap={!isMobileOrTab ? 20 : 40}>
          {apiPlanData
            ?.filter(
              i =>
                i?.type?.toLowerCase() === tier?.toLowerCase() ||
                i?.type?.toLowerCase() === 'lifetime',
            )
            ?.map((eachPlanDetails, index) => (
              <SubscriptionComponent
                index={index}
                user={dashboardData?.user}
                planId={eachPlanDetails?.subscriptionId}
                subscribedPlanId={planTaken?.subscriptionId}
                description={eachPlanDetails?.description}
                title={eachPlanDetails?.title}
                // selected={selectedSubscription === eachPlanDetails?.title}
                isActivePlan={
                  planTaken.name?.toLowerCase() ===
                    eachPlanDetails?.title?.toLowerCase() &&
                  planTaken?.tenure?.toLowerCase() === tier?.toLowerCase()
                }
                canBeActivePlan={
                  planTaken.name?.toLowerCase() ===
                    eachPlanDetails?.title?.toLowerCase() &&
                  ((planTaken?.tenure?.toLowerCase() === 'monthly' &&
                    tier?.toLowerCase() === 'yearly') ||
                    (planTaken?.tenure?.toLowerCase() === 'yearly' &&
                      tier?.toLowerCase() === 'monthly'))
                }
                userPlan={userPlanDetails}
                isBestSeller={eachPlanDetails?.title === 'Aspiring Learners'}
                listData={eachPlanDetails?.features?.map(
                  item => `${item?.name} - ${item?.value}`,
                )}
                getIcon={planIconsToShow[index]}
                actualPrice={formatCurrency(
                  eachPlanDetails?.mrp,
                  eachPlanDetails?.currency,
                )}
                checkBoxTickColor={CCColors.Pink}
                checkboxColor={`${CCColors.Pink}16`}
                monthlyPrice={formatCurrency(
                  eachPlanDetails?.price,
                  eachPlanDetails?.currency,
                )}
                monthlyMrp={formatCurrency(
                  eachPlanDetails?.mrp,
                  eachPlanDetails?.currency,
                )}
                yearlyPrice={formatCurrency(
                  eachPlanDetails?.price,
                  eachPlanDetails?.currency,
                )}
                yearlyMrp={formatCurrency(
                  eachPlanDetails?.mrp,
                  eachPlanDetails?.currency,
                )}
                // discountPrice={eachPlanDetails?.price}
                tier={
                  eachPlanDetails?.title?.toLowerCase() === 'novice'
                    ? 'Free'
                    : tier === 'monthly'
                    ? 'Monthly'
                    : 'Yearly'
                }
                onSelect={() => setSelectedSubscription(eachPlanDetails?.title)}
                onViewAllFeatures={() => {
                  setColumnSelected(eachPlanDetails?.title);
                  scrollToTier(ccTableRef);
                }}
              />
            ))}
          {/* <SubscriptionComponent
            user={dashboardData?.user}
            description="Perfect if you’re just getting started with your chess journey"
            title="Free"
            selected={selectedSubscription === 'free'}
            isActivePlan={activePlan.toLowerCase() === 'free'}
            listData={freePlayerList}
            getIcon={freePlanIcon}
            checkBoxTickColor={CCColors.Pink}
            checkboxColor={`${CCColors.Pink}16`}
            discountPrice={0}
            tier="Free"
            onSelect={() => setSelectedSubscription('free')}
            onViewAllFeatures={() => {
              setColumnSelected('free');
              scrollToTier(ccTableRef);
            }}
          />
          <SubscriptionComponent
            isActivePlan={activePlan === 'aspiringLearners'}
            user={dashboardData?.user}
            description="Learning-oriented plan where you can set rules & daily gaming limit"
            title="aspiringLearners"
            isBestSeller={true}
            selected={selectedSubscription === 'aspiringLearners'}
            listData={casualPlayerList}
            getIcon={casualPlanIcon}
            checkBoxTickColor={CCColors.Green}
            checkboxColor={CCColors.White}
            actualPrice={2 * casualMoney + 1}
            discountPrice={casualMoney}
            tier={tier === 'monthly' ? 'Monthly' : 'Yearly'}
            onSelect={() => setSelectedSubscription('aspiringLearners')}
            onViewAllFeatures={() => {
              setColumnSelected('aspiringLearners');
              scrollToTier(ccTableRef);
            }}
          />
          <SubscriptionComponent
            isActivePlan={activePlan === 'aspiringChampions'}
            user={dashboardData?.user}
            description="Learning-oriented plan where you can set rules & daily gaming limit"
            title="aspiringChampions"
            selected={selectedSubscription === 'aspiringChampions'}
            listData={tournamentPlayerList}
            getIcon={tournamentPlanIcon}
            checkBoxTickColor={CCColors.Blue}
            checkboxColor={`${CCColors.Blue}16`}
            actualPrice={2 * tournamentMoney + 1}
            discountPrice={tournamentMoney}
            tier={tier === 'monthly' ? 'Monthly' : 'Yearly'}
            onSelect={() => setSelectedSubscription('aspiringChampions')}
            onViewAllFeatures={() => {
              setColumnSelected('aspiringChampions');
              scrollToTier(ccTableRef);
            }}
          /> */}
        </Box>

        <>
          <CCText
            style={{marginTop: 60, marginBottom: 30}}
            fontSize={24}
            fontWeight="medium"
            textAlign="center"
            color={CCColors.TextColor.Dark}>
            Compare Plans
          </CCText>

          <Box style={styles.table}>
            <View ref={ccTableRef}>
              {console.log(tableDataForPlanDetails, 'tableDataForPlanDetails')}

              {!isMobileOrTab ? (
                tableDataForPlanDetails?.tableData?.length && (
                  <CCTable
                    displayLimit={tableDataForPlanDetails?.tableData?.length}
                    headers={updatedTableData?.headers}
                    data={updatedTableData?.tableData}
                    enableBorderStyle={true}
                    orangeBorderColumnIndex={
                      planTaken?.name === 'Aspiring Champions' ? -1 : 2
                    }
                    headerCellColor={CCColors.White}
                    BestSellerTag={
                      <View style={styles.bestSellerWrapper}>
                        <Text style={styles.bestSellerText}>Best seller</Text>
                      </View>
                    }
                    ActivePlanTagIndex={
                      (planTaken?.name === 'Novice' ||
                        tier === planTaken?.tenure?.toLowerCase()) &&
                      headerKeys?.indexOf(planTaken?.name)
                    }
                    ActivePlanTag={
                      <View style={styles.activeWrapper}>
                        <CCText style={styles.activeText}>Active Plan</CCText>
                      </View>
                    }
                  />
                )
              ) : (
                <Box flexDirection="column" style={{width: '100%'}}>
                  <CompareTableMobile
                    displayLimit={tableDataForPlanDetails?.tableData?.length}
                    headers={tableDataForPlanDetails?.headers}
                    data={tableDataForPlanDetails?.tableData}
                    tier={tier}
                  />
                  <Box
                    flexDirection="row"
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    {apiPlanData
                      ?.filter(
                        i =>
                          i?.type?.toLowerCase() === tier?.toLowerCase() ||
                          i?.type?.toLowerCase() === 'lifetime',
                      )
                      ?.map((eachPlanDetails, index) => (
                        <PaymentButton
                          index={index}
                          user={dashboardData?.user}
                          planId={eachPlanDetails?.subscriptionId}
                          subscribedPlanId={planTaken?.subscriptionId}
                          isActivePlan={
                            planTaken.name?.toLowerCase() ===
                              eachPlanDetails?.title?.toLowerCase() &&
                            planTaken?.tenure?.toLowerCase() ===
                              tier?.toLowerCase()
                          }
                          title={eachPlanDetails?.title}
                          tier={
                            eachPlanDetails?.title?.toLowerCase() === 'novice'
                              ? 'Free'
                              : tier === 'monthly'
                              ? 'Monthly'
                              : 'Yearly'
                          }
                          userPlan={userPlanDetails}
                        />
                      ))}
                  </Box>
                </Box>
              )}
            </View>
          </Box>
        </>
      </ScrollView>

      <Box style={styles.faq}>
        <FAQ />
      </Box>
    </Box>
  );
};

export default Subscription;
