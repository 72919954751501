// import necessary modules
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  Switch,
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
} from 'react-native';
import StockfishEngine from '.';
import {styles} from './styles';
import Entypo from 'react-native-vector-icons/Entypo';
import SlideModal from '@/Atoms/SlideModal';
import CCButton from '@/Atoms/CCButton';
import Slider from '@react-native-community/slider';
import ConditionalRender from '@/Atoms/ConditionalRender';
import customStyles from '@/Screens/Games/styles';

type StockfishComponentProps = {
  fen: string;
  depth?: number;
  variations?: number;
  engineOn?: boolean;
  onResult?: (a: any, b: any) => void;
  count?: number;
  customStyle?: StyleProp<ViewStyle>;
};

// Define the renderMoves function
const renderMoves = (moves, baseTurn, score, parentCount = 0) => {
  let count = parentCount;
  const elements: any = [];
  elements.push(
    <React.Fragment key="0">
      {score !== undefined && <CCText fontWeight="semibold">{score}</CCText>}
      <Spacer spacing={5} horizontal />
    </React.Fragment>,
  );
  if (baseTurn === 'b') {
    count = count - 1;
    if (moves?.length > 0) {
      elements.push(
        <React.Fragment key="0">
          <CCText>{++count}. ... </CCText>
          <CCText>{moves?.[0]}</CCText>
          <Spacer spacing={5} horizontal />
        </React.Fragment>,
      );
    }
    for (let i = 1; i < moves?.length; i += 2) {
      elements.push(
        <React.Fragment key={i}>
          <CCText>
            {`${++count}. `}
            {moves?.[i]}
          </CCText>
          {i + 1 < moves?.length && (
            <>
              <Spacer spacing={5} horizontal />
              <CCText>{moves?.[i + 1]}</CCText>
            </>
          )}
          <Spacer spacing={5} horizontal />
        </React.Fragment>,
      );
    }
  } else {
    for (let i = 0; i < moves?.length; i += 2) {
      elements.push(
        <React.Fragment key={i}>
          <CCText>
            {`${++count}. `}
            {moves?.[i]}
          </CCText>
          {i + 1 < moves?.length && (
            <>
              <Spacer spacing={5} horizontal />
              <CCText>{moves?.[i + 1]}</CCText>
            </>
          )}
          <Spacer spacing={5} horizontal />
        </React.Fragment>,
      );
    }
  }
  return elements;
};

// Define the MoveSequencesDisplay component
const MoveSequencesDisplay = ({moveSequences, count, baseTurnProp}) => {
  const [showFull, setShowFull] = useState({});

  useEffect(() => {
    if (moveSequences) {
      setShowFull(
        Object.keys(moveSequences).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {}),
      );
    }
  }, [moveSequences]);

  const sortedEntries = useMemo(
    () =>
      Object.entries(moveSequences).sort((a, b) => {
        const scoreA = a[1]?.score;
        const scoreB = b[1]?.score;

        // Check if scores are mate indicators (e.g., "M1#", "M2#") and prioritize mates
        const isMateA = typeof scoreA === 'string' && scoreA.startsWith('M');
        const isMateB = typeof scoreB === 'string' && scoreB.startsWith('M');

        if (isMateA && isMateB) {
          if (baseTurnProp === 'w') {
            // For Black, sort mates in descending order (M3# before M2#)
            return parseInt(scoreB.slice(1)) - parseInt(scoreA.slice(1));
          } else {
            // For White, sort mates in ascending order (M1# before M2#)
            return parseInt(scoreA.slice(1)) - parseInt(scoreB.slice(1));
          }
        } else if (isMateA) {
          return -1;
        } else if (isMateB) {
          return 1;
        } else if (scoreA !== undefined && scoreB !== undefined) {
          return baseTurnProp === 'b' ? scoreA - scoreB : scoreB - scoreA;
        }

        return 0;
      }),
    [baseTurnProp, moveSequences],
  );

  return (
    <View>
      {sortedEntries.map(([variationNumber, {moves, baseTurn, score}]) => (
        <Box
          key={variationNumber}
          style={[score !== undefined ? styles.eachVariation : {}]}>
          <CCText
            {...(showFull?.[variationNumber] ? {} : {numberOfLines: 1})}
            style={showFull?.[variationNumber] ? {} : {flex: 0.89}}>
            {renderMoves(moves, baseTurn, score, count)}
          </CCText>
          {!showFull?.[variationNumber] && score !== undefined && (
            <Box type="row-flex-end" style={{flex: 0.1}}>
              <Entypo
                name="chevron-small-down"
                size={20}
                color={CCColors?.Black}
                onPress={() =>
                  setShowFull(prev => ({...prev, [variationNumber]: true}))
                }
              />
            </Box>
          )}
        </Box>
      ))}
    </View>
  );
};

// Define the StockfishComponent
const StockfishComponent = ({
  fen,
  depth = 30,
  variations = 3,
  count,
  engineOn,
  onResult,
  customStyle,
}: StockfishComponentProps) => {
  const [enableStockfish, setEnableStockfish] = useState(false);
  const [moveSequences, setMoveSequences] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [settings, setSettings] = useState({depth: 30, variations: 3});
  const [currentVariations, setCurrentVariations] = useState(variations);
  const [currentDepth, setCurrentDepth] = useState(depth);
  const [baseTurnState, setBaseTurnState] = useState('w');
  const scoreRegex = /score (cp|mate) ([\-+]?[0-9]+)/;

  const handleStockfishResult = (
    variationNumber,
    moves,
    baseTurnTemp,
    extractedScore,
  ) => {
    let sanMovesOnly = moves?.splice(moves?.indexOf('pvSan') + 1);
    setBaseTurnState(baseTurnTemp);

    let displayScore;
    if (event?.data?.includes('score mate')) {
      const mateMatch = event?.data?.match(/score mate ([\-+]?[0-9]+)/);
      if (mateMatch) {
        const mateMoves = parseInt(mateMatch[1], 10);
        displayScore = `M${Math.abs(mateMoves)}#`;
      }
    } else {
      displayScore = (Number(extractedScore) / 100).toFixed(2);
    }

    if (baseTurnTemp === 'b' && !event?.data?.includes('score mate')) {
      extractedScore = extractedScore * -1;
      displayScore = (Number(extractedScore) / 100).toFixed(2);
    }

    setMoveSequences(prev => {
      const newResult = {
        ...prev,
        [variationNumber]: {
          moves: sanMovesOnly.splice(0, sanMovesOnly?.indexOf('bmc')),
          score: displayScore,
          baseTurn: baseTurnTemp,
        },
        baseTurn: baseTurnTemp,
      };
      return newResult;
    });
  };

  const handleSaveSettings = () => {
    setMoveSequences({});
    setIsModalVisible(false);
    setSettings({depth: currentDepth, variations: currentVariations});
    // Apply the settings to Stockfish
  };

  return (
    <>
      <Box
        type="row-center-between"
        style={[
          styles.switchBox,
          customStyle,
          enableStockfish
            ? {backgroundColor: updateColorWithOpacity(CCColors.Green, 0.3)}
            : {
                backgroundColor: updateColorWithOpacity(CCColors.Error, 0.3),
              },
        ]}>
        <Box type="row-flex-start">
          <Switch
            trackColor={{
              false: updateColorWithOpacity(CCColors.Error, 0.3),
              true: updateColorWithOpacity(CCColors.Green, 0.3),
            }}
            thumbColor={enableStockfish ? CCColors.Green : CCColors.Error}
            onValueChange={() => setEnableStockfish(!enableStockfish)}
            value={enableStockfish}
          />
          <Spacer spacing={10} horizontal />
          <CCText color={enableStockfish ? CCColors?.Green : CCColors?.Error}>
            Evaluation is {enableStockfish ? 'on' : 'off'}
          </CCText>
        </Box>
        <Spacer spacing={10} horizontal />
        <Box>
          <TouchableOpacity onPress={() => setIsModalVisible(true)}>
            <Entypo name="cog" size={24} color={CCColors.Black} />
          </TouchableOpacity>
        </Box>
      </Box>
      {!enableStockfish && <Spacer spacing={5} />}
      <StockfishEngine
        fen={fen}
        depth={settings.depth}
        variations={settings.variations}
        engineOn={enableStockfish}
        onResult={handleStockfishResult}
      />
      <ConditionalRender
        condition={enableStockfish}
        childrenA={
          <MoveSequencesDisplay
            moveSequences={moveSequences}
            count={count}
            baseTurnProp={baseTurnState}
          />
        }
      />

      <SlideModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        modalHeaderTitle="Evaluation Settings">
        <Box>
          <CCText>Depth ({currentDepth}/37)</CCText>
          <Slider
            minimumValue={1}
            maximumValue={37}
            step={1}
            value={currentDepth}
            onValueChange={value => setCurrentDepth(value)}
          />
          <CCText>Variations ({currentVariations}/5)</CCText>
          <Slider
            minimumValue={1}
            maximumValue={5}
            step={1}
            value={currentVariations}
            onValueChange={value => setCurrentVariations(value)}
          />
          <Spacer spacing={20} />
          <CCButton onPress={handleSaveSettings}>Save</CCButton>
        </Box>
      </SlideModal>
    </>
  );
};

function sortGamesByScore(games) {
  if (games.baseTurn === 'b') {
    // Extract the games excluding the baseTurn key
    let gameEntries = Object.entries(games).filter(
      ([key, value]) => key !== 'baseTurn',
    );

    // Sort the games by score
    gameEntries.sort((a, b) => a[1].score - b[1].score);

    // Reconstruct the sorted object
    let sortedGames = {baseTurn: games.baseTurn};
    gameEntries.forEach(([key, value]) => {
      sortedGames[key] = value;
    });

    return sortedGames;
  } else {
    return games;
  }
}

export default StockfishComponent;
