import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import SlideModal from '@/Atoms/SlideModal';
import {FontSizes} from '@/Utils/Dimensions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {View, Image} from 'react-native';
import chevronDoubleDown from '../../../../Assets/Images/Svgs/chevronDoubleDown.svg';
import chevronDoubleUp from '../../../../Assets/Images/Svgs/chevronDoubleUp.svg';
import {faEquals} from '@fortawesome/free-solid-svg-icons';
import CCColors from '@/Utils/CCColors';
import challengeIcon from '../../../../Assets/Images/Svgs/challengehover.svg';
import message from '../../../../Assets/Images/Svgs/message.svg';
import points from '../../../../Assets/Images/Svgs/points.svg';
import {renderStatusIcon} from './ccLeagueUtils';
import Divider from '@/Atoms/Divider/Divider';

const ChallengeModal = ({
  openChallenge,
  handleCloseChallenge,
  isMobileOrTab,
  standing,
  hoveredRow,
  index,
  challengePlayer,
}) => {
  return (
    <SlideModal
      type="center"
      contentPadding={0}
      visible={openChallenge}
      onClose={handleCloseChallenge}
      customStyle={{minWidth: '40%'}}
      customMobileStyle={{
        borderRadius: !isMobileOrTab && 12,

        backgroundColor: 'white',
        zIndex: 5,
        // flex: 1,
        // maxHeight: "80%", // Set a maximum height
        // overflowY: 'auto',
        overflow: 'hidden', // Use overflow hidden instead of overflowY
        bottom: 0,
        width: '100%', // Ensure full-width modal
        maxHeight: '80%', // Prevent it from occupying the entire screen
        position: 'absolute',
        ...(isMobileOrTab && {
          borderTopLeftRadius: 12, // Top-left border radius only for mobile
          borderTopRightRadius: 12, // Top-right border radius only for mobile
        }),
      }}
      customMargin={0}
      modalHeaderTitle={''}>
      <Box
        flexDirection="column"
        justifyContent="center"
        style={{
          width: '100%',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingBottom: 20,
        }}>
        <Box
          style={{
            position: 'relative',
            height: 45,
            width: 45,
            zIndex: -1,
            paddingBottom: 20,
          }}>
          <Image
            source={standing.profile}
            style={{
              height: 45,
              width: 45,
            }}
          />

          <Box
            style={{
              position: 'absolute',
              zIndex: 100,
              bottom: 0,
              right: 0,
            }}>
            {renderStatusIcon(standing.status)}
          </Box>
        </Box>
        <Box
          flexDirection="column"
          gap={2}
          style={{
            paddingVertical: 10,
            marginHorizontal: 'auto',
          }}>
          <CCText fontSize={FontSizes[24]} fontWeight={500}>
            {index + 1}. {standing.player_name}
          </CCText>
        </Box>
        <Box type="row-center" gap={10}>
          {standing.fide_rating > 0 && (
            <>
              <Box
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 5,
                }}>
                <CCText
                  color={CCColors.Grey.Grey}
                  fontWeight={300}
                  fontSize={FontSizes[8]}>
                  FIDE RATING
                </CCText>
                <CCText
                  color={CCColors.Grey.Grey}
                  fontWeight={500}
                  fontSize={FontSizes[10]}>
                  {standing.fide_rating}
                </CCText>
              </Box>
              <Divider isVertical style={{height: 30}} />
            </>
          )}
          <Box
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingHorizontal: 5,
            }}>
            <CCText
              color={CCColors.Grey.Grey}
              fontWeight={300}
              fontSize={FontSizes[8]}>
              CC RATING
            </CCText>
            <CCText
              color={CCColors.Grey.Grey}
              fontWeight={500}
              fontSize={FontSizes[10]}>
              {standing.rating}
            </CCText>
          </Box>
        </Box>
        <Box margin={10} type="row-center-between" gap={10}>
          <Image source={points} style={{height: 20, width: 20}} />
          <CCText
            color={CCColors.Rewards.TextGreen}
            fontWeight={400}
            fontSize={FontSizes[12]}>
            {standing.net_score}
          </CCText>
        </Box>
        <Box
          gap={20}
          style={{
            flexDirection: 'row',
            // justifyContent: 'space-between',
          }}>
          <CCButton
            onPress={challengePlayer}
            contentContainerStyle={{
              paddingHorizontal: 15,
            }}>
            <Box flexDirection="row" gap={8}>
              <Image
                source={challengeIcon}
                style={{
                  height: 16,
                  width: 16,
                  paddingRight: 8,
                }}
              />
              <CCText fontWeight={500} fontSize={FontSizes[14]}>
                Challenge
              </CCText>
            </Box>
          </CCButton>
          {/* <CCButton
            style={{
              backgroundColor: CCColors.Primary.lightBrown,
            }}
            contentContainerStyle={{
              paddingHorizontal: 15,
            }}>
            <Box flexDirection="row" gap={8}>
              <Image
                source={message}
                style={{
                  height: 16,
                  width: 16,
                  paddingRight: 8,
                }}
              />
              <CCText fontWeight={500} fontSize={FontSizes[14]}>
                Chat
              </CCText>
            </Box>
          </CCButton> */}
        </Box>
      </Box>
    </SlideModal>
  );
};

export default ChallengeModal;
